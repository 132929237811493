import React, { useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { ACCESS_CONTROL_STATUSES, SELECT_VALUE_TYPE } from 'constants/enums'
import ICONS from 'constants/icons'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Dropdown from 'components/Dropdown'
import RadioGroup from 'components/RadioGroup'
import Icon from 'components/Icon'

const {
    NEW,
    REJECTED,
    GRANTED_WITHOUT_FILES,
    GRANTED_WITH_FILES,
    REQUESTED_AGAIN,
} = ACCESS_CONTROL_STATUSES

const ChangeAccessAction = ({
    handleChangeAccess,
    open,
    setOpen,
    item,
    hasDataAccess,
}) => {
    const t = useTranslate()

    const { id, status } = item

    const handleChange = (value) => {
        if (!hasDataAccess) return

        handleChangeAccess(value)
        setOpen(false)
    }

    const handleOpen = () => {
        if (!hasDataAccess) return
        setOpen(!open)
    }

    const newRequestOptions = [
        { id: GRANTED_WITHOUT_FILES.id, name: 'Without files' },
        { id: GRANTED_WITH_FILES.id, name: 'With files' },
    ]

    const allRequestOptions = [
        ...newRequestOptions,
        {
            id: REJECTED.id,
            name: 'Withdraw access',
        },
    ]

    const noAccess = [NEW.id, REJECTED.id, REQUESTED_AGAIN.id].includes(status)

    const { statusTitle, statusIcon } = useMemo(() => {
        return noAccess
            ? {
                  statusTitle: t('general.grantAccess'),
                  statusIcon: ICONS.GRANT_ACCESS,
              }
            : {
                  statusTitle: t('general.changeAccess'),
                  statusIcon: ICONS.CHANGE_ACCESS,
              }
    }, [status])

    return (
        <div className="a-access">
            <div className={`a-access__select`} onClick={handleOpen}>
                <Icon name={statusIcon} />
                {statusTitle}
                <span className="m-selectGroup__arrow"></span>
            </div>

            <Dropdown open={open}>
                <RadioGroup
                    name={`access-${id}`}
                    showLabel={false}
                    defaultOptions={
                        noAccess ? newRequestOptions : allRequestOptions
                    }
                    value={status}
                    setValue={handleChange}
                    valueType={SELECT_VALUE_TYPE.STRING}
                    reload={status}
                />
            </Dropdown>
        </div>
    )
}

ChangeAccessAction.propTypes = {
    handleChangeAccess: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    item: PropTypes.object.isRequired,
    hasDataAccess: PropTypes.bool,
}

export default clickOutsideHOC(ChangeAccessAction)
