import React from 'react'

import { CHANNEL_TYPE_CHANNEL } from 'constants/chat'
import ICONS from 'constants/icons'

import { getChannelUserId, getIsOnline } from './ChatComponent'
import ChatUserSearch from './ChatUserSearch'

const CustomUserListItem = ({
    channel,
    id,
    displayTitle,
    displayImage,
    unread,
    currentUser,
    client,
    channelUsers,
    activeChannel,
    activateChannelByUser,
    handleDeleteChannel,
}) => {
    if (channel?.data?.channelType === CHANNEL_TYPE_CHANNEL) return

    if (channel?.state?.messageSets[0].messages.length < 1) return

    // NOTE:: If there is an 'id' then the results are listed inside the search dropdown
    // and the online status and delete option should not be displayed there
    const userId = id || getChannelUserId(channel, currentUser)
    const online = id ? false : getIsOnline(userId, client, channelUsers)

    return (
        <ChatUserSearch
            user={{
                id: userId,
                name: displayTitle,
                image: displayImage,
                channel: channel,
                online: online,
            }}
            unreadCount={unread}
            handleClick={(channel) => activateChannelByUser(channel)}
            activeChannel={activeChannel}
            channel={channel}
            actions={
                id
                    ? []
                    : [
                          {
                              icon: ICONS.DELETE,
                              handleAction: handleDeleteChannel,
                              tooltip: 'chat.deleteDirectMessage',
                          },
                      ]
            }
        />
    )
}

export default CustomUserListItem
