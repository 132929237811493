import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import Icon from 'components/Icon'
import COLORS from 'constants/colors'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'
import ICONS from 'constants/icons'

import IconButton from 'components/IconButton'
import Input from 'components/Input'
import ChatUserSearch from 'components/chat/ChatUserSearch'
import Button from 'components/Button'

const EditChannel = ({ activeChannel, setEditChannel, createdByMe }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const { setAlert } = useContext(AlertContext)
    const { chatClient: client, currentUser } = useContext(CurrentUserContext)

    const [searchingUsers, setSearchingUsers] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [channelName, setChannelName] = useState(
        activeChannel?.data?.name?.replaceAll('_', ' ')
    )
    const [members, setMembers] = useState(
        Object.values(activeChannel.state.members).map(({ user }) => user)
    )

    const handleChannelName = async ({ target: { value } }) => {
        setChannelName(value)
    }

    const handleSearch = async (value) => {
        setSearchValue(value)

        if (!value) {
            setSearchingUsers([])
            return
        }

        const { users } = await client.queryUsers({
            name: { $autocomplete: value },
        })
        setSearchingUsers(users)
    }

    const handleRemoveMembers = async (user) => {
        setMembers(members.filter((u) => u.id !== user?.id))
    }

    const handleAddNewMembers = async (user) => {
        if (!members.some((u) => u.id === user.id)) {
            setMembers([...members, user])
            setSearchingUsers([])
            setSearchValue('')
        } else {
            setAlert(t('message.userIsAlreadyAdded'), ALERT_TYPES.WARNING)
        }
    }

    const saveChannel = async () => {
        try {
            const initialChannelUsers = Object.values(
                activeChannel.state.members
            ).map(({ user }) => user)

            const newUsers = members.filter(
                (user) =>
                    !initialChannelUsers.some((item) => item.id === user.id)
            )

            const deleteUsers = initialChannelUsers.filter(
                (user) => !members.some((item) => item.id === user.id)
            )

            const filters = {
                type: 'team',
                members: { $in: [currentUser.id.toString() || ''] },
            }

            const existingChannel = await client.queryChannels(filters)

            let existingChannelArray = []

            existingChannelArray = existingChannel.filter(
                (item) =>
                    item.data.name == channelName &&
                    item.data.name !== activateChannelName
            )

            if (!!existingChannelArray.length) {
                setAlert(t('chat.channelNameExist'), ALERT_TYPES.WARNING)
                return
            } else {
                await activeChannel.updatePartial({
                    set: { name: channelName },
                })
            }

            if (!!deleteUsers.length) {
                await activeChannel.removeMembers([
                    ...deleteUsers.map((u) => u.id?.toString()),
                ])
            }

            if (!!newUsers.length) {
                await activeChannel.addMembers([
                    ...newUsers.map((u) => u.id?.toString()),
                ])
            }

            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)

            setEditChannel(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const activateChannelName = activeChannel?.data?.name?.replaceAll('_', ' ')

    const handleBackButton = () => {
        setEditChannel(false)
    }

    return (
        <Fragment>
            <div className="str-chat__channel-header -channels aligned-center">
                {isTablet && (
                    <div className="-backBtn">
                        <IconButton
                            icon={ICONS.ARROW_LEFT}
                            iconColor={COLORS.DARK_BLUE}
                            onClick={handleBackButton}
                        />
                    </div>
                )}
                <span className="a-bodyTextMedium">
                    {t(
                        `chat.${createdByMe ? 'editChannel' : 'channelDetails'}`
                    )}
                </span>
            </div>
            <div className={'add-chanel-wrapper -edit'}>
                <div>
                    <div>
                        <Input
                            name="channelName"
                            label={'form.label.channelName'}
                            placeholder="form.placeholder.channelName"
                            onChange={handleChannelName}
                            defaultValue={activateChannelName}
                            disabled={!createdByMe}
                        />
                    </div>
                    <div className="-mt30 column">
                        <span className="a-bodyTextMedium -mb10">
                            {t('chat.channelMembers')}
                        </span>
                        {members.map((user) => {
                            return (
                                <div
                                    className={`chat-users-search-result ${
                                        !createdByMe ? '-noHover' : ''
                                    }`}
                                    key={user?.id}
                                >
                                    <div className="result-wrapper">
                                        <div className="-resultImg">
                                            {user?.image ? (
                                                <img
                                                    alt=""
                                                    src={`${user.image}`}
                                                />
                                            ) : (
                                                <span className="m-avatar__icon">
                                                    <Icon
                                                        name={ICONS.USER}
                                                        color={
                                                            COLORS.DARK_BLUE_40
                                                        }
                                                        size={ICON_SIZE.SIZE18}
                                                    />
                                                </span>
                                            )}
                                        </div>
                                        <div className="column -resultText">
                                            <div className="aligned-center -nameWrapper">
                                                <span className="a-mediumTextSemiBold -name">
                                                    {user?.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {createdByMe && (
                                        <span
                                            onClick={() =>
                                                handleRemoveMembers(user)
                                            }
                                            className="removeItemIcon"
                                        ></span>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    {createdByMe && (
                        <div className="m-searchField -withLabel -mt20">
                            <div className="aligned-center">
                                <Input
                                    name="searchUserField"
                                    label="form.label.addNewMembers"
                                    placeholder="form.placeholder.searchUsers"
                                    onChange={(e) =>
                                        handleSearch(e.target.value)
                                    }
                                    icon={ICONS.SEARCH}
                                    iconColor={COLORS.DARK_BLUE_40}
                                    size={ICON_SIZE.SIZE16}
                                    showLabel={true}
                                    value={searchValue}
                                />
                            </div>
                            {searchingUsers.length !== 0 && (
                                <div className={'channel-users-search'}>
                                    <ul>
                                        {searchingUsers.map((user) => (
                                            <ChatUserSearch
                                                key={user.id}
                                                user={{
                                                    ...user,
                                                    online: false,
                                                }}
                                                handleClick={
                                                    handleAddNewMembers
                                                }
                                            />
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="create-channel-btn-wrapper -mt30">
                    <Button
                        label="chat.cancel"
                        onClick={handleBackButton}
                        btnClass={BUTTON_STATUS.SECONDARY}
                        size={BUTTON_SIZE.SMALL}
                    />
                    {createdByMe && (
                        <Button
                            label="chat.saveChanges"
                            onClick={saveChannel}
                            disabled={!channelName.trim().length}
                            size={BUTTON_SIZE.SMALL}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default EditChannel
