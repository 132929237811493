import React from 'react'
import { useField } from 'formik'

import { INPUT_FILED_TYPE } from 'constants/enums'

import Input, { InputMainPropTypes } from 'components/Input'

const InputField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { value } = field

    const { setValue } = helpers

    const hasError = meta.touched && meta.error

    const handleChange = (event) => {
        const { value } = event.target
        setValue(
            props.type === INPUT_FILED_TYPE.NUMBER && value
                ? Number(value)
                : value
        )
    }

    return (
        <Input
            {...field}
            {...props}
            onChange={handleChange}
            value={value ?? ''}
            error={hasError}
        />
    )
}

InputField.propTypes = {
    ...InputMainPropTypes,
}

export default InputField
