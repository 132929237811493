import React, { Fragment } from 'react'

import COLORS from 'constants/colors'
import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Accordion from 'components/Accordion'

const RejectionTemplateItem = ({
    template,
    selectedTemplate,
    chooseTemplate,
    editTemplate,
}) => {
    const activeTemplate =
        selectedTemplate && template.id === selectedTemplate.id

    return (
        <div key={template.id} className="m-rejectionTemplateItem fullWidth">
            <Accordion
                title={template.title}
                withBorders
                active={activeTemplate}
                withSelect
                translateTitle={false}
            >
                <Fragment>
                    <p
                        className={
                            'a-bodyTextRegular a-lightText -opacity-60 -mt10'
                        }
                    >
                        {template.body}
                    </p>
                    <div className="-mt20 -editButton">
                        <Button
                            onClick={() => editTemplate(template)}
                            label="button.editTemplate"
                            icon={ICONS.ROUNDED_EDIT}
                            iconColor={COLORS.LIGHT_BLUE}
                            iconPosition={ICON_POSITION.LEFT}
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                        />
                    </div>
                </Fragment>
            </Accordion>
            <div
                className="-clickableOverlay"
                onClick={(e) => chooseTemplate(template, e)}
            ></div>
        </div>
    )
}

export default RejectionTemplateItem
