import moment from 'moment'

const convertTimeFormat = (timeStr) => {
    timeStr = timeStr.trim().toUpperCase()

    let hours, minutes
    if (timeStr.includes('AM') || timeStr.includes('PM')) {
        const isPM = timeStr.includes('PM')
        ;[hours, minutes] = timeStr.split(/[:\s]/).map(Number)
        if (isPM && hours < 12) hours += 12
        if (!isPM && hours === 12) hours = 0
    } else {
        ;[hours, minutes] = timeStr.split(':').map(Number)
    }

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    return `${formattedHours}:${formattedMinutes}:00`
}

const removeSecondsFromTime = (timeStr) => {
    const value = moment(timeStr, 'HH:mm:ss').format('HH:mm')
    return value
}

export { convertTimeFormat, removeSecondsFromTime }
