import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import useFetchData from 'hooks/useFetchData'

import { isFormDisabled } from 'utils/form'

import { SELECT_VALUE_TYPE } from 'constants/enums'

import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'

const RadioGroup = ({
    name,
    label,
    value,
    setValue,
    entityType,
    params,
    defaultOptions,
    isJSONAPI,
    apiUrl,
    displayAttribute,
    descriptionAttribute,
    required,
    error,
    disabled,
    valueType,
    onUpdate,
    showLabel,
    column,
    reload,
}) => {
    const fieldRef = useRef()

    const isDisabled = disabled || isFormDisabled(fieldRef)

    const { data } = useFetchData(
        entityType,
        params,
        true,
        false,
        reload,
        defaultOptions,
        isJSONAPI,
        apiUrl
    )

    const handleChange = (item) => {
        if (isDisabled) return
        switch (valueType) {
            case SELECT_VALUE_TYPE.STRING:
                setValue(item?.id ?? item)
                if (onUpdate) onUpdate(item?.id ?? item)
                break
            case SELECT_VALUE_TYPE.OBJECT:
            default:
                setValue(item)
                if (onUpdate) onUpdate(item)
                break
        }
    }

    const getIsChecked = (item) => {
        if (
            valueType === SELECT_VALUE_TYPE.OBJECT &&
            (item.id || item.id === 0)
        ) {
            return item.id === value?.id
        }
        return (item.id ?? item) === value
    }

    return (
        <div
            scroll-attribute={name}
            className={`m-radioGroupContainer ${isDisabled ? '-disabled' : ''}`}
        >
            {showLabel && (
                <FieldLabel
                    className="m-checkboxGroup__label"
                    label={label || `form.label.${name}`}
                    required={required}
                />
            )}
            <div
                className={`m-radioGroupContainer__options ${
                    descriptionAttribute ? '-descriptionAttribute' : ''
                } ${column ? '-column' : ''}`}
            >
                {data.map((item) => {
                    const itemId = item.id ?? item
                    const itemName = `${name}${itemId}`
                    return (
                        <div
                            key={itemId}
                            className={`-radioGroupElements ${
                                (isDisabled || item.disabled) &&
                                !getIsChecked(item)
                                    ? '-disabled'
                                    : ''
                            } ${getIsChecked(item) ? '-checkedItem' : ''}`}
                        >
                            <div className="m-radioGroup">
                                <input
                                    type="radio"
                                    ref={fieldRef}
                                    name={itemName}
                                    id={itemName}
                                    value={itemId}
                                    onChange={() => handleChange(item)}
                                    checked={getIsChecked(item)}
                                    disabled={item.disabled}
                                />
                                <label
                                    htmlFor={itemName}
                                    className="a-mediumText"
                                    title={item.tooltip}
                                >
                                    <span className="radio"></span>
                                    <div>
                                        <span className="label a-mediumText">
                                            {displayAttribute
                                                ? item[displayAttribute]
                                                : item}
                                        </span>
                                        {descriptionAttribute && (
                                            <div
                                                className={`m-radioGroupContainer__options--descriptionAttribute a-captionsTextRegular a-lightText -opacity60 -mt5 ${
                                                    column ? '-column' : ''
                                                }`}
                                            >
                                                {item[descriptionAttribute]}
                                            </div>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    )
                })}
            </div>
            {error && <FieldError className="errorMessage" error={error} />}
        </div>
    )
}

export const RadioMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    entityType: PropTypes.string,
    params: PropTypes.object,
    defaultOptions: PropTypes.array,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    required: PropTypes.bool,
    displayAttribute: PropTypes.string,
    descriptionAttribute: PropTypes.string,
    disabled: PropTypes.bool,
    valueType: PropTypes.oneOf([
        SELECT_VALUE_TYPE.STRING,
        SELECT_VALUE_TYPE.OBJECT,
    ]),
    showLabel: PropTypes.bool,
    reload: PropTypes.any,
}

RadioGroup.propTypes = {
    ...RadioMainPropTypes,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

RadioGroup.defaultProps = {
    defaultOptions: [],
    displayAttribute: 'name',
    valueType: SELECT_VALUE_TYPE.OBJECT,
    required: false,
    disabled: false,
    showLabel: true,
}

export default RadioGroup
