import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'

import useToggleDropdown from 'hooks/useToggleDropdown'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const Accordion = ({
    title,
    children,
    isLoading,
    openByDefault,
    icon,
    columnTabs,
    headingComponent,
    withBorders,
    active,
    withSelect,
    translateTitle,
    index,
    activeAccordion,
    handleActiveAccordion,
    onlyOneOpen,
}) => {
    const t = useTranslate()

    const { contentRef, open, setOpen } = useToggleDropdown(openByDefault)

    const contentEl = useRef()

    useEffect(() => {
        setOpen(openByDefault)
    }, [isLoading])

    const isOpen = onlyOneOpen ? activeAccordion === index : open

    const handleOpen = (index) =>
        onlyOneOpen ? handleActiveAccordion(index) : setOpen(!open)

    const handleActionClick = (index, isIconClick = false) => {
        if (isIconClick || (!isIconClick && !withSelect && !headingComponent)) {
            handleOpen(index)
        }
    }

    const handleHeaderClick = (index) => handleActionClick(index)
    const handleIconClick = (index) => handleActionClick(index, true)

    return (
        <div
            className={`a-accordion fullWidth ${isOpen ? '-open' : ''} ${
                columnTabs ? '-columnTabs' : ''
            } ${withBorders ? '-withBorders -mt20' : ''} ${
                active ? '-active' : ''
            } ${headingComponent ? '-withHeadingComponent' : ''}`}
        >
            <div
                onClick={() => handleHeaderClick(index)}
                className="a-accordion__header"
            >
                {icon && (
                    <Icon
                        name={icon}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE20}
                    />
                )}
                <span
                    className={`${
                        columnTabs ? 'a-mediumText' : 'a-bodyTextMedium'
                    } ${withSelect ? 'aligned-center -gap15' : ''}`}
                >
                    {withSelect && (
                        <span
                            className={`-selectedIndicator aligned-center justify-center ${
                                active ? '-active' : ''
                            }`}
                        ></span>
                    )}
                    {title && <span>{translateTitle ? t(title) : title}</span>}
                </span>
                {headingComponent && headingComponent}
                <div className="-icon" onClick={() => handleIconClick(index)}>
                    <Icon name={ICONS.CHEVRON_DOWN} />
                </div>
            </div>
            <div
                className="a-accordion__content"
                ref={onlyOneOpen ? contentEl : contentRef}
                style={
                    onlyOneOpen && activeAccordion === index
                        ? {
                              maxHeight: `calc(${contentEl.current?.scrollHeight}px + 3px)`,
                          }
                        : { maxHeight: '0px' }
                }
            >
                <div className="-elements">{children}</div>
            </div>
        </div>
    )
}

Accordion.propTypes = {
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    openByDefault: PropTypes.bool,
    icon: PropTypes.string,
    columnTabs: PropTypes.bool,
    headingComponent: PropTypes.element,
    withBorders: PropTypes.bool,
    active: PropTypes.bool,
    withSelect: PropTypes.bool,
    translateTitle: PropTypes.bool,
    onlyOneOpen: PropTypes.bool,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    activeAccordion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleActiveAccordion: PropTypes.func,
}

Accordion.defaultProps = {
    columnTabs: false,
    withBorders: false,
    active: false,
    withSelect: false,
    translateTitle: true,
    onlyOneOpen: false,
}

export default Accordion
