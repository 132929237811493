import React from 'react'

import ActionButton from './ActionButton'

const TableActions = ({ actions }) => {
    return (
        <div className="m-table__actions">
            {actions.length > 0 &&
                actions.map((action, index) => (
                    <ActionButton key={index} {...action} />
                ))}
        </div>
    )
}

export default TableActions
