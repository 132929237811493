import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'

import renderLoader from '../../utils/loaderHelper'

import ICONS from 'constants/icons'

import AddNew from '../../AddNew'
import SkillRecordList from './SkillRecordList'
import NoData from 'components/NoData'
import CreateSkillRecord from './CreateSkillRecord'

const SkillRecordWrapper = ({
    data,
    isLoading,
    fetchData,
    title,
    showActions,
    refetchData,
}) => {
    const t = useTranslate()

    const [createSkill, setCreateSkill] = useState(false)

    return (
        <>
            <h2 className="a-bodyTextMedium">{title && t(title)}</h2>
            {showActions && (
                <AddNew
                    handleAction={() => setCreateSkill(true)}
                    label="general.addSkills"
                />
            )}
            {!showActions && data.length === 0 && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t('general.nothingHereRightNow')}
                />
            )}
            {createSkill && (
                <CreateSkillRecord
                    data={data}
                    open={createSkill}
                    setOpen={setCreateSkill}
                    fetchData={fetchData}
                    showActions={showActions}
                    refetchData={refetchData}
                />
            )}
            <SkillRecordList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                showActions={showActions}
                refetchData={refetchData}
            />

            {renderLoader(isLoading, true)}
        </>
    )
}

SkillRecordWrapper.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    title: PropTypes.string,
    refetchData: PropTypes.func,
}

SkillRecordWrapper.defaultProps = {
    showActions: false,
    title: '',
}

export default SkillRecordWrapper
