const XDEBUG_ACTIVE = 'xdebug_active'

export const isXdebugActive = () => {
    return localStorage.getItem(XDEBUG_ACTIVE) === 'true'
}

export const enableXdebug = () => {
    localStorage.setItem(XDEBUG_ACTIVE, 'true')
}

export const disableXdebug = () => {
    localStorage.removeItem(XDEBUG_ACTIVE)
}

window.isXdebugActive = isXdebugActive;
window.enableXdebug = enableXdebug;
window.disableXdebug = disableXdebug;