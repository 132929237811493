import PropTypes from 'prop-types'

import Icon from './Icon'
import COLORS from 'constants/colors'

const NoData = ({
    icon,
    iconSize,
    iconColor,
    title,
    description,
    isSmallWindow,
}) => {
    return (
        <div className="m-noData">
            <div className="-mb15 -mt10">
                <Icon name={icon} color={iconColor} size={iconSize} />
            </div>
            <div className="-mb10">
                <span className="a-bodyTextBold">{title}</span>
            </div>
            <div className="-mb30">
                <p
                    className={`a-mediumText a-lightText -opacity-60 ${
                        isSmallWindow
                            ? 'm-noData__noDataDescriptionSmallScreen'
                            : 'm-noData__noDataDescription'
                    }`}
                >
                    {description}
                </p>
            </div>
        </div>
    )
}

NoData.propTypes = {
    icon: PropTypes.string.isRequired,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isSmallWindow: PropTypes.bool,
}

NoData.defaultProps = {
    iconSize: 50,
    iconColor: COLORS.DEEP_GREEN,
    isSmallWindow: false,
}

export default NoData
