import React from 'react'
import { useField } from 'formik'

import ArrayCustomGroup, {
    ArrayCustomGroupMainPropTypes,
} from 'components/ArrayCustomGroup'

const ArrayCustomGroupField = (props) => {
    const [field, , helpers] = useField(props.name)
    const { value } = field
    const { setValue } = helpers

    const [itemField, meta, itemHelpers] = useField(props.itemName)
    const { value: itemValue } = itemField
    const { setValue: setItemValue } = itemHelpers
    const hasError = meta.touched && meta.error

    return (
        <div className="a-selectFromToGroup">
            <ArrayCustomGroup
                {...props}
                value={value}
                setValue={setValue}
                itemValue={itemValue}
                setItemValue={setItemValue}
                error={hasError}
            />
        </div>
    )
}

ArrayCustomGroupField.propTypes = {
    ...ArrayCustomGroupMainPropTypes,
}

export default ArrayCustomGroupField
