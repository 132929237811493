import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useMenuItems from 'hooks/useMenuItems'
import useCanLeavePage from 'hooks/useCanLeavePage'
import useLogout from 'hooks/useLogout'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { ACTION } from 'constants/constants'

import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import Logo from 'components/Logo'
import SidebarItem from './SidebarItem'

const Sidebar = ({
    open,
    setOpen,
    hocRef,
    menu,
    isMobile,
    isSmallScreen,
    hamBtnRef,
}) => {
    const t = useTranslate()
    const menuItems = useMenuItems(menu)
    const { handleLogout } = useLogout()

    const [active, setActive] = useState()

    const { currentUser, isDiver, currentCompany } = useContext(
        CurrentUserContext
    )

    const { canLeavePage, handleShowModal } = useCanLeavePage()

    const profileRoute = isDiver
        ? `${ROUTES.DIVER_PROFILE}/${currentUser?.profileHash}`
        : `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${currentCompany?.profileHash}`

    const diverProfileRoute = `${ROUTES.DIVER_PROFILE}/${currentUser?.profileHash}`
    const organizationProfileRoute = `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${currentCompany?.profileHash}`

    const handleOpenModal = (action, route) => {
        if (!canLeavePage && action === ACTION.NAVIGATE) {
            handleShowModal(route)
        } else if (!canLeavePage) {
            handleShowModal(null)
        }
    }

    if (!currentUser || (!isDiver && !currentCompany)) return null

    return (
        <div
            ref={hocRef}
            className={`m-sidebar ${!isMobile ? '-lowerLevel' : ''}  ${
                open ? '' : '-closed'
            }`}
        >
            <div className="m-sidebar__scroll">
                {isMobile && (
                    <div
                        className="m-toggler"
                        ref={hamBtnRef}
                        onClick={() => setOpen(!open)}
                    >
                        <span></span>
                        <span></span>
                    </div>
                )}
                {isMobile && (
                    <Logo
                        color1={COLORS.DARK_BLUE}
                        color2={COLORS.LIGHT_BLUE}
                        color3={COLORS.LIGHT_BLUE}
                    />
                )}
                {isSmallScreen && (
                    <>
                        {!isDiver && (
                            <Link
                                to={
                                    canLeavePage
                                        ? organizationProfileRoute
                                        : undefined
                                }
                                onClick={() => {
                                    setOpen(!open)
                                    !canLeavePage &&
                                        handleOpenModal(
                                            ACTION.NAVIGATE,
                                            organizationProfileRoute
                                        )
                                }}
                            >
                                <Avatar
                                    firstName={currentCompany.name}
                                    lastName=""
                                    avatar={currentCompany?.thumbnails?.md}
                                    icon={ICONS.BUILDING}
                                />
                            </Link>
                        )}
                        <Link
                            to={canLeavePage ? diverProfileRoute : undefined}
                            onClick={() => {
                                setOpen(!open)
                                !canLeavePage &&
                                    handleOpenModal(
                                        ACTION.NAVIGATE,
                                        diverProfileRoute
                                    )
                            }}
                            className={isDiver ? '' : 'personalProfile'}
                        >
                            <Avatar
                                firstName={currentUser.firstName}
                                lastName={currentUser.lastName}
                                avatar={currentUser.thumbnails.md}
                            />
                        </Link>
                        <Link
                            className="m-avatar__item -logout a-bodyTextMedium"
                            to={canLeavePage ? '#' : undefined}
                            onClick={() =>
                                canLeavePage
                                    ? handleLogout()
                                    : handleOpenModal(ACTION.LOGOUT)
                            }
                        >
                            <Icon
                                name={ICONS.LOGOUT}
                                color={COLORS.LIGHT_BLUE}
                                size={ICON_SIZE.SIZE20}
                            />
                            {t('button.logout')}
                        </Link>
                        <div className="a-separator -bottom -dashedbottom" />
                    </>
                )}
                {menuItems.map(
                    ({
                        key,
                        label,
                        to,
                        icon,
                        subitems,
                        className,
                        separator,
                        notAvailableAtTheMoment,
                    }) =>
                        separator ? (
                            <div
                                key={key}
                                className="a-separator -bottom -dashedbottom"
                            ></div>
                        ) : (
                            <SidebarItem
                                key={key}
                                label={label}
                                to={to}
                                icon={icon}
                                subitems={subitems}
                                active={active}
                                className={className}
                                setActive={setActive}
                                setOpen={setOpen}
                                open={open}
                                notAvailableAtTheMoment={
                                    notAvailableAtTheMoment
                                }
                                canLeavePage={canLeavePage}
                                handleOpenModal={handleOpenModal}
                            />
                        )
                )}
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    menu: PropTypes.array,
    isSmallScreen: PropTypes.bool,
    isMobile: PropTypes.bool,
}

export default Sidebar
