import React, { useMemo, useState } from 'react'

export const ImagePreviewModalContext = React.createContext()

const modalInitialState = {
    image: null,
    currentImageIndex: 0,
    handleDownload: () => {},
    handleClose: () => {},
}

const ImagePreviewModalContextProvider = (props) => {
    const [imagePreviewModal, setImagePreviewModal] =
        useState(modalInitialState)

    const showImagePreviewModal = (data) => setImagePreviewModal(data)

    const closeImagePreviewModal = () => setImagePreviewModal(modalInitialState)

    const contextValue = useMemo(
        () => ({
            imagePreviewModal,
            showImagePreviewModal,
            closeImagePreviewModal,
        }),
        [imagePreviewModal]
    )

    return (
        <ImagePreviewModalContext.Provider value={contextValue}>
            {props.children}
        </ImagePreviewModalContext.Provider>
    )
}

export default ImagePreviewModalContextProvider
