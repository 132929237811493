import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { BASE_URL } from 'utils/axiosClient'

import COLORS from 'constants/colors'
import { AVAILABILITY, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'
import TransparentImagePreview from 'components/TransparentImagePreview'
import IconButton from 'components/IconButton'
import ListOfStatusesWithTooltip from 'components/ListOfStatusesWithTooltip'

const UserInfo = ({ user, myProfile, setOpen }) => {
    const t = useTranslate()
    const childRef = useRef(null)

    const [openProfilePhoto, setOpenProfilePhoto] = useState(false)

    const {
        firstName,
        middleName,
        lastName,
        currentTitle,
        positions,
        userStatusCode,
        avatarPath,
        identityVerified,
        thumbnails,
    } = user

    const handleEdit = () => {
        setOpen(true)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    // NOTE:: for later (design needs to be changed)
    // const handleShare = () => {
    //     console.log('Share')
    // }

    const renderStatusClassName = (userStatusCode) => {
        switch (userStatusCode) {
            case AVAILABILITY.AVAILABLE_FOR_HIRE:
                return '-available'
            case AVAILABILITY.UNAVAILABLE:
                return '-unavailable'
            case AVAILABILITY.EMPLOYED:
                return '-employed'
            default:
                return '-noStatus'
        }
    }

    const handleOpenProfilePhoto = () => {
        setOpenProfilePhoto(true)
    }

    const handleClickOutside = (event) => {
        if (
            childRef?.current &&
            !childRef.current.contains(event.target) &&
            setOpenProfilePhoto
        ) {
            setOpenProfilePhoto(false)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setOpenProfilePhoto(false)
        }
    }

    return (
        <div className="a-userInfo">
            <div className="a-userInfo__header space-between">
                {/* <IconButton onClick={handleShare} icon={ICONS.SHARE} /> */}

                {myProfile && (
                    <div className="-editProfileButton">
                        <IconButton
                            icon={ICONS.EDIT}
                            iconColor={COLORS.DARK_BLUE_40}
                            iconSize={ICON_SIZE.SIZE20}
                            onClick={handleEdit}
                        />
                    </div>
                )}
                {identityVerified && (
                    <div className="-verifiedIcon aligned-center -gap5">
                        <Icon
                            name={ICONS.VERIFICATION_PROFILE}
                            size={ICON_SIZE.SIZE16}
                        />
                        <span className="a-captionsTextRegular">
                            {t('general.verified')}
                        </span>
                    </div>
                )}
            </div>

            <div className="a-userInfo__mainInfo">
                <div className="column aligned-center -profileImgAndText -gap15">
                    <div
                        className={`a-userInfo__mainInfo--${
                            thumbnails?.md ? 'image' : 'placeholder'
                        }`}
                        style={{
                            backgroundImage: `url("${
                                thumbnails?.md ? BASE_URL + thumbnails?.md : ''
                            }")`,
                        }}
                        onClick={() => handleOpenProfilePhoto()}
                    >
                        {!thumbnails?.md && (
                            <div className="-placeholderImage">
                                <Icon
                                    name={ICONS.USER}
                                    color={COLORS.WHITE}
                                    size={ICON_SIZE.SIZE80}
                                />
                            </div>
                        )}
                    </div>
                    {userStatusCode && (
                        <div
                            className={`-status ${renderStatusClassName(
                                userStatusCode
                            )}`}
                            onClick={() => handleOpenProfilePhoto()}
                        ></div>
                    )}

                    <div className="a-userInfo__mainInfo--info aligned-center justify-center column">
                        <span className="-name a-bodyTextMedium aligned-center">
                            <span className="centered-text">
                                {firstName}{' '}
                                {middleName && middleName.charAt(0) + '.'}{' '}
                                {lastName}
                                {currentTitle && ', ' + currentTitle}
                            </span>
                        </span>
                        {positions && (
                            <ListOfStatusesWithTooltip
                                positions={positions}
                                count={3}
                                statusColor="-purple"
                            />
                        )}
                    </div>
                </div>
            </div>
            {thumbnails?.md && (
                <TransparentImagePreview
                    openImage={openProfilePhoto}
                    setOpenImage={setOpenProfilePhoto}
                    imageUrl={`url("${
                        avatarPath ? BASE_URL + avatarPath : ''
                    }")`}
                    childRef={childRef}
                />
            )}
        </div>
    )
}

UserInfo.propTypes = {
    user: PropTypes.object,
    myProfile: PropTypes.bool,
    userID: PropTypes.number,
    verified: PropTypes.bool,
    setOpen: PropTypes.func,
}

UserInfo.defaultProps = {
    myProfile: false,
    verified: false,
}

export default UserInfo
