import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { createEntityService } from 'services/entity.service'
import { setUserToken } from 'services/localStorage.service'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import VerificationCode from 'components/VerificationCode'
import Loader from 'components/Loader'

const VerifyEmail = ({ newEmail, handleClose }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { setCurrentUser } = useContext(CurrentUserContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [code, setCode] = useState(['', '', '', ''])
    const [isVerified, setIsVerified] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleConfirmVerificationMessage = () => {
        handleClose()
        closeConfirmationModal()
    }

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true)
            const {
                data: { email, token },
            } = await createEntityService(
                `${ENTITIES.USER}/${ENTITIES.CONFIRM_EMAIL_CHANGE}`,
                { code: code.join('') },
                false
            )
            setUserToken(token)
            setCurrentUser((state) => ({ ...state, email }))
            setIsVerified(true)
            showConfirmationModal({
                title: 'general.success',
                message: 'message.successfullyUpdatedEmailAddress',
                icon: ICONS.CHECKMARK_CIRCLE,
                iconColor: COLORS.GREEN_60,
                iconSize: ICON_SIZE.SIZE80,
                confirmLabel: 'button.okClose',
                handleConfirm: () => handleConfirmVerificationMessage(),
                hideCancel: true,
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleResendCode = async () => {
        try {
            await createEntityService(
                `${ENTITIES.USER}/${ENTITIES.RESEND_VERIFICATION_CODE}`,
                null,
                false
            )
            setAlert(
                `${t('alerts.messages.emailVerificationCode')} ${newEmail}`,
                ALERT_TYPES.SUCCESS
            )
            setCode(['', '', '', ''])
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    if (isVerified) return null

    return (
        <Modal
            open={!!newEmail}
            setOpen={handleClose}
            closeOnClickOutside={false}
            title="general.verifyYourEmail"
            isSubmitting={isSubmitting}
            smallModal
            buttons={{
                prevBtn: {
                    label: 'noAuth.resendCode',
                    handleClick: handleResendCode,
                },
                nextBtn: {
                    label: 'button.confirm',
                    handleClick: handleSubmit,
                },
            }}
        >
            <div className="_wr -modalElements">
                <div className="_w">
                    <p className="_12 -mb20 a-centeredText a-bodyTextRegular">
                        {`${t('noAuth.pleaseEnterConfirmationCode')} `}
                        <span className="a-bodyTextMedium">{newEmail}</span>
                    </p>
                    <div className="_12 content-center">
                        <VerificationCode
                            code={code}
                            setCode={setCode}
                            onSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
            {isSubmitting && <Loader />}
        </Modal>
    )
}

VerifyEmail.propTypes = {
    newEmail: PropTypes.string,
    handleClose: PropTypes.func,
}

export default VerifyEmail
