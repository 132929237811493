import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from './Button'
import Badge from './Badge'

const BadgeTooltipContent = ({
    badge,
    size,
    additionalBadgeKey,
    badgeKey,
    id,
}) => {
    const navigate = useNavigate()

    const handleReadMore = (badge) => {
        const badgeCategory = badge.category

        navigate(
            `/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${
                ROUTES.BADGES
            }?subTab=${badgeCategory}&badgeId=${`badge-${badge.key}`}`
        )
    }

    return (
        <div className={`tooltip-content -badge`}>
            <Badge
                key={id}
                badgeKey={badgeKey}
                additionalBadgeKey={additionalBadgeKey}
                size={size}
            />
            <div>
                <div className="-mb5 a-bodyTextBold">
                    <span>{badge?.title}</span>
                </div>
                <div className="-mb15 a-mediumText a-lightText overflowText">
                    <span>{badge?.description}</span>
                </div>
                <Button
                    label="button.readMore"
                    type={BUTTON_TYPE.BUTTON}
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.XSMALL}
                    icon={ICONS.ARROW_RIGHT}
                    iconColor={COLORS.LIGHT_BLUE}
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={() => {
                        handleReadMore(badge)
                    }}
                />
            </div>
        </div>
    )
}

BadgeTooltipContent.propTypes = {
    badge: PropTypes.object,
    size: PropTypes.number,
    additionalBadgeKey: PropTypes.string,
    badgeKey: PropTypes.string,
    id: PropTypes.number,
}

BadgeTooltipContent.defaultProps = {
    size: 100,
}

export default BadgeTooltipContent
