import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import { createEntityService } from 'services/entity.service'

import usePremiumFeature from 'hooks/usePremiumFeature'

import ROUTES from 'constants/routes'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import { FEATURES } from 'constants/features'

import Modal from 'components/Modal'
import { SelectField } from 'components/formFields'

import { getVerificationLevels } from 'data/diveRecordVerificationLevels'
import FirstLevelOfVerification from './FirstLevelOfVerification'
import SecondLevelOfVerification from './SecondLevelOfVerification'

const VerificationSelection = ({ setOpen, diveRecord, fetchData }) => {
    const t = useTranslate()

    const { hasFeatureAccess, handlePremiumFeature } = usePremiumFeature()
    const hasSecondLevelVerificationAccess = hasFeatureAccess(
        FEATURES.DIVE_RECORD_VERIFICATION_LEVEL_2
    )

    const { setAlert } = useContext(AlertContext)

    const [verificationLevel, setVerificationLevel] = useState(null)
    const [showVerificationStep, setShowVerificationStep] = useState(false)
    const [verificationToken, setVerificationToken] = useState()
    const [showPremiumModal, setShowPremiumModal] = useState(false)

    const initialValues = {
        verificationLevel: verificationLevel,
    }

    const validation = Yup.object({
        verificationLevel: Yup.object().required(t('form.error.required')),
    })

    const handleNext = async ({ verificationLevel }) => {
        try {
            if (verificationLevel.id === 1) {
                const { data } = await createEntityService(
                    `${ENTITIES.DIVE_RECORD_VERIFICATION_TOKEN}/${diveRecord.id}`
                )
                setVerificationToken(data.firstLevelVerificationToken)
            }

            setVerificationLevel(verificationLevel)
            setShowVerificationStep(true)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setShowVerificationStep(false)
    }

    const handleProFeature = () => {
        setShowPremiumModal(true)
        handlePremiumFeature({ cancelAction: () => setShowPremiumModal(false) })
    }

    if (
        showVerificationStep &&
        verificationToken &&
        verificationLevel?.id === 1
    )
        return (
            <FirstLevelOfVerification
                verificationLevel={verificationLevel}
                verificationToken={verificationToken}
                setOpen={setShowVerificationStep}
                handleClose={handleClose}
            />
        )

    if (showVerificationStep && verificationLevel?.id === 2)
        return (
            <SecondLevelOfVerification
                verificationLevel={verificationLevel}
                diveRecord={diveRecord}
                fetchData={fetchData}
                setOpen={setShowVerificationStep}
                handleClose={handleClose}
            />
        )

    if (showPremiumModal) return null

    const verificationLevelOptions = getVerificationLevels(t)
        .filter(({ id }) => id === 1 || id === 2)
        .map((item) => ({
            ...item,
            isProFeature: !hasSecondLevelVerificationAccess && item.id === 2,
        }))

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleNext}
        >
            {() => (
                <Form className="-modalElements">
                    <Modal
                        closeOnClickOutside={false}
                        smallModalWithDropdowns={true}
                        title="button.sendForVerification"
                        setOpen={setOpen}
                        smallModal
                        buttons={{
                            nextBtn: {
                                label: 'button.next',
                            },
                        }}
                    >
                        <div className="_12">
                            <SelectField
                                name="verificationLevel"
                                defaultOptions={verificationLevelOptions}
                                disabledItems={[diveRecord.verifiedLevel]}
                                displayAttribute="title"
                                icon="icon"
                                required
                                handleProFeature={handleProFeature}
                            />
                        </div>
                        <div className="_12 -mt10">
                            <p>
                                <Link
                                    to={`/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`}
                                >
                                    {t('general.learnMore')}
                                </Link>
                                {` ${t('general.aboutVerificationLevels')}`}
                            </p>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default VerificationSelection
