import PropTypes from 'prop-types'

import ENTITIES from 'constants/entities'

import { createEntityService } from 'services/entity.service'

import TravelDocumentForm from './TravelDocumentForm'

const CreateTravelDocument = ({
    open,
    setOpen,
    fetchData,
    showActions,
    refetchData,
}) => {
    const handleSubmit = async (formData) => {
        await createEntityService(
            ENTITIES.TRAVEL_DOCUMENTATION,
            formData,
            true,
            ['visas', 'recordInformations']
        )
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <TravelDocumentForm
            title="general.addNewTravelDocument"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

CreateTravelDocument.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    dataLength: PropTypes.number,
    refetchData: PropTypes.func,
}

CreateTravelDocument.defaultProps = {
    showActions: false,
}

export default CreateTravelDocument
