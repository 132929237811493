import { useTranslate } from 'react-polyglot'

import { VERIFICATION_LEVEL_COLORS } from 'constants/constants'

const VerificationLevels = ({ user, myProfile }) => {
    const t = useTranslate()

    const handleMissingLevels = (data, totalLevels) => {
        const result = Array.from({ length: totalLevels }, (_, i) => ({
            level: i,
            num_records: 0,
            color: VERIFICATION_LEVEL_COLORS[i],
        }))

        data.forEach((item) => {
            result[item.level].num_records = item.num_records
        })

        return result
    }

    const verifiedLevels =
        !!user?.diveRecordsVerifiedLevels.length &&
        user.diveRecordsVerifiedLevels

    const totalLevels = 6
    const data =
        verifiedLevels && handleMissingLevels(verifiedLevels, totalLevels)

    if (!data && myProfile) {
        return (
            <span className="a-captionsTextRegular a-lightText -opacity-60 -mt10">
                {t('general.noDiveRecordsMyProfile')}
            </span>
        )
    }

    if (!data) return null

    return (
        <div className="-diveRecordsLevels column">
            <span className="a-mediumText a-lightText -opacity-60 -mt20">
                {t('general.diveRecords')}
            </span>
            {data && (
                <div className="aligned-center -gap20 -mt10 flex-wrap">
                    {data.map((item, index) => {
                        return (
                            <div
                                className="-levels aligned-center -gap10"
                                key={index}
                            >
                                <span
                                    className={`a-status a-bodyTextMedium -${
                                        item.color || 'greenWithBcg'
                                    }`}
                                >
                                    {item.num_records}
                                </span>
                                <span className="a-captionsTextRegular">
                                    Level {item.level}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default VerificationLevels
