import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useCanLeavePage from 'hooks/useCanLeavePage'

import COLORS from 'constants/colors'
import { PROTECTED_ROUTES } from 'constants/constants'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const Breadcrumbs = ({ breadcrumbs, coverImage }) => {
    const t = useTranslate()

    const { canLeavePage, handleShowModal, pathname } = useCanLeavePage()
    const handleOpenModal = (to, queryParams) => {
        if (
            !canLeavePage &&
            !PROTECTED_ROUTES.some((route) => to.includes(route))
        ) {
            handleShowModal(`${to}${queryParams ?? ''}`)
        }
    }

    const renderBreadcrumbs = () => {
        return breadcrumbs.map(
            (
                {
                    label,
                    to,
                    icon,
                    queryParams,
                    translate = true,
                    showAlways = false,
                },
                index
            ) => {
                if (pathname.includes(to) || showAlways) {
                    return (
                        <Link
                            key={index}
                            className={`a-breadcrumbs__item ${
                                coverImage ? '-coverImage' : ''
                            } a-captionsTextRegular ${
                                coverImage
                                    ? 'a-whiteText -withLink'
                                    : 'a-lightText'
                            }`}
                            to={canLeavePage ? `${to}${queryParams ?? ''}` : ''}
                            onClick={() => handleOpenModal(to, queryParams)}
                        >
                            {icon && (
                                <Icon
                                    name={icon}
                                    size={ICON_SIZE.SIZE16}
                                    color={COLORS.DARK_BLUE_40}
                                />
                            )}
                            {translate ? t(label) : label}
                        </Link>
                    )
                }
            }
        )
    }

    return <div className="a-breadcrumbs">{renderBreadcrumbs()}</div>
}

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array.isRequired,
    coverImage: PropTypes.bool,
}

Breadcrumbs.defaultProps = {
    coverImage: false,
}

export default Breadcrumbs
