import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { CurrentUserContext } from './CurrentUserContext'
import { AlertContext } from './AlertContext'

import { ALERT_TYPES } from 'constants/enums'

import { BASE_URL } from 'utils/axiosClient'

import {
    getMercureSubscribeToken,
    getMercureTopicToken,
} from 'services/localStorage.service'
import { connectMercureService } from 'services/auth.service'

export const NotificationContext = createContext()

const NotificationContextProvider = ({ children }) => {
    const { currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const [mercureTokens, setMercureTokens] = useState()
    const [reconnect, setReconnect] = useState(false)
    const [unreadCount, setUnreadCount] = useState(0)
    const [unreadNotifications, setUnreadNotifications] = useState([])
    const [reloadUnreadNotifications, setReloadUnreadNotifications] = useState(
        false
    )

    const reconnectMercureService = async () => {
        try {
            const tokens = await connectMercureService()
            setMercureTokens(tokens)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useEffect(() => {
        if (currentUser) {
            setUnreadCount(currentUser?.unseenNotifications)
        }
    }, [currentUser])

    useEffect(() => {
        if (reconnect) {
            reconnectMercureService()
            setReconnect(false)
        }
    }, [reconnect])

    useEffect(() => {
        if (currentUser) {
            const url = new URL(`${BASE_URL}/.well-known/mercure`)
            url.searchParams.append('topic', getMercureTopicToken())
            url.searchParams.append('authorization', getMercureSubscribeToken())

            const eventSource = new EventSource(url)

            eventSource.onmessage = (event) => {
                const response = JSON.parse(event.data)
                const { notification, unseenNotifications } = response
                setUnreadNotifications((state) => [notification, ...state])
                setUnreadCount(unseenNotifications)
            }

            eventSource.onerror = () => {
                setReconnect(true)
            }

            return () => {
                eventSource.close()
            }
        }
    }, [currentUser, mercureTokens?.mercureSubscribeToken])

    const contextValue = useMemo(
        () => ({
            unreadCount,
            unreadNotifications,
            reloadUnreadNotifications,
            setUnreadCount,
            setUnreadNotifications,
            setReloadUnreadNotifications,
        }),
        [unreadCount, unreadNotifications, reloadUnreadNotifications]
    )

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationContextProvider
