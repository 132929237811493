import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'

import useWindowDimensions from 'hooks/useWindowDimension'

import { TOOLTIP_POSITION } from 'constants/enums'

const BadgeTooltip = ({ children, tooltip, position, badgesRef }) => {
    const { isTablet } = useWindowDimensions()
    const singleBadgeRef = useRef(null)

    const contentPosition = useMemo(() => {
        if (badgesRef.current && singleBadgeRef.current) {
            const badgesRect = badgesRef.current.getBoundingClientRect()
            const singleBadgeRect =
                singleBadgeRef.current.getBoundingClientRect()

            const singleBadgeRight = singleBadgeRect.right
            const badgeRight = badgesRect.right

            const fitsRight = badgeRight >= singleBadgeRight + 400

            return fitsRight ? '-right' : '-left'
        }
    }, [badgesRef.current, singleBadgeRef.current])

    const renderClassName = () => {
        return isTablet ? '-bottom' : `-${position || ''}`
    }

    return (
        <span
            className={`a-badgeTooltipInfo ${renderClassName()}`}
            ref={singleBadgeRef}
        >
            {children}
            {!isTablet && (
                <div className={`tooltip-content visible ${contentPosition}`}>
                    {tooltip}
                </div>
            )}
        </span>
    )
}

BadgeTooltip.propTypes = {
    position: PropTypes.oneOf([
        TOOLTIP_POSITION.RIGHT,
        TOOLTIP_POSITION.LEFT,
        TOOLTIP_POSITION.TOP,
        TOOLTIP_POSITION.BOTTOM,
    ]),
}

BadgeTooltip.defaultProps = {
    position: TOOLTIP_POSITION.RIGHT,
}

export default BadgeTooltip
