import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import CardHeader from 'components/card/CardHeader'
import CardFooter from 'components/card/CardFooter'
import ENTITIES from '../../../../../constants/entities'

const ChamberEventList = ({
    data,
    fetchData,
    isLoading,
    setEditItem,
    entity,
    labels,
    hideActions,
    unitImperial,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const {
        timeStartLabel,
        timeEndLabel,
        holdDeptsTimeLabel,
        areYouSureLabel,
        deleteLabel,
        cardTitle,
        editButtonTitle,
        viewFullLabel,
    } = labels

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(entity, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: areYouSureLabel,
            title: deleteLabel,
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })

    if (isLoading && data.length === 0 && !hideActions) {
        return <CardPlaceholder />
    }

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    const chamberFinalDepthLabel = entity === ENTITIES.CHAMBER_DECOMPRESSION_EVENT
        ? 'form.label.chamberShallowestDepth'
        : 'form.label.chamberFinalDepth'

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            !hideActions ? '_12 _l6 -mt20' : 'fullWidth -mb20'
                        }`}
                    >
                        <CardContainer>
                            <CardHeader
                                item={item}
                                title={t(cardTitle)}
                                actions={
                                    hideActions
                                        ? []
                                        : [
                                              {
                                                  handleAction: setEditItem,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip: editButtonTitle,
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip: deleteLabel,
                                              },
                                          ]
                                }
                            />
                            <Info
                                label="form.label.chamberInitialDepth"
                                value={
                                    item.chamberInitialDepth +
                                    unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            {(item.timeStartHours !== undefined || item.timeStartMinutes !== undefined) && (
                                <Info
                                    label={timeStartLabel}
                                    value={
                                        (item.timeStartHours !== undefined ? (
                                            item.timeStartHours + 'h '
                                        ) : '') +
                                        (item.timeStartMinutes !== undefined ? (
                                            item.timeStartMinutes + 'min'
                                        ) : '')
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.chamberInitialO2 !== undefined && (
                                <Info
                                    label="form.label.chamberInitialO2"
                                    value={
                                        item.chamberInitialO2 + '%'
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label={chamberFinalDepthLabel}
                                value={
                                    item.chamberFinalDepth +
                                    unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            {(item.timeStopHours !== undefined || item.timeStopMinutes !== undefined) && (
                                <Info
                                    label={timeEndLabel}
                                    value={
                                        (item.timeStopHours !== undefined ? (
                                            item.timeStopHours + 'h '
                                        ) : '') +
                                        (item.timeStopMinutes !== undefined ? (
                                            item.timeStopMinutes + 'min'
                                        ) : '')
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.chamberFinalO2 !== undefined && (
                                <Info
                                    label="form.label.chamberFinalO2"
                                    value={
                                        item.chamberFinalO2 + '%'
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.holdDepthsTime !== undefined && (
                                <Info
                                    label={'form.label.' + holdDeptsTimeLabel}
                                    value={item.holdDepthsTime}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.comment && (
                                <Info
                                    label="form.label.comment"
                                    value={item.comment}
                                    column
                                />
                            )}
                            {!hideActions && (
                                <CardFooter withTopMargin>
                                    <Button
                                        label={viewFullLabel}
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition={ICON_POSITION.RIGHT}
                                        onClick={() => setEditItem(item)}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                    />
                                </CardFooter>
                            )}
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ChamberEventList
