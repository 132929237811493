import PropTypes from 'prop-types'
import { useContext } from 'react'
import { AlertContext } from 'contexts/AlertContext'

import { createEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'

import SkillRecordForm from './SkillRecordForm'

const CreateSkillRecord = ({
    data,
    open,
    setOpen,
    fetchData,
    showActions,
    refetchData,
}) => {
    const { setAlert } = useContext(AlertContext)

    const handleSubmit = async (formData, { setSubmitting }) => {
        const id = formData.skillCategory.id
        const skillsData = {
            data: {
                type: ENTITIES.SKILL_RECORD,
                attributes: {
                    skills: formData.skills.map((skill) => {
                        if (skill.id) {
                            return {
                                skill: { id: skill.id },
                            }
                        }
                    }),
                },
            },
        }
        try {
            setSubmitting(true)
            await createEntityService(
                `${ENTITIES.SKILL_RECORD}/${id}`,
                skillsData,
                false
            )
            fetchData()
            showActions && refetchData && refetchData()
            setOpen(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <SkillRecordForm
            title="general.addSkills"
            savedData={data}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

CreateSkillRecord.propTypes = {
    data: PropTypes.array,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
}

CreateSkillRecord.defaultProps = {
    showActions: false,
}

export default CreateSkillRecord
