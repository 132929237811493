const USER_TOKEN = 'snd_user_token'
const USER_ID = 'snd_user_id'
const USER_LANGUAGE = 'snd_user_language_code'
const MERCURE_SUBSCRIBE_TOKEN = 'snd_mercure_subscribe_token'
const MERCURE_TOPIC_TOKEN = 'snd_mercure_topic_token'
const LAST_SCREEN_URL = 'snd_last_screen'

export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN)
}

export const setUserToken = (token) => {
    localStorage.setItem(USER_TOKEN, token)
}

export const deleteUserToken = () => {
    localStorage.removeItem(USER_TOKEN)
}

export const getUserId = () => {
    return Number(localStorage.getItem(USER_ID))
}

export const setUserId = (id) => {
    localStorage.setItem(USER_ID, id)
}

export const deleteUserId = () => {
    localStorage.removeItem(USER_ID)
}

export const getUserLanguage = () => {
    return localStorage.getItem(USER_LANGUAGE)
}

export const setUserLanguage = (code) => {
    localStorage.setItem(USER_LANGUAGE, code)
}

export const deleteUserLanguage = () => {
    localStorage.removeItem(USER_LANGUAGE)
}

export const getEntityFilters = (key) => {
    return localStorage.getItem(key)
}

export const setEntityFilters = (key, filters) => {
    localStorage.setItem(key, filters)
}

export const getMercureSubscribeToken = () => {
    return localStorage.getItem(MERCURE_SUBSCRIBE_TOKEN)
}

export const setMercureSubscribeToken = (token) => {
    localStorage.setItem(MERCURE_SUBSCRIBE_TOKEN, token)
}

export const deleteMercureSubscribeToken = () => {
    localStorage.removeItem(MERCURE_SUBSCRIBE_TOKEN)
}

export const getMercureTopicToken = () => {
    return localStorage.getItem(MERCURE_TOPIC_TOKEN)
}

export const setMercureTopicToken = (token) => {
    localStorage.setItem(MERCURE_TOPIC_TOKEN, token)
}

export const deleteMercureTopicToken = () => {
    localStorage.removeItem(MERCURE_TOPIC_TOKEN)
}

export const clearStorage = () => {
    localStorage.clear()
}

export const getLastScreenURL = () => {
    return localStorage.getItem(LAST_SCREEN_URL)
}

export const setLastScreenURL = (url) => {
    localStorage.setItem(LAST_SCREEN_URL, url)
}

export const deleteLastScreenURL = () => {
    localStorage.removeItem(LAST_SCREEN_URL)
}
