import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { FEATURES, FEATURES_CATEGORIES } from 'constants/features'

import CategoryNameRow from './CategoryNameRow'
import FeaturesDataRow from './FeaturesDataRow'

const getFeaturesByCategory = (cat) =>
    Object.values(FEATURES).filter((feature) => feature.category === cat)

const categoryKeys = Object.values(FEATURES_CATEGORIES)

const TABLE_DATA = categoryKeys.map((key) => ({
    key: key,
    data: getFeaturesByCategory(key),
}))

const PackageTableData = ({ activePackage }) => {
    return (
        <Fragment>
            {TABLE_DATA.map((item) => {
                return (
                    <Fragment key={item.key}>
                        <CategoryNameRow title={item.key} />
                        <FeaturesDataRow
                            info={item.data}
                            activePackage={activePackage}
                        />
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

PackageTableData.propTypes = {
    activePackage: PropTypes.object,
}

export default PackageTableData
