import { useContext, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'

import { ImagePreviewModalContext } from 'contexts/ImagePreviewModalContext'

import { getUserToken } from '../services/localStorage.service'
import { BASE_URL } from '../utils/axiosClient'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

import { BUTTON_STATUS } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import Button from './Button'
import Modal from './Modal'
import Loader from './Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const FileModal = ({
    file,
    uploaded,
    setOpenFile,
    fetchEntity,
    customFetch,
}) => {
    const { showImagePreviewModal, closeImagePreviewModal } = useContext(
        ImagePreviewModalContext
    )

    const [fileUrl, setFileUrl] = useState('')
    const [numPages, setNumPages] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentImageIndex, setCurrentImageIndex] = useState(null)
    const [loading, setLoading] = useState(false)

    const { path, fileType, public: isPublic, originalName } = file

    const uploadedImages = uploaded.filter(
        (item) =>
            item.fileType === 'image/png' ||
            item.fileType === 'image/jpeg' ||
            item.fileType === 'image/jpg' ||
            item.fileType === 'application/octet-stream' ||
            item.fileType === 'image/webp'
    )

    useEffect(() => {
        if (uploadedImages.length) {
            const index = uploadedImages.findIndex(
                (item) => item.id === file.id
            )
            setCurrentImageIndex(index)
            if (index !== -1) {
                preparePrivateFile(uploadedImages[index])
            }
        }
    }, [])

    const handleKeyPress = (event) => {
        if (event.key === 'ArrowLeft') {
            setCurrentImageIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : uploadedImages.length - 1
            )
        } else if (event.key === 'ArrowRight') {
            setCurrentImageIndex((prevIndex) =>
                prevIndex < uploadedImages.length - 1 ? prevIndex + 1 : 0
            )
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [])

    useEffect(() => {
        if (
            currentImageIndex !== null &&
            uploadedImages.length > 0 &&
            (fileType === 'image/png' ||
                fileType === 'image/jpeg' ||
                fileType === 'image/jpg' ||
                fileType === 'application/octet-stream' ||
                fileType === 'image/webp')
        ) {
            preparePrivateFile(uploadedImages[currentImageIndex])
        }
    }, [currentImageIndex])

    const preparePrivateFile = async (item) => {
        const token = getUserToken()

        const fileUrl = `${BASE_URL}/api/${
            !token ? 'public/' : ''
        }${fetchEntity}${item?.id}`

        setLoading(true)
        try {
            let response

            if (customFetch) {
                response = await customFetch()
            } else {
                response = await fetch(fileUrl, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
            }

            const blobData = await response.blob()

            setFileUrl(URL.createObjectURL(blobData))
        } catch (error) {
            // Handle error
            console.error('Error fetching file:', error)
        } finally {
            // Set loading state to false when done (whether successful or not)
            setLoading(false)
        }
    }

    useEffect(() => {
        const getPrivateFiles = async () => {
            if (
                fileType !== 'image/png' ||
                fileType !== 'image/jpeg' ||
                fileType !== 'image/jpg' ||
                fileType !== 'application/octet-stream' ||
                fileType !== 'image/webp'
            ) {
                await preparePrivateFile(file)
            }
        }

        if (isPublic && !fileUrl) {
            setFileUrl(BASE_URL + path)
        } else if (!isPublic && !fileUrl) {
            getPrivateFiles()
        }
    }, [])

    useEffect(() => {
        if (
            (fileType === 'application/msword' ||
                fileType ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
            fileUrl
        ) {
            handleDownloadDocOrDocx()
            setOpenFile(false)
        }
    }, [fileUrl])

    useEffect(() => {
        if (
            fileType !== 'application/pdf' &&
            fileType !== 'application/msword' && // Adjust for DOC
            fileType !==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && // Adjust for DOCX
            fileUrl
        ) {
            !loading &&
                showImagePreviewModal({
                    image: fileUrl,
                    currentImageIndex: currentImageIndex,
                    originalName: originalName,
                    handleDownload: () => handleDownloadImage(),
                    handleClose: () => handleClose(),
                })
        }
    }, [currentImageIndex, fileUrl, loading])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1)
    }

    const handleNextPage = () => {
        if (currentPage < numPages) setCurrentPage(currentPage + 1)
    }

    const handleClose = () => {
        setOpenFile(null)
        closeImagePreviewModal()
    }

    const handleDownload = async () => {
        try {
            const response = await fetch(fileUrl)
            const blob = await response.blob()

            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(blob)
            downloadLink.download = file.originalName
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        } catch (error) {
            console.error('Error downloading PDF:', error)
        }
    }

    const handleDownloadImage = () => {
        const anchor = document.createElement('a')
        anchor.href = fileUrl
        anchor.download = fileUrl?.alt || file?.originalName || 'image'
        anchor.click()
    }

    const handleDownloadDocOrDocx = async () => {
        try {
            const response = await fetch(fileUrl)
            const blob = await response.blob()

            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(blob)
            downloadLink.download = file.originalName
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        } catch (error) {
            console.error('Error downloading DOCX:', error)
        }
    }

    return (
        <div>
            {fileType === 'application/pdf' && (
                <div>
                    {fileUrl && (
                        <Modal
                            open={true}
                            setOpen={setOpenFile}
                            closeOnClickOutside={false}
                            customClass="-pdfModal"
                            title={file.originalName}
                            buttons={{
                                downloadBtn: {
                                    label: 'general.downloadPDF',
                                    handleClick: handleDownload,
                                },
                            }}
                            noTranslateTitle={true}
                        >
                            <div className="pdf-content fullWidth">
                                <div className="-mt20 _12">
                                    <Document
                                        file={fileUrl}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={currentPage} />
                                    </Document>
                                </div>
                                {numPages && (
                                    <div className="-pageControls ">
                                        <div className="a-mediumText">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.TERTIARY
                                                }
                                                icon={ICONS.CHEVRON_LEFT}
                                                onClick={handlePrevPage}
                                                disabled={currentPage === 1}
                                            ></Button>
                                            <span>
                                                {currentPage} of {numPages}
                                            </span>
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.TERTIARY
                                                }
                                                icon={ICONS.CHEVRON_RIGHT}
                                                onClick={handleNextPage}
                                                disabled={
                                                    currentPage === numPages
                                                }
                                            ></Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Modal>
                    )}
                </div>
            )}
            {loading && <Loader />}
        </div>
    )
}

FileModal.propTypes = {
    file: PropTypes.object,
    uploaded: PropTypes.array,
    setOpenFile: PropTypes.func,
    fetchEntity: PropTypes.string,
    customFetch: PropTypes.func,
}

FileModal.defaultProps = {
    fetchEntity: ENTITIES.FILES_GET,
}

export default FileModal
