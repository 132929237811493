const hasValue = (value) => value || value === 0

const isFormEmpty = (values) => {
    for (let key in values) {
        if (Array.isArray(values[key])) {
            if (values[key].length !== 0) return false
        } else if (typeof values[key] === 'object' && values[key] !== null) {
            if (!isFormEmpty(values[key])) return false
        } else {
            if (hasValue(values[key])) return false
        }
    }
    return true
}

export default isFormEmpty
