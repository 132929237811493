import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ToggleSwitchField from 'components/formFields/ToggleSwitchField'

const ExportProfileItem = ({ title, name, showBorder }) => {
    const t = useTranslate()

    return (
        <Fragment>
            {title && (
                <h6 className="a-bodyTextMedium -mt20 -mb10">{t(title)}</h6>
            )}
            <div
                className={`space-between -pb10 ${
                    showBorder ? 'a-separator -bottom -mb10' : ''
                }`}
            >
                <label className="a-mediumText">
                    {t(`form.label.${name}`)}
                </label>
                <ToggleSwitchField name={name} showLabel={false} />
            </div>
        </Fragment>
    )
}

ExportProfileItem.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    showBorder: PropTypes.bool,
}

ExportProfileItem.defaultProps = {
    showBorder: true,
}

export default ExportProfileItem
