import { Formik, Form } from 'formik'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import { SelectField } from 'components/formFields'
import Button from 'components/Button'
import Loader from 'components/Loader'

const DiverCountry = ({ userData, setUserData, setStep }) => {
    const t = useTranslate()

    const initialValues = {
        country: userData.country ?? null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        country: Yup.object().required(requiredMessage),
    })

    const handleSubmit = (formData) => {
        setUserData((data) => ({ ...data, ...formData }))
        setStep((step) => step + 1)
    }

    return (
        <div className="m-form -register -mb20">
            <h1 className="-title">
                {`${t('noAuth.welcome')}, ${userData.firstName}!`}
            </h1>
            <h1 className="-title -mb20"> {t('general.whatsYourLocation')}</h1>
            <span className="a-bodyTextRegular a-lightText -mb50 a-centeredText">
                {t('general.seeContractorsAndJobs')}
            </span>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <SelectField
                            name="country"
                            label="form.label.countryRegion"
                            entityType={ENTITIES.COUNTRY}
                            searchable
                            required
                        />
                        <div className="-mt30">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.next"
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

DiverCountry.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
}

export default DiverCountry
