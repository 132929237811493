import React from 'react'
import { useField } from 'formik'

import SelectGroup, { SelectGroupMainPropTypes } from 'components/SelectGroup'

const SelectGroupField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { setValue, setTouched } = helpers

    const hasError = meta.touched && meta.error

    return (
        <SelectGroup
            {...props}
            value={field.value}
            setValue={setValue}
            setTouched={setTouched}
            error={hasError}
        />
    )
}

SelectGroupField.propTypes = {
    ...SelectGroupMainPropTypes,
}

export default SelectGroupField
