import { PropTypes } from 'prop-types'

import BADGE from 'constants/badges'

import {
    AirDivingSupervisorBadge,
    BellSaturationSupervisorBadge,
    CertifiedNdtInspectionBadge,
    CivilEngineeringBadge,
    ConstructionDiverBadge,
    DiverMedicBadge,
    NumberOfDivesBadge,
    NumberOfDivesBronzeBadge,
    NumberOfDivesGoldBadge,
    NumberOfDivesPlatinumBadge,
    NumberOfDivesSilverBadge,
    OilGasIndustryBadge,
    RenewableEnergyBadge,
    SaturationBellDivingBadge,
    ScubaDivingBadge,
    SurfaceSuppliedBadge,
    VerifiedBadge,
} from './badges'

const BADGES = {
    [BADGE.VERIFIED]: { component: VerifiedBadge },
    [BADGE.NUMBER_OF_DIVES]: { component: NumberOfDivesBadge },
    [BADGE.NUMBER_OF_DIVES_BRONZE]: { component: NumberOfDivesBronzeBadge },
    [BADGE.NUMBER_OF_DIVES_SILVER]: { component: NumberOfDivesSilverBadge },
    [BADGE.NUMBER_OF_DIVES_GOLD]: { component: NumberOfDivesGoldBadge },
    [BADGE.NUMBER_OF_DIVES_PLATINUM]: { component: NumberOfDivesPlatinumBadge },
    [BADGE.SCUBA_DIVING]: { component: ScubaDivingBadge },
    [BADGE.SURFACE_SUPPLIED]: { component: SurfaceSuppliedBadge },
    [BADGE.SATURATION_BELL_DIVING]: { component: SaturationBellDivingBadge },
    [BADGE.AIR_DIVING_SUPERVISOR]: { component: AirDivingSupervisorBadge },
    [BADGE.BELL_SATURATION_SUPERVISOR]: {
        component: BellSaturationSupervisorBadge,
    },
    [BADGE.DIVER_MEDIC]: { component: DiverMedicBadge },
    [BADGE.OIL_GAS_INDUSTRY]: { component: OilGasIndustryBadge },
    [BADGE.RENEWABLE_ENERGY]: { component: RenewableEnergyBadge },
    [BADGE.CIVIL_ENGINEERING]: { component: CivilEngineeringBadge },
    [BADGE.CERTIFIED_NDT_INSPECTION]: {
        component: CertifiedNdtInspectionBadge,
    },
    [BADGE.CONSTRUCTION_DIVER]: { component: ConstructionDiverBadge },
}

const Badge = ({ badgeKey, additionalBadgeKey, color, size }) => {
    const BadgeComponent = BADGES[additionalBadgeKey || badgeKey].component

    if (!BadgeComponent) return null

    return <BadgeComponent color={color} size={size} />
}

Badge.propTypes = {
    badgeKey: PropTypes.string.isRequired,
    additionalBadgeKey: PropTypes.string,
}

export default Badge
