import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'
import useQueryParams from 'hooks/useQueryParams'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'
import generatePagination from './generatePagination'
import PageSelector from './PageSelector'

const LeftIcon = ({ handleClick, page, icon }) => {
    return (
        <span
            onClick={handleClick}
            className={`item -arrow ${
                page === 1 ? 'disabled' : ''
            } a-mediumText`}
        >
            <Icon name={icon} color={COLORS.DARK_BLUE_40} />
        </span>
    )
}

const RightIcon = ({ handleClick, page, paginationItems, icon }) => {
    return (
        <span
            onClick={handleClick}
            className={`item -arrow -flipped ${
                page === paginationItems[paginationItems.length - 1]
                    ? 'disabled'
                    : ''
            }`}
        >
            <Icon name={icon} color={COLORS.DARK_BLUE_40} />
        </span>
    )
}

const PAGE = 1
const PAGINATION_OPTIONS = [10, 20, 30]

const Pagination = ({
    meta,
    totalItems,
    paginationOptions,
    defaultItemsPerPage,
    showPageSelector,
    forSmallScreens,
    scrollToTop,
    topPosition,
}) => {
    const { isSmallerThanXlScreen, isSmallerTablet } = useWindowDimensions()

    const {
        page: queryPage,
        itemsPerPage: queryItemsPerPage,
        updateQueryParams,
        addFilterParams,
    } = useQueryParams()

    const page = Number(queryPage) || PAGE
    const itemsPerPage = Number(queryItemsPerPage) || defaultItemsPerPage

    const paginationItems = generatePagination(
        page,
        totalItems,
        itemsPerPage,
        isSmallerThanXlScreen ? 0 : 1
    )

    useEffect(() => {
        if (scrollToTop) {
            window.scrollTo({
                top: topPosition,
                behavior: 'smooth',
            })
        }
    }, [meta?.currentPage])

    const setPage = (page) => {
        updateQueryParams({ page })
    }

    const setItemsPerPage = (itemsPerPage) => {
        addFilterParams({ itemsPerPage })
    }

    return (
        <div
            className={`m-pagination ${
                forSmallScreens ? '-differentOnSmallScreens' : ''
            }`}
        >
            {showPageSelector && !!totalItems && (
                <PageSelector
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    options={paginationOptions}
                    totalItems={totalItems}
                />
            )}
            {paginationItems.length > 1 && (
                <div className="pagination">
                    <LeftIcon
                        handleClick={() => setPage(1)}
                        page={page}
                        icon={ICONS.DOUBLE_CHEVRON_LEFT}
                    />
                    <LeftIcon
                        handleClick={() => setPage(page - 1)}
                        page={page}
                        icon={ICONS.CHEVRON_LEFT}
                    />
                    {(isSmallerTablet ? [page] : paginationItems).map(
                        (item, index) => (
                            <span
                                key={index}
                                onClick={
                                    typeof item === 'number'
                                        ? () => setPage(item)
                                        : undefined
                                }
                                className={`a-mediumText -number item ${
                                    item === page ? 'active' : ''
                                } ${
                                    typeof item !== 'number' ? 'disabled' : ''
                                }`}
                            >
                                {item}
                            </span>
                        )
                    )}
                    <RightIcon
                        handleClick={() => setPage(page + 1)}
                        page={page}
                        paginationItems={paginationItems}
                        icon={ICONS.CHEVRON_LEFT}
                    />
                    <RightIcon
                        handleClick={() =>
                            setPage(paginationItems[paginationItems.length - 1])
                        }
                        page={page}
                        paginationItems={paginationItems}
                        icon={ICONS.DOUBLE_CHEVRON_LEFT}
                    />
                </div>
            )}
        </div>
    )
}

Pagination.propTypes = {
    meta: PropTypes.object,
    totalItems: PropTypes.number.isRequired,
    paginationOptions: PropTypes.array,
    defaultItemsPerPage: PropTypes.number,
    showPageSelector: PropTypes.bool,
    forSmallScreens: PropTypes.bool,
    scrollToTop: PropTypes.bool,
    topPosition: PropTypes.number,
}

Pagination.defaultProps = {
    paginationOptions: PAGINATION_OPTIONS,
    defaultItemsPerPage: PAGINATION_OPTIONS[0],
    showPageSelector: true,
    forSmallScreens: false,
    scrollToTop: false,
    topPosition: 0,
}

export default Pagination
