import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { BASE_URL } from 'utils/axiosClient'
import {
    NOTIFICATION_LEVEL,
    NOTIFICATION_TYPE,
    renderCustomNotificationIcon,
} from 'utils/notificationHelper'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const {
    POSITION_INVITE_SENT_TO_DIVER,
    POSITION_INVITE_ACCEPTED,
    POSITION_INVITE_REJECTED,
    POSITION_APPLICATION_SUBMITTED_BY_DIVER,
    POSITION_APPLICATION_ACCEPTED,
    POSITION_APPLICATION_REJECTED,
    MEDICAL_RECORD_EXPIRING,
    MEDICAL_RECORD_EXPIRED,
    TRAVEL_DOCUMENT_EXPIRING,
    TRAVEL_DOCUMENT_EXPIRED,
    CERTIFICATE_EXPIRING,
    CERTIFICATE_EXPIRED,
    DATA_ACCESS_REQUEST_RECEIVED,
    DIVE_INVITE_EMPLOYED,
    DIVE_INVITE_CANDIDATE_STATUS_CHANGE,
    DIVE_RECORD_SUPERVISOR_REQUEST,
    DIVE_RECORD_SUPERVISOR_ACCEPTED,
    DIVE_RECORD_SUPERVISOR_REJECTED,
    DIVE_INVITE_REAPPLIED,
    DIVE_INVITE_CANCELED,
    DIVE_INVITE_WITHDRAWN,
    DIVER_REMOVED_FROM_POSITION,
    NEW_PROJECT_ASSOCIATED,
} = NOTIFICATION_TYPE

const { DANGER, INFO, WARNING } = NOTIFICATION_LEVEL

const NotificationImageBox = ({
    notificationLevel,
    notificationType,
    avatarPath,
}) => {
    const renderContent = () => {
        switch (notificationLevel) {
            case INFO:
                if (
                    notificationType !== POSITION_APPLICATION_ACCEPTED &&
                    notificationType !== POSITION_APPLICATION_REJECTED &&
                    notificationType !== DIVE_INVITE_EMPLOYED &&
                    notificationType !== DIVE_INVITE_CANCELED &&
                    notificationType !== DIVER_REMOVED_FROM_POSITION &&
                    notificationType !== DIVE_INVITE_CANDIDATE_STATUS_CHANGE &&
                    notificationType !== NEW_PROJECT_ASSOCIATED
                ) {
                    return (
                        <Fragment>
                            {avatarPath ? (
                                <img
                                    src={`${BASE_URL}${avatarPath}`}
                                    alt="avatar"
                                />
                            ) : (
                                <span className="m-avatar__icon">
                                    <Icon
                                        name={ICONS.USER}
                                        color={COLORS.WHITE}
                                        size={ICON_SIZE.SIZE20}
                                    />
                                </span>
                            )}
                        </Fragment>
                    )
                } else {
                    return (
                        <Icon
                            name={
                                renderCustomNotificationIcon(notificationType)
                                    .icon
                            }
                            iconSize={ICON_SIZE.SIZE24}
                        />
                    )
                }
            case WARNING:
                return (
                    <Icon
                        name={ICONS.ALERT_WARNING}
                        size={ICON_SIZE.SIZE24}
                        color={COLORS.ORANGE}
                    />
                )
            default:
                return <Icon name={ICONS.NOTIFICATION_DANGER} />
        }
    }

    return <Fragment>{renderContent()}</Fragment>
}

NotificationImageBox.propTypes = {
    notificationLevel: PropTypes.oneOf([DANGER, INFO, WARNING]),
    notificationType: PropTypes.oneOf([
        POSITION_INVITE_SENT_TO_DIVER,
        POSITION_INVITE_ACCEPTED,
        POSITION_INVITE_REJECTED,
        POSITION_APPLICATION_SUBMITTED_BY_DIVER,
        POSITION_APPLICATION_ACCEPTED,
        POSITION_APPLICATION_REJECTED,
        MEDICAL_RECORD_EXPIRING,
        MEDICAL_RECORD_EXPIRED,
        TRAVEL_DOCUMENT_EXPIRING,
        TRAVEL_DOCUMENT_EXPIRED,
        CERTIFICATE_EXPIRING,
        CERTIFICATE_EXPIRED,
        DATA_ACCESS_REQUEST_RECEIVED,
        DIVE_INVITE_EMPLOYED,
        DIVE_INVITE_CANDIDATE_STATUS_CHANGE,
        DIVE_RECORD_SUPERVISOR_REQUEST,
        DIVE_RECORD_SUPERVISOR_ACCEPTED,
        DIVE_RECORD_SUPERVISOR_REJECTED,
        DIVE_INVITE_REAPPLIED,
        DIVE_INVITE_CANCELED,
        DIVE_INVITE_WITHDRAWN,
        DIVER_REMOVED_FROM_POSITION,
        NEW_PROJECT_ASSOCIATED,
    ]),
    additionalData: PropTypes.string,
}

NotificationImageBox.defaultProps = {
    notificationLevel: INFO,
}

export default NotificationImageBox
