import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { BUTTON_STATUS, ICON_SIZE, PACKAGE_INFO_DATA } from 'constants/enums'

import Button from 'components/Button'
import Icon from 'components/Icon'
import useWindowDimensions from 'hooks/useWindowDimension'

const PackageInfoRow = ({ bottom, activePackage }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    return (
        <tr className={`-packageInfoRow ${bottom ? '-bottom' : ''}`}>
            {!isTablet && <td></td>}
            {(isTablet ? [activePackage] : PACKAGE_INFO_DATA).map((item) => {
                return (
                    <td key={item.key} colSpan={isTablet ? 2 : 0}>
                        <div className="column -pt30 -pb30 aligned-center">
                            {!bottom && (
                                <div className="-mb20 column justify-center aligned-center">
                                    <div className="justify-center -gap5">
                                        <Icon
                                            name={item.icon}
                                            size={ICON_SIZE.SIZE20}
                                            color={item.iconColor}
                                        />
                                        <span>{t(`general.${item.key}`)}</span>
                                    </div>
                                    <span className="a-mediumText a-lightText -opacity-60 -mt5">
                                        {t(item.description)}
                                    </span>
                                </div>
                            )}
                            <div className="a-mediumText centered-text column">
                                {item.active ? (
                                    <span className="-mt15">
                                        {t('general.currentlyActive')}
                                    </span>
                                ) : (
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        label={item.buttonLabel}
                                        disabled
                                    />
                                )}
                            </div>
                        </div>
                    </td>
                )
            })}
        </tr>
    )
}

PackageInfoRow.propTypes = {
    bottom: PropTypes.bool,
    activePackage: PropTypes.object,
}

PackageInfoRow.defaultProps = {
    bottom: false,
}

export default PackageInfoRow
