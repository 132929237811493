
const ALLOWED_USERS = [
    'dragan.vukelic@ewasoft.rs',
    'info@divesms.com',
    'vukasin@ewasoft.rs',
    'firag78601@comsb.com'
]

const isAllowedAdci = (currentUser) => currentUser.email && ALLOWED_USERS.includes(currentUser.email)

export default isAllowedAdci