import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'
import FileModal from './FileModal'

const InfoFiles = ({
    label,
    files,
    translate,
    withSpaceBetween,
    fetchEntity,
}) => {
    const t = useTranslate()

    const [filesForPreview, setFilesForPreview] = useState([])
    const [openFile, setOpenFile] = useState(null)

    const handleOpenPreview = (item) => {
        setOpenFile(item.file ?? item)
    }

    useEffect(() => {
        setFilesForPreview(files?.map((item) => item?.file ?? item))
    }, [files])

    const renderValue = (item) => (
        <div className={'-value file a-link'}>
            <div className="-file">
                <Icon
                    name={ICONS.DOCUMENT}
                    color={COLORS.LIGHT_BLUE}
                    size={ICON_SIZE.SIZE20}
                />
            </div>
            <span
                className="-fileName a-mediumText"
                onClick={() => handleOpenPreview(item)}
            >
                {item?.originalName || item?.file?.originalName}
            </span>
        </div>
    )

    if (!files?.length) return null

    return (
        <Fragment>
            <div
                className={`-infoFiles -row ${
                    withSpaceBetween ? '-withSpaceBetween' : ''
                }`}
            >
                <p className="-label">{translate ? label : t(label)}:</p>
                <div className="-wrap">
                    {Array.isArray(files) ? (
                        <div className="-values">
                            {files.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        {renderValue(item)}
                                    </Fragment>
                                )
                            })}
                        </div>
                    ) : (
                        renderValue(files)
                    )}
                </div>
            </div>
            {openFile && (
                <FileModal
                    file={openFile}
                    uploaded={filesForPreview}
                    setOpenFile={setOpenFile}
                    fetchEntity={fetchEntity}
                />
            )}
        </Fragment>
    )
}

InfoFiles.propTypes = {
    label: PropTypes.string,
    files: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    translate: PropTypes.bool,
    withSpaceBetween: PropTypes.bool,
    fetchEntity: PropTypes.string,
}

InfoFiles.defaultProps = {
    translate: false,
}

export default InfoFiles
