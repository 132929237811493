import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 1.1.0',
    introText:
        'We’re excited to announce the latest updates to our web application! This release focuses on user interface enhancements, GDPR compliance, improved data protection, and much more. Below is an overview of the newly released features and upcoming improvements:',
    items: [
        {
            title: 'GDPR Compliance',
            description:
                'We’ve introduced several updates to strengthen data privacy and transparency:',
            icon: ICONS.SHIELD,
            descriptionList: [
                {
                    title: 'Account Deletion',
                    description:
                        'Users can now permanently delete their accounts.',
                },
                {
                    title: 'Log accepted/declined terms and conditions',
                    description: 'Every action regarding terms is logged.',
                },
                {
                    title: 'Cancel Terms and Conditions',
                    description: 'Users can now retract acceptance of terms.',
                },
                {
                    title: 'Deactivate account',
                    description: 'Temporarily disable your account with ease.',
                },
                {
                    title: 'Delete User',
                    description:
                        'Full account deletion process has been enhanced.',
                },
            ],
        },
        {
            title: 'Data Protection',
            description:
                'Ensuring data privacy and access control is critical:',
            icon: ICONS.DATA_PROTECTION,
            descriptionList: [
                {
                    title: 'Data access control for dive records and CV export',
                    description: 'Tightened security on data access.',
                },
                {
                    title: 'Restricted access for unverified users',
                    description:
                        'Unverified users have a lot more restricted access to other data from the platform.',
                },
                {
                    title: 'Display only verified divers in searches',
                    description:
                        'Search results now filter out all unverified divers.',
                },
                {
                    title: 'Persona verification adjustments',
                    description: 'Updates to the verification process.',
                },
            ],
        },
        {
            title: 'UI/UX Improvements',
            description:
                'We’ve made significant UI/UX enhancements across the platform:',
            icon: ICONS.UI_UX_IMPROVEMENTS,
            descriptionList: [
                {
                    title: 'Improved fonts, spacing, and color schemes',
                    description: 'A cleaner and more modern look.',
                },
                {
                    title: 'User profile adjustments',
                    description: 'Improved layout and interaction.',
                },
                {
                    title: 'Modal header and footer visibility',
                    description:
                        'Ensuring better visibility for modal windows.',
                },
                {
                    title: 'Fixed header and footer in dive record',
                    description: 'Persistent navigation for ease of use.',
                },
                {
                    title: 'Empty state generic solution',
                    description:
                        'Standardized design for empty states with icons and text.',
                },
                {
                    title: 'Footer updates',
                    description:
                        'Improved navigation at the bottom of the page.',
                },
                {
                    title: 'Mobile card action improvements',
                    description: 'Easier interaction on mobile devices.',
                },
                {
                    title: 'Signup uploader adjustment',
                    description:
                        'Enhanced profile photo uploader during signup.',
                },
                {
                    title: 'Profile image zoom',
                    description: 'Zoom functionality added to profile images.',
                },
                {
                    title: 'Project team member filters',
                    description:
                        'Filters are now introduced also in projects team members page.',
                },
            ],
        },
        {
            title: 'Mobile Notifications',
            description:
                'Stay informed on the go! We’ve added new notification features to keep you updated in real-time via mobile devices.',
            icon: ICONS.NOTIFICATION_BELL,
        },
        {
            title: 'User Badges',
            description: 'Showcase your achievements with our new badges:',
            icon: ICONS.MEDAL,
            descriptionList: [
                {
                    title: 'New badge introduced',
                    description:
                        'Unlock and display badges based on your activities.',
                },
                {
                    title: 'Badges page',
                    description:
                        'Visit badges page to learn more about each one and how you can earn it.',
                },
            ],
        },
        {
            title: 'User Settings',
            description: 'We’ve enhanced the user settings experience:',
            icon: ICONS.SETTINGS,
            descriptionList: [
                {
                    title: 'Links and sidebar adjustments',
                    description: 'Easier navigation in your settings.',
                },
                {
                    title: 'General preferences',
                    description: 'Customize your experience even further.',
                },
                {
                    title: 'Sign-in and security',
                    description: 'Improved security settings.',
                },
                {
                    title: 'Units & Formats',
                    description:
                        'Adjust the application’s units and formats to your preference.',
                },
            ],
        },
        {
            title: 'Gallery Improvements',
            description:
                'We’ve optimized our gallery to deliver a smoother user experience, including enhanced loading and display features.',
            icon: ICONS.GALLERY_IMPROVEMENTS,
        },
        {
            title: ' Miscellaneous Enhancements',
            icon: ICONS.GLOBE,
            descriptionList: [
                {
                    title: 'Additional language options',
                    description:
                        'Now offering wider range of languages that you can add to your profile.',
                },
            ],
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingSectionDescription: 'Stay tuned for these upcoming improvements:',
    upcomingItems: [
        {
            title: 'Dive Record Level 2:',
            description: 'Platform member verification enhancements.',
        },
        {
            title: 'Dive Organization Account Management:',
            description: 'Enhanced account creation and profile management.',
        },
        {
            title: 'Incident Data:',
            description:
                'Divers will have the ability to hide incident-related data.',
        },
        {
            title: 'Dive Record Level 3:',
            description: 'Supervisor-level verification for companies.',
        },
        {
            title: 'User Invites:',
            description: 'Invite users directly via search.',
        },
        {
            title: 'Date format settings:',
            description:
                'Implementation of date formatting based on user preferences.',
        },
        {
            title: 'Notification settings:',
            description:
                'Fine-tune your notification preferences from the settings page.',
        },
    ],
    outroText:
        'Thank you for your continued support! These updates are designed to improve your experience with Skill N Depth. If you have any questions or feedback, feel free to reach out to our support team. Stay tuned for more exciting updates!',
}

const Release100 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release100
