import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

import { AlertContext } from 'contexts/AlertContext'
import { signUpUserService } from 'services/auth.service'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const UserSignUp = ({ userData, setUserData, setVerifyAccount }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await signUpUserService(formData)
            setUserData(formData)
            setVerifyAccount(true)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR, t)
        } finally {
            setSubmitting(false)
        }
    }

    const initialValues = {
        email: userData?.email ?? '',
        password: userData?.password ?? '',
        confirmPassword: userData?.confirmPassword ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        email: Yup.string()
            .email(t('form.error.invalidEmail'))
            .lowercase(t('form.error.invalidEmailUppercase'))
            .strict()
            .matches(REGEXP.EMAIL, t('form.error.invalidEmail'))
            .required(requiredMessage),
        password: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .matches(REGEXP.PASSWORD, t('form.error.mustContain'))
            .required(requiredMessage),
        confirmPassword: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .oneOf(
                [Yup.ref('password'), null],
                t('form.error.passwordsMustMatch')
            )
            .required(requiredMessage),
    })

    return (
        <div className="m-form -register -mt20">
            <h1 className="-title">{t('noAuth.welcomeTo')}</h1>
            <span className="m-form__signUp -mt20 -mb50">
                {t('links.alreadyHave')}{' '}
                <Link to={ROUTES.LOGIN}>{t('links.signIn')}</Link>
            </span>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <InputField
                            type={INPUT_FILED_TYPE.TEXT}
                            name="email"
                            icon={ICONS.MAIL}
                            size={ICON_SIZE.SIZE18}
                            iconColor={COLORS.DARK_BLUE_60}
                            required
                        />
                        <InputField
                            type={INPUT_FILED_TYPE.PASSWORD}
                            name="password"
                            required
                        />
                        <InputField
                            type={INPUT_FILED_TYPE.PASSWORD}
                            name="confirmPassword"
                            required
                        />

                        <div className="m-noAuth__form--legal -mt20">
                            <p className="a-captionsTextRegular a-lightText">
                                {t('noAuth.policyDescription')}
                                <Link
                                    to="https://skillndepth.com/terms-of-service"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    {t('noAuth.termsOfService')}{' '}
                                </Link>
                                {t('noAuth.and')}{' '}
                                <Link
                                    to="https://skillndepth.com/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('noAuth.privacyPolicy')}
                                </Link>
                                .
                            </p>
                        </div>

                        <div className="-mt20">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.agreeAndJoin"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
            <span className="a-captionsTextRegular -separatorText a-lightText -opacity-60">
                <span className="-line"></span>
                <span className="-text">{t('noAuth.orContinueWith')}</span>
            </span>
            <div className="a-socialButtons">
                <IconButton
                    onClick={() =>
                        setAlert(t('alerts.messages.featureInDevelopment'))
                    }
                    icon={ICONS.GOOGLE}
                    size={ICON_SIZE.SIZE18}
                    className="-singleButton"
                />
                <IconButton
                    onClick={() =>
                        setAlert(t('alerts.messages.featureInDevelopment'))
                    }
                    icon={ICONS.LINKEDIN}
                    size={ICON_SIZE.SIZE18}
                    className="-singleButton"
                />
            </div>
        </div>
    )
}

UserSignUp.propTypes = {
    userData: PropTypes.object,
    setUserData: PropTypes.func.isRequired,
    setVerifyAccount: PropTypes.func.isRequired,
}

export default UserSignUp
