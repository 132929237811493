import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import { useTranslate } from 'react-polyglot'

const Experience = ({ user, isSmallScreen }) => {
    const t = useTranslate()

    const { totalExperienceYears, skills, experiences, tasks } = user

    const showExperience = useMemo(() => {
        return skills.length > 0 || experiences.length > 0 || tasks.length > 0
    }, [skills.length, experiences.length, tasks.length])

    const divingModeExperiences = experiences.filter(
        (experience) => experience.divingModeName !== '/'
    )

    if (!showExperience) return null

    return (
        <div className="m-personalInfoCard__experience -pt15">
            {experiences?.length !== 0 && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mb10">
                        {t('general.experienceInDiveModes')}
                    </span>
                    <div className="listOfStatuses">
                        {divingModeExperiences.map((experience, index) =>
                            experience.divingModeName ? (
                                <span
                                    className="a-status -orange a-captionsTextRegular"
                                    key={index}
                                >
                                    {experience.divingModeName}
                                </span>
                            ) : (
                                ''
                            )
                        )}
                    </div>
                </div>
            )}
            {skills?.length !== 0 && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.skills')}
                    </span>
                    <div className="listOfStatuses">
                        {skills.map((skill, index) => (
                            <span
                                className="a-status -blue a-captionsTextRegular"
                                key={index}
                            >
                                {skill}
                            </span>
                        ))}
                    </div>
                </div>
            )}
            {tasks?.length !== 0 && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.tasks')}
                    </span>
                    <div className="listOfStatuses">
                        {tasks.map((task, index) => (
                            <span
                                className="a-status -gray a-captionsTextRegular"
                                key={index}
                            >
                                {task}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

Experience.propTypes = {
    user: PropTypes.object,
    isSmallScreen: PropTypes.bool,
}

Experience.defaultProps = {
    isSmallScreen: false,
}

export default Experience
