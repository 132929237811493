import CardPlaceholder from 'components/CardPlaceholder'
import Loader from 'components/Loader'

const renderLoader = (isLoading, page, isLoadingFilters = false) => {
    if (isLoading || isLoadingFilters) {
        return page ? (
            <CardPlaceholder numberOfCards={page ? 1 : 2} />
        ) : (
            <Loader />
        )
    }
}

export default renderLoader
