import { useEffect, useRef, useState } from 'react'

const useToggleDropdown = (openByDefault = false) => {
    const [open, setOpen] = useState(openByDefault)

    const contentRef = useRef(null)

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = open
                ? `calc(${contentRef.current.scrollHeight}px + 3px)`
                : '0'
        }
    }, [open])

    return { contentRef, open, setOpen }
}

export default useToggleDropdown
