import { useTranslate } from 'react-polyglot'
import { useLocation } from 'react-router-dom'

import { useContext, useMemo, useState } from 'react'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'
import { getEntityService } from 'services/entity.service'
import { setLastScreenURL } from 'services/localStorage.service'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    PROJECT_POSITION_ACTIONS,
} from 'constants/enums'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import CreateDiveRecordModalForm from 'screens/diver/diveRecord/components/CreateDiveRecordModalForm'
import InfoRow from 'components/InfoRow'
import CardHeader from 'components/card/CardHeader'

const ProjectListSidebar = () => {
    const t = useTranslate()
    const { pathname } = useLocation()

    const { isDiver } = useContext(CurrentUserContext)
    const [openCreateDiveRecordModal, setOpenCreateDiveRecordModal] =
        useState(false)
    const [positionOnCurrentProject, setPositionOnCurrentProject] =
        useState(null)
    const [projectId, setProjectId] = useState()

    const { data, meta, isLoading, fetchData, loadMore } =
        useFetchDataByQueryParams(
            ENTITIES.DIVER_DIVE_PROJECTS,
            {
                include:
                    'company,divingMode,country,locationType,projectStatus,creator',
            },
            true
        )

    const noProjectsText = useMemo(() => {
        if (data.length === 0) {
            return t('general.noProjects')
        }
    }, [data.length])

    const condition = data.length === 0 && !isLoading

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    const handleCreateDiveRecord = async (item) => {
        setLastScreenURL(pathname)

        if (isDiver) {
            const diverProjectPositions = await getEntityService(
                ENTITIES.DIVER_PROJECT_POSITIONS,
                {
                    project: item?.id,
                    include: 'diverPosition,project,project.creator',
                }
            )

            setPositionOnCurrentProject(
                diverProjectPositions.data.find((obj) =>
                    obj?.availableActions.some(
                        (item) =>
                            item === PROJECT_POSITION_ACTIONS.PERSON_EMPLOYED
                    )
                )?.diverPositionName
            )

            setProjectId(item.id)

            setOpenCreateDiveRecordModal(true)
        }
    }

    return (
        <div className="fullWidth">
            {data.map((item, index) => (
                <div key={index} className="-mb10 -mt10">
                    <CardContainer link={`${ROUTES.PROJECT}/${item.id}`}>
                        <CardHeader
                            title={item?.projectName}
                            item={item}
                            statuses={[
                                {
                                    type: 'status',
                                    name: [item?.divingModeName],
                                    color: 'orange',
                                },
                                {
                                    type: 'status',
                                    name: [item?.locationTypeName],
                                    color: 'green',
                                },
                            ]}
                            sidebarCard
                        />
                        <Info
                            value={item?.companyName}
                            iconName={ICONS.ABOUT_COMPANY}
                            onlyInOneLine
                        />
                        <div className="-mt10">
                            <InfoRow withButtons>
                                <Button
                                    label="button.createDiveRecord"
                                    buttonSize={BUTTON_SIZE.MEDIUM}
                                    onClick={() => handleCreateDiveRecord(item)}
                                />
                            </InfoRow>
                        </div>
                    </CardContainer>
                </div>
            ))}
            {showEmptyValueMessage && (
                <span className="a-captionsTextRegular a-lightText justify-center">
                    {noProjectsText}
                </span>
            )}

            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {openCreateDiveRecordModal && (
                <CreateDiveRecordModalForm
                    setOpenCreateDiveRecordModal={setOpenCreateDiveRecordModal}
                    positionOnCurrentProject={positionOnCurrentProject}
                    projectId={projectId}
                />
            )}
            {renderLoader(isLoading)}
        </div>
    )
}

export default ProjectListSidebar
