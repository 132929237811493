import React from 'react'

import { ACCESS_CONTROL_STATUSES } from 'constants/enums'

const StatusCell = ({ value }) => {
    const statusKey = Object.keys(ACCESS_CONTROL_STATUSES).find(
        (key) => ACCESS_CONTROL_STATUSES[key].id === value
    )

    const status = ACCESS_CONTROL_STATUSES[statusKey]

    return (
        <div className={`a-status a-captionsTextRegular -${status.color}`}>
            {status.name}
        </div>
    )
}

export default StatusCell
