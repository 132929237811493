import React from 'react'

import { CHANNEL_TYPE_DM } from 'constants/chat'

const CustomListItem = ({
    channel,
    unread,
    activeChannel,
    activateChannel,
    showActions = true,
}) => {
    if (channel.data.channelType === CHANNEL_TYPE_DM) {
        return ''
    }

    return (
        <button
            className={`channel-preview ${
                activeChannel === channel ? '-active' : ''
            }`}
            onClick={() => activateChannel(channel)}
        >
            <div className="channel-preview__item">
                <p className="a-mediumTextSemiBold"># {channel.data.name}</p>
                {showActions && unread > 0 && (
                    <span className="unread-count"> {unread}</span>
                )}
            </div>
        </button>
    )
}

export default CustomListItem
