import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { getEntityByIdService, getEntityService } from 'services/entity.service'
import { loginService } from 'services/auth.service'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTES from 'constants/routes'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
    ROLES,
} from 'constants/enums'
import ICONS from 'constants/icons'
import REGEXP from 'constants/regex'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import Loader from 'components/Loader'
import FocusError from 'components/FocusError'

const Login = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)
    const { setCurrentUser, setCurrentCompany } = useContext(CurrentUserContext)

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const userId = await loginService(formData)

            if (userId) {
                const { data } = await getEntityByIdService(
                    ENTITIES.USER,
                    userId,
                    {
                        include:
                            'role,country,diverPositions,countryPhoneCode,userSetting,regions,userStatus,avatar,heroImage',
                    }
                )

                if (
                    data?.role?.name === ROLES.ROLE_DIVING_CONTRACTOR.name &&
                    data?.companies
                ) {
                    const { data: companyData } = await getEntityByIdService(
                        ENTITIES.COMPANY,
                        data.companies[0].id,
                        {
                            include:
                                'industries,services,user,vessels,clients,countryPhoneCode,hqCountry,size,regions,logo',
                        }
                    )

                    setCurrentCompany(companyData)
                }

                setCurrentUser(data)

                navigate(!data.finishedSignUp ? ROUTES.REGISTER : ROUTES.HOME)
            }
        } catch (error) {
            if (error.response?.status === 401) {
                if (
                    error.response.data.message ===
                    t('form.error.userAccountDeletion')
                ) {
                    setAlert(
                        t('message.userAccountDeletion'),
                        ALERT_TYPES.NOTIFICATION
                    )
                } else {
                    setAlert(
                        t('message.incorrectCredentials'),
                        ALERT_TYPES.NOTIFICATION
                    )
                }
            } else {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        } finally {
            setSubmitting(false)
        }
    }

    const initialValues = {
        identifier: '',
        password: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        identifier: Yup.string()
            .email(t('form.error.invalidEmail'))
            .matches(REGEXP.EMAIL, t('form.error.invalidEmail'))
            .required(requiredMessage),
        password: Yup.string().required(requiredMessage),
    })

    return (
        <div className="m-form -login">
            <h1 className="-title -mb40">{t('noAuth.welcomeTo')}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <InputField
                            name="identifier"
                            type={INPUT_FILED_TYPE.EMAIL}
                            label="form.label.email"
                            placeholder="form.placeholder.email"
                            icon={ICONS.MAIL}
                            iconColor={COLORS.DARK_BLUE_60}
                            size={ICON_SIZE.SIZE18}
                            required
                        />
                        <InputField
                            name="password"
                            label="form.label.password"
                            type={INPUT_FILED_TYPE.PASSWORD}
                            required
                        />
                        <Link
                            to={ROUTES.FORGOT_PASSWORD}
                            className="a-bodyTextRegular -forgotPassword -mb20"
                        >
                            {t('links.forgotPassword')}
                        </Link>
                        <Button
                            btnClass={BUTTON_STATUS.PRIMARY}
                            type={BUTTON_TYPE.SUBMIT}
                            label="button.signIn"
                            disabled={isSubmitting}
                        />
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
            <span className="m-form__link">
                {t('links.dontHave')}{' '}
                <Link to={ROUTES.REGISTER}>{t('links.signUp')}</Link>
            </span>
        </div>
    )
}

export default Login
