import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import Loader from 'components/Loader'

const SkillsForm = ({ handleSubmit, initialData, handleClose, title }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        name: initialData?.name ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        name: Yup.string().required(requiredMessage),
    })

    const onSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await handleSubmit(formData)
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validation}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div>
                            <InputField
                                name="name"
                                type={INPUT_FILED_TYPE.TEXT}
                                label="form.label.skillName"
                                placeholder="form.label.skillName"
                                required
                            />
                        </div>
                        <div className="-mt30">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                label={
                                    initialData
                                        ? 'button.saveChanges'
                                        : 'button.createSkill'
                                }
                                type={BUTTON_TYPE.SUBMIT}
                                buttonSize={BUTTON_SIZE.LARGE}
                                disabled={isSubmitting}
                            />
                            <div className="-mt15">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    label="button.cancel"
                                    onClick={handleClose}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    isSubmitting={isSubmitting}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SkillsForm
