import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import { refetchProfile } from '../../utils/fetchHelper'

import ENTITIES from 'constants/entities'

import { PersonalEquipmentForm } from './..'

const CreatePersonalEquipment = ({
    open,
    setOpen,
    fetchData,
    refetchData,
    filteredData,
}) => {
    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.PERSONAL_EQUIPMENT, formData, true)
        fetchData()

        const isNonRetiredEquipment = !formData?.removedFromService

        refetchData &&
            refetchProfile(isNonRetiredEquipment, filteredData, refetchData)
    }

    return (
        <PersonalEquipmentForm
            title="general.addNewPersonalEquipment"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

CreatePersonalEquipment.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
}

CreatePersonalEquipment.defaultProps = {
    showActions: false,
}

export default CreatePersonalEquipment
