import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams } from 'react-router-dom'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import { ALERT_TYPES, BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import CardPlaceholder from 'components/CardPlaceholder'
import CardContainer from 'components/CardContainer'
import Button from 'components/Button'
import Info from 'components/Info'
import CardHeader from 'components/card/CardHeader'
import CardFooter from 'components/card/CardFooter'

const SaturationEventList = ({
    data,
    fetchData,
    isLoading,
    setEditItem,
    hideActions,
    unitImperial,
}) => {
    const t = useTranslate()

    const { projectId } = useParams()
    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    const temperatureUnitOfMeasurement = unitImperial
        ? t('form.label.fahrenheit')
        : t('form.label.celsius')

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.SATURATION_EVENT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'saturationDailyEvent.deleteAreYouSure',
            title: 'saturationDailyEvent.delete',
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })

    const formatBellTeam = (team) =>
        team?.map((teamMember) => `${teamMember.fullName}`).join(', ')

    if (isLoading && data.length === 0 && !hideActions) {
        return <CardPlaceholder />
    }

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            !hideActions ? '_12 _l6 -mt20' : 'fullWidth -mb20'
                        }`}
                    >
                        <CardContainer>
                            <CardHeader
                                title={t('saturationDailyEvent.title')}
                                item={item}
                                actions={
                                    hideActions
                                        ? []
                                        : [
                                              {
                                                  handleAction: setEditItem,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip:
                                                      'saturationDailyEvent.edit',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip:
                                                      'saturationDailyEvent.delete',
                                              },
                                          ]
                                }
                            />
                            <Info
                                label="form.label.dayInSaturation"
                                value={item.dayInSaturation}
                                withBorder
                                withSpaceBetween
                            />
                            {((item.bellTeamOneMembers &&
                                item.bellTeamOneMembers.length) ||
                                item.bellTeamOneText) && (
                                <Info
                                    label="form.label.bellTeamOneMembers"
                                    value={
                                        projectId && item.bellTeamOneMembers
                                            ? formatBellTeam(
                                                  item.bellTeamOneMembers
                                              )
                                            : item.bellTeamOneText
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {((item.bellTeamTwoMembers &&
                                item.bellTeamTwoMembers.length) ||
                                item.bellTeamTwoText) && (
                                <Info
                                    label="form.label.bellTeamTwoMembers"
                                    value={
                                        projectId && item.bellTeamTwoMembers
                                            ? formatBellTeam(
                                                  item.bellTeamTwoMembers
                                              )
                                            : item.bellTeamTwoText
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {((item.bellTeamThreeMembers &&
                                item.bellTeamThreeMembers.length) ||
                                item.bellTeamThreeText) && (
                                <Info
                                    label="form.label.bellTeamThreeMembers"
                                    value={
                                        projectId && item.bellTeamThreeMembers
                                            ? formatBellTeam(
                                                  item.bellTeamThreeMembers
                                              )
                                            : item.bellTeamThreeText
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.deepestChamberStorageDepth"
                                value={
                                    item.deepestChamberStorageDepth +
                                    unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            {item.chamberAverageTemperature !== undefined && (
                                <Info
                                    label="form.label.chamberAverageTemperature"
                                    value={
                                        item.chamberAverageTemperature +
                                        temperatureUnitOfMeasurement
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.chamberAverageHumidity !== undefined && (
                                <Info
                                    label="form.label.chamberAverageHumidity"
                                    value={
                                        item.chamberAverageHumidity +
                                        t('form.label.relativeHumidity')
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.comment"
                                value={item.comment}
                                withSpaceBetween
                            />
                            {!hideActions && (
                                <CardFooter withTopMargin>
                                    <Button
                                        label="saturationDailyEvent.view"
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition="right"
                                        onClick={() => setEditItem(item)}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                    />
                                </CardFooter>
                            )}
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SaturationEventList
