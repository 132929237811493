/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from 'react'

import { AlertContext } from 'contexts/AlertContext'

import { getEntityService } from 'services/entity.service'

import { ALERT_TYPES } from 'constants/enums'

const responseInitialState = {
    data: [],
    meta: { totalItems: 0 },
    isLoading: false,
    error: null,
}

const paginationParams = {
    page: 1,
    itemsPerPage: 10,
}

const useFetchData = (
    entity,
    params = {},
    condition = true,
    pagination = true,
    reload = false,
    defaultOptions = [],
    isJSONAPI = true,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    const { setAlert } = useContext(AlertContext)

    const isReady = useRef(false)

    const [response, setResponse] = useState({
        ...responseInitialState,
        data: defaultOptions,
        meta: { totalItems: defaultOptions.length },
    })

    const loadMore = response.data.length < response.meta?.totalItems
    const allowedFetch = entity && condition && defaultOptions.length < 1

    useEffect(() => {
        if (allowedFetch) {
            fetchData()
        } else {
            setResponse({
                ...responseInitialState,
                data: defaultOptions,
                meta: { totalItems: defaultOptions.length },
            })
        }
    }, [reload])

    // Clean response on entity change
    useEffect(() => {
        return () => setResponse(responseInitialState)
    }, [entity])

    const fetchData = async (fetchParams, allowedFetchData = allowedFetch) => {
        try {
            if (!allowedFetchData) return

            setResponse((res) => ({ ...res, isLoading: true }))

            const requestParams = {
                ...(pagination ? paginationParams : { pagination: false }),
                ...params,
                ...fetchParams,
            }

            if (requestParams.include && Array.isArray(requestParams.include)) {
                requestParams.include = requestParams.include.join(',')
            }

            const { data, meta } = await getEntityService(
                entity,
                requestParams,
                isJSONAPI,
                false,
                apiUrl
            )

            // If pagination is turned off or page is set to 1, show only new data
            setResponse((res) => ({
                data:
                    !pagination || requestParams.page === 1
                        ? data
                        : [...res.data, ...data],
                meta,
                isLoading: false,
                error: null,
            }))

            isReady.current = true
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
            setResponse({ ...responseInitialState, error })
        }
    }

    return {
        ...response,
        loadMore,
        fetchData,
        isReady: isReady.current,
    }
}

export default useFetchData
