import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import TableCard from './TableCard'

const TableCards = ({
    headerItems,
    data,
    isLoading,
    rowActions,
    defaultItemsPerPage,
}) => {
    const t = useTranslate()

    const { itemsPerPage: queryItemsPerPage } = useQueryParams()

    const itemsPerPage = Number(queryItemsPerPage) || defaultItemsPerPage

    if (isLoading && data?.length === 0)
        return (
            <>
                {[...Array(itemsPerPage).keys()].map((item) => (
                    <div key={item} className="m-table__placeholder mobile">
                        {headerItems.map(({ key }) => (
                            <div key={key}>
                                <span></span>
                            </div>
                        ))}
                    </div>
                ))}
            </>
        )

    if (data?.length > 0)
        return (
            <div className="m-tableCards">
                {data.map((item, index) => (
                    <TableCard
                        key={index}
                        item={item}
                        headerItems={headerItems}
                        rowActions={rowActions}
                        itemIndex={index}
                    />
                ))}
            </div>
        )

    return (
        <div className="m-table__empty">
            <div colSpan={headerItems.length + rowActions.length}>
                <span className="a-captionsTextRegular a-lightText justify-center">
                    {t('general.noItems')}
                </span>
            </div>
        </div>
    )
}

TableCards.propTypes = {
    headerItems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    rowActions: PropTypes.array,
    defaultItemsPerPage: PropTypes.number,
}

TableCards.defaultProps = {
    rowActions: [],
}

export default TableCards
