import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { setLastScreenURL } from 'services/localStorage.service'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'
import PropTypes from 'prop-types'
import HistoricalProjectModalForm
    from 'screens/diver/profile/diverInformation/modals/HistoricalProjectModalForm'
import { createEntityService } from 'services/entity.service'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import { AlertContext } from 'contexts/AlertContext'
import { useTranslate } from 'react-polyglot'

const CreateHistoricalProjectModal = ({ fetchHistoricalProjects, setOpenHistoricalProjectModal, openHistoricalModal, setHistoricalItem }) => {
    const t = useTranslate()
    const { pathname } = useLocation()

    const { setAlert } = useContext(AlertContext)

    const handleCreateDiveRecord = () => {
        setLastScreenURL(pathname)
        setOpenHistoricalProjectModal(true)
    }

    const handleSubmit = async (formData) => {
        try {
            await createEntityService(
                ENTITIES.HISTORICAL_PROJECT,
                formData
            )
            setAlert(
                t('message.successfullySavedHistoricalProject'),
                ALERT_TYPES.SUCCESS
            )
            fetchHistoricalProjects()
        } catch (e) {
            setAlert(
                t('message.errorWhileSavingHistoricalProject'),
                ALERT_TYPES.ERROR
            )
        }
        setHistoricalItem(null)
        setOpenHistoricalProjectModal(false)
    }

    return (
        <div className="fullWidth">
            <AddNew
                handleAction={handleCreateDiveRecord}
                label="button.addHistoricalDiveProject"
            />

            {openHistoricalModal && (
                <HistoricalProjectModalForm
                    setOpenCreateHistoricalProjectModal={setOpenHistoricalProjectModal}
                    initialData={null}
                    title={'button.addHistoricalDiveProject'}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    )
}

CreateHistoricalProjectModal.propTypes = {
    fetchHistoricalProjects: PropTypes.func,
    setOpenHistoricalProjectModal: PropTypes.func,
    openHistoricalModal: PropTypes.bool,
    setHistoricalItem: PropTypes.func,
}

CreateHistoricalProjectModal.defaultProps = {
}

export default CreateHistoricalProjectModal
