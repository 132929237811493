import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import REGEXP from 'constants/regex'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'

import { resetPasswordService } from 'services/auth.service'

import ROUTES from 'constants/routes'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const ResetPassword = () => {
    const t = useTranslate()
    const { token } = useParams()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const handleSubmit = async (formData) => {
        try {
            await resetPasswordService({ ...formData, token })
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            navigate(ROUTES.LOGIN)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const initialValues = { password: '', repeatPassword: '' }

    const requiredMessage = t('form.error.required')

    const validate = Yup.object().shape({
        password: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .matches(REGEXP.PASSWORD, t('form.error.mustContain'))
            .required(requiredMessage),
        repeatPassword: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .oneOf(
                [Yup.ref('password'), null],
                t('form.error.passwordsMustMatch')
            )
            .required(requiredMessage),
    })

    return (
        <div className="m-form -register -password">
            <h1 className="-title -mb20">
                {t('noAuth.createNewPasswordTitle')}
            </h1>
            <span className="-descriotion a-captionsTextRegular a-lightText">
                {t('noAuth.createNewPasswordText')}
            </span>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validate}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <div className="_w -mt40">
                            <div className="_l12">
                                <InputField
                                    type={INPUT_FILED_TYPE.PASSWORD}
                                    name="password"
                                    label="form.label.newPassword"
                                    placeholder="form.placeholder.newPassword"
                                    required
                                />
                                <div className="a-captionsTextRegular a-lightText -description">
                                    {t('noAuth.passwordDescription')}
                                </div>
                                <InputField
                                    type={INPUT_FILED_TYPE.PASSWORD}
                                    name="repeatPassword"
                                    placeholder="form.placeholder.confirmPassword"
                                    required
                                />
                                <Button
                                    type={BUTTON_TYPE.SUBMIT}
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    label="button.saveNewPassword"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ResetPassword
