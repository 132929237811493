import PropTypes from 'prop-types'

const CardPlaceholder = ({ numberOfCards }) => {
    return (
        <div className="space-between -mt20">
            {[...Array(numberOfCards).keys()].map((item) => (
                <div
                    key={item}
                    className={`${
                        numberOfCards === 1 ? '_12 cardPlaceholder' : '_12 _m6'
                    }`}
                >
                    <div className="placeholderCard">
                        <div className="-image"></div>
                    </div>
                </div>
            ))}
        </div>
    )
}

CardPlaceholder.propTypes = {
    numberOfCards: PropTypes.number,
}

CardPlaceholder.defaultProps = {
    numberOfCards: 2,
}

export default CardPlaceholder
