import React, { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import SettingsItem from './components/SettingsItem'
import ChangeSettingsItem from './components/ChangeSettingsItem'
import ChangeEmailModal from './components/signInAndSecurity/ChangeEmailModal'
import ChangePhoneNumberModal from './components/signInAndSecurity/ChangePhoneNumberModal'
import ChangePasswordModal from './components/signInAndSecurity/ChangePasswordModal'
import ToggleSwitch from 'components/ToggleSwitch'

const SignInAndSecurity = () => {
    const t = useTranslate()

    const [twoStepVerification, setTwoStepVerification] = useState(false)

    const {
        currentUser: { email, countryPhoneCode, phoneNumber },
        isDiver,
    } = useContext(CurrentUserContext)

    return (
        <div>
            <h6 className="a-bodyTextBold -mb20">
                {t('general.signInAndSecurity')}
            </h6>
            <SettingsItem
                title="form.label.email"
                note="message.changeEmailOnceIn48h"
            >
                <ChangeSettingsItem
                    label="general.yourCurrentEmailAddress"
                    btnLabel="button.changeEmailAddress"
                    value={email}
                    ModalComponent={ChangeEmailModal}
                />
            </SettingsItem>
            <SettingsItem title="form.label.phoneNumber">
                <ChangeSettingsItem
                    label="general.yourCurrentPhoneNumber"
                    btnLabel="button.changePhoneNumber"
                    value={
                        countryPhoneCode && phoneNumber
                            ? `${countryPhoneCode?.callingCode} ${phoneNumber}`
                            : t('general.noPhoneNumber')
                    }
                    ModalComponent={ChangePhoneNumberModal}
                />
            </SettingsItem>
            <ChangeSettingsItem
                title="form.label.password"
                btnLabel="button.changePassword"
                ModalComponent={ChangePasswordModal}
            />
            {isDiver && (
                <SettingsItem
                    title="form.label.twoStepVerification"
                    note="message.featureUnderDevelopment"
                >
                    <div className="space-between aligned-center">
                        <span className="a-mediumText">
                            {t('general.turnOnTwoStepVerification')}
                        </span>
                        <ToggleSwitch
                            name="twoStepVerification"
                            value={twoStepVerification}
                            onChange={(e) =>
                                setTwoStepVerification(e.target.checked)
                            }
                            showLabel={false}
                            disabled={true}
                            tooltip={'message.twoStepVerificationAvailableSoon'}
                        />
                    </div>
                </SettingsItem>
            )}
        </div>
    )
}

export default SignInAndSecurity
