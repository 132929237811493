import { useTranslate } from 'react-polyglot'
import { useContext, useState } from 'react'

import axios, { POST } from 'utils/axiosClient'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'

import useLogout from 'hooks/useLogout'

import Modal from 'components/Modal'
import Input from 'components/Input'

import { ALERT_TYPES, BUTTON_STATUS, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

const DeleteAccount = ({ deleteAccount, setDeleteAccount }) => {
    const t = useTranslate()

    const { handleLogout } = useLogout()

    const { setAlert } = useContext(AlertContext)
    const [inputValue, setInputValue] = useState('')

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleCancel = () => {
        setDeleteAccount(false)
    }

    const handleConfirm = () => {
        closeConfirmationModal()
        handleLogout()
    }

    const handleDeleteAccount = async () => {
        if (inputValue === t('general.delete')) {
            try {
                await axios(POST, ENTITIES.DELETE_ACCOUNT, {})
                showConfirmationModal({
                    title: 'general.success',
                    message: 'message.deletedText',
                    icon: ICONS.CHECKMARK_CIRCLE,
                    iconColor: COLORS.GREEN_60,
                    iconSize: ICON_SIZE.SIZE80,
                    confirmLabel: 'button.okClose',
                    handleConfirm: () => handleConfirm(),
                    hideCancel: true,
                })
                setDeleteAccount(false)
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        } else {
            setAlert(
                { message: t('general.pleaseTypeDelete') },
                ALERT_TYPES.ERROR
            )
        }
    }

    return (
        <Modal
            open={deleteAccount}
            setOpen={setDeleteAccount}
            smallModal
            buttons={{
                prevBtn: {
                    handleClick: handleCancel,
                    label: 'button.goBackFromModal',
                },
                nextBtn: {
                    handleClick: handleDeleteAccount,
                    label: 'button.deleteMyAccount',
                    disabled: inputValue !== t('general.delete'),
                    btnClass: BUTTON_STATUS.DANGER_RED,
                },
            }}
        >
            <div className="_wr">
                <div className="_w">
                    <h3 className="-mb20 _12 justify-center">
                        {t('general.deleteYourAccount')}
                    </h3>
                </div>
                <p className="a-bodyTextRegular -mb20 text-align-center">
                    {t('general.deleteYourAccountText')}
                </p>
                <p className="a-bodyTextRegular -mb30 text-align-center">
                    {t('general.deleteYourAccountText2')}
                </p>
            </div>
            <div className="_12">
                <Input
                    name="inputText"
                    showLabel={false}
                    placeholder="form.placeholder.delete"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </div>
        </Modal>
    )
}

export default DeleteAccount
