const range = (start, end) => {
    const length = end - start + 1
    return Array.from({ length }, (_, i) => i + start)
}

const generatePagination = (
    currentPage,
    totalItems,
    perPage,
    siblingCount = 1
) => {
    const totalPageCount = Math.ceil(totalItems / perPage)

    //firstPage + lastPage + currentPage + 2*DOTS = 5
    const totalPageNumbers = siblingCount + 5

    if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount)
    }

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    const leftSiblingIndex = Math.max(
        currentPage - siblingCount,
        firstPageIndex
    )
    const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        lastPageIndex
    )

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    if (!shouldShowLeftDots && shouldShowRightDots) {
        let leftItemCount = 3 + 2 * siblingCount
        let leftRange = range(1, leftItemCount)

        return [...leftRange, '...', totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
        let rightItemCount = 3 + 2 * siblingCount
        let rightRange = range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
        )
        return [firstPageIndex, '...', ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = range(leftSiblingIndex, rightSiblingIndex)
        return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex]
    }
}

export default generatePagination
