import React from 'react'
import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import FormalEducationForm from './FormalEducationForm'

const EditFormalEducation = ({
    data,
    open,
    setOpen,
    fetchData,
    refetchData,
    showActions,
}) => {
    const handleSubmit = async (formData) => {
        await editEntityService(
            ENTITIES.FORMAL_EDUCATION,
            data.id,
            formData,
            true
        )
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <FormalEducationForm
            title="general.editFormalEducation"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
        />
    )
}

EditFormalEducation.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    showActions: PropTypes.bool,
}

EditFormalEducation.defaultProps = {
    showActions: false,
}

export default EditFormalEducation
