import { Form, Formik, useFormikContext } from 'formik'
import { useContext, useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    getTomorrowDate,
} from 'services/moment.service'

import COLORS from 'constants/colors'
import { YES_NO_OPTIONS } from 'constants/constants'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    FILE_UPLOAD_TYPE,
    ICON_SIZE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import { MEDICAL_EXAM_TYPE } from '../../constants'

import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import { CheckboxField, SelectField } from 'components/formFields'
import DateTimeField from 'components/formFields/DateTimeField'
import FileUploadField from 'components/formFields/FileUploadField'
import InputField from 'components/formFields/InputField'
import RadioGroupField from 'components/formFields/RadioGroupField'
import TextAreaField from 'components/formFields/TextAreaField'

const MedicalRecordFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext

    const firstRender = useRef(true)

    const { medicalExam } = values

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (
            !firstRender.current ||
            medicalExam?.id !== MEDICAL_EXAM_TYPE.DIVER_MEDICAL_EXAM.id
        ) {
            setFieldValue('medicalExamStandard', null)
            setFieldTouched('medicalExamStandard', false)
            setFieldValue('fitToDive', null)
            setFieldTouched('fitToDive', false)
        }
    }, [medicalExam])

    return null
}

const MedicalRecordForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        medicalRecordType: initialData?.medicalRecordType ?? null,
        medicalExam: initialData?.medicalExam ?? null,
        medicalFacility: initialData?.medicalFacility ?? '',
        medicalFacilityAddress: initialData?.medicalFacilityAddress ?? '',
        doctorName: initialData?.doctorName ?? '',
        medicalExamStandard: initialData?.medicalExamStandard ?? '',
        dateOfExam: initialData?.dateOfExam ?? '',
        dateOfExpiatory: initialData?.dateOfExpiatory ?? '',
        fitToDive:
            initialData?.fitToDive !== undefined
                ? Number(initialData.fitToDive)
                : null,
        restrictions: initialData ? Number(initialData?.restrictions) : null,
        restrictionsNote: initialData?.restrictions
            ? initialData?.restrictionsNote
            : '',
        routineMedications: initialData
            ? Number(initialData?.routineMedications)
            : null,
        routineMedicationsNote: initialData?.routineMedications
            ? initialData?.routineMedicationsNote
            : '',
        files: initialData?.files ?? [],
        comment: initialData?.comment ?? '',
        noExpirationDate: initialData?.noExpirationDate ?? false,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        medicalRecordType: Yup.object().required(requiredMessage),
        medicalExam: Yup.object().required(requiredMessage),
        medicalFacility: Yup.string().trim().required(requiredMessage),
        doctorName: Yup.string().trim().required(requiredMessage),
        medicalFacilityAddress: Yup.string().trim().required(requiredMessage),
        medicalExamStandard: Yup.object().when('medicalExam', {
            is: (val) => val?.id === MEDICAL_EXAM_TYPE.DIVER_MEDICAL_EXAM.id,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        dateOfExam: Yup.date()
            .test('dateOfExam', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        getTodaysDate(),
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.dateOfExamInFuture'),
                    path: 'dateOfExam',
                })
            })
            .required(requiredMessage),
        dateOfExpiatory: Yup.date()
            .nullable()
            .test('dateOfExpiatory', (value, { parent, createError }) => {
                if (parent.noExpirationDate === true) {
                    return true
                }

                if (!value) {
                    return createError({
                        message: requiredMessage,
                        path: 'dateOfExpiatory',
                    })
                }

                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return createError({
                        message: t('form.error.expirationDateInPast'),
                        path: 'dateOfExpiatory',
                    })
                }

                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.dateOfExam,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.expirationAfterExamDate'),
                        path: 'dateOfExpiatory',
                    })
                }
                return true
            }),
        fitToDive: Yup.boolean().when('medicalExam', {
            is: (val) => val?.id === MEDICAL_EXAM_TYPE.DIVER_MEDICAL_EXAM.id,
            then: () => Yup.boolean().required(requiredMessage),
            otherwise: () => Yup.boolean().nullable(),
        }),
        restrictions: Yup.boolean().required(requiredMessage),
        restrictionsNote: Yup.string().when('restrictions', {
            is: (val) => val === true,
            then: () => Yup.string().trim().required(requiredMessage),
            otherwise: () => Yup.string().notRequired(),
        }),
        files: Yup.array()
            .min(1, t('form.error.atLeastOneMedicalRecordFile'))
            .required(requiredMessage),
        noExpirationDate: Yup.boolean().required(requiredMessage),
    })

    const onSubmit = async (
        { fitToDive, restrictions, routineMedications, ...formData },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)

            const requestData = {
                ...formData,
                restrictions: Boolean(restrictions),
                routineMedications: Boolean(routineMedications),
                restrictionsNote: restrictions ? formData.restrictionsNote : '',
                routineMedicationsNote: routineMedications
                    ? formData.routineMedicationsNote
                    : '',
                user: { id: userId, entityType: ENTITIES.USER },
            }

            const requestDataWithFitToDive = {
                ...requestData,
                fitToDive: Boolean(fitToDive),
            }

            await handleSubmit(
                fitToDive === null ? requestData : requestDataWithFitToDive
            )

            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({
                values: {
                    restrictions,
                    routineMedications,
                    medicalExam,
                    dateOfExam,
                    noExpirationDate,
                },
                isSubmitting,
                setFieldValue,
            }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title={title || ''}
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {},
                            nextBtn: {
                                label: 'button.saveMedicalRecord',
                            },
                        }}
                    >
                        <div className="_wr">
                            <MedicalRecordFormContext />
                            <div className="_w">
                                <div className="_12 _m6">
                                    <SelectField
                                        name="medicalRecordType"
                                        placeholder="form.placeholder.medicalRecordType"
                                        entityType={
                                            ENTITIES.MEDICAL_RECORD_TYPE
                                        }
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="medicalExam"
                                        placeholder="form.placeholder.medicalExam"
                                        entityType={ENTITIES.MEDICAL_EXAM_TYPE}
                                        required
                                        createNew
                                        searchable
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <InputField
                                        name="medicalFacility"
                                        label="form.label.nameOfMedicalFacility"
                                        placeholder="form.placeholder.nameOfMedicalFacility"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="medicalFacilityAddress"
                                        icon={ICONS.LOCATION}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="doctorName"
                                        label="form.label.nameOfTheDoctor"
                                        placeholder="form.placeholder.nameOfTheDoctor"
                                        required
                                    />
                                </div>
                                {medicalExam?.id ===
                                    MEDICAL_EXAM_TYPE.DIVER_MEDICAL_EXAM.id && (
                                    <div className="_12 _m6">
                                        <SelectField
                                            name="medicalExamStandard"
                                            placeholder="form.placeholder.medicalExamStandard"
                                            entityType={
                                                ENTITIES.DIVING_MEDICAL_EXAM_STANDARD
                                            }
                                            required
                                            createNew
                                            searchable
                                        />
                                    </div>
                                )}
                                <div className="_12 _m6">
                                    <DateTimeField
                                        name="dateOfExam"
                                        required
                                        maxDate={getTodaysDate()}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_12 _s7 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="dateOfExpiatory"
                                        disabled={noExpirationDate === true}
                                        required={noExpirationDate !== true}
                                        minDate={getTomorrowDate()}
                                    />
                                </div>
                                <div className="_12 _s5 _m6 centered-checkbox -withSelect">
                                    <CheckboxField
                                        name="noExpirationDate"
                                        label="form.label.noExpirationDate"
                                        translate
                                        onChange={(e) => {
                                            const newValue = e.target.checked
                                            setFieldValue(
                                                'noExpirationDate',
                                                newValue
                                            )
                                            if (newValue === true) {
                                                setFieldValue(
                                                    'dateOfExpiatory',
                                                    null
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                {medicalExam?.id ===
                                    MEDICAL_EXAM_TYPE.DIVER_MEDICAL_EXAM.id && (
                                    <div className="_12">
                                        <RadioGroupField
                                            name="fitToDive"
                                            defaultOptions={YES_NO_OPTIONS}
                                            valueType={SELECT_VALUE_TYPE.STRING}
                                            required
                                        />
                                    </div>
                                )}

                                <div className="_12 _m6 -radioGroupWithTextarea">
                                    <RadioGroupField
                                        name="restrictions"
                                        defaultOptions={YES_NO_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                    />

                                    {restrictions === 1 && (
                                        <div className="-mt12">
                                            <TextAreaField
                                                name="restrictionsNote"
                                                label="form.label.describeRestrictions"
                                                showPlaceholder={false}
                                                required
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="_12 _m6 -radioGroupWithTextarea">
                                    <RadioGroupField
                                        name="routineMedications"
                                        defaultOptions={YES_NO_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                    />
                                    {routineMedications === 1 && (
                                        <div className="-mt12">
                                            <TextAreaField
                                                name="routineMedicationsNote"
                                                label="form.label.describeMedications"
                                                showPlaceholder={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="comment"
                                        label="form.label.comment"
                                    />
                                </div>
                            </div>
                            <div className="_w -pb20">
                                <Separator />
                                <FileUploadField
                                    name="files"
                                    label="form.label.uploadFiles"
                                    type={FILE_UPLOAD_TYPE.FILE}
                                    required
                                />
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default MedicalRecordForm
