import { PRIMARY_TRAINING_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

export const TRAINING_RECORD_OPTIONS = {
    DIVE_TRAINING: 'diveTraining',
    VOCATION_TRAINING: 'vocationTraining',
    CERTIFICATES: 'certificates',
    FORMAL_EDUCATION: 'formal_education',
}

export const getTrainingTypeForSubTab = (subTab) => {
    switch (subTab) {
        case TRAINING_RECORD_OPTIONS.DIVE_TRAINING:
            return {id: PRIMARY_TRAINING_TYPE.DIVE_SCHOOL_TRAINING, entityType: ENTITIES.PRIMARY_TRAINING_TYPE};
        case TRAINING_RECORD_OPTIONS.VOCATION_TRAINING:
            return {id: PRIMARY_TRAINING_TYPE.OTHER_TRAINING, entityType: ENTITIES.PRIMARY_TRAINING_TYPE};
        default:
            return null;
    }
}