import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES } from 'constants/enums'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { DIVING_MODES } from 'screens/diver/diveRecord/constants/divingModes'
import CreateDivingModeExperience from './CreateDivingModeExperience'
import { SelectField } from 'components/formFields'

const AddDivingModeModal = ({
    open,
    setOpen,
    fetchData,
    refetchData,
    divingModes,
    showActions,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [addExperience, setAddExperience] = useState(false)
    const [choosedDivingMode, setChoosedDivingMode] = useState(null)

    const requiredMessage = t('form.error.required')

    const initialValues = {
        divingMode: choosedDivingMode,
    }

    const validation = Yup.object({
        divingMode: Yup.object().required(requiredMessage),
    })

    const onSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            setAddExperience(true)
            setChoosedDivingMode(formData.divingMode)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const enabledDivingModes = () => {
        return divingModes.filter(
            (mode) => mode.code !== DIVING_MODES.CHAMBER_RECOMPRESSION_ONLY.code
        )
    }

    const handleClose = () => {
        setAddExperience(false)
        setOpen(false)
    }

    if (addExperience) {
        return (
            <CreateDivingModeExperience
                open={addExperience}
                setOpen={setAddExperience}
                handleClose={handleClose}
                fetchData={fetchData}
                showActions={showActions}
                refetchData={refetchData}
                divingMode={choosedDivingMode}
            />
        )
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => {
                    return (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                title="general.addDivingModeExperience"
                                smallModalWithDropdowns
                                isSubmitting={isSubmitting}
                                smallModal
                                buttons={{
                                    nextBtn: {
                                        label: 'button.continue',
                                    },
                                }}
                            >
                                <div className="_wr">
                                    <div className="-form _w">
                                        <div className="fullWidth">
                                            <div className="_12">
                                                <SelectField
                                                    name="divingMode"
                                                    label="form.label.divingMode"
                                                    placeholder="form.placeholder.divingMode"
                                                    defaultOptions={enabledDivingModes()}
                                                    searchable
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AddDivingModeModal
