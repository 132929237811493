import COLORS from 'constants/colors'
import React from 'react'
import PropTypes from 'prop-types'

const NumberOfDivesGoldBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <circle cx="25" cy="25" r="23" fill={COLORS.ORANGE_CUSTOM_6} />
            <g filter="url(#filter0_i_21877_20895)">
                <circle
                    cx="24.9996"
                    cy="25.0001"
                    r="19.0943"
                    fill={COLORS.ORANGE_CUSTOM_5}
                />
            </g>
            <mask
                id="mask0_21877_20895"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="46"
                height="46"
            >
                <circle cx="25" cy="25" r="23" fill={COLORS.ORANGE_CUSTOM_4} />
            </mask>
            <g mask="url(#mask0_21877_20895)">
                <path
                    opacity="0.15"
                    d="M39.7544 2L49.267 11.5126L12.6828 48.0967L3.17026 38.5841L39.7544 2Z"
                    fill="white"
                />
                <path
                    opacity="0.15"
                    d="M25.1313 -4.5094L30.4312 0.790498L-6.15289 37.3746L-11.4528 32.0747L25.1313 -4.5094Z"
                    fill="white"
                />
                <path
                    opacity="0.15"
                    d="M56.3765 16.3208L61.6764 21.6207L25.0922 58.2048L19.7923 52.9049L56.3765 16.3208Z"
                    fill="white"
                />
            </g>
            <path
                d="M16.3143 29.2462C15.7218 29.2462 15.1252 29.1733 14.5245 29.0275C13.9238 28.8816 13.4054 28.6751 12.9692 28.4077L13.8456 26.5972C14.1995 26.824 14.5821 26.9982 14.9936 27.1197C15.4133 27.2412 15.8288 27.302 16.2403 27.302C16.6517 27.302 16.985 27.225 17.2401 27.0711C17.4952 26.9091 17.6227 26.6863 17.6227 26.4028C17.6227 26.2327 17.5734 26.0828 17.4746 25.9532C17.3759 25.8155 17.2072 25.7142 16.9685 25.6494C16.7299 25.5765 16.3884 25.54 15.944 25.54H13.5988L14.0308 20.5701H19.536V22.4293H14.8948L16.1415 21.3721L15.8576 24.7259L14.6109 23.6687H16.5242C17.38 23.6687 18.0671 23.7902 18.5855 24.0333C19.104 24.2682 19.4825 24.5882 19.7211 24.9932C19.9598 25.3902 20.0791 25.8398 20.0791 26.342C20.0791 26.8443 19.9433 27.3182 19.6718 27.7637C19.4084 28.2012 19.0011 28.5576 18.4498 28.833C17.8984 29.1085 17.1866 29.2462 16.3143 29.2462Z"
                fill="white"
            />
            <path
                d="M24.602 29.2462C23.8614 29.2462 23.2031 29.072 22.6271 28.7237C22.0593 28.3753 21.6108 27.8731 21.2816 27.2169C20.9525 26.5607 20.7879 25.7628 20.7879 24.8231C20.7879 23.8834 20.9525 23.0855 21.2816 22.4293C21.6108 21.7731 22.0593 21.2709 22.6271 20.9225C23.2031 20.5742 23.8614 20.4 24.602 20.4C25.3344 20.4 25.9845 20.5742 26.5523 20.9225C27.1283 21.2709 27.5809 21.7731 27.9101 22.4293C28.2393 23.0855 28.4038 23.8834 28.4038 24.8231C28.4038 25.7628 28.2393 26.5607 27.9101 27.2169C27.5809 27.8731 27.1283 28.3753 26.5523 28.7237C25.9845 29.072 25.3344 29.2462 24.602 29.2462ZM24.602 27.2777C24.8654 27.2777 25.0958 27.1967 25.2933 27.0346C25.499 26.8726 25.6595 26.6134 25.7747 26.257C25.8899 25.8924 25.9475 25.4145 25.9475 24.8231C25.9475 24.2236 25.8899 23.7457 25.7747 23.3892C25.6595 23.0328 25.499 22.7736 25.2933 22.6116C25.0958 22.4495 24.8654 22.3685 24.602 22.3685C24.3387 22.3685 24.1042 22.4495 23.8985 22.6116C23.701 22.7736 23.5405 23.0328 23.4171 23.3892C23.3019 23.7457 23.2443 24.2236 23.2443 24.8231C23.2443 25.4145 23.3019 25.8924 23.4171 26.257C23.5405 26.6134 23.701 26.8726 23.8985 27.0346C24.1042 27.1967 24.3387 27.2777 24.602 27.2777Z"
                fill="white"
            />
            <path
                d="M33.052 29.2462C32.3114 29.2462 31.6531 29.072 31.0771 28.7237C30.5093 28.3753 30.0608 27.8731 29.7316 27.2169C29.4025 26.5607 29.2379 25.7628 29.2379 24.8231C29.2379 23.8834 29.4025 23.0855 29.7316 22.4293C30.0608 21.7731 30.5093 21.2709 31.0771 20.9225C31.6531 20.5742 32.3114 20.4 33.052 20.4C33.7844 20.4 34.4345 20.5742 35.0023 20.9225C35.5784 21.2709 36.031 21.7731 36.3601 22.4293C36.6893 23.0855 36.8539 23.8834 36.8539 24.8231C36.8539 25.7628 36.6893 26.5607 36.3601 27.2169C36.031 27.8731 35.5784 28.3753 35.0023 28.7237C34.4345 29.072 33.7844 29.2462 33.052 29.2462ZM33.052 27.2777C33.3154 27.2777 33.5458 27.1967 33.7433 27.0346C33.949 26.8726 34.1095 26.6134 34.2247 26.257C34.3399 25.8924 34.3975 25.4145 34.3975 24.8231C34.3975 24.2236 34.3399 23.7457 34.2247 23.3892C34.1095 23.0328 33.949 22.7736 33.7433 22.6116C33.5458 22.4495 33.3154 22.3685 33.052 22.3685C32.7887 22.3685 32.5542 22.4495 32.3485 22.6116C32.151 22.7736 31.9905 23.0328 31.8671 23.3892C31.7519 23.7457 31.6943 24.2236 31.6943 24.8231C31.6943 25.4145 31.7519 25.8924 31.8671 26.257C31.9905 26.6134 32.151 26.8726 32.3485 27.0346C32.5542 27.1967 32.7887 27.2777 33.052 27.2777Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_i_21877_20895"
                    x="5.90527"
                    y="5.90576"
                    width="38.5423"
                    height="38.5426"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.353846" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_20895"
                    />
                </filter>
            </defs>
        </svg>
    )
}

NumberOfDivesGoldBadge.propTypes = {
    size: PropTypes.number,
}

NumberOfDivesGoldBadge.defaultProps = {
    size: 50,
}

export default NumberOfDivesGoldBadge
