import React from 'react'
import { useField } from 'formik'

import SelectMultiselectGroup, {
    SelectMultiselectGroupMainPropTypes,
} from 'components/SelectMultiselectGroup'

const SelectMultiselectGroupField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { setValue, setTouched } = helpers

    const hasError = meta.touched && meta.error

    return (
        <SelectMultiselectGroup
            {...props}
            value={field.value}
            setValue={setValue}
            setTouched={setTouched}
            error={hasError}
        />
    )
}

SelectMultiselectGroupField.propTypes = {
    ...SelectMultiselectGroupMainPropTypes,
}

export default SelectMultiselectGroupField
