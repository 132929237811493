import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import Separator from 'components/Separator'
import ToggleSwitchField from 'components/formFields/ToggleSwitchField'
import {
    NOTIFICATIONS_SETTINGS_MODULES,
    NOTIFICATION_TYPES,
} from 'constants/enums'

const { IN_APP, EMAIL, PUSH } = NOTIFICATION_TYPES

const {
    DATA_ACCESS_CONTROL,
    DIVE_RECORD_VERIFICATIONS,
    JOB_APPLICATIONS,
    JOB_APPLICATION_STATUSES,
    RELEASE_UPDATES,
} = NOTIFICATIONS_SETTINGS_MODULES

const notificationTypes = {
    [IN_APP]: { title: 'inApp' },
    [EMAIL]: { title: 'email' },
    [PUSH]: { title: 'push' },
}

const notificationModules = {
    [DATA_ACCESS_CONTROL]: {
        title: 'general.dataAccessControl',
        description: 'general.dataAccessControlNotification',
    },
    [DIVE_RECORD_VERIFICATIONS]: {
        title: 'general.diveRecordVerifications',
        description: 'general.diveRecordVerificationsNotification',
    },
    [JOB_APPLICATIONS]: {
        title: 'general.jobApplications',
        description: 'general.jobApplicationsNotification',
    },
    [JOB_APPLICATION_STATUSES]: {
        title: 'general.jobApplicationStatuses',
        description: 'general.jobApplicationStatusesNotification',
    },
    [RELEASE_UPDATES]: {
        title: 'general.releaseUpdates',
        description: 'general.releaseUpdatesNotification',
        separator: false,
    },
}

const NotificationsSettingsItem = ({ index, module, items }) => {
    const t = useTranslate()

    const { isTablet } = useWindowDimensions()

    const { title, description, separator = true } = notificationModules[module]

    const renderToggleSwitch = ({ notificationType }, itemIndex) => (
        <div
            className={
                isTablet ? 'space-between fullWidth -mt10' : 'aligned-center'
            }
        >
            {isTablet && (
                <div className="a-mediumText">
                    {t(`general.${notificationTypes[notificationType].title}`)}
                </div>
            )}
            <ToggleSwitchField
                name={`[${index}].items[${itemIndex}].enabled`}
                showLabel={false}
                disabled
            />
        </div>
    )

    return (
        <div className="_12">
            <p className="a-mediumTextSemiBold">{t(title)}</p>
            <div
                className={`${
                    isTablet ? 'column' : 'space-between aligned-center'
                }  -mt10`}
            >
                <div className="a-mediumText m-boxes -notificationSettings">
                    {t(description)}
                </div>
                <div
                    className={`${
                        isTablet ? 'column fullWidth' : 'space-between -gap30'
                    }`}
                >
                    {items.map((item, index) => (
                        <Fragment key={index}>
                            {renderToggleSwitch(item, index)}
                        </Fragment>
                    ))}
                </div>
            </div>
            {separator && <Separator marginTop={10} fullWidth />}
        </div>
    )
}

NotificationsSettingsItem.propTypes = {
    index: PropTypes.number,
    module: PropTypes.number,
    items: PropTypes.array,
}

export default NotificationsSettingsItem
