import { useTranslate } from 'react-polyglot'

import { SUPPORT } from 'constants/constants'
import ICONS from 'constants/icons'

import LinkBox from 'components/LinkBox'
import ROUTES from 'constants/routes'

const SupportBanner = () => {
    const t = useTranslate()
    return (
        <div className="column fullWidth">
            <span className="a-bodyTextMedium -sidebarTitle">
                {t('general.needHelp')}
            </span>
            <p className="-mt10 -mb10 a-mediumText a-lightText -opacity-60 -sidebarDescription">
                {t('general.reachOutToUsAnytime')}
            </p>
            <LinkBox
                link={`${ROUTES.HELP_AND_INFO}/${ROUTES.FAQ}`}
                icon={ICONS.QUESTION_MARK_ICON}
                text="general.readOur"
                linkText="general.faq"
            />
            <LinkBox
                icon={ICONS.PHONE}
                text="general.callUs"
                linkText={SUPPORT.NUMBER}
                translateText={[true, false]}
                linkType="phone"
            />
            <LinkBox
                icon={ICONS.MAIL}
                text="general.emailUs"
                linkText={SUPPORT.EMAIL}
                translateText={[true, false]}
                linkType="email"
            />
        </div>
    )
}

export default SupportBanner
