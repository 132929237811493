import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import RESPONSE_CODES from 'constants/responseCodes'
import { ALERT_TYPES } from 'constants/enums'
import ROUTES from 'constants/routes'

export const ContractorContext = React.createContext()

const ContractorContextProvider = ({ children }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { profileHash } = useParams()

    const [company, setCompany] = useState(null)

    const { setAlert } = useContext(AlertContext)
    const { currentCompany, isVerified } = useContext(CurrentUserContext)

    // If the current user is not verified, he can only fetch his company profile
    const canFetchProfile =
        isVerified ||
        (!isVerified && profileHash === currentCompany?.profileHash)

    const { data, isLoading, fetchData, error } = useFetchData(
        ENTITIES.COMPANY_BY_HASH,
        {
            profileHash: profileHash,
            include:
                'industries,services,user,vessels,clients,countryPhoneCode,hqCountry,size,regions,logo,heroImage,organizationTypes,locationOfServices',
        },
        !!profileHash && canFetchProfile,
        false,
        profileHash
    )

    useEffect(() => {
        setCompany(null)
    }, [profileHash])

    useEffect(() => {
        if (data && !Array.isArray(data)) {
            setCompany({
                ...data,
                id: data._id,
            })
        }
    }, [data])

    useEffect(() => {
        if (
            error?.response?.status === RESPONSE_CODES.FORBIDDEN ||
            !canFetchProfile
        ) {
            const msg = error?.response?.data?.message
            if (msg) {
                setAlert(t(msg), ALERT_TYPES.ERROR)
            }
            navigate(ROUTES.HOME)
        }
    }, [error])

    const contextValue = useMemo(
        () => ({
            company,
            setCompany,
            fetchContractor: fetchData,
            isLoadingContractor: isLoading,
            error,
        }),
        [company, isLoading]
    )

    return (
        <ContractorContext.Provider value={contextValue}>
            {children}
        </ContractorContext.Provider>
    )
}

export default ContractorContextProvider
