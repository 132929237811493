import React from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'
import { MultiselectField } from './formFields'

const MultiselectWithIcon = ({ icon, multiselectProps }) => {
    const { iconName, iconSize, iconColor } = icon

    return (
        <div className="a-multiselectWithIcon aligned-start -gap20 fullWidth">
            {icon.length !== 0 && (
                <div className="a-multiselectWithIcon__icon -mt25">
                    <Icon name={iconName} size={iconSize} color={iconColor} />
                </div>
            )}
            <div className="a-multiselectWithIcon__multiselect fullWidth">
                <MultiselectField {...multiselectProps} />
            </div>
        </div>
    )
}

MultiselectWithIcon.propTypes = {
    icon: PropTypes.object,
    multiselectProps: PropTypes.object,
}

MultiselectWithIcon.defaultProps = {
    icon: {},
    multiselectProps: {},
}

export default MultiselectWithIcon
