import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslate } from 'react-polyglot'
import useFetchData from 'hooks/useFetchData'

import { ALERT_TYPES } from 'constants/enums'
import ENTITIES from 'constants/entities'

import {
    createEntityService,
    deleteEntityService,
} from 'services/entity.service'
import { formatDate, MOMENT_FORMATS } from 'services/moment.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import Info from 'components/Info'

import TextAreaField from 'components/formFields/TextAreaField'
import Modal from 'components/Modal'
import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'

const DiveTeamListItemCommentsModal = ({
    diveTeamItem,
    refetchData,
    open,
    setOpen,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const [submitted, setSubmitted] = useState(false)

    const initialValues = {
        comment: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        comment: Yup.string().required(requiredMessage),
    })

    const { data: comments, fetchData: fetchComments } = useFetchData(
        ENTITIES.DIVER_INVITE_COMMENT,
        {
            diverInviteId: diveTeamItem.id,
            include: 'commenter',
        },
        true,
        false
    )

    const saveComment = async (values, { resetForm }) => {
        try {
            setSubmitted(true)
            await createEntityService(ENTITIES.DIVER_INVITE_COMMENT, {
                diverInvite: {
                    id: diveTeamItem.id,
                    entityType: ENTITIES.INVITE,
                },
                commenter: {
                    id: userId,
                    entityType: ENTITIES.USER,
                },
                comment: values.comment,
            })

            setAlert(t('general.commentSaved'), ALERT_TYPES.SUCCESS)
            fetchComments()
            refetchData()
            resetForm()
            setSubmitted(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const deleteComment = async (comment) => {
        try {
            await deleteEntityService(ENTITIES.DIVER_INVITE_COMMENT, comment.id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchComments()
            refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <div className="_wr">
            <div className="_w">
                <div className="_12">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={saveComment}
                    >
                        {({ values, handleSubmit }) => {
                            return (
                                <Form>
                                    <Modal
                                        open={open}
                                        setOpen={setOpen}
                                        closeOnClickOutside={false}
                                        title="general.comments"
                                        buttons={{
                                            nextBtn: {
                                                label: 'button.addComment',
                                                disabled: submitted,
                                            },
                                        }}
                                    >
                                        <div className="_12">
                                            <TextAreaField name="comment" />
                                        </div>
                                        <div className="_12 -pb20">
                                            {comments?.map((item) => {
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className="a-separator -top -darker -mt20 -pt20"
                                                    >
                                                        <CardContainer
                                                            noBorderCard
                                                        >
                                                            <CardHeader
                                                                title={
                                                                    item
                                                                        .commenter
                                                                        .fullName
                                                                }
                                                                subtitle={formatDate(
                                                                    item.createdAt,
                                                                    MOMENT_FORMATS.DATETIME
                                                                )}
                                                                avatar={
                                                                    item
                                                                        .commenter
                                                                        .thumbnails
                                                                        ?.md
                                                                }
                                                                placeholderIcon={
                                                                    ICONS.USER
                                                                }
                                                                actions={
                                                                    item
                                                                        .commenter
                                                                        .id !==
                                                                    userId
                                                                        ? []
                                                                        : [
                                                                              {
                                                                                  handleAction:
                                                                                      () => {
                                                                                          deleteComment(
                                                                                              item
                                                                                          )
                                                                                      },
                                                                                  icon: ICONS.DELETE,
                                                                                  iconColor:
                                                                                      COLORS.RED,
                                                                                  tooltip:
                                                                                      'button.deleteComment',
                                                                              },
                                                                          ]
                                                                }
                                                            />
                                                            <Info
                                                                value={
                                                                    item.comment
                                                                }
                                                                descriptionTextWithoutLimit={
                                                                    true
                                                                }
                                                                column
                                                            />
                                                        </CardContainer>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Modal>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

DiveTeamListItemCommentsModal.propTypes = {
    diveTeamItem: PropTypes.object,
    refetchData: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default DiveTeamListItemCommentsModal
