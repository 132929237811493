import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ROUTES from 'constants/routes'

import { DIVE_RECORD_STEPS } from 'screens/diver/diveRecord/constants/diveRecordSteps'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Input from 'components/Input'

const FirstLevelOfVerification = ({
    verificationLevel,
    verificationToken,
    setOpen,
    handleClose,
}) => {
    const t = useTranslate()

    const [linkCopied, setLinkCopied] = useState(false)

    const verificationLink = `${window.location.origin}/${ROUTES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION}/${verificationToken}/${DIVE_RECORD_STEPS[0].route}`

    const handleCopyLink = () => {
        navigator.clipboard.writeText(verificationLink)
        setLinkCopied(true)
    }

    return (
        <Modal
            closeOnClickOutside={false}
            customClass="-verificationModal"
            setOpen={handleClose}
            title={verificationLevel.title}
            noTranslateTitle
            buttons={{
                prevBtn: {
                    label: 'button.back',
                    handleClick: () => setOpen(false),
                },
                nextBtn: {
                    label: 'button.done',
                    handleClick: handleClose,
                },
            }}
            smallModal
        >
            <p className="a-bodyTextRegular _12 a-centeredText">
                {t('general.shareVerificationLinkDescription')}
            </p>
            <div className="_12 verificationLink aligned-center -mt20">
                <Input
                    name="verificationLink"
                    defaultValue={verificationLink}
                    showLabel={false}
                    showPlaceholder={false}
                    readOnly
                />
                <Button
                    label={linkCopied ? 'button.linkCopied' : 'button.copyLink'}
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.LARGE}
                    icon={linkCopied ? null : ICONS.LINK}
                    iconColor={COLORS.LIGHT_BLUE}
                    onClick={handleCopyLink}
                />
            </div>
        </Modal>
    )
}

export default FirstLevelOfVerification
