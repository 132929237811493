import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'

import Button from './Button'

/**
 * Pass only buttons array for internal active manipulation
 * To manipulate active item from outside of component pass activeButtonIndex prop
 * @param buttons
 * @param activeButtonIndex
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleButtons = ({ buttons, activeButtonIndex = 0 }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0)

    useEffect(() => {
        setActiveTabIndex(activeButtonIndex)
    }, [activeButtonIndex])

    const handleClick = (button, index) => {
        setActiveTabIndex(index)
        button.clickHandler()
    }

    return (
        <div className="m-toggleButtons aligned-center justify-center">
            <div className="m-toggleButtons__wrapper aligned-center justify-center">
                {buttons.map((button, index) => {
                    return (
                        <Button
                            key={index}
                            btnClass={
                                index === activeTabIndex
                                    ? BUTTON_STATUS.PRIMARY
                                    : BUTTON_STATUS.TERTIARY
                            }
                            buttonSize={BUTTON_SIZE.SMALL}
                            label={button.label}
                            onClick={() => handleClick(button, index)}
                        ></Button>
                    )
                })}
            </div>
        </div>
    )
}

ToggleButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    activeButtonIndex: PropTypes.number,
}

export default ToggleButtons
