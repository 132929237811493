import { Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'

import { NotificationContext } from 'contexts/NotificationContext'

import Notification from './Notification'

const NotificationList = ({ isVerified }) => {
    const {
        unreadNotifications,
        setUnreadNotifications,
        setUnreadCount,
        reloadUnreadNotifications,
    } = useContext(NotificationContext)

    const { data, meta, fetchData } = useFetchData(ENTITIES.NOTIFICATIONS, {
        seen: false,
        include: 'user',
        itemsPerPage: 4,
    })

    useEffect(() => {
        // Avoid data refetch if the first request was not executed
        if (meta.totalNumberOfUnread !== undefined) {
            fetchData()
        }
    }, [reloadUnreadNotifications])

    useEffect(() => {
        if (meta.totalNumberOfUnread !== undefined) {
            setUnreadCount(meta.totalNumberOfUnread)
            setUnreadNotifications([])
        }
    }, [data])

    const displayNotifications = [...unreadNotifications, ...data]?.slice(0, 4)

    return (
        <Fragment>
            {displayNotifications?.map((notification, id) => {
                return (
                    <Notification
                        key={id}
                        notification={notification}
                        fetchUnreadNotifications={fetchData}
                        isVerified={isVerified}
                    />
                )
            })}
        </Fragment>
    )
}

NotificationList.propTypes = {
    isVerified: PropTypes.bool,
}

export default NotificationList
