import { Fragment, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { DiveRecordContext } from './contexts/DiveRecordContext'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'

import StepButtons from 'components/StepButtons'
import EquipmentSummary from './components/equipment/EquipmentSummary'

const EquipmentVerification = () => {
    const { diveRecordId } = useParams()

    const { diveRecord, goPreviousStep, goToNextStep } =
        useContext(DiveRecordContext)

    const { data: equipmentData } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordEquipment',
                'diveRecordEquipment.divingApparelProtection',
                'diveRecordEquipment.otherDivingApparelProtections',
                'diveRecordEquipment.breathingApparatusType',
                'diveRecordEquipment.buoyancyFlotations',
                'diveRecordEquipment.otherEquipmentOutfittings',
                'diveRecordEquipment.gasCylinders',
                'diveRecordEquipment.gasCylinders.gasCylinderPurpose',
                'diveRecordEquipment.gasCylinders.gasContents',
            ],
        }
    )

    const data = diveRecordId ? equipmentData : diveRecord.data

    if (!data) return null

    return (
        <Fragment>
            <div className="ofs_l2 _l8 -diveRecordContent">
                <EquipmentSummary
                    data={data.diveRecordEquipment}
                    diveRecordId={diveRecordId ? data.id : data._id}
                    unitImperial={data.unitImperial}
                    verification
                />
            </div>
            <StepButtons
                handleBack={goPreviousStep}
                handleNext={goToNextStep}
                canSkip={false}
            />
        </Fragment>
    )
}

export default EquipmentVerification
