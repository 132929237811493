import React from 'react'

const SendDiveRecordForVerificationLevelOne = () => {

    return (
        <div className={'guidde'}>

            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to send dive record for verification - Level 1</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/uRdKxD3EgggKwfdoQfQoFw"
                        title="How to send dive record for verification - Level 1"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen={true}
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Welcome to our step-by-step guide on how to send your dive record for
                        verification at Level 1 using the Skill N Depth application. This process
                        will help ensure that your historical dive records are verified by your
                        supervisor, even if they don't have a Skill N Depth account. Let’s walk
                        through the process together!
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Introduction
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Start by navigating to the Skill N Depth platform.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2Fwn3V18hAGwJ26BM13wV1Va_doc.png?alt=media&token=857e7b12-a656-4564-8221-deb5a329816a"
                    alt="Introduction"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Click "My records"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once on the platform, locate and click on the "My records" section. This
                        is where all your dive records are stored. You'll need to find the
                        specific record you want to verify.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2Fmk3AnxQ89Qm364ZPNQ5z4v_doc.png?alt=media&token=f9cf808b-1c9b-4972-af85-bffd844d8108"
                    alt="Click 'My records'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Click "Filters"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        To make it easier to find records that need verification, use the filter
                        feature. Click on the "Filters" button to begin narrowing down your
                        search.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FasgUopDBqng9HRNYCnvn1a_doc.png?alt=media&token=61165eac-47c7-4f84-b643-3a77d4ff6ebd"
                    alt="Click 'Filters'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Click the status field
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        In the filters, you'll see a field labeled "Please select status." Click
                        on this field to choose the status of the dive records you want to filter.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FdFJZmw9NrbWtFK26jKvQHB_doc.png?alt=media&token=43c66a66-bac6-4de6-a7b5-a12ce9768e89"
                    alt="Click the status field"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Select Completed
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        From the dropdown options, select "Completed." This will filter your dive
                        records to show only those that are fully populated but have not yet been
                        verified.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2F8peCUrNghTbYhrbtkbHm59_doc.png?alt=media&token=f5eeb729-f622-496a-9058-07cd1d17e0da"
                    alt="Select Completed"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Click "Filter"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        After selecting the appropriate status, apply the filters by clicking on
                        the "Filter" button. This will display a list of all records that are
                        ready for verification.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FsxmA59h6pKRzMWMsMpBF46_doc.png?alt=media&token=48e53c9a-002d-41a9-833f-db263a4e4540"
                    alt="Click 'Filter'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Click the share button
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now that you have the list of records, it's time to create a sharable
                        link. Click on the share button next to the dive record you wish to
                        verify. Remember, this guide focuses on the first level of verification.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2F6p8BBmrWGs823hF69rmDai_doc.png?alt=media&token=c5f3f589-c94f-493e-b6fd-297f24e241f8"
                    alt="Click the share button"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Read the description
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        A share modal will appear with important information about the visibility
                        of your dive record. Once you share the link, the record will be publicly
                        accessible via that link. It's important to share this link only with your
                        supervisor, who will complete the verification.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FvGbTr2fJjBVXymCXaubyq6_doc.png?alt=media&token=dfc3ac07-7a8a-4c72-8b9e-f83d82f7bffd"
                    alt="Read the  description"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Click "Copy link"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Finally, copy the link provided in the modal and send it to your
                        supervisor using your preferred messaging app (e.g., Email, SMS,
                        WhatsApp). Once your supervisor has verified the record, you’ll receive a
                        notification, and your dive record will be marked with a verification
                        badge.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FxawaaSWavsXmVqcAp2BE3C_doc.png?alt=media&token=1d6b002e-30a4-4ca4-87b1-16da336529b0"
                    alt="Click 'Copy link'"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        And that’s it! You've successfully sent your dive record for Level 1
                        verification. Once your supervisor completes the process, your record will
                        be officially verified. If you need to verify additional records or move
                        to a higher verification level, feel free to explore our other guides.
                        Happy diving!
                    </p>
                </div>
            </div>

        </div>
    )
}

export default SendDiveRecordForVerificationLevelOne
