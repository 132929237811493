import axios, { GET, PATCH, POST } from 'utils/axiosClient'
import { formatRequestData, formatResponseData } from 'utils/jsonApiFormatters'

import {
    setUserId,
    setUserToken,
    setUserLanguage,
    setMercureTopicToken,
    setMercureSubscribeToken,
} from './localStorage.service'

import ENTITIES from 'constants/entities'
import { DEFAULT_LANGUAGE } from 'constants/enums'

export const loginService = async (formData, apiUrl) => {
    try {
        const {
            data: { token, user_id, language },
        } = await axios(POST, '/users/login', formData, false, apiUrl)

        if (token) {
            setUserToken(token)
            setUserId(user_id)
            setUserLanguage(language || DEFAULT_LANGUAGE)
        }

        await connectMercureService()

        return user_id
    } catch (error) {
        throw error
    }
}

export const connectMercureService = async () => {
    try {
        const {
            data: { mercureSubscribeToken, mercureTopicToken },
        } = await axios(GET, '/mercure_proxy')

        setMercureSubscribeToken(mercureSubscribeToken)
        setMercureTopicToken(mercureTopicToken)

        return { mercureSubscribeToken, mercureTopicToken }
    } catch (error) {
        throw error
    }
}

export const registerUserService = async (
    data,
    token,
    apiUrl,
    isJSONAPI = true
) => {
    try {
        const preparedData = isJSONAPI
            ? formatRequestData(ENTITIES.USER, data)
            : data
        const { data: registerData } = await axios(
            POST,
            `/users/verification/${token}`,
            preparedData,
            isJSONAPI,
            apiUrl
        )
        return registerData
    } catch (error) {
        throw error
    }
}

export const verificationUserService = async (formData) => {
    try {
        const requestData = formatRequestData(ENTITIES.USER, formData)

        const response = await axios(POST, `/users/verification`, requestData)

        const { data, meta } = formatResponseData(response.data)
        setUserId(data.id)
        setUserToken(data.token)

        await connectMercureService()

        return { data, meta }
    } catch (error) {
        throw error
    }
}

export const signUpUserService = async (data, apiUrl, isJSONAPI = true) => {
    try {
        const preparedData = isJSONAPI
            ? formatRequestData(ENTITIES.USER, data)
            : data
        const { data: signUpData } = await axios(
            POST,
            `/users/sign_up`,
            preparedData,
            isJSONAPI,
            apiUrl
        )
        return signUpData
    } catch (error) {
        throw error
    }
}

export const resendEmailUserService = async (email, apiUrl) => {
    try {
        const { data } = await axios(
            POST,
            `/users/resend_register_email/${email}`,
            email,
            false,
            apiUrl
        )

        return data
    } catch (error) {
        throw error
    }
}

export const resendCodeUserService = async (email, apiUrl) => {
    try {
        const { data } = await axios(
            POST,
            `/users/resend_verification/${email}`,
            email,
            false,
            apiUrl
        )

        return data
    } catch (error) {
        throw error
    }
}

export const forgotPasswordService = async (
    formData,
    isJSONAPI = true,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    try {
        const preparedData = isJSONAPI
            ? formatRequestData(ENTITIES.USER, formData)
            : formData
        const response = await axios(
            POST,
            '/users/forgot_password',
            preparedData,
            isJSONAPI,
            apiUrl
        )
        return response
    } catch (error) {
        throw error
    }
}

export const resetPasswordService = async (
    data,
    isJSONAPI = true,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    try {
        const { token, ...restData } = data
        const preparedData = isJSONAPI
            ? formatRequestData(ENTITIES.USER, restData)
            : restData
        await axios(
            PATCH,
            `/users/reset_password/${token}`,
            preparedData,
            isJSONAPI,
            apiUrl
        )
    } catch (error) {
        throw error
    }
}
