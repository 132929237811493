import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useContext } from 'react'

import { checkExpiryDate, formatDate } from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ALERT_TYPES } from 'constants/enums'

import CardHeader from 'components/card/CardHeader'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import Accordion from 'components/Accordion'

const VisaCard = ({
    visa,
    index,
    handleShowVisaForm,
    setVisas,
    visas,
    setEditVisa,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const {
        id,
        visaNumber,
        dateOfExpiatory,
        country,
        visaType,
        visaName,
        files,
    } = visa

    const handleEdit = () => {
        handleShowVisaForm()
        setEditVisa(visa)
    }

    const handleConfirm = async (index) => {
        try {
            const visasCopy = [...visas]
            visasCopy.splice(index, 1)
            setVisas(visasCopy)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = (index) => {
        showConfirmationModal({
            message: 'message.areYouSureDeleteVisaInfo',
            title: 'general.deleteVisa',
            handleConfirm: () => handleConfirm(index),
            handleCancel,
        })
    }

    if (!!visa.length) return null

    return (
        <CardContainer noBorderCard>
            <Accordion
                headingComponent={
                    <CardHeader
                        title={`${t('general.visa')} ${index + 1}`}
                        actions={[
                            {
                                handleAction: handleEdit,
                                icon: ICONS.EDIT,
                                iconColor: COLORS.DARK_BLUE,
                                tooltip: 'button.editVisa',
                            },
                            {
                                handleAction: () => handleDelete(index),
                                icon: ICONS.DELETE,
                                iconColor: COLORS.RED,
                                tooltip: 'button.deleteVisa',
                            },
                        ]}
                    />
                }
                withBorders
            >
                <div>
                    <Info label="form.label.visaNumber" value={visaNumber} />
                    <Info
                        label="form.label.dateOfExpiatory"
                        value={formatDate(dateOfExpiatory)}
                        textColor={checkExpiryDate(dateOfExpiatory)}
                    />
                    <Info
                        label="form.label.countryOfIssue"
                        value={country.name}
                    />
                    <Info label="form.label.visaType" value={visaType} />
                    <Info label="form.label.visaName" value={visaName} />
                    <InfoFiles label="general.documentsCopy" files={files} />
                </div>
            </Accordion>
        </CardContainer>
    )
}

VisaCard.propTypes = {
    visa: PropTypes.object,
    index: PropTypes.number,
    handleShowVisaForm: PropTypes.func,
    setVisas: PropTypes.func,
    visas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setEditVisa: PropTypes.func,
}

export default VisaCard
