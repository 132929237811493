import PropTypes from 'prop-types'

const FieldError = ({ error, className }) => {
    return (
        <span
            className={`errorMessage ${className || ''} ${
                error ? '-active' : ''
            }`}
        >
            {error}
        </span>
    )
}

FieldError.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default FieldError
