import { useTranslate } from 'react-polyglot'

import BADGE from 'constants/badges'
import { BADGES_OPTIONS } from '../constants/badgeConstants'

const useBadgeData = () => {
    const t = useTranslate()

    return {
        BADGES: [
            {
                key: BADGE.NUMBER_OF_DIVES_BRONZE,
                category: BADGES_OPTIONS.NUMBER_OF_DIVES,
                icon: BADGE.NUMBER_OF_DIVES_BRONZE,
                title: t('badge.bronzeBadge'),
                description: t('badge.bronzeBadgeDesc'),
                criteria: [
                    t('badge.bronzeBadgeCriteria1'),
                    t('badge.bronzeBadgeCriteria2'),
                    t('badge.bronzeBadgeCriteria3'),
                ],
            },
            {
                key: BADGE.NUMBER_OF_DIVES_SILVER,
                category: BADGES_OPTIONS.NUMBER_OF_DIVES,
                icon: BADGE.NUMBER_OF_DIVES_SILVER,
                title: t('badge.silverBadge'),
                description: t('badge.silverBadgeDesc'),
                criteria: [
                    t('badge.silverBadgeCriteria1'),
                    t('badge.silverBadgeCriteria2'),
                    t('badge.silverBadgeCriteria3'),
                ],
            },
            {
                key: BADGE.NUMBER_OF_DIVES_GOLD,
                category: BADGES_OPTIONS.NUMBER_OF_DIVES,
                icon: BADGE.NUMBER_OF_DIVES_GOLD,
                title: t('badge.goldBadge'),
                description: t('badge.goldBadgeDesc'),
                criteria: [
                    t('badge.goldBadgeCriteria1'),
                    t('badge.goldBadgeCriteria2'),
                    t('badge.goldBadgeCriteria3'),
                ],
            },
            {
                key: BADGE.NUMBER_OF_DIVES_PLATINUM,
                category: BADGES_OPTIONS.NUMBER_OF_DIVES,
                icon: BADGE.NUMBER_OF_DIVES_PLATINUM,
                title: t('badge.platinumBadge'),
                description: t('badge.platinumBadgeDesc'),
                criteria: [
                    t('badge.platinumBadgeCriteria1'),
                    t('badge.platinumBadgeCriteria2'),
                    t('badge.platinumBadgeCriteria3'),
                ],
            },
        ],
        JOB_QUALIFICATION_BADGES: [
            {
                key: BADGE.SCUBA_DIVING,
                category: BADGES_OPTIONS.JOB_QUALIFICATION,
                icon: BADGE.SCUBA_DIVING,
                title: t('badge.scubaDiver'),
                description: t('badge.scubaDiverDesc'),
                criteria: [
                    t('badge.scubaDiverDescCriteria1'),
                    t('badge.scubaDiverDescCriteria2'),
                    t('badge.scubaDiverDescCriteria3'),
                    {
                        type: 'list',
                        items: [
                            t('badge.scubaDiverCriteriaItem1'),
                            t('badge.scubaDiverCriteriaItem2'),
                            t('badge.scubaDiverCriteriaItem3'),
                        ],
                    },
                    t('badge.scubaDiverDescCriteria4'),
                    t('badge.scubaDiverDescCriteria5'),
                ],
            },
            {
                key: BADGE.SURFACE_SUPPLIED,
                category: BADGES_OPTIONS.JOB_QUALIFICATION,
                icon: BADGE.SURFACE_SUPPLIED,
                title: t('badge.surfaceSuppliedDiver'),
                description: t('badge.surfaceSuppliedDiverDesc'),
                criteria: [
                    t('badge.surfaceSuppliedDiverCriteria1'),
                    t('badge.surfaceSuppliedDiverCriteria2'),
                    {
                        type: 'list',
                        items: [
                            t('badge.surfaceSuppliedDiverCriteriaItem1'),
                            t('badge.surfaceSuppliedDiverCriteriaItem2'),
                            t('badge.surfaceSuppliedDiverCriteriaItem3'),
                        ],
                    },
                    t('badge.surfaceSuppliedDiverCriteria3'),
                    t('badge.surfaceSuppliedDiverCriteria4'),
                ],
            },
            {
                key: BADGE.SATURATION_BELL_DIVING,
                category: BADGES_OPTIONS.JOB_QUALIFICATION,
                icon: BADGE.SATURATION_BELL_DIVING,
                title: t('badge.closedBellDiver'),
                description: t('badge.closedBellDiverDesc'),
                criteria: [
                    t('badge.closedBellDiverCriteria1'),
                    t('badge.closedBellDiverCriteria2'),
                    {
                        type: 'list',
                        items: [
                            t('badge.closedBellDiverCriteriaItem1'),
                            t('badge.closedBellDiverCriteriaItem2'),
                            t('badge.closedBellDiverCriteriaItem3'),
                        ],
                    },
                    t('badge.closedBellDiverCriteria3'),
                    t('badge.closedBellDiverCriteria4'),
                ],
            },
            {
                key: BADGE.AIR_DIVING_SUPERVISOR,
                category: BADGES_OPTIONS.JOB_QUALIFICATION,
                icon: BADGE.AIR_DIVING_SUPERVISOR,
                title: t('badge.airDivingSupervisor'),
                description: t('badge.airDivingSupervisorDesc'),
                criteria: [
                    t('badge.airDivingSupervisorCriteria1'),
                    t('badge.airDivingSupervisorCriteria2'),
                    {
                        type: 'list',
                        items: [
                            t('badge.airDivingSupervisorCriteriaItem1'),
                            t('badge.airDivingSupervisorCriteriaItem2'),
                            t('badge.airDivingSupervisorCriteriaItem3'),
                        ],
                    },
                    t('badge.airDivingSupervisorCriteria3'),
                    t('badge.airDivingSupervisorCriteria4'),
                ],
            },
            {
                key: BADGE.BELL_SATURATION_SUPERVISOR,
                category: BADGES_OPTIONS.JOB_QUALIFICATION,
                icon: BADGE.BELL_SATURATION_SUPERVISOR,
                title: t('badge.bellSupervisor'),
                description: t('badge.bellSupervisorDesc'),
                criteria: [
                    t('badge.bellSupervisorCriteria1'),
                    t('badge.bellSupervisorCriteria2'),
                    {
                        type: 'list',
                        items: [
                            t('badge.bellSupervisorCriteriaItem1'),
                            t('badge.bellSupervisorCriteriaItem2'),
                            t('badge.bellSupervisorCriteriaItem3'),
                        ],
                    },
                    t('badge.bellSupervisorCriteria3'),
                    t('badge.bellSupervisorCriteria4'),
                ],
            },
            {
                key: BADGE.DIVER_MEDIC,
                category: BADGES_OPTIONS.JOB_QUALIFICATION,
                icon: BADGE.DIVER_MEDIC,
                title: t('badge.diverMedic'),
                description: t('badge.diverMedicDesc'),
                criteria: [
                    t('badge.diverMedicCriteria1'),
                    t('badge.diverMedicCriteria2'),
                    t('badge.diverMedicCriteria3'),
                ],
            },
        ],
        SPECIALIZED_DIVING_SKILLS_BADGES: [
            {
                key: BADGE.OIL_GAS_INDUSTRY,
                category: BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS,
                icon: BADGE.OIL_GAS_INDUSTRY,
                title: t('badge.oilIndustryDiver'),
                description: t('badge.oilIndustryDiverDesc'),
                criteria: [
                    t('badge.oilIndustryDiverCriteria1'),
                    t('badge.oilIndustryDiverCriteria2'),
                    t('badge.oilIndustryDiverCriteria3'),
                ],
            },
            {
                key: BADGE.CIVIL_ENGINEERING,
                category: BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS,
                icon: BADGE.CIVIL_ENGINEERING,
                title: t('badge.civilEngineeringDiver'),
                description: t('badge.civilEngineeringDiverDesc'),
                criteria: [
                    t('badge.civilEngineeringDiverCriteria1'),
                    t('badge.civilEngineeringDiverCriteria2'),
                    t('badge.civilEngineeringDiverCriteria3'),
                ],
            },
            {
                key: BADGE.RENEWABLE_ENERGY,
                category: BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS,
                icon: BADGE.RENEWABLE_ENERGY,
                title: t('badge.renewableEnergyDiver'),
                description: t('badge.renewableEnergyDiverDesc'),
                criteria: [
                    t('badge.renewableEnergyDiverCriteria1'),
                    t('badge.renewableEnergyDiverCriteria2'),
                    t('badge.renewableEnergyDiverCriteria3'),
                ],
            },
            {
                key: BADGE.CERTIFIED_NDT_INSPECTION,
                category: BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS,
                icon: BADGE.CERTIFIED_NDT_INSPECTION,
                title: t('badge.inspectionDiver'),
                description: t('badge.inspectionDiverDesc'),
                criteria: [
                    t('badge.inspectionDiverCriteria1'),
                    t('badge.inspectionDiverCriteria2'),
                    t('badge.inspectionDiverCriteria3'),
                    t('badge.inspectionDiverCriteria4'),
                    t('badge.inspectionDiverCriteria5'),
                ],
            },
            {
                key: BADGE.CONSTRUCTION_DIVER,
                category: BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS,
                icon: BADGE.CONSTRUCTION_DIVER,
                title: t('badge.constructionDiver'),
                description: t('badge.constructionDiverDesc'),
                criteria: [
                    t('badge.constructionDiverCriteria1'),
                    t('badge.constructionDiverCriteria2'),
                    t('badge.constructionDiverCriteria3'),
                ],
            },
        ],
    }
}

export default useBadgeData
