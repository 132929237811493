import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { DIVE_RECORD_STEPS } from 'screens/diver/diveRecord/constants/diveRecordSteps'
import {
    SUB_TAB,
    TAB,
} from 'screens/diver/profile/diverInformation/constants/tabs'

export const NOTIFICATION_LEVEL = {
    INFO: 1,
    WARNING: 2,
    DANGER: 3,
}

export const NOTIFICATION_TYPE = {
    POSITION_INVITE_SENT_TO_DIVER: 1,
    POSITION_INVITE_ACCEPTED: 2,
    POSITION_INVITE_REJECTED: 3,
    POSITION_APPLICATION_SUBMITTED_BY_DIVER: 4,
    POSITION_APPLICATION_ACCEPTED: 5,
    POSITION_APPLICATION_REJECTED: 6,
    MEDICAL_RECORD_EXPIRING: 7,
    MEDICAL_RECORD_EXPIRED: 8,
    TRAVEL_DOCUMENT_EXPIRING: 9,
    TRAVEL_DOCUMENT_EXPIRED: 10,
    CERTIFICATE_EXPIRING: 11,
    CERTIFICATE_EXPIRED: 12,
    DATA_ACCESS_REQUEST_RECEIVED: 13,
    DIVE_INVITE_EMPLOYED: 14,
    DIVE_INVITE_CANDIDATE_STATUS_CHANGE: 15,
    DIVE_RECORD_SUPERVISOR_REQUEST: 16,
    DIVE_RECORD_SUPERVISOR_ACCEPTED: 17,
    DIVE_RECORD_SUPERVISOR_REJECTED: 18,
    DIVE_INVITE_REAPPLIED: 19,
    DIVE_INVITE_CANCELED: 20,
    DIVE_INVITE_WITHDRAWN: 21,
    DIVER_REMOVED_FROM_POSITION: 22,
    NEW_PROJECT_ASSOCIATED: 30,
}

export const renderNotificationIcon = (notificationLevel) => {
    switch (notificationLevel) {
        case NOTIFICATION_LEVEL.DANGER:
            return ICONS.NOTIFICATION_DANGER
        case NOTIFICATION_LEVEL.WARNING:
            return ICONS.ALERT_WARNING
        default:
            return ICONS.ALERT_INFO
    }
}

export const notificationNavigation = (
    entityId,
    notificationType,
    userId,
    positionId,
    additionalData
) => {

    switch (notificationType) {
        case NOTIFICATION_TYPE.MEDICAL_RECORD_EXPIRED:
        case NOTIFICATION_TYPE.MEDICAL_RECORD_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.MEDICAL_INFO}`
        case NOTIFICATION_TYPE.TRAVEL_DOCUMENT_EXPIRED:
        case NOTIFICATION_TYPE.TRAVEL_DOCUMENT_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.TRAVEL_DOCUMENTATION}`
        case NOTIFICATION_TYPE.CERTIFICATE_EXPIRED:
        case NOTIFICATION_TYPE.CERTIFICATE_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.TRAINING_RECORD}&subTab=${SUB_TAB.CERTIFICATES}`
        case NOTIFICATION_TYPE.POSITION_APPLICATION_SUBMITTED_BY_DIVER:
            return `${ROUTES.PROJECT}/${entityId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=2`
        case NOTIFICATION_TYPE.POSITION_INVITE_ACCEPTED:
            return `${ROUTES.PROJECT}/${entityId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=4`
        case NOTIFICATION_TYPE.POSITION_INVITE_REJECTED:
            return `${ROUTES.PROJECT}/${entityId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=3`
        case NOTIFICATION_TYPE.DATA_ACCESS_REQUEST_RECEIVED:
            return `${ROUTES.SETTINGS}/${
                ROUTES.DATA_ACCESS_CONTROL
            }/${getDataAccessModulePath(additionalData?.module)}`
        case NOTIFICATION_TYPE.DIVE_RECORD_SUPERVISOR_REQUEST:
            return `/${ROUTES.DIVE_RECORD_SUMMARY}/${entityId}/${DIVE_RECORD_STEPS[0].route}`
        case NOTIFICATION_TYPE.DIVE_RECORD_SUPERVISOR_ACCEPTED:
            return `/${ROUTES.DIVE_RECORDS}/${entityId}`
        case NOTIFICATION_TYPE.DIVE_RECORD_SUPERVISOR_REJECTED:
            return `/${ROUTES.DIVE_RECORD}/${entityId}/${DIVE_RECORD_STEPS[0].route}`
        default:
            return `${ROUTES.PROJECT}/${entityId}`
    }
}

export const renderImageBoxBadge = (notificationType) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.POSITION_INVITE_SENT_TO_DIVER:
        case NOTIFICATION_TYPE.POSITION_APPLICATION_SUBMITTED_BY_DIVER:
            return '-applied'
        case NOTIFICATION_TYPE.POSITION_INVITE_ACCEPTED:
            return '-accepted'
        case NOTIFICATION_TYPE.POSITION_INVITE_REJECTED:
            return '-rejected'
        default:
            return '-noBadge'
    }
}

const getDataAccessModulePath = (module) => {
    switch (module) {
        case 1:
            return ROUTES.MEDICAL_INFO
        case 2:
            return ROUTES.TRAVEL_DOCUMENTATION
        case 3:
            return ROUTES.TRAINING_RECORDS
        case 4:
            return ROUTES.PERSONAL_EQUIPMENT
        case 5:
            return ROUTES.PAST_EMPLOYMENT
        case 6:
            return ROUTES.DIVE_RECORDS
        case 7:
            return ROUTES.EXPORT_CV
        case 8:
            return ROUTES.DATA_ACCESS_PROJECT
        default:
            return ROUTES.PAST_EMPLOYMENT
    }
}

export const renderCustomNotificationIcon = (notificationType) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.POSITION_APPLICATION_ACCEPTED:
        case NOTIFICATION_TYPE.DIVE_INVITE_CANDIDATE_STATUS_CHANGE:
            return { className: '-warning', icon: ICONS.APPLICATION_CANDIDATE }
        case NOTIFICATION_TYPE.POSITION_APPLICATION_REJECTED:
        case NOTIFICATION_TYPE.DIVE_INVITE_CANCELED:
        case NOTIFICATION_TYPE.DIVER_REMOVED_FROM_POSITION:
            return { className: '-rejected', icon: ICONS.APPLICATION_REJECTED }
        case NOTIFICATION_TYPE.DIVE_INVITE_EMPLOYED:
        case NOTIFICATION_TYPE.NEW_PROJECT_ASSOCIATED:
            return { className: '-accepted', icon: ICONS.APPLICATION_ACCEPTED }
        default:
            return { className: '-noBcg', icon: ICONS.APPLICATION_ACCEPTED }
    }
}

export const setImageBoxClassName = (notificationLevel, notificationType) => {
    switch (notificationLevel) {
        case NOTIFICATION_LEVEL.INFO: {
            return renderCustomNotificationIcon(notificationType).className
        }
        case NOTIFICATION_LEVEL.WARNING:
            return '-warning'
        default:
            return '-danger'
    }
}
