import PropTypes from 'prop-types'
import { deleteEntityService } from 'services/entity.service'
import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
} from 'constants/enums'
import { ALERT_TYPES, BUTTON_STATUS, BUTTON_TYPE, ICON_POSITION, INFO_VALUE_TYPE } from 'constants/enums'
import React, { useContext, useState } from 'react'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { useTranslate } from 'react-polyglot'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'
import { getProjectStatusColor } from 'utils/dataFormatters'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import { formatDate, MOMENT_FORMATS } from 'services/moment.service'
import EditHistoricalProjectModal from 'screens/diver/profile/diverInformation/modals/EditHistoricalProjectModal'
import CreateHistoricalProjectModal from 'screens/diver/profile/diverInformation/modals/CreateHistoricalProjectModal'
import Button from 'components/Button'
import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import ProjectVisibilityIcon from 'components/icons/ProjectVisabilityIcon'

const HistoricalProjectList = (props) => {
    const {myProfile, profilePage, userID, user} = props
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { userId, isVerified } =
        useContext(CurrentUserContext)
    const [historicalItem, setHistoricalItem] = useState(null)
    const [openHistoricalProjectModal, setOpenHistoricalProjectModal] =
        useState(false)
    const showMockData = !isVerified && !myProfile

    const { data, meta, isLoading, fetchData, loadMore } =
        useFetchDataByQueryParams(
            ENTITIES.HISTORICAL_PROJECT,
            {
                creator_id: !myProfile && profilePage ? userID : userId,
            },
            true,
            true,
        )

    const handleEditHistorical = (historicalProject) => {
        setHistoricalItem(historicalProject)
        setOpenHistoricalProjectModal(true)
    }

    const handleDeleteHistorical = async (id) => {
        try {
            await deleteEntityService(ENTITIES.HISTORICAL_PROJECT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            await fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDeleteHistoricalConfirmation = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteHistoricalProject',
            title: 'button.deleteHistoricalProject',
            handleConfirm: () => handleDeleteHistorical(id),
            handleCancel: () => closeConfirmationModal(),
        })

    const getActions = (item) =>  item.creatorId !== userId ? [] : [
        {
            handleAction: handleEditHistorical,
            icon: ICONS.EDIT,
            iconColor: COLORS.DARK_BLUE,
            tooltip: 'button.editProject',
        },
        {
            handleAction: (item) =>
                handleDeleteHistoricalConfirmation(item),
            icon: ICONS.DELETE,
            iconColor: COLORS.RED,
            tooltip: 'button.deleteProject',
        },
    ];

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    const status = meta?.dataAccessStatus

    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id
        ].includes(status)


    if (profilePage && !hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.PROJECT}
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profileId={userID}
                checkAccessControl={fetchData}
                profile={user}
            />
        )
    }

    return (
        <div>
            <div className="-mb20">
                {!historicalItem && profilePage && myProfile && (
                    <div className="justify-center -mb20">
                        <CreateHistoricalProjectModal
                            fetchHistoricalProjects={fetchData}
                            openHistoricalModal={openHistoricalProjectModal}
                            setOpenHistoricalProjectModal={setOpenHistoricalProjectModal}
                            setHistoricalItem={setHistoricalItem}
                        />
                    </div>
                )}
                {data.map((item, index) => (
                    <div key={index} className="-mb10">
                        <CardContainer>
                            <CardHeader
                                title={item?.name}
                                item={item}
                                statuses={[
                                    {
                                        type: 'status',
                                        name: [item?.locationTypeName],
                                        color: 'green',
                                    },
                                    {
                                        type: 'status',
                                        name: [item?.projectStatusName],
                                        color: getProjectStatusColor(
                                            item?.projectStatusCode
                                        ),
                                    },
                                ]}
                                actions={getActions(item)}
                                customIcon={
                                    <ProjectVisibilityIcon
                                        creatorId={item.creatorId}
                                        hidden={item.hidden}
                                    />
                                }
                            />
                            <Info
                                label="form.label.contractor"
                                value={item?.companyName}
                                companyID={item?.companyProfileHash}
                                valueType={
                                    !showMockData
                                        ? INFO_VALUE_TYPE.CONTRACTOR_LINK
                                        : null
                                }
                            />
                            <Info
                                label="form.label.divingMode"
                                value={item?.divingModeName}
                                color="orange"
                            />
                            <InfoRow withButtons>
                                <Info
                                    label="form.label.countryOfWork"
                                    value={item?.countryName}
                                />
                            </InfoRow>
                            {item?.regionId && (
                                <Info
                                    label="form.label.geographicalRegion"
                                    value={item?.regionName}
                                />
                            )}

                            {item?.locationDetail && (
                                <Info
                                    label="form.label.locationDetail"
                                    value={item?.locationDetail}
                                />
                            )}

                            {item?.nameOfVessel && (
                                <Info
                                    label="form.label.divingVessel"
                                    value={item?.nameOfVessel}
                                />
                            )}

                            {item?.positionId && (
                                <Info
                                    label="form.label.teamPositions"
                                    value={item?.positionName}
                                />
                            )}
                            {item?.projectScope && (
                                <Info
                                    label="form.label.scopeOfWork"
                                    value={item?.projectScope}
                                    onlyInOneLine={false}
                                    column={true}
                                />
                            )}
                            {(item?.fromDate || item?.toDate) && (
                                <InfoRow>
                                    {item?.fromDate && (
                                        <Info
                                            label="form.label.projectStart"
                                            value={formatDate(
                                                item?.fromDate,
                                                MOMENT_FORMATS.DATE_MONTHYEAR
                                            )}
                                        />
                                    )}
                                    {item?.toDate && (
                                        <Info
                                            label="form.label.projectEnd"
                                            value={formatDate(
                                                item?.toDate,
                                                MOMENT_FORMATS.DATE_MONTHYEAR
                                            )}
                                        />
                                    )}
                                </InfoRow>
                            )}
                        </CardContainer>
                    </div>
                ))}
                { loadMore && (
                    <div className="justify-center">
                        <Button
                            label="general.loadMore"
                            type={BUTTON_TYPE.BUTTON}
                            btnClass={BUTTON_STATUS.TERTIARY}
                            icon={ICONS.PLUS}
                            iconColor={COLORS.LIGHT_BLUE}
                            iconPosition={ICON_POSITION.RIGHT}
                            onClick={handleLoadMore}
                        />
                    </div>
                )}
                {renderLoader(isLoading, profilePage)}
                {historicalItem && (
                    <EditHistoricalProjectModal
                        initialData={historicalItem}
                        fetchHistoricalProjects={fetchData}
                        openHistoricalModal={openHistoricalProjectModal}
                        setOpenHistoricalProjectModal={setOpenHistoricalProjectModal}
                        setHistoricalItem={setHistoricalItem}
                    ></EditHistoricalProjectModal>
                )}
            </div>
        </div>
    )
}

HistoricalProjectList.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    companyID: PropTypes.number,
    projectStatus: PropTypes.array,
    myProfile: PropTypes.bool,
}

HistoricalProjectList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default HistoricalProjectList
