import { Fragment, useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchData from 'hooks/useFetchData'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import { mockTopJobPics } from 'data/mockData'

import Icon from 'components/Icon'

const TopJobPics = () => {
    const t = useTranslate()

    const { isVerified } = useContext(CurrentUserContext)

    const { data } = useFetchData(
        ENTITIES.POPULAR_JOBS,
        {
            itemsPerPage: 5,
        },
        isVerified
    )

    const topJobPics = isVerified ? data : mockTopJobPics

    return (
        <div className="m-topJobPics">
            <div className="column">
                <span className="a-bodyTextMedium">
                    {t('general.topJobPics')}
                </span>
                <span className="a-mediumText a-lightText -opacity-60 -mt10">
                    {t('general.basedOnYourProfile')}
                </span>
            </div>
            <div
                className={`m-topJobPics__jobs ${
                    !isVerified ? `a-blurredContainer` : ''
                }`}
            >
                {topJobPics
                    ? topJobPics.map((item, id) => {
                          return (
                              <Link
                                  key={item.id}
                                  className={`column -job ${
                                      !isVerified ? '-notClickable' : ''
                                  } -mt20`}
                                  to={
                                      isVerified
                                          ? `${ROUTES.PROJECT}/${item.projectId}`
                                          : null
                                  }
                              >
                                  <span className="a-bodyTextMedium">
                                      {item.role}
                                  </span>
                                  <span className="a-mediumText -info aligned-center -mt10">
                                      <Icon
                                          name={ICONS.DIVE_PROJECTS_OUTLINE}
                                          size={ICON_SIZE.SIZE20}
                                          color={COLORS.DARK_BLUE_60}
                                      />
                                      <span>{item.projectName}</span>
                                  </span>
                                  <span className="a-mediumText -info aligned-center -mt15">
                                      <Icon
                                          name={ICONS.BUILDING}
                                          size={ICON_SIZE.SIZE20}
                                          color={COLORS.DARK_BLUE_60}
                                      />
                                      <span>{item.companyName}</span>
                                  </span>
                                  <span className="a-mediumText -info aligned-center -mt10">
                                      <Icon
                                          name={ICONS.LOCATION}
                                          size={ICON_SIZE.SIZE20}
                                          color={COLORS.DARK_BLUE_60}
                                      />
                                      <span>
                                          {item.geographicalRegion},
                                          {item.countryOfWork}{' '}
                                      </span>
                                  </span>
                                  <span className="a-mediumText -info aligned-center -mt10">
                                      <Icon
                                          name={ICONS.WALLET}
                                          size={ICON_SIZE.SIZE20}
                                          color={COLORS.DARK_BLUE_60}
                                      />
                                      <span>
                                          {(item.fromPayRate && !item.toPayRate
                                              ? 'from '
                                              : '') +
                                              (item.fromPayRate || 0) +
                                              (item.toPayRate ? '-' : '') +
                                              (item.toPayRate || '') +
                                              '$ ' +
                                              t('general.perDay')}
                                      </span>
                                  </span>
                                  {!!item.skillPack.length && (
                                      <Fragment>
                                          <span className="a-mediumText a-lightText -mt10">
                                              {t('form.label.requiredSkills')}
                                          </span>
                                          <div className="listOfStatuses -mt5">
                                              {item.skillPack
                                                  .slice(0, 5)
                                                  .map((skillName, id) => {
                                                      return (
                                                          <span
                                                              className="a-status -blue a-captionsTextRegular"
                                                              key={id}
                                                          >
                                                              {skillName}
                                                          </span>
                                                      )
                                                  })}
                                              {item.skillPack.length > 5 && (
                                                  <span className="a-status -blue a-captionsTextRegular">
                                                      +{' '}
                                                      {item.skillPack.length -
                                                          5}
                                                  </span>
                                              )}
                                          </div>
                                      </Fragment>
                                  )}
                              </Link>
                          )
                      })
                    : null}
                {topJobPics.length === 0 && (
                    <div className="a-centeredText a-captionsTextRegular a-lightText -opacity-60 -mt20">
                        {t('general.noTopJobPics')}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TopJobPics
