import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useTranslate } from 'react-polyglot'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'

import useFetchDataById from 'hooks/useFetchDataById'
import useSaveByStep from './hooks/useSaveByStep'

import { persistEntityService } from 'services/entity.service'
import { getDefaultBreathingApparatusType } from '../../../utils/diveRecordFunctions'

import { DIVING_APPAREL } from './constants/divingApparel'
import { WETSUIT_THICKNESS } from './constants/diveRecordEntities'

import StepButtons from 'components/StepButtons'
import {
    InputField,
    MultiselectField,
    SelectField,
} from 'components/formFields'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import FocusError from '../../../components/FocusError'
import GasCylinders from './components/GasCylinders'

const EquipmentRecordFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { divingApparelProtection } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            setFieldValue(`apparelThickness`, '')
            setFieldTouched(`apparelThickness`, false)
        } else {
            firstRender.current = false
        }
    }, [divingApparelProtection])

    return null
}

const EquipmentRecord = () => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const formRef = useRef()

    const { diveRecord, handlePostSaveAction, goToStepRef } = useContext(
        DiveRecordContext
    )
    const { currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { data: diveRecordData, isLoading } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordEquipment',
                'diveRecordEquipment.divingApparelProtection',
                'diveRecordEquipment.breathingApparatusType',
                'diveRecordEquipment.otherDivingApparelProtections',
                'diveRecordEquipment.buoyancyFlotations',
                'diveRecordEquipment.otherEquipmentOutfittings',
                'diveRecordEquipment.cylinders',
                'diveRecordEquipment.cylinders.gasCylinderPurpose',
                'diveRecordEquipment.cylinders.gasContents',
            ],
        }
    )

    const [gasCylindersError, setGasCylindersError] = useState('')

    const initialData = diveRecordData
        ? diveRecordData.diveRecordEquipment
        : null

    const [gasCylinders, setGasCylinders] = useState([])

    useEffect(() => {
        if (!isLoading && diveRecordData && initialData) {
            setGasCylinders(initialData.cylinders)
        }
    }, [isLoading, diveRecordData])

    const handleSave = async (
        formData,
        formActions,
        complete = true,
        goBack = false
    ) => {
        try {
            setIsSubmitting(true)
            await formRef.current.validateForm()

            const gasCylindersError =
                !gasCylinders || gasCylinders?.length === 0

            const isValid =
                !Object.keys(formRef.current.errors).length &&
                !gasCylindersError

            if (gasCylindersError) {
                const selector = `[scroll-attribute=gasCylindersErrorScroll]`
                const errorElement = document.querySelector(selector)
                if (errorElement) {
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }

            if (complete && gasCylindersError) {
                setGasCylindersError(t('general.setGasCylindersError'))
                setIsSubmitting(false)
                return
            }

            await persistEntityService(
                ENTITIES.DIVE_RECORD_EQUIPMENT,
                {
                    ...formData,
                    cylinders: gasCylinders,
                    diveRecord: diveRecord.data,
                    complete: initialData?.complete ? isValid : complete,
                },
                diveRecord.data?.diveRecordEquipment?.id,
                ['cylinders']
            )
            handlePostSaveAction(complete, goBack, goToStepRef.current)
            setIsSubmitting(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useSaveByStep(formRef, handleSave)

    if (!diveRecordData || !diveRecord.data) {
        return null
    }

    const isImperialUnit =
        diveRecordData.unitImperial ?? currentUser.userSetting.unitImperial

    const unitOfMeasurementLabelForWeight = isImperialUnit
        ? 'form.label.lbs'
        : 'form.label.kg'

    const initialValues = {
        breathingApparatusType:
            initialData?.breathingApparatusType ??
            getDefaultBreathingApparatusType(diveRecord.data),
        divingApparelProtection: initialData?.divingApparelProtection ?? null,
        apparelThickness: initialData?.apparelThickness ?? '',
        otherDivingApparelProtections:
            initialData?.otherDivingApparelProtections ?? [],
        buoyancyFlotations: initialData?.buoyancyFlotations ?? [],
        buoyancyTotalWeight: initialData?.buoyancyTotalWeight ?? '',
        otherEquipmentOutfittings: initialData?.otherEquipmentOutfittings ?? [],
        cylinders: gasCylinders,
    }

    const requiredMessage = t('form.error.required')

    const validateWetsuitThickness = () => {
        return Yup.string().test(
            'apparelThickness',
            (value, { parent, createError }) => {
                if (parent['divingApparelProtection'] && !value) {
                    return createError({
                        message: t('form.error.required'),
                        path: 'apparelThickness',
                    })
                }
                return true
            }
        )
    }

    const validation = Yup.object().shape({
        breathingApparatusType: Yup.object().required(requiredMessage),
        divingApparelProtection: Yup.object().nullable(),
        apparelThickness: validateWetsuitThickness(),
        otherDivingApparelProtections: Yup.array().notRequired(),
        buoyancyFlotations: Yup.array().notRequired(),
        buoyancyTotalWeight: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber')),
        otherEquipmentOutfittings: Yup.array().notRequired(),
    })

    return (
        <Fragment>
            <div className="_wr -contentElements -diveRecordContent">
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={handleSave}
                    validationSchema={validation}
                >
                    {({
                        values: { divingApparelProtection },
                        isSubmitting,
                    }) => {
                        return (
                            <Form>
                                <FocusError />
                                <EquipmentRecordFormContext
                                    initialValues={initialValues}
                                />
                                <div className="_wr">
                                    <div className="_w">
                                        <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                            {t(
                                                'general.breathingApparatusType'
                                            )}
                                        </span>

                                        <div className="_12 _m4">
                                            <SelectField
                                                name="breathingApparatusType"
                                                entityType={
                                                    ENTITIES.BREATHING_APPARATUS_TYPE
                                                }
                                                searchable
                                                required
                                            />
                                        </div>
                                        <Separator />
                                    </div>

                                    <div className="_w">
                                        <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                            {t('general.equipment')}
                                        </span>

                                        <div className="_12 _m4">
                                            <SelectField
                                                name="divingApparelProtection"
                                                label={
                                                    'form.label.divingWetSuit'
                                                }
                                                placeholder={
                                                    'form.placeholder.divingWetSuit'
                                                }
                                                entityType={
                                                    ENTITIES.DIVING_APPAREL_PROTECTION
                                                }
                                                searchable
                                            />
                                        </div>
                                        {divingApparelProtection &&
                                            (divingApparelProtection.code ===
                                                DIVING_APPAREL.ONE_PART ||
                                                divingApparelProtection.code ===
                                                    DIVING_APPAREL.TWO_PART) && (
                                                <div className="_12 _m4 ">
                                                    <SelectField
                                                        name="apparelThickness"
                                                        defaultOptions={
                                                            WETSUIT_THICKNESS
                                                        }
                                                        displayAttribute={null}
                                                        searchable
                                                        required
                                                    />
                                                </div>
                                            )}
                                        <div className="_12 _m4">
                                            <MultiselectField
                                                name="otherDivingApparelProtections"
                                                entityType={
                                                    ENTITIES.OTHER_DIVING_APPAREL_PROTECTION
                                                }
                                                searchable
                                                createNew
                                            />
                                        </div>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <MultiselectField
                                                name="buoyancyFlotations"
                                                entityType={
                                                    ENTITIES.BUOYANCY_FLOTATION
                                                }
                                                searchable
                                            />
                                        </div>
                                        <div className="_12 _m4 ">
                                            <InputField
                                                name="buoyancyTotalWeight"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units={
                                                    unitOfMeasurementLabelForWeight
                                                }
                                            />
                                        </div>
                                        <div className="_12 _m4">
                                            <MultiselectField
                                                name="otherEquipmentOutfittings"
                                                entityType={
                                                    ENTITIES.OTHER_EQUIPMENT_OUTFITTING
                                                }
                                                searchable
                                                createNew
                                            />
                                        </div>

                                        <Separator />
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </Form>
                        )
                    }}
                </Formik>
                <GasCylinders
                    gasCylinders={gasCylinders}
                    setGasCylinders={setGasCylinders}
                    error={gasCylindersError}
                />
            </div>
            <StepButtons
                backlabel="button.back"
                skipLabel="button.saveAndContinueLater"
                handleNext={() => formRef?.current?.handleSubmit()}
                handleSkip={() =>
                    handleSave(formRef?.current?.values, null, false)
                }
                handleBack={() =>
                    handleSave(formRef?.current?.values, null, false, true)
                }
                canSkip
                disableNext={isSubmitting}
            />
            {(isLoading ||
                (formRef?.current?.isSubmitting &&
                    gasCylinders?.length > 0)) && <Loader />}
        </Fragment>
    )
}

export default EquipmentRecord
