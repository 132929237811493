import PropTypes from 'prop-types'

const Separator = ({ fullWidth, marginTop }) => {
    return (
        <div
            className={`a-separator -after -pt${marginTop} ${
                fullWidth ? `-fullWidth -mt${marginTop}` : '-mt15'
            }`}
        ></div>
    )
}

Separator.propTypes = {
    fullWidth: PropTypes.bool,
    marginTop: PropTypes.number,
}

Separator.defaultProps = {
    fullWidth: false,
    marginTop: 25,
}

export default Separator
