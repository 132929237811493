import React from 'react'
import { useTranslate } from 'react-polyglot'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'
import { formatGteLteRange } from 'utils/filterButtonFormatters'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { BUTTON_STATUS, BUTTON_TYPE, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'
import {
    JOBS_INDUSTRY_TYPE_KEY,
    JOBS_SKILL_CATEGORY_TYPE_KEY,
    PROJECT_TYPE_KEY,
    SKILL_TYPE_KEY,
} from 'constants/projectFilterKeys'
import { JOBS_SEARCH_FILED } from 'constants/constants'
import FILTERS from 'constants/filters'

import Button from 'components/Button'
import JobCard from 'components/jobList/JobCard'
import SearchAndFilter from 'components/SearchAndFilter'
import JobsFilters from './JobsFilters'
import NoData from 'components/NoData'

const filters = {
    company: { entityType: ENTITIES.COMPANY, displayAttribute: 'name' },
    countryOfWork: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    industry: { entityType: ENTITIES.INDUSTRY, displayAttribute: 'name' },
    projectType: {
        entityType: ENTITIES.PROJECT_INDUSTRY_TYPES,
        displayAttribute: 'name',
    },
    modeOfDive: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    geographicalRegion: {
        entityType: ENTITIES.REGION,
        displayAttribute: 'name',
    },
    locationType: {
        entityType: ENTITIES.LOCATION_TYPE,
        displayAttribute: 'name',
    },
    countryOfMobilization: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    bodyOfWater: {
        entityType: ENTITIES.BODY_OF_WATER,
        displayAttribute: 'name',
    },
    positionType: {
        entityType: ENTITIES.DIVER_POSITION,
        displayAttribute: 'name',
    },
    keyword: { type: 'text' },
    projectName: { type: 'text' },
    toDurationDays: {
        type: 'range',
        unit: 'days',
        formatter: formatGteLteRange,
    },
    fromPayRate: {
        unit: '$',
    },
    skillCategory: {
        entityType: ENTITIES.SKILL_CATEGORY,
        displayAttribute: 'name',
    },
    skill: {
        entityType: ENTITIES.SKILL,
        displayAttribute: 'name',
    },
}

const JobList = () => {
    const t = useTranslate()

    const {
        data: jobs,
        meta,
        isLoading,
        fetchData,
        loadMore,
    } = useFetchDataByQueryParams(ENTITIES.JOBS_ELASTIC, {}, true)

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.JOBS
    )

    const condition =
        jobs.length === 0 &&
        Object.keys(activeFilters).length === 0 &&
        !isLoading

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    const handleFilterItemRemoved = (key, filters) => {
        if (
            key === JOBS_INDUSTRY_TYPE_KEY &&
            filters.hasOwnProperty(PROJECT_TYPE_KEY)
        ) {
            delete filters[PROJECT_TYPE_KEY]
        }
        if (
            key === JOBS_SKILL_CATEGORY_TYPE_KEY &&
            filters.hasOwnProperty(SKILL_TYPE_KEY)
        ) {
            delete filters[SKILL_TYPE_KEY]
        }

        return filters
    }

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchJob"
                activeFilters={activeFilters}
                filters={filters}
                showDefaultKey={false}
                searchKey={JOBS_SEARCH_FILED}
                modifyFiltersOnRemove={handleFilterItemRemoved}
            >
                <JobsFilters activeFilters={activeFilters} />
            </SearchAndFilter>
            {jobs.map((item) => {
                return (
                    <div key={item.id} className="-mb10 a-jobCard">
                        <JobCard item={item}></JobCard>
                    </div>
                )
            })}
            {jobs.length === 0 &&
                Object.keys(activeFilters).length !== 0 &&
                !isLoading && (
                    <NoData
                        icon={ICONS.SEARCH_ICON}
                        title={t('general.noResults')}
                        description={t('general.noSearchResultsDesc')}
                    />
                )}
            {showEmptyValueMessage &&
                Object.keys(activeFilters).length === 0 && (
                    <>
                        {Object.keys(activeFilters).length === 0 && (
                            <NoData
                                icon={ICONS.NO_DATA}
                                title={t('general.noDataResults')}
                                description={t('general.noProjectResultsDesc')}
                            />
                        )}
                    </>
                )}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {renderLoader(isLoading, null, isLoadingFilters)}
        </div>
    )
}

export default JobList
