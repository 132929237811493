import React from 'react'
import PropTypes from 'prop-types'

const Dropdown = ({
    children,
    dropdownRef,
    handleReachedBottom,
    open,
    notificationDropdown,
    mainSearchDropdown,
    dropup,
    explanation,
}) => {
    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target
        const bottom = scrollTop + clientHeight >= scrollHeight - 1
        if (bottom) {
            handleReachedBottom()
        }
    }

    return (
        <div
            ref={dropdownRef}
            className={`m-dropdown ${open ? '-open' : ''} ${
                notificationDropdown ? '-notification' : ''
            } ${mainSearchDropdown ? '-mainSearch' : ''} ${
                dropup ? '-dropup' : ''
            }`}
        >
            <div className="-dropdownContainer">
                {explanation && (
                    <span className="a-captionsTextRegular a-lightText -opacity-60 -result -noHover -explanation">
                        {explanation}
                    </span>
                )}
                <div
                    className={`-dropdownOptions  ${
                        explanation ? '-withExplanation' : ''
                    }`}
                    onScroll={handleReachedBottom ? handleScroll : undefined}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

Dropdown.propTypes = {
    notificationDropdown: PropTypes.bool,
    mainSearchDropdown: PropTypes.bool,
    dropup: PropTypes.bool,
    explanation: PropTypes.string,
}

Dropdown.defaultProps = {
    notificationDropdown: false,
    mainSearchDropdown: false,
    dropup: false,
}

export default Dropdown
