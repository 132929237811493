import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import { refetchProfile } from '../../utils/fetchHelper'

import ENTITIES from 'constants/entities'
import { MEDICAL_EXAM_TYPE, MEDICAL_RECORD_TYPE } from '../../constants'

import MedicalRecordForm from '../MedicalRecordForm'

const CreateMedicalRecord = ({
    open,
    setOpen,
    fetchMedicalRecords,
    filteredData,
    refetchData,
}) => {
    const handleSubmit = async (formData) => {
        try {
            await createEntityService(ENTITIES.MEDICAL_RECORD, formData, true)
            fetchMedicalRecords()

            const condition =
                formData?.medicalRecordType.id ===
                    MEDICAL_RECORD_TYPE.CLINICAL_EXAM_FORM.id &&
                [
                    MEDICAL_EXAM_TYPE.ANNUAL.id,
                    MEDICAL_EXAM_TYPE.SEMI_ANNUAL.id,
                ].includes(formData?.medicalExam.id)

            refetchData && refetchProfile(condition, filteredData, refetchData)
        } catch (error) {
            throw error
        }
    }

    return (
        <MedicalRecordForm
            title="general.addNewMedicalRecord"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

CreateMedicalRecord.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchMedicalRecords: PropTypes.func,
    filteredData: PropTypes.array,
    refetchData: PropTypes.func,
}

CreateMedicalRecord.defaultProps = {
    showActions: false,
}

export default CreateMedicalRecord
