import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

const NumberOfDivesPlatinumBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <circle cx="25.5" cy="25" r="23" fill={COLORS.DARK_BLUE_15} />
            <g filter="url(#filter0_i_21987_4729)">
                <circle
                    cx="25.4996"
                    cy="24.9999"
                    r="19.0943"
                    fill={COLORS.SILVER}
                />
            </g>
            <mask
                id="mask0_21987_4729"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="47"
                height="46"
            >
                <circle cx="25.5" cy="25" r="23" fill={COLORS.DARK_BLUE_15} />
            </mask>
            <g mask="url(#mask0_21987_4729)">
                <path
                    opacity="0.3"
                    d="M40.2544 2L49.767 11.5126L13.1828 48.0967L3.67025 38.5841L40.2544 2Z"
                    fill="white"
                />
                <path
                    opacity="0.3"
                    d="M25.6309 -4.5094L30.9308 0.790498L-5.65338 37.3746L-10.9533 32.0747L25.6309 -4.5094Z"
                    fill="white"
                />
                <path
                    opacity="0.3"
                    d="M56.8765 16.3208L62.1764 21.6207L25.5922 58.2048L20.2923 52.9049L56.8765 16.3208Z"
                    fill="white"
                />
            </g>
            <path
                d="M11.7516 29.0528V21.5843L12.9866 22.7445H10.2842V20.5934H14.6375V29.0528H11.7516Z"
                fill={COLORS.DARK_BLUE_60}
            />
            <path
                d="M19.5651 29.2462C18.8069 29.2462 18.1344 29.073 17.5474 28.7265C16.9686 28.3801 16.5121 27.8766 16.1778 27.2159C15.8518 26.5553 15.6887 25.7577 15.6887 24.8231C15.6887 23.8885 15.8518 23.0909 16.1778 22.4303C16.5121 21.7696 16.9686 21.2661 17.5474 20.9197C18.1344 20.5732 18.8069 20.4 19.5651 20.4C20.3232 20.4 20.9917 20.5732 21.5705 20.9197C22.1575 21.2661 22.614 21.7696 22.9401 22.4303C23.2743 23.0909 23.4414 23.8885 23.4414 24.8231C23.4414 25.7577 23.2743 26.5553 22.9401 27.2159C22.614 27.8766 22.1575 28.3801 21.5705 28.7265C20.9917 29.073 20.3232 29.2462 19.5651 29.2462ZM19.5651 26.9621C19.7526 26.9621 19.9197 26.8977 20.0664 26.7688C20.2132 26.6399 20.3273 26.4183 20.4088 26.1041C20.4985 25.7899 20.5433 25.3629 20.5433 24.8231C20.5433 24.2753 20.4985 23.8483 20.4088 23.5421C20.3273 23.2279 20.2132 23.0063 20.0664 22.8774C19.9197 22.7485 19.7526 22.6841 19.5651 22.6841C19.3776 22.6841 19.2105 22.7485 19.0637 22.8774C18.917 23.0063 18.7988 23.2279 18.7091 23.5421C18.6276 23.8483 18.5868 24.2753 18.5868 24.8231C18.5868 25.3629 18.6276 25.7899 18.7091 26.1041C18.7988 26.4183 18.917 26.6399 19.0637 26.7688C19.2105 26.8977 19.3776 26.9621 19.5651 26.9621Z"
                fill={COLORS.DARK_BLUE_60}
            />
            <path
                d="M28.0317 29.2462C27.2736 29.2462 26.601 29.073 26.0141 28.7265C25.4353 28.3801 24.9787 27.8766 24.6445 27.2159C24.3184 26.5553 24.1554 25.7577 24.1554 24.8231C24.1554 23.8885 24.3184 23.0909 24.6445 22.4303C24.9787 21.7696 25.4353 21.2661 26.0141 20.9197C26.601 20.5732 27.2736 20.4 28.0317 20.4C28.7899 20.4 29.4584 20.5732 30.0372 20.9197C30.6241 21.2661 31.0807 21.7696 31.4068 22.4303C31.741 23.0909 31.9081 23.8885 31.9081 24.8231C31.9081 25.7577 31.741 26.5553 31.4068 27.2159C31.0807 27.8766 30.6241 28.3801 30.0372 28.7265C29.4584 29.073 28.7899 29.2462 28.0317 29.2462ZM28.0317 26.9621C28.2192 26.9621 28.3864 26.8977 28.5331 26.7688C28.6798 26.6399 28.794 26.4183 28.8755 26.1041C28.9652 25.7899 29.01 25.3629 29.01 24.8231C29.01 24.2753 28.9652 23.8483 28.8755 23.5421C28.794 23.2279 28.6798 23.0063 28.5331 22.8774C28.3864 22.7485 28.2192 22.6841 28.0317 22.6841C27.8442 22.6841 27.6771 22.7485 27.5304 22.8774C27.3836 23.0063 27.2654 23.2279 27.1758 23.5421C27.0942 23.8483 27.0535 24.2753 27.0535 24.8231C27.0535 25.3629 27.0942 25.7899 27.1758 26.1041C27.2654 26.4183 27.3836 26.6399 27.5304 26.7688C27.6771 26.8977 27.8442 26.9621 28.0317 26.9621Z"
                fill={COLORS.DARK_BLUE_60}
            />
            <path
                d="M36.5831 29.2462C35.8249 29.2462 35.1524 29.073 34.5654 28.7265C33.9866 28.3801 33.5301 27.8766 33.1958 27.2159C32.8698 26.5553 32.7067 25.7577 32.7067 24.8231C32.7067 23.8885 32.8698 23.0909 33.1958 22.4303C33.5301 21.7696 33.9866 21.2661 34.5654 20.9197C35.1524 20.5732 35.8249 20.4 36.5831 20.4C37.3412 20.4 38.0097 20.5732 38.5885 20.9197C39.1755 21.2661 39.632 21.7696 39.9581 22.4303C40.2923 23.0909 40.4595 23.8885 40.4595 24.8231C40.4595 25.7577 40.2923 26.5553 39.9581 27.2159C39.632 27.8766 39.1755 28.3801 38.5885 28.7265C38.0097 29.073 37.3412 29.2462 36.5831 29.2462ZM36.5831 26.9621C36.7706 26.9621 36.9377 26.8977 37.0844 26.7688C37.2312 26.6399 37.3453 26.4183 37.4268 26.1041C37.5165 25.7899 37.5613 25.3629 37.5613 24.8231C37.5613 24.2753 37.5165 23.8483 37.4268 23.5421C37.3453 23.2279 37.2312 23.0063 37.0844 22.8774C36.9377 22.7485 36.7706 22.6841 36.5831 22.6841C36.3956 22.6841 36.2285 22.7485 36.0817 22.8774C35.935 23.0063 35.8168 23.2279 35.7271 23.5421C35.6456 23.8483 35.6048 24.2753 35.6048 24.8231C35.6048 25.3629 35.6456 25.7899 35.7271 26.1041C35.8168 26.4183 35.935 26.6399 36.0817 26.7688C36.2285 26.8977 36.3956 26.9621 36.5831 26.9621Z"
                fill={COLORS.DARK_BLUE_60}
            />
            <defs>
                <filter
                    id="filter0_i_21987_4729"
                    x="6.40527"
                    y="5.90552"
                    width="38.5423"
                    height="38.5426"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.353846" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21987_4729"
                    />
                </filter>
            </defs>
        </svg>
    )
}

NumberOfDivesPlatinumBadge.propTypes = {
    size: PropTypes.number,
}

NumberOfDivesPlatinumBadge.defaultProps = {
    size: 50,
}

export default NumberOfDivesPlatinumBadge
