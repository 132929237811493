import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import COLORS from 'constants/colors'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import { Table } from 'components/table'
import CreateAvailablePositions from './CreateAvailablePositions'
import EditAvailablePositions from './EditAvailablePositions'
import SkillsList from './SkillsList'
import Loader from 'components/Loader'

const TABLE_HEADER = [
    {
        key: 'diverPosition.name',
        title: 'table.header.positionType',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
    },
    {
        key: 'numOfWorkers',
        title: 'table.header.numOfWorkers',
        showOnSmallScreens: true,
        smallScreenHeader: true,
    },
    {
        key: 'payRatePerDay',
        title: 'table.header.payRatePerDay',
        showOnSmallScreens: true,
        smallScreenHeader: true,
    },
    {
        key: 'description',
        title: 'table.header.positionDescription',
        showOnSmallScreens: true,
        smallScreenHeader: true,
    },
    {
        key: 'projectPositionSkill',
        title: 'table.header.skills',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        CellComponent: SkillsList,
    },
]

const AvailablePositions = ({
    availablePositions,
    setAvailablePositions,
    projectId,
    positionsError,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [create, setCreate] = useState(false)
    const [editItem, setEditItem] = useState()
    const [editItemIndex, setEditItemIndex] = useState()

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const { data: positionsWithEmployees, isLoading } = useFetchData(
        ENTITIES.EMPLOYED_PROJECT_POSITIONS,
        {
            projectId: projectId,
        },
        projectId !== undefined,
        false
    )

    const handleConfirmPositionDelete = (index) => {
        const newAvailablePositions = [...availablePositions]
        newAvailablePositions.splice(index, 1)
        setAvailablePositions(newAvailablePositions)
        closeConfirmationModal()
    }

    const handleCancel = () => closeConfirmationModal()

    const handleCreate = () => {
        setCreate(true)
    }

    const handleEdit = (item, index) => {
        setEditItem(item)
        setEditItemIndex(index)
    }

    const handleDelete = (item, index) => {
        if (
            positionsWithEmployees.some((position) => position.id === item.id)
        ) {
            setAlert(t('form.error.notAllowedDeletion'), ALERT_TYPES.ERROR)
        } else {
            showConfirmationModal({
                confirmLabel: 'button.deleteProjectPosition',
                message: 'message.areYouSureDeleteProjectPosition',
                title: 'general.deleteProjectPosition',
                handleConfirm: () => handleConfirmPositionDelete(index),
                handleCancel,
            })
        }
    }

    return (
        <div>
            <div className="_wr">
                <div className="-mt20 -titleAndButton">
                    <span className="a-bodyTextMedium">
                        {t('general.availablePositions')}{' '}
                        <span className="redStar">*</span>
                    </span>
                    <Button
                        label="button.addPosition"
                        btnClass={BUTTON_STATUS.SECONDARY}
                        buttonSize={BUTTON_SIZE.MEDIUM}
                        type={BUTTON_TYPE.SUBMIT}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        onClick={handleCreate}
                    />
                </div>
            </div>
            {positionsError && availablePositions.length === 0 && (
                <span className="_12 errorMessage -active">
                    {positionsError}
                </span>
            )}
            <Table
                headerItems={TABLE_HEADER}
                data={availablePositions}
                totalItems={availablePositions.length}
                fullWidthTitle
                paginate={false}
                rowDropdownActions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        tooltip: 'button.edit',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleDelete,
                        icon: ICONS.DELETE,
                        iconColor: 'red',
                        tooltip: 'button.delete',
                        title: 'general.deleteTitle',
                        confirmationMessage: 'general.deleteConfirmTitle',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                ]}
            />
            {create && (
                <CreateAvailablePositions
                    availablePositions={availablePositions}
                    setAvailablePositions={setAvailablePositions}
                    setCreate={setCreate}
                />
            )}
            {editItem && (
                <EditAvailablePositions
                    editItem={editItem}
                    setEditItem={setEditItem}
                    setAvailablePositions={setAvailablePositions}
                    editItemIndex={editItemIndex}
                    setEditItemIndex={setEditItemIndex}
                />
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default AvailablePositions
