import React from 'react'
import PropTypes from 'prop-types'

import useFetchData from 'hooks/useFetchData'

import { SELECT_VALUE_TYPE } from 'constants/enums'

import Checkbox from 'components/Checkbox'
import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'

const CheckboxGroup = ({
    label,
    value,
    setValue,
    entityType,
    params,
    defaultOptions,
    isJSONAPI,
    apiUrl,
    displayAttribute,
    required,
    error,
    disabled,
    valueType,
    hasFrame,
    translate,
    showLabel,
    column,
    ...props
}) => {
    const { data } = useFetchData(
        entityType,
        params,
        true,
        false,
        false,
        defaultOptions,
        isJSONAPI,
        apiUrl
    )

    const handleChange = (event, item) => {
        const { checked } = event.target

        switch (valueType) {
            case SELECT_VALUE_TYPE.STRING:
                const newItem = item?.id || item
                checked
                    ? setValue([...value, newItem])
                    : setValue(value.filter((item) => item !== newItem))
                break
            case SELECT_VALUE_TYPE.OBJECT:
            default:
                checked
                    ? setValue([...value, item])
                    : setValue(
                          value.filter(
                              (el) => (el.id || el) !== (item.id || item)
                          )
                      )
                break
        }
    }

    const getIsChecked = (item) => {
        if (valueType === SELECT_VALUE_TYPE.OBJECT && item.id) {
            return value?.some((el) => el.id === item.id)
        }
        return value?.some((el) => el === (item.id || item))
    }

    return (
        <div
            className={`m-checkboxGroup ${hasFrame ? '-frame' : ''} ${
                error ? '-error' : ''
            } ${column ? '-column' : ''}`}
        >
            {showLabel && (
                <FieldLabel
                    className="m-checkboxGroup__label"
                    label={label || `form.label.${props.name}`}
                    required={required}
                />
            )}
            <div className="m-checkboxGroup__options">
                {data.map((item) => {
                    const itemId = item.id || item
                    const itemName = item.name || item
                    return (
                        <Checkbox
                            key={itemId}
                            name={itemId}
                            groupName={props.name}
                            label={itemName}
                            value={getIsChecked(item)}
                            onChange={(e) => handleChange(e, item)}
                            disabled={disabled}
                            translate={translate}
                        />
                    )
                })}
            </div>
            {error && <FieldError className="errorMessage" error={error} />}
        </div>
    )
}

export const CheckboxGroupMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    entityType: PropTypes.string,
    params: PropTypes.object,
    defaultOptions: PropTypes.array,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    required: PropTypes.bool,
    displayAttribute: PropTypes.string,
    disabled: PropTypes.bool,
    valueType: PropTypes.oneOf([
        SELECT_VALUE_TYPE.STRING,
        SELECT_VALUE_TYPE.OBJECT,
    ]),
    hasFrame: PropTypes.bool,
    translate: PropTypes.bool,
    showLabel: PropTypes.bool,
}

CheckboxGroup.propTypes = {
    ...CheckboxGroupMainPropTypes,
    value: PropTypes.array.isRequired,
    setValue: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

CheckboxGroup.defaultProps = {
    defaultOptions: [],
    displayAttribute: 'name',
    valueType: SELECT_VALUE_TYPE.OBJECT,
    hasFrame: false,
    required: false,
    disabled: false,
    translate: false,
    showLabel: true,
}

export default CheckboxGroup
