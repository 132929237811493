const MEDICAL_EXAM_TYPE = {
    DIVER_MEDICAL_EXAM: { id: 1, name: 'Diver Medical Exam' },
    OFFSHORE_MEDICAL_EXAM: { id: 2, name: 'Offshore medical exam' },
    STANDARD_OCCUPATIONAL_MEDICAL_EXAM: {
        id: 3,
        name: 'Standard occupational medical exam',
    },
    ANNUAL: { id: 4, name: 'Annual' },
    SEMI_ANNUAL: { id: 5, name: 'Semi Annual' },
}

const MEDICAL_RECORD_TYPE = {
    ANNUAL: { id: 1, name: 'Annual' },
    CORRECTIVE_FOLLOW_UP: { id: 2, name: 'Corrective / Follow up' },
    BIENNIAL: { id: 3, name: 'Biennial' },
    CLINICAL_EXAM_FORM: { id: 10, name: 'Clinical exam form' },
}

export { MEDICAL_EXAM_TYPE, MEDICAL_RECORD_TYPE }
