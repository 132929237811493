import React from 'react'
import PropTypes from 'prop-types'

import { ROLES } from 'constants/enums'
import ROUTES from 'constants/routes'

import { Link } from 'react-router-dom'

const UserWithRoleCell = ({ value }) => {
    const { fullName, roles, profileHash, companyProfileHash, companyName } = value

    const isDiver = roles[0] === ROLES.ROLE_DIVER.name

    const profileRoute = isDiver
        ? `${ROUTES.DIVER_PROFILE}/${profileHash}`
        : `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${companyProfileHash}`

    const title = isDiver
        ? fullName
        : companyName

    return (
        <div className="aligned-start column">
            <Link className="a-link" to={profileRoute}>
                {title}
            </Link>
            <span className="a-captionsTextRegular a-lightText -opacity-60">
                {roles.map((roleKey) => ROLES[roleKey].label).join(', ')}
            </span>
        </div>
    )
}

UserWithRoleCell.propTypes = {
    value: PropTypes.object.isRequired,
}

export default UserWithRoleCell
