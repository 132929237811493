import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'

const ChangeSettingsItem = ({
    title,
    label,
    value,
    btnLabel,
    ModalComponent,
}) => {
    const t = useTranslate()

    const { isSmallScreen } = useWindowDimensions()

    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <div
                className={`space-between aligned-center -mb20 ${
                    isSmallScreen ? 'flex-wrap' : ''
                }`}
            >
                {title && (
                    <span className="a-mediumTextSemiBold">{t(title)}</span>
                )}
                {label && (
                    <span
                        className={`a-mediumText ${
                            isSmallScreen ? '-mb20' : ''
                        }`}
                    >
                        {`${t(label)}: `}
                        <span className="a-mediumTextSemiBold">{value}</span>
                    </span>
                )}
                <div
                    className={
                        isSmallScreen && label ? 'fullWidth justify-end' : ''
                    }
                >
                    <Button
                        label={btnLabel}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.ARROW_RIGHT}
                        iconPosition={ICON_POSITION.RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        onClick={() => setOpen(true)}
                    />
                </div>
            </div>
            {open && <ModalComponent open={open} setOpen={setOpen} />}
        </Fragment>
    )
}

ChangeSettingsItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    btnLabel: PropTypes.string,
    ModalComponent: PropTypes.func,
}

export default ChangeSettingsItem
