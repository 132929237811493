import React, { useEffect, useRef } from 'react'
import SignaturePad from 'react-signature-pad-wrapper'
import PropTypes from 'prop-types'

import { BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'

import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import COLORS from 'constants/colors'
import Button from './Button'

const getCroppedCanvasImage = (canvas) => {
    let originalCtx = canvas.getContext('2d', { willReadFrequently: true })

    let originalWidth = canvas.width
    let originalHeight = canvas.height
    let imageData = originalCtx.getImageData(
        0,
        0,
        originalWidth,
        originalHeight
    )

    let minX = originalWidth + 1,
        maxX = -1,
        minY = originalHeight + 1,
        maxY = -1,
        x = 0,
        y,
        currentPixelColorValueIndex

    for (y = 0; y < originalHeight; y++) {
        for (x = 0; x < originalWidth; x++) {
            currentPixelColorValueIndex = (y * originalWidth + x) * 4
            let currentPixelAlphaValue =
                imageData.data[currentPixelColorValueIndex + 3]
            if (currentPixelAlphaValue > 0) {
                if (minX > x) minX = x
                if (maxX < x) maxX = x
                if (minY > y) minY = y
                if (maxY < y) maxY = y
            }
        }
    }

    let croppedWidth = maxX - minX
    let croppedHeight = maxY - minY
    if (croppedWidth < 0 || croppedHeight < 0) return null
    let cuttedImageData = originalCtx.getImageData(
        minX,
        minY,
        croppedWidth,
        croppedHeight
    )

    let croppedCanvas = document.createElement('canvas'),
        croppedCtx = croppedCanvas.getContext('2d', {
            willReadFrequently: true,
        })

    croppedCanvas.width = croppedWidth
    croppedCanvas.height = croppedHeight
    croppedCtx.putImageData(cuttedImageData, 0, 0)

    return croppedCanvas.toDataURL()
}

const Signature = ({
    value,
    onChange,
    label,
    required,
    error,
    disabled,
    showLabel,
    setTouched,
    preview,
    ...props
}) => {
    const signaturePadRef = useRef()

    useEffect(() => {
        const handleEndStroke = () => {
            onChange(
                getCroppedCanvasImage(
                    signaturePadRef.current.signaturePad.canvas
                )
            )
            if (setTouched) setTouched(false)
        }

        if (signaturePadRef.current && signaturePadRef.current.signaturePad) {
            signaturePadRef.current.signaturePad.addEventListener(
                'endStroke',
                handleEndStroke,
                false
            )

            return () => {
                signaturePadRef.current?.signaturePad.removeEventListener(
                    'endStroke',
                    handleEndStroke
                )
            }
        }
    }, [signaturePadRef])

    useEffect(() => {
        if (value && signaturePadRef.current?.isEmpty()) {
            signaturePadRef.current?.fromDataURL(value)
        }
    }, [])

    const handleClear = () => {
        signaturePadRef.current?.clear()
        if (setTouched) setTouched(true)
        onChange('')
    }

    return (
        <div
            className={`m-signature ${error ? '-error' : ''} ${
                disabled || preview ? '-disabled' : ''
            } ${preview ? '-preview' : ''}`}
        >
            {showLabel && !preview && (
                <FieldLabel
                    htmlFor={props.name}
                    label={label || `form.label.${props.name}`}
                    required={required}
                />
            )}
            <SignaturePad
                ref={signaturePadRef}
                options={{ penColor: COLORS.DARK_BLUE }}
                height={preview ? 50 : 150}
                redrawOnResize
            />
            {error && !preview && (
                <FieldError className="errorMessage" error={error} />
            )}
            {!preview && (
                <div className="clear">
                    <Button
                        label="button.clearSignature"
                        icon={ICONS.CLOSE}
                        iconColor={COLORS.LIGHT_BLUE}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        onClick={handleClear}
                    />
                </div>
            )}
        </div>
    )
}

export const SignatureMainPropTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
    preview: PropTypes.bool,
}

Signature.propTypes = {
    ...SignatureMainPropTypes,
    value: PropTypes.any,
    onChange: PropTypes.func,
    setTouched: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

Signature.defaultProps = {
    required: false,
    disabled: false,
    showLabel: true,
    preview: false,
}

export default Signature
