import React from 'react'
import { useTranslate } from 'react-polyglot'

import { ACCESS_CONTROL_STATUSES } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const { NEW, REJECTED, REQUESTED_AGAIN, GRANTED } = ACCESS_CONTROL_STATUSES

const DenyAccessAction = ({ handleChangeAccess, status, noFiles }) => {
    const t = useTranslate()

    if (
        ![NEW.id, REQUESTED_AGAIN.id].includes(status) &&
        !(status === GRANTED.id && noFiles)
    )
        return null

    return (
        <div
            className="a-access__select -deny"
            onClick={() => handleChangeAccess(REJECTED.id)}
        >
            <Icon name={ICONS.DENY_ACCESS} />
            {t('general.denyAccess')}
        </div>
    )
}

export default DenyAccessAction
