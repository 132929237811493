import { useTranslate } from 'react-polyglot'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import useQueryParams from 'hooks/useQueryParams'

import { BADGES_OPTIONS } from './constants/badgeConstants'
import useBadgeData from './data/Badges'

import TabNavigation from 'components/tabs/TabNavigation'
import BadgeContent from './components/BadgeContent'

const Badges = () => {
    const t = useTranslate()
    const searchParams = useLocation().search
    const {
        subTab = BADGES_OPTIONS.NUMBER_OF_DIVES,
        filterParams: { badgeId },
    } = useQueryParams()

    useEffect(() => {
        if (badgeId) {
            const element = document.getElementById(badgeId)
            if (element) {
                const top = element.offsetTop
                window.scrollTo({
                    top,
                    behavior: 'smooth',
                })
            }
        }
    }, [badgeId])

    const {
        BADGES,
        JOB_QUALIFICATION_BADGES,
        SPECIALIZED_DIVING_SKILLS_BADGES,
    } = useBadgeData()

    const navTabs = [
        {
            key: BADGES_OPTIONS.NUMBER_OF_DIVES,
            title: 'badge.numberOfDives',
            queryParams: searchParams,
        },
        {
            key: BADGES_OPTIONS.JOB_QUALIFICATION,
            title: 'badge.jobQualification',
            queryParams: searchParams,
        },
        {
            key: BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS,
            title: 'badge.specializedDivingSkills',
            queryParams: searchParams,
        },
    ]

    const badgesData = {
        [BADGES_OPTIONS.NUMBER_OF_DIVES]: {
            data: BADGES,
            paragraph: 'badge.availableBadgesParagraph',
        },
        [BADGES_OPTIONS.JOB_QUALIFICATION]: {
            data: JOB_QUALIFICATION_BADGES,
            paragraph: 'badge.jobQualificationsParagraph',
        },
        [BADGES_OPTIONS.SPECIALIZED_DIVING_SKILLS]: {
            data: SPECIALIZED_DIVING_SKILLS_BADGES,
            paragraph: 'badge.specializedBadgesParagraph',
        },
    }

    const renderTabContent = () => <BadgeContent {...badgesData[subTab]} />

    return (
        <div>
            <div className="-mb20">
                <span className=" a-bodyTextMedium">{t('badge.badges')}</span>
            </div>
            <p className="a-mediumText a-lightText -mb10">
                {t('badge.badgesFirstParagraph')}
            </p>
            <p className="a-mediumText a-lightText -mb10">
                {t('badge.badgesSecondParagraph')}
            </p>
            <p className="a-mediumText a-lightText -mb20">
                {t('badge.badgesThirdParagraph')}
            </p>
            <p className="a-mediumText a-lightText -mb20">
                {t('badge.badgesFourthParagraph')}
            </p>
            <div className="-mb10">
                <span className="a-mediumTextSemiBold">
                    {t('badge.availableBadges')}
                </span>
            </div>
            <TabNavigation tabs={navTabs} tabQueryParam="subTab" />
            {renderTabContent()}
        </div>
    )
}

export default Badges
