import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-polyglot'

import formatSize from 'utils/fileSize'

import IconButton from 'components/IconButton'

import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

const ProgressBar = ({
    progressObject,
    setHidePercentageBar,
    setProgressObject,
    rejectedFiles,
}) => {
    const t = useTranslate()

    useEffect(() => {
        if (Object.keys(progressObject.files).length === 0) {
            setHidePercentageBar(true)
            setProgressObject((progress) => {
                return {
                    ...progress,
                    files: {},
                }
            })
        }
    }, [progressObject.files])

    // Filter files that are completed (100%)
    const completedFiles = useMemo(() => {
        return Object.keys(progressObject.files).filter(
            (fileId) => progressObject.files[fileId].percentage === 100
        )
    }, [progressObject.files])

    // Filter files that are not completed (less than 100%)
    const ongoingFiles = useMemo(() => {
        return Object.keys(progressObject.files).filter(
            (fileId) => progressObject.files[fileId].percentage < 100
        )
    }, [progressObject.files])

    // Filter files that failed
    const failedFiles = useMemo(() => {
        return Object.keys(progressObject.files).filter(
            (fileId) => progressObject.files[fileId].error
        )
    }, [progressObject.files])

    const displayText = useMemo(() => {
        return ongoingFiles.length > 0
            ? t('general.uploading')
            : t('general.uploaded')
    }, [progressObject])

    const handleClose = () => {
        setHidePercentageBar(true)
        setProgressObject((progress) => {
            return {
                ...progress,
                files: {},
            }
        })
    }

    return (
        <div className="m-gallery__box -uploading -mb20">
            <div className="-textAndButton -mb20">
                <span className="a-bodyTextBold a-lightText">
                    {`${displayText} ${
                        Object.keys(progressObject.files).length
                    } ${
                        Object.keys(progressObject.files).length === 1
                            ? t('general.photo')
                            : t('general.photos')
                    }`}
                </span>
                <IconButton
                    icon={ICONS.CLOSE}
                    iconColor={COLORS.SECONDARY}
                    iconSize={ICON_SIZE.SIZE20}
                    onClick={() => {
                        handleClose()
                    }}
                />
            </div>

            {/* Display ongoing uploads */}
            {ongoingFiles.length > 0 && (
                <>
                    {ongoingFiles.map((fileId, key) => {
                        const file = progressObject.files[fileId]
                        return (
                            <div
                                className="aligned-start column -mb20"
                                key={key}
                            >
                                <div className="aligned-center -gap10 -nameAndSize">
                                    <span className="a-mediumText -fileName">
                                        {file.name}
                                    </span>
                                    <span className="a-captionsTextRegular">
                                        {file.size && formatSize(file.size)}
                                    </span>
                                </div>
                                <div className="-barAndButton">
                                    <div className="-progressBar">
                                        <div className="-track"></div>
                                        <div
                                            className="-progress"
                                            style={{
                                                maxWidth: `${file.percentage}%`,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <span className="a-captionsTextRegular">
                                    {Math.round(
                                        (file.percentage + Number.EPSILON) * 100
                                    ) / 100}
                                    %
                                </span>
                            </div>
                        )
                    })}
                </>
            )}

            {/* Display completed uploads */}
            {completedFiles.length > 0 && (
                <div className="completed-files-section">
                    {completedFiles.map((fileId, key) => {
                        const file = progressObject.files[fileId]
                        return (
                            <div
                                className="aligned-start column -mb20"
                                key={key}
                            >
                                <div className="aligned-center -gap10 -nameAndSize">
                                    <span className="a-mediumText -fileName">
                                        {file.name}
                                    </span>
                                    <span className="a-captionsTextRegular a-lightText">
                                        {file.size && formatSize(file.size)}
                                    </span>
                                </div>
                                <span className="a-captionsTextRegular -greenText">
                                    {t('general.uploaded')}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )}

            {/* Display failed uploads */}
            {(failedFiles.length > 0 || rejectedFiles.length > 0) && (
                <div className="failed-files-section">
                    {failedFiles.map((fileId, key) => {
                        const file = progressObject.files[fileId]
                        return (
                            <div
                                className="aligned-start column -mb20"
                                key={key}
                            >
                                <div className="aligned-center -gap10 -nameAndSize">
                                    <span className="a-mediumText -fileName">
                                        {file.name}
                                    </span>
                                    <span className="a-captionsTextRegular a-lightText">
                                        {file.size && formatSize(file.size)}
                                    </span>
                                </div>
                                <span className="a-captionsTextRegular -redText">
                                    {file.error || t('general.uploadFailed')}
                                </span>
                            </div>
                        )
                    })}
                    {rejectedFiles.map((file, key) => {
                        return (
                            <div
                                className="aligned-start column -mb20"
                                key={key}
                            >
                                <div className="aligned-center -gap10 -nameAndSize">
                                    <span className="a-mediumText -fileName">
                                        {file.file.name}
                                    </span>
                                    <span className="a-captionsTextRegular a-lightText">
                                        {file.file.size &&
                                            formatSize(file.file.size)}
                                    </span>
                                </div>
                                <span className="a-captionsTextRegular -redText">
                                    {t('general.uploadFailed')}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

ProgressBar.propTypes = {
    progressObject: PropTypes.object.isRequired,
}

export default ProgressBar
