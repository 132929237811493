import PropTypes from 'prop-types'
import { INDUSTRY_TYPE_KEY, PROJECT_TYPE_KEY } from 'constants/projectFilterKeys'
import useFormikFieldChange from 'hooks/useFormikFieldChange'

const ProjectFilterFormikContext = ({ emptyValues }) => {

    const industryChangedHandler = (values, setFieldValue) => {
        setFieldValue(PROJECT_TYPE_KEY, emptyValues[PROJECT_TYPE_KEY])
    }

    useFormikFieldChange([INDUSTRY_TYPE_KEY], industryChangedHandler)
}

ProjectFilterFormikContext.propTypes = {
    emptyValues: PropTypes.shape({
        [PROJECT_TYPE_KEY]: PropTypes.array
    })
}

export default ProjectFilterFormikContext
