import React, { Fragment, useState } from 'react'

import Modal from 'components/Modal'
import FilterIconButton from 'components/FilterIconButton'

const CompanyListFilters = () => {
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <FilterIconButton setOpen={setOpen} />
            {open && (
                <Modal
                    open={open}
                    setOpen={setOpen}
                    closeOnClickOutside={false}
                    title="Coming soon"
                    noTranslateTitle={true}
                ></Modal>
            )}
        </Fragment>
    )
}

export default CompanyListFilters
