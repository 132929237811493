import React, { useState, useCallback, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useField } from 'formik'
import * as Dropzone from 'react-dropzone/dist/index'
import heic2any from 'heic2any'

import { AlertContext } from 'contexts/AlertContext'

import { formatResponseData } from 'utils/jsonApiFormatters'
import axios, { POST } from 'utils/axiosClient'

import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'
import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'
import FileModal from 'components/FileModal'

const FileUploadField = ({
    name,
    label,
    entityType,
    uploadEntity,
    fetchEntity,
    isJSONAPI,
    apiUrl,
    multipleUpload,
    reload,
    required,
    showLabel,
    disabled,
    canDelete,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [field, meta, helpers] = useField(name)

    const { value } = field
    const { setValue } = helpers

    const error = meta.touched && meta.error

    const [uploaded, setUploaded] = useState(value)
    const [openFile, setOpenFile] = useState(null)

    useEffect(() => {
        setValue(uploaded)
    }, [uploaded])

    useEffect(() => {
        setUploaded(value)
    }, [reload])

    const onDrop = useCallback(
        async (acceptedFiles) => {
            for (const element of acceptedFiles) {
                const formData = new FormData()
                let acceptedData = element
                let fileName = element.name

                if (['image/heic', 'image/heif'].includes(element.type)) {
                    // Convert .heic image to .jpg format
                    let convertedImage = await heic2any({
                        blob: element,
                        toType: 'image/jpeg', // Convert to JPEG format
                    })
                    acceptedData = convertedImage
                    fileName = element.name.replace('.heic', '.jpeg')
                }

                formData.append('file', acceptedData, fileName)

                axios(
                    POST,
                    `/${entityType}/${uploadEntity}`,
                    formData,
                    isJSONAPI,
                    apiUrl
                )
                    .then(async ({ data }) => {
                        let file = data

                        if (isJSONAPI) {
                            const { data: uploadedFile } =
                                formatResponseData(data)
                            file = uploadedFile
                        }

                        if (multipleUpload) {
                            setUploaded((prev) => [...prev, file])
                        } else {
                            setUploaded(file)
                        }
                    })
                    .catch((error) => {
                        setAlert(error, ALERT_TYPES.ERROR, t)
                    })
            }
        },
        [setValue]
    )
    const { getRootProps, getInputProps, isDragActive } = Dropzone.useDropzone({
        onDrop,
        disabled,
    })

    const handleDeleteSingleItem = (e, index) => {
        e.stopPropagation()
        if (multipleUpload) {
            const uploadedNew = [...uploaded]
            uploadedNew.splice(index, 1)
            setUploaded(uploadedNew)
        } else {
            setUploaded(null)
        }
    }

    const handleOpenPreview = (item) => {
        setOpenFile(item)
    }

    const uploadedFiles = multipleUpload ? uploaded : [uploaded]

    return (
        <div className={`m-addAndUpload ${disabled ? '-disabled' : ''}`}>
            {showLabel && (
                <FieldLabel
                    label={label || `form.label.${name}`}
                    required={required}
                    className="-mb5 _12 _l6"
                />
            )}
            <div className="m-addAndUpload__box">
                <div {...getRootProps()} className="_12 _l6">
                    <div className="a-file">
                        <input {...getInputProps()} />
                        <div className="icon">
                            <Icon
                                name={ICONS.UPLOAD}
                                size={ICON_SIZE.SIZE20}
                                color={COLORS.LIGHT_BLUE}
                            />
                        </div>

                        <p className="a-mediumText">
                            {isDragActive
                                ? `${t('form.placeholder.dropFile')}`
                                : `${t('form.placeholder.dragNDrop')}`}
                        </p>
                    </div>
                </div>
                {uploaded && !!uploadedFiles.length && (
                    <div className="files _12 _l6">
                        {uploadedFiles?.map((item, index) => (
                            <div key={item.id} className="file a-link">
                                <Icon
                                    name={ICONS.DOCUMENT}
                                    color={COLORS.LIGHT_BLUE}
                                    size={ICON_SIZE.SIZE20}
                                />
                                <span
                                    className="-fileName"
                                    onClick={() => handleOpenPreview(item)}
                                >
                                    {item.originalName}
                                </span>
                                {canDelete && (
                                    <span
                                        onClick={(e) =>
                                            handleDeleteSingleItem(e, index)
                                        }
                                        className="a-file__multiple--remove"
                                    >
                                        <Icon
                                            name={ICONS.DELETE}
                                            size={20}
                                            color={COLORS.RED}
                                        />
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {openFile && (
                <FileModal
                    file={openFile}
                    uploaded={multipleUpload ? uploaded : [uploaded]}
                    setOpenFile={setOpenFile}
                    fetchEntity={fetchEntity}
                />
            )}
            {error && <FieldError className="_12" error={error} />}
        </div>
    )
}

FileUploadField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    entityType: PropTypes.string,
    uploadEntity: PropTypes.string,
    fetchEntity: PropTypes.string,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    multipleUpload: PropTypes.bool,
    required: PropTypes.bool,
    showLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    canDelete: PropTypes.bool,
    reload: PropTypes.bool,
}

FileUploadField.defaultProps = {
    entityType: ENTITIES.FILE,
    uploadEntity: 'upload',
    fetchEntity: ENTITIES.FILES_GET,
    isJSONAPI: true,
    multipleUpload: true,
    required: false,
    showLabel: true,
    disabled: false,
    canDelete: true,
}

export default FileUploadField
