import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { PACKAGE_INFO_DATA } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const FeaturesDataRow = ({ info, activePackage }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const renderCellData = (item, index) => {
        return item.values ? (
            item.values[index]
        ) : (
            <Icon name={ICONS.CHECKMARK_SMALL} />
        )
    }

    return (
        <Fragment>
            {info.map((item) => {
                return (
                    <tr key={item.key}>
                        <td className="a-mediumText -pt10 -pb10">
                            <div className="-pt10 -pb10">
                                {t(`features.${item.key}`)}
                            </div>
                        </td>
                        {(isTablet ? [activePackage] : PACKAGE_INFO_DATA).map(
                            (packageItem, index) => (
                                <td className="centered-text" key={index}>
                                    <div
                                        className={`centered-text -pt10 -pb10 aligned-center  ${
                                            isTablet
                                                ? 'justify-end'
                                                : 'justify-center'
                                        }`}
                                    >
                                        <span className="a-mediumTextSemiBold a-lightText -opacity-60">
                                            {item.packages.includes(
                                                packageItem.id
                                            )
                                                ? renderCellData(item, index)
                                                : ''}
                                        </span>
                                    </div>
                                </td>
                            )
                        )}
                    </tr>
                )
            })}
        </Fragment>
    )
}

FeaturesDataRow.propTypes = {
    info: PropTypes.array,
    activePackage: PropTypes.object,
}

export default FeaturesDataRow
