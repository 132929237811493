import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

export const DIVE_RECORD_STEPS = [
    {
        id: 1,
        name: 'general.generalInformation',
        icon: ICONS.INFO_CIRCLE,
        route: ROUTES.GENERAL_INFORMATION,
    },
    {
        id: 2,
        name: 'general.equipment',
        icon: ICONS.INFO_CIRCLE,
        route: ROUTES.EQUIPMENT,
    },
    {
        id: 3,
        name: 'general.diveProfileData',
        icon: ICONS.INFO_CIRCLE,
        route: ROUTES.DIVE_PROFILE,
    },
    {
        id: 4,
        name: 'general.workPerformed',
        icon: ICONS.INFO_CIRCLE,
        route: ROUTES.WORK_PERFORMED,
    },
    {
        id: 5,
        name: 'general.divePerformance',
        icon: ICONS.INFO_CIRCLE,
        route: ROUTES.DIVE_SUMMARY,
    },
    {
        id: 6,
        name: 'general.validationVerification',
        icon: ICONS.INFO_CIRCLE,
        route: ROUTES.VERIFICATION,
    },
]
