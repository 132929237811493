import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'

import DivingModeExperienceForm from './DivingModeExperienceForm'

const EditDivingModeExperience = ({
    data,
    open,
    setOpen,
    handleClose,
    fetchData,
    refetchData,
    showActions,
    divingMode,
}) => {
    const t = useTranslate()

    const handleSubmit = async (formData) => {
        await editEntityService(ENTITIES.EXPERIENCE, data.id, formData, true, [
            'experienceDivingModes',
        ])
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <DivingModeExperienceForm
            title={`${t('general.editExperienceIn')} ${divingMode.name} ${t(
                'general.divingMode'
            )}`}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            initialData={data}
            handleSubmit={handleSubmit}
            divingMode={divingMode}
        />
    )
}

EditDivingModeExperience.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleClose: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    showActions: PropTypes.bool,
    divingMode: PropTypes.object,
}

EditDivingModeExperience.defaultProps = {
    showActions: false,
}

export default EditDivingModeExperience
