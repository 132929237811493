import PropTypes from 'prop-types'
import useWindowDimensions from 'hooks/useWindowDimension'

const CardFooter = ({ children, withBorder, withTopMargin }) => {
    const { isMobile } = useWindowDimensions()

    const justifyEnd = children?.length
        ? children.filter((item) => item).length === 1
        : true

    if (!children) return null

    return (
        <div
            className={`a-stepCard__footer fullWidth aligned-center -gap10
                ${withBorder ? 'a-separator -top -dashedtop -pt15 -mt20' : ''} 
                ${withTopMargin ? '-mt15' : ''} 
                ${justifyEnd ? 'justify-end' : 'space-between'} ${
                    isMobile ? 'column fullWidth' : ''
                } `}
        >
            {children}
        </div>
    )
}

CardFooter.propTypes = {
    withBorder: PropTypes.bool,
    withTopMargin: PropTypes.bool,
}

CardFooter.defaultProps = {
    withBorder: false,
    withTopMargin: false,
}

export default CardFooter
