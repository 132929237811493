import PropTypes from 'prop-types'
import {
    JOBS_INDUSTRY_TYPE_KEY,
    JOBS_SKILL_CATEGORY_TYPE_KEY,
    PROJECT_TYPE_KEY,
    SKILL_TYPE_KEY,
} from 'constants/projectFilterKeys'
import useFormikFieldChange from 'hooks/useFormikFieldChange'

const JobsFilterFormikContext = ({ emptyValues }) => {
    const industryChangedHandler = (values, setFieldValue) => {
        setFieldValue(PROJECT_TYPE_KEY, emptyValues[PROJECT_TYPE_KEY])
    }

    const skillChangedHandler = (values, setFieldValue) => {
        setFieldValue(SKILL_TYPE_KEY, emptyValues[SKILL_TYPE_KEY])
    }

    useFormikFieldChange([JOBS_INDUSTRY_TYPE_KEY], industryChangedHandler)
    useFormikFieldChange([JOBS_SKILL_CATEGORY_TYPE_KEY], skillChangedHandler)
}

JobsFilterFormikContext.propTypes = {
    emptyValues: PropTypes.shape({
        [PROJECT_TYPE_KEY]: PropTypes.array,
        [SKILL_TYPE_KEY]: PropTypes.array,
    }),
}

export default JobsFilterFormikContext
