import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { formatDate, MOMENT_FORMATS } from 'services/moment.service'

import useFetchDataById from 'hooks/useFetchDataById'

import { BUTTON_SIZE, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import { PROFILE_STEPS } from 'constants/constants'

import Button from 'components/Button'
import SettingsItem from './components/SettingsItem'
import FocusError from 'components/FocusError'
import { SelectField } from 'components/formFields'
import SwitchSettingsItem from './components/SwitchSettingsItem'
import IdentityVerification from '../Homepage/components/IdentityVerification'

const GeneralPreferences = () => {
    const t = useTranslate()

    const { userId, currentUser, isDiver } = useContext(CurrentUserContext)

    const initialValues = {
        languages: '',
        darkMode: false,
    }

    const { data } = useFetchDataById(ENTITIES.DIVER_PROFILE, userId)

    if (!data) return null

    const stepsToComplete = PROFILE_STEPS.filter(
        ({ key }) => data.completionProgress[key] === 0
    )

    const profileCompletion = stepsToComplete?.length

    const isVerified = currentUser?.identityVerified
    const personaApprovalDate = currentUser?.personaApprovalDate
        ? formatDate(currentUser.personaApprovalDate, MOMENT_FORMATS.DATE_API)
        : '/'

    const message = isVerified
        ? `${t('general.verifiedByPersona')} ${personaApprovalDate}`
        : `${t('general.notVerifiedByPersona')}`

    // NOTE: Should be implemented on different task
    const handleSubmit = () => {
        console.log('To be done')
    }

    const handleCancel = () => {
        console.log('To be done')
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form className="_wr space-between clearPadding">
                <FocusError />
                <div className="column aligned-inherit space-between">
                    <div className="_w">
                        <span className="a-bodyTextMedium fullWidth _12">
                            {t('general.generalPreferences')}
                        </span>
                        <div className="-mt20 _12">
                            <SettingsItem
                                title="general.languages"
                                note="notes.featureUnderDevelopment"
                                description="general.changeTheAppLanguage"
                            >
                                <div className="-mt20 _12 _m6 clearPadding">
                                    <SelectField
                                        name="languages"
                                        label="form.label.selectLanguage"
                                        placeholder="form.placeholder.english"
                                        required
                                        disabled
                                    />
                                </div>
                            </SettingsItem>
                        </div>
                        <div className="-mt10 _12">
                            <SettingsItem
                                title="general.appMode"
                                note="notes.featureUnderDevelopment"
                            >
                                <SwitchSettingsItem
                                    label="general.switchToDarkMode"
                                    name="darkMode"
                                    disabled
                                    tooltip="general.darkModeWillBeSoon"
                                />
                            </SettingsItem>
                        </div>
                        {isDiver && (
                            <div className="-mt20 _12">
                                <SettingsItem title="general.verification">
                                    <div className="fullWidth -mt10 space-between -gap10 aligned-center flex-wrap">
                                        <span className="a-lightText a-mediumText">
                                            {profileCompletion > 0
                                                ? t(
                                                      'general.youNeedToCompleteYourProfile'
                                                  )
                                                : message}
                                        </span>

                                        {profileCompletion === 0 &&
                                            !currentUser.identityVerified && (
                                                <IdentityVerification
                                                    settingsPage={true}
                                                />
                                            )}
                                    </div>
                                </SettingsItem>
                            </div>
                        )}
                    </div>
                    <div className="_w space-between -buttons -mt30">
                        <div className="-mt20 -prevButton _12 _m6 _l4">
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                additionalClasses="fullWidth"
                                type={BUTTON_TYPE.BUTTON}
                                label="button.discard"
                                buttonSize={BUTTON_SIZE.LARGE}
                                onClick={handleCancel}
                                disabled
                            />
                        </div>
                        <div className="-mt20 -nextButton _12 _m6 ofs_l4 _l4">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                additionalClasses="fullWidth"
                                type={BUTTON_TYPE.SUBMIT}
                                buttonSize={BUTTON_SIZE.LARGE}
                                label="button.saveChanges"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}

export default GeneralPreferences
