import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { getProfileTabs } from './constants/tabs'

import TabNavigation from 'components/tabs/TabNavigation'
import UserInfo from './components/UserInfo'

const DiverInformation = ({ myProfile, userID, user, setOpen }) => {
    const t = useTranslate()

    return (
        <div className="diverInformation">
            <div className="-box -mb20">
                <UserInfo
                    user={user}
                    myProfile={myProfile}
                    userID={userID}
                    setOpen={setOpen}
                />
            </div>

            <div className="-box">
                <p className="-mb20 a-bodyTextMedium">
                    {t('general.personalInformation')}
                </p>

                <TabNavigation
                    tabs={getProfileTabs(user, myProfile)}
                    tabQueryParam="tab"
                    columnView
                    scrollToTarget={false}
                />
            </div>
        </div>
    )
}

DiverInformation.propTypes = {
    myProfile: PropTypes.bool,
    userID: PropTypes.number,
    user: PropTypes.object,
    setOpen: PropTypes.func,
}

DiverInformation.defaultProps = {
    myProfile: false,
}

export default DiverInformation
