import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'

import { AlertContext } from 'contexts/AlertContext'

import { editEntityService } from 'services/entity.service'

import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchData from 'hooks/useFetchData'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'

import NotificationsSettingsItem from './components/notifications/NotificationsSettingsItem'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Note from 'components/Note'

const Notifications = () => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [notificationItems, setNotificationItems] = useState()

    const { isTablet } = useWindowDimensions()

    const {
        data: { data },
    } = useFetchData(
        ENTITIES.NOTIFICATION_SETTINGS,
        {},
        true,
        false,
        false,
        [],
        false
    )

    useEffect(() => {
        setNotificationItems(data ?? [])
    }, [data])

    if (!notificationItems) return null

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)

            const data = formData.reduce((acc, { items }) => {
                acc.push(...items.map(({ id, enabled }) => ({ id, enabled })))
                return acc
            }, [])

            const { data: responseData } = await editEntityService(
                ENTITIES.NOTIFICATION_SETTINGS,
                null,
                { data },
                false
            )

            setNotificationItems(responseData)

            setAlert(
                t('message.successfullyChangedNotificationSettings'),
                ALERT_TYPES.SUCCESS
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    if (!notificationItems.length) return null

    return (
        <Formik
            initialValues={notificationItems}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ isSubmitting, handleReset, dirty }) => (
                <Form className="_wr space-between clearPadding">
                    <div className="column aligned-inherit space-between">
                        <div className="_w">
                            <h6 className="_12 a-bodyTextBold -mb20">
                                {t('general.notifications')}
                            </h6>
                            <Note note="message.featureUnderDevelopment" />
                            {!isTablet && (
                                <div className="_12 space-between aligned-center -mb10">
                                    <div className="a-mediumText">
                                        {t('general.feature')}
                                    </div>
                                    <div className="space-between -gap30">
                                        <div
                                            className="a-mediumText"
                                            title={t(
                                                'general.inAppNotificationsDescription'
                                            )}
                                        >
                                            {t('general.inApp')}
                                        </div>
                                        <div
                                            className="a-mediumText"
                                            title={t(
                                                'general.emailNotificationsDescription'
                                            )}
                                        >
                                            {t('general.email')}
                                        </div>
                                        <div
                                            className="a-mediumText"
                                            title={t(
                                                'general.pushNotificationsDescription'
                                            )}
                                        >
                                            {t('general.push')}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {notificationItems.map(
                                ({ module, items }, index) => (
                                    <NotificationsSettingsItem
                                        key={index}
                                        index={index}
                                        module={module}
                                        items={items}
                                    />
                                )
                            )}
                        </div>
                        <div className="_w space-between -buttons -mt30">
                            <div className="-mt20 -prevButton _12 _m6 _l4">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    additionalClasses="fullWidth"
                                    label="button.discard"
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    onClick={handleReset}
                                    disabled={isSubmitting || !dirty}
                                />
                            </div>
                            <div className="-mt20 -nextButton _12 _m6 ofs_l4 _l4">
                                <Button
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    additionalClasses="fullWidth"
                                    type={BUTTON_TYPE.SUBMIT}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    label="button.saveChanges"
                                    disabled={isSubmitting || !dirty}
                                />
                            </div>
                        </div>
                    </div>
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

export default Notifications
