import React from 'react'
import { useField } from 'formik'

import LocationMap, { LocationMapMainPropTypes } from 'components/LocationMap'

const LocationMapField = (props) => {
    const [field, meta, helpers] = useField(props)
    const { setValue, setTouched } = helpers

    const hasError = meta.touched && meta.error

    return (
        <LocationMap
            {...field}
            {...props}
            value={field.value}
            setValue={setValue}
            setTouched={setTouched}
            error={hasError}
        />
    )
}

LocationMapField.propTypes = {
    ...LocationMapMainPropTypes,
}

export default LocationMapField
