import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const DiveRecordsAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.DIVE_RECORDS}
            accessTypeLabel="form.label.selectAccessForDiveRecords"
            dataAccessAttribute="accessDiveRecord"
            title="general.diveRecords"
        />
    )
}

export default DiveRecordsAccessControl
