import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

const DiverMedicBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                d="M36.5 3.15C36.5 2.51487 35.9851 2 35.35 2H14.65C14.0149 2 13.5 2.51487 13.5 3.15V12.35C13.5 12.9851 12.9851 13.5 12.35 13.5H3.15C2.51487 13.5 2 14.0149 2 14.65V35.35C2 35.9851 2.51487 36.5 3.15 36.5H12.35C12.9851 36.5 13.5 37.0149 13.5 37.65V46.85C13.5 47.4851 14.0149 48 14.65 48H35.35C35.9851 48 36.5 47.4851 36.5 46.85V37.65C36.5 37.0149 37.0149 36.5 37.65 36.5H46.85C47.4851 36.5 48 35.9851 48 35.35V14.65C48 14.0149 47.4851 13.5 46.85 13.5H37.65C37.0149 13.5 36.5 12.9851 36.5 12.35V3.15Z"
                fill={COLORS.RED}
            />
            <g filter="url(#filter0_i_21877_21025)">
                <path
                    d="M33.1794 6.66678C33.1794 6.04369 32.6742 5.53857 32.0512 5.53857H17.9486C17.3255 5.53857 16.8204 6.04369 16.8204 6.66678V15.6924C16.8204 16.3155 16.3153 16.8206 15.6922 16.8206H6.66654C6.04344 16.8206 5.53833 17.3257 5.53833 17.9488V32.0514C5.53833 32.6745 6.04345 33.1796 6.66654 33.1796H15.6922C16.3153 33.1796 16.8204 33.6847 16.8204 34.3078V43.3334C16.8204 43.9565 17.3255 44.4617 17.9486 44.4617H32.0512C32.6742 44.4617 33.1794 43.9565 33.1794 43.3334V34.3078C33.1794 33.6847 33.6845 33.1796 34.3076 33.1796H43.3332C43.9563 33.1796 44.4614 32.6745 44.4614 32.0514V17.9488C44.4614 17.3257 43.9563 16.8206 43.3332 16.8206H34.3076C33.6845 16.8206 33.1794 16.3155 33.1794 15.6924V6.66678Z"
                    fill={COLORS.SILVER}
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.623 18.9937C29.4727 18.8968 29.3475 18.945 29.2719 19.0907C29.2719 19.0907 29.297 18.8968 29.3475 18.6055L29.297 18.5568C28.9965 18.2656 28.4952 17.9742 28.1442 17.8042C27.0915 17.2701 25.8882 17.0515 24.7105 17.0758C23.5572 17.1001 22.3793 17.3184 21.3263 17.828C20.9757 17.9984 20.5245 18.2897 20.2485 18.5567L20.2236 18.5812C20.2485 18.7755 20.2987 18.945 20.3488 19.1151C20.3488 19.1151 20.1484 18.8968 19.8976 19.1151C19.5971 19.4309 19.9226 20.1596 20.023 20.5231C20.0983 20.7906 20.1984 20.9601 20.2736 21.1062C20.2736 21.1307 20.2736 21.1789 20.2736 21.2033C20.2736 21.4703 20.3737 21.6646 20.5245 21.6646C21.1759 24.0197 23.106 26.0103 24.8106 26.0103C26.4144 26.0103 28.3695 24.0197 28.9965 21.6646C29.147 21.6646 29.247 21.4459 29.247 21.2033L29.2218 21.1307C29.3221 21.0094 29.3975 20.8149 29.4977 20.5232C29.5979 20.2077 29.9739 19.2368 29.623 18.9937Z"
                fill={COLORS.DARK_BLUE_20}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4555 15.4979H25.2325V14.5268H26.1851V13.7742H25.2325V12.8032H24.4555V13.7742H23.528V14.5268H24.4555V15.4979ZM29.4937 17.8534V15.5345V13.2156C29.4937 13.2156 24.8565 9.47696 20.2444 13.2156V17.8534C20.2444 17.8534 21.723 16.518 24.8065 16.4693C27.8892 16.421 29.4937 17.8534 29.4937 17.8534Z"
                fill={COLORS.RED}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9285 26.1051C22.079 26.0312 22.2578 26.0544 22.393 26.1534C22.9698 26.576 23.5196 26.7812 23.8737 26.8636C24.0374 26.9017 24.1839 27.0087 24.2435 27.1659L24.7425 28.4814C24.7705 28.5554 24.8751 28.5554 24.9031 28.4814L25.4021 27.1659C25.4617 27.0087 25.6082 26.9017 25.7719 26.8636C26.126 26.7812 26.6758 26.576 27.2526 26.1534C27.3878 26.0544 27.5666 26.0312 27.7171 26.1051C29.0282 26.7492 31.4251 28.0007 32.21 28.6932C33.0095 29.3987 33.3862 30.5987 33.4899 31.5453C33.4994 31.6313 33.4813 31.7181 33.433 31.7899C32.6972 32.8848 29.9386 34.5706 24.8229 34.5706C19.5215 34.5706 16.9365 32.8851 16.2123 31.7902C16.1646 31.718 16.1464 31.6313 16.1558 31.5453C16.2596 30.5987 16.6361 29.3987 17.4356 28.6932C18.2205 28.0007 20.6174 26.7492 21.9285 26.1051ZM29.9326 30.0475H26.9778V30.6631H29.9326V30.0475Z"
                fill={COLORS.RED}
            />
            <defs>
                <filter
                    id="filter0_i_21877_21025"
                    x="5.53833"
                    y="5.53857"
                    width="39.2769"
                    height="39.2769"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.176923" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21025"
                    />
                </filter>
            </defs>
        </svg>
    )
}

DiverMedicBadge.propTypes = {
    size: PropTypes.number,
}

DiverMedicBadge.defaultProps = {
    size: 50,
}

export default DiverMedicBadge
