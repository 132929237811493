import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { formatDate } from 'services/moment.service'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getProjectStatusColor } from 'utils/dataFormatters'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { INFO_VALUE_TYPE } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import MapView from 'components/MapView'
import CardHeader from 'components/card/CardHeader'
import ProjectVisibilityIcon from 'components/icons/ProjectVisabilityIcon'
import { formatFromToValues } from 'screens/divingContractor/project/common/projectFormatActions'

const ProjectContainer = ({ data, handleDelete }) => {
    const t = useTranslate()

    const { isDiver, userId } = useContext(CurrentUserContext)

    const {
        id,
        name,
        projectStatus,
        company,
        country,
        region,
        locationDetail,
        bodyOfWater,
        altitude,
        countryOfMobilization,
        nameOfVessel,
        fromDate,
        toDurationDays,
        fromDurationDays,
        locationType,
        divingMode,
        fromDepth,
        toDepth,
        projectPositions,
        projectIndustryType,
        projectScope,
        creator,
        unitImperial,
        geoLat,
        geoLong,
        hidden,
        client,
        industry,
    } = data

    const formattedPositions = (projectPositions || []).map(
        (position) => position?.diverPosition?.name
    )

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    return (
        <CardContainer>
            <CardHeader
                title={name}
                largeTitle
                statuses={[
                    {
                        type: 'status',
                        name: [projectStatus?.name],
                        color: getProjectStatusColor(projectStatus?.code),
                    },
                ]}
                item={data}
                actions={
                    isDiver || creator.id !== userId
                        ? []
                        : [
                              {
                                  handleAction: handleDelete,
                                  icon: ICONS.DELETE,
                                  iconColor: COLORS.RED,
                                  tooltip: 'button.deleteProject',
                              },
                          ]
                }
                customIcon={
                    <ProjectVisibilityIcon
                        creatorId={creator.id}
                        hidden={hidden}
                    />
                }
            />
            <Info
                label="form.label.divingContractor"
                value={company.name}
                companyID={company?.profileHash}
                valueType={INFO_VALUE_TYPE.CONTRACTOR_LINK}
                withBorder
                withSpaceBetween
            />
            {client && <Info
                label="form.label.client"
                value={client}
                withBorder
                withSpaceBetween
            />}
            {fromDate && <Info
                label="form.label.projectStart"
                value={formatDate(fromDate)}
                withBorder
                withSpaceBetween
            />}
            {(toDurationDays || fromDurationDays) && (
                <Info
                    label="form.label.expectedDuration"
                    value={formatFromToValues(fromDurationDays, toDurationDays)}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.location"
                value={country.name}
                withBorder
                withSpaceBetween
            />

            {region && (
                <Info
                    label="form.label.geographicalRegion"
                    value={region.name}
                    withBorder
                    withSpaceBetween
                />
            )}

            {locationDetail && (
                <Info
                    label="form.label.locationDetail"
                    value={locationDetail}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.locationType"
                value={locationType.name}
                color="green"
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.countryOfMobilization"
                value={countryOfMobilization.name}
                withBorder
                withSpaceBetween
            />
            {bodyOfWater && (
                <Info
                    label="form.label.bodyOfWater"
                    value={bodyOfWater.name}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.altitude"
                value={altitude + ' ' + unitOfMeasurementLabel}
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.industry"
                value={industry.name}
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.projectType"
                value={projectIndustryType?.name}
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.divingMode"
                value={divingMode.name}
                color="orange"
                withBorder
                withSpaceBetween
            />
            {(toDepth || fromDepth) && (
                <Info
                    label="form.label.waterDepths"
                    value={formatFromToValues(fromDepth, toDepth) + ' m'}
                    withBorder
                    withSpaceBetween
                />
            )}
            {nameOfVessel && (
                <Info
                    label="form.label.divingVessel"
                    value={nameOfVessel}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.teamPositions"
                value={formattedPositions}
                separator={formattedPositions.length > 1}
                withBorder
                withSpaceBetween
            />
            <div className="-mb10">
                <Info
                    label="form.label.scopeOfWork"
                    value={projectScope}
                    column
                    descriptionTextWithoutLimit
                />
            </div>
            <div className="aligned-center">
                <MapView geoLat={Number(geoLat)} geoLong={Number(geoLong)} />
            </div>
        </CardContainer>
    )
}

ProjectContainer.propTypes = {
    data: PropTypes.object,
    handleDelete: PropTypes.func,
    canCreateDiveRecord: PropTypes.bool,
}

export default ProjectContainer
