import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES } from 'constants/enums'

import GasCylindersForm from './GasCylindersForm'

const EditGasCylinders = ({
    editItem,
    setEditItem,
    setGasCylinders,
    editItemIndex,
    setEditItemIndex,
}) => {
    const { setAlert } = useContext(AlertContext)
    const t = useTranslate()

    const handleClose = () => {
        setEditItem(null)
        setEditItemIndex(null)
    }

    const handleSubmit = (values, { setSubmitting }) => {
        try {
            setSubmitting(true)
            setGasCylinders((prevGasCylinders) => {
                const newGasCylinders = [...prevGasCylinders]

                newGasCylinders[editItemIndex] = {
                    ...newGasCylinders[editItemIndex],
                    ...values,
                }

                return newGasCylinders
            })
            setAlert(t('message.successGasCylinderEdit'), ALERT_TYPES.SUCCESS)

            setEditItem(null)
        } catch (error) {
            setAlert(t('message.errorGasCylinderEdit'), ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div scroll-attribute="availablePositionsErrorScroll">
            <GasCylindersForm
                initialData={editItem}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                title={t('general.editGasCylinder')}
            />
        </div>
    )
}

export default EditGasCylinders
