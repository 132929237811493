import { useContext, useEffect } from 'react'

import { DiveRecordContext } from '../contexts/DiveRecordContext'

const useSaveByStep = (innerRef, handleSave) => {
    const { diveRecord, formRef, activeStep } = useContext(DiveRecordContext)

    useEffect(() => {
        formRef.current = {
            handleSave: (goToStep) =>
                goToStep < activeStep || activeStep === 3
                    ? handleSave(
                          innerRef?.current?.values,
                          null,
                          false,
                          false,
                          goToStep
                      )
                    : innerRef.current.handleSubmit(),
        }
    }, [innerRef.current, diveRecord])

    return null
}

export default useSaveByStep
