import ContractorContextProvider from './context/ContractorContext'

import Profile from './Profile'

const ProfileScreen = () => {
    return (
        <ContractorContextProvider>
            <Profile />
        </ContractorContextProvider>
    )
}

export default ProfileScreen
