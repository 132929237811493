import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES, BUTTON_STATUS, TABLE_FILTER_TYPE } from 'constants/enums'

import { AlertContext } from 'contexts/AlertContext'

import { Table } from 'components/table'
import CreateEntity from './CreateSkill'
import EditEntity from './EditSkill'

const TABLE_HEADER = [
    {
        key: 'id',
        title: 'table.header.id',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        sortable: true,
    },
    {
        key: 'name',
        title: 'table.header.skillName',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        sortable: true,
    },
]

const SkillsManagement = () => {
    const { data, meta, isLoading, fetchData } = useFetchDataByQueryParams(
        ENTITIES.SKILL
    )

    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)

    const handleCreate = () => {
        setCreate(true)
    }

    const handleEdit = ({ id }) => {
        setEdit(id)
    }

    const handleDelete = async (id) => {
        try {
            // await deleteEntityService(ENTITIES.USER, id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Fragment>
            <div>
                <Table
                    title="menuItems.skills"
                    headerItems={TABLE_HEADER}
                    data={data}
                    totalItems={meta?.totalItems}
                    isLoading={isLoading}
                    searchable={true}
                    actions={[
                        {
                            handleAction: handleCreate,
                            icon: ICONS.PLUS,
                            iconColor: '#ffffff',
                            label: 'button.createSkill',
                            tooltip: 'button.createSkill',
                        },
                    ]}
                    rowActions={[
                        {
                            handleAction: handleEdit,
                            icon: ICONS.EDIT,
                            tooltip: 'button.edit',
                            btnClass: BUTTON_STATUS.TERTIARY,
                        },
                        {
                            handleAction: handleDelete,
                            icon: ICONS.DELETE,
                            tooltip: 'button.delete',
                            title: 'general.deleteTitle',
                            confirmationMessage: 'general.deleteConfirmTitle',
                            btnClass: BUTTON_STATUS.TERTIARY,
                        },
                    ]}
                    filterActions={[
                        {
                            type: TABLE_FILTER_TYPE.TEXT,
                            name: 'name',
                            label: 'form.label.skillName',
                            placeholder: 'form.label.skillName',
                        },
                    ]}
                />
                {create && (
                    <CreateEntity
                        open={create}
                        setOpen={setCreate}
                        fetchData={fetchData}
                    />
                )}
                {edit && (
                    <EditEntity
                        open={edit}
                        setOpen={setEdit}
                        fetchData={fetchData}
                    />
                )}
            </div>
        </Fragment>
    )
}

export default SkillsManagement
