const uppyConfig = {
    restrictions: {
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1
    },
    /** Chunk size 10MB */
    chunkSize: 1024 * 1024 * 10,
    limit: 10,
}

export default uppyConfig;