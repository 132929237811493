export const DIVING_MODES = {
    SCUBA_CONVENTIONAL: {
        id: 1,
        name: 'general.scubaConventional',
        code: 'SCUBA_CONVENTIONAL',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    SURFACE_SUPPLIED: {
        id: 2,
        name: 'general.surfaceSupplied',
        code: 'SURFACE_SUPPLIED',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    CLOSED_BELL_SATURATION: {
        id: 3,
        name: 'general.closedBellSaturation',
        code: 'CLOSED_BELL_SATURATION',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    SCUBA_REBREATHER: {
        id: 4,
        name: 'general.scubaReBreather',
        code: 'SCUBA_REE_BREATHER',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    WET_BELL: {
        id: 5,
        name: 'general.wetBell',
        code: 'SURFACE_SUPPLIED_WET_BELL',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    CLOSED_BELL_BOUNCE: {
        id: 6,
        name: 'general.closedBellBounce',
        code: 'CLOSED_BELL_BOUNCE',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    ATMOSPHERIC_DIVING_SUIT: {
        id: 7,
        name: 'general.atmosphericDivingSuit',
        code: 'ATMOSPHERIC_DIVING_SUIT',
        chamberPressurizationOnly: false,
        entityType: 'diving_modes',
    },
    CHAMBER_RECOMPRESSION_ONLY: {
        id: 8,
        name: 'general.chamberRecompressionOnly',
        code: 'CHAMBER_RECOMPRESSION',
        chamberPressurizationOnly: true,
        entityType: 'diving_modes',
    },
}
