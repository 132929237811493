import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const TravelDocumentsAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.TRAVEL_DOCUMENTS}
            accessTypeLabel="form.label.selectAccessForTravelDocumentation"
            dataAccessAttribute="accessTravelDocuments"
            title="general.travelDocumentation"
        />
    )
}

export default TravelDocumentsAccessControl
