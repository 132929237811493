const refetchProfile = (
    condition,
    filteredData,
    refetchData,
    editMode,
    deleteMode
) => {
    if (
        (!filteredData.length && condition) ||
        (filteredData.length === 1 && !condition && editMode) ||
        (filteredData.length === 1 && condition && deleteMode)
    ) {
        refetchData()
    }
}

export { refetchProfile }
