import { useNavigate } from 'react-router-dom'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import React from 'react'

import IconButton from 'components/IconButton'
import { getConvertToOrdinals } from 'utils/ordinalNumber'

const VerificationLevelIcon = ({ levelOfVerification, sidebarList }) => {
    const navigate = useNavigate()

    const icons = [
        ICONS.LEVEL_0,
        ICONS.LEVEL_1,
        ICONS.LEVEL_2,
        ICONS.LEVEL_3,
        ICONS.LEVEL_4,
        ICONS.LEVEL_5,
    ]

    return (
        <IconButton
            icon={icons[levelOfVerification]}
            onClick={() =>
                navigate(
                    `/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`
                )
            }
            tooltip={`${getConvertToOrdinals(
                levelOfVerification
            )} level of verification ${!sidebarList ? '- Learn more' : ''}`}
            translate={false}
        />
    )
}

export default VerificationLevelIcon
