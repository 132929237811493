import { DIVER_POSITION_CATEGORIES } from 'constants/enums'

const positionCategoryIds = {
    occupationalDivingPersonnel: DIVER_POSITION_CATEGORIES.DIVING_PERSONNEL.id,
    marineMobileOffshoreUnitPersonnel:
        DIVER_POSITION_CATEGORIES.MARINE_MOU_PERSONNEL.id,
    rovPersonnel: DIVER_POSITION_CATEGORIES.ROV_PERSONNEL.id,
    offshoreSurveyPersonnel: DIVER_POSITION_CATEGORIES.SURVEY_PERSONNEL.id,
    projectMissionPersonnel:
        DIVER_POSITION_CATEGORIES.PROJECT_MISSION_PERSONNEL.id,
    projectOperationsManagementPersonnel:
        DIVER_POSITION_CATEGORIES.PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL.id,
}

export { positionCategoryIds }
