import React, { useRef, useState, useEffect } from 'react'

const clickOutsideHOC = (WrappedComponent, toggle = false, touch = false) => (
    props
) => {
    const hocRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [outsideClick, setOutsideClick] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (hocRef?.current && !hocRef.current?.contains(event.target)) {
                if (touch && open) setOutsideClick(true)
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open])

    return (
        <div ref={hocRef} onClick={toggle ? () => setOpen(!open) : undefined}>
            <WrappedComponent
                {...props}
                open={open}
                setOpen={setOpen}
                outsideClick={outsideClick}
            />
        </div>
    )
}

export default clickOutsideHOC
