import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'

import { forgotPasswordService } from 'services/auth.service'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

const CheckEmail = ({ email, setEmail }) => {
    const { setAlert } = useContext(AlertContext)

    const t = useTranslate()

    const navigate = useNavigate()

    const handleSubmit = async () => {
        try {
            await forgotPasswordService({ email })
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <div className="m-form -login -password">
            <div className="-backBtn">
                <Button
                    btnClass={BUTTON_STATUS.TERTIARY}
                    type={BUTTON_TYPE.BUTTON}
                    icon={ICONS.ARROW_LEFT}
                    iconColor={COLORS.LIGHT_BLUE}
                    label="links.back"
                    onClick={() => setEmail(null)}
                />
            </div>
            <div className="m-form__text -mb30">
                <div className=" column aligned-center">
                    <h1 className="-title -mb40">
                        {t('noAuth.checkEmailTitle')}
                    </h1>
                    <span className="-descriptionText a-mediumText a-lightText">
                        {t('noAuth.checkEmailMail')}
                    </span>
                    <span className="-descriptionText a-mediumText a-lightText -mt20">
                        <b>{email}</b>
                    </span>
                    <p className="-descriptionText a-mediumText a-lightText -mt20">
                        {t('noAuth.checkEmailText')}
                    </p>
                </div>
            </div>
            <Button
                btnClass={BUTTON_STATUS.PRIMARY}
                type={BUTTON_TYPE.BUTTON}
                onClick={() => {
                    navigate(ROUTES.LOGIN)
                }}
                label="button.backToSignIn"
            />
            <div className="-mt30">
                <span>{t('noAuth.didntGetEmail')} </span>
                <span className="a-blueLink" onClick={handleSubmit}>
                    {' '}
                    {t('noAuth.resendEmail')}
                </span>
            </div>
        </div>
    )
}

export default CheckEmail
