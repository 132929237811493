import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import useQueryParams from 'hooks/useQueryParams'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import { SEARCH_DELAY } from 'constants/constants'

import Input from 'components/Input'
import ICONS from 'constants/icons'

const Search = ({ searchKey, searchPlaceholder, disabled }) => {
    const { filterParams, addFilterParams } = useQueryParams()

    const [searchValue, setSearchValue] = useState(
        filterParams[searchKey] || ''
    )

    useEffect(() => {
        setSearchValue(filterParams[searchKey] || '')
    }, [filterParams[searchKey]])

    const handleSearchDebounce = _.debounce((event) => {
        const { value } = event.target
        addFilterParams({ [searchKey]: value })
    }, SEARCH_DELAY)

    const handleSearch = (event) => {
        const { value } = event.target
        setSearchValue(value)

        handleSearchDebounce(event)
    }

    return (
        <div className="m-searchField a-searchAndFilter__search">
            <Input
                name="filterSearch"
                value={searchValue}
                onChange={handleSearch}
                placeholder={searchPlaceholder}
                icon={ICONS.SEARCH}
                iconColor={COLORS.DARK_BLUE_40}
                size={ICON_SIZE.SIZE16}
                showLabel={false}
                disabled={disabled}
            />
        </div>
    )
}

Search.propTypes = {
    searchKey: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
}

Search.defaultProps = {
    searchKey: 'search',
    searchPlaceholder: 'form.placeholder.search',
    disabled: false,
}

export default Search
