import React, { useContext } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Breadcrumbs from 'components/Breadcrumbs'
import Loader from 'components/Loader'
import BottomTimeLineChart from './components/BottomTimeLineChart'

const Analytics = () => {
    const { isLoadingCurrentCompany } = useContext(CurrentUserContext)

    if (isLoadingCurrentCompany) return <Loader />

    return (
        <div className="m-boxes">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'menuItems.analytics',
                            to: ROUTES.ANALYTICS,
                            icon: ICONS.HOMEPAGE_BREADCRUMB,
                        },
                    ]}
                />
            </div>

            <BottomTimeLineChart />
        </div>
    )
}

export default Analytics
