import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { formatDate } from 'services/moment.service'

import { DIVE_PHYSICAL_CHARACTERISTIC, DIVE_RECORD_TYPE } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'

const GeneralInformationSummary = ({
    data,
    unitImperial,
    verification,
    diveRecordType,
}) => {
    const t = useTranslate()

    if (!data) return null

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    const divePhysicalCharacteristics = data.divePhysicalCharacteristics
        ? data.divePhysicalCharacteristics.map((item) => item.code)
        : ''

    const divePhysicalCharacteristicsWithLenghtOfPenetration =
        data.divePhysicalCharacteristics
            ? data.divePhysicalCharacteristics.map((item) => {
                  if (
                      item.code ===
                      DIVE_PHYSICAL_CHARACTERISTIC.LENGTH_DISTANCE_OF_PENETRATION
                  ) {
                      return `${item.name} - ${
                          data.lengthDistanceOfPenetration
                      }${t(unitOfMeasurementLabel)}`
                  }
                  return item.name
              })
            : []

    const waterQualities = data.waterQualities
        ? data.waterQualities.map((item) => item.name)
        : ''

    const bottomConditions = data.bottomConditions
        ? data.bottomConditions.map((item) => item.name)
        : ''

    const environmentalConditions =
        data.waveHeight ||
        data.waterTemperature ||
        data.airTemperature ||
        data.waterVisibility ||
        data.waterCurrent ||
        data.waterQualities?.length > 0 ||
        data.bottomConditions?.length > 0 ||
        data.windConditions ||
        data.nightTimeDive

    return (
        <CardContainer noBorderCard={!verification}>
            <div className="-infoGroup column">
                <span className="-mb15 a-bodyTextRegular">
                    {t('general.locationDetails')}
                </span>
                {data.dateOfDive && (
                    <Info
                        label="form.label.dateOfDive"
                        value={formatDate(data.dateOfDive)}
                        withSpaceBetween
                    />
                )}
                {data.nameOfEmployer && (
                    <Info
                        label="form.label.nameOfEmployer"
                        value={data.nameOfEmployer}
                        withSpaceBetween
                    />
                )}
                {data.nameOfProject && (
                    <Info
                        label="form.label.nameOfProject"
                        value={data.nameOfProject}
                        withSpaceBetween
                    />
                )}
                {data.industry && (
                    <Info
                        label="form.label.industry"
                        value={data.industry.name}
                        withSpaceBetween
                    />
                )}
                {data.projectIndustryType && (
                    <Info
                        label="form.label.projectType"
                        value={data.projectIndustryType?.name}
                        withSpaceBetween
                    />
                )}
                {data.nameOfVessel && (
                    <Info
                        label="form.label.nameOfVessel"
                        value={data.nameOfVessel}
                        withSpaceBetween
                    />
                )}
                {data.diveContractorRepresentative && (
                    <Info
                        label="form.label.diveContractorRepresentative"
                        value={data.diveContractorRepresentative}
                        withSpaceBetween
                    />
                )}
                {!!data.divers?.length && (
                    <Info
                        label="form.label.nameOfDivers"
                        value={data.divers
                            .map((item) => item.fullName)
                            .join(', ')}
                        withSpaceBetween
                    />
                )}
                {data.diversText && (
                    <Info
                        label="form.label.diversText"
                        value={data.diversText}
                        withSpaceBetween
                    />
                )}
                {data.supervisorName && !data.supervisor && (
                    <Info
                        label="form.label.diveSupervisor"
                        value={data.supervisorName}
                        withSpaceBetween
                    />
                )}
                {data.supervisor && (
                    <Info
                        label="form.label.diveSupervisor"
                        value={
                            diveRecordType ===
                                DIVE_RECORD_TYPE.SUPERVISOR_RECORD.id &&
                            data.project
                                ? data.nameOfEmployer
                                : data.supervisor?.fullName
                        }
                        withSpaceBetween
                    />
                )}
                {data.comment && (
                    <Info
                        label="form.label.comment"
                        value={data.comment}
                        column
                    />
                )}
            </div>
            <div className="-infoGroup column">
                <span className="-mb10 a-bodyTextRegular">
                    {t('general.geographicalData')}
                </span>
                {data.country && (
                    <Info
                        label="form.label.country"
                        value={data.country.name}
                        withSpaceBetween
                    />
                )}
                {data.region && (
                    <Info
                        label="form.label.region"
                        value={data.region.name}
                        withSpaceBetween
                    />
                )}
                {data.locationType && (
                    <Info
                        label="form.label.locationType"
                        value={data.locationType.name}
                        withSpaceBetween
                    />
                )}
                {data.locationDetail && (
                    <Info
                        label="form.label.locationDetail"
                        value={data.locationDetail}
                        withSpaceBetween
                    />
                )}
                {data.bodyOfWater && (
                    <Info
                        label="form.label.bodyOfWater"
                        value={data.bodyOfWater.name}
                        withSpaceBetween
                    />
                )}
                {data?.altitude !== undefined && (
                    <Info
                        label="form.label.altitude"
                        value={`${data.altitude}${t(unitOfMeasurementLabel)}`}
                        withSpaceBetween
                    />
                )}
            </div>
            <div className="-infoGroup column">
                <span className="-mb10 a-bodyTextRegular">
                    {t('general.diveDetails')}
                </span>
                {data.divingMode && (
                    <Info
                        label="form.label.divingMode"
                        value={data.divingMode.name}
                        withSpaceBetween
                    />
                )}
                {data.divingPlatform && (
                    <Info
                        label="form.label.divingPlatform"
                        value={data.divingPlatform.name}
                        withSpaceBetween
                    />
                )}

                {divePhysicalCharacteristicsWithLenghtOfPenetration.length >
                    0 && (
                    <Info
                        label="form.label.divePhysicalCharacteristics"
                        value={
                            divePhysicalCharacteristicsWithLenghtOfPenetration
                        }
                        color="gray"
                        withSpaceBetween
                    />
                )}

                {data.breathingApparatusType && (
                    <Info
                        label="form.label.breathingApparatusType"
                        value={data.breathingApparatusType.name}
                        color="gray"
                        withSpaceBetween
                    />
                )}
            </div>
            {diveRecordType !== DIVE_RECORD_TYPE.SUPERVISOR_RECORD.id &&
                environmentalConditions && (
                    <div className="-infoGroup column">
                        {
                            <span className="-mb10 a-bodyTextRegular">
                                {t('general.environmentalConditions')}
                            </span>
                        }
                        {data.waveHeight && (
                            <Info
                                label="form.label.waveHeight"
                                value={data.waveHeight}
                                withSpaceBetween
                            />
                        )}
                        {data.waterTemperature && (
                            <Info
                                label="form.label.waterTemperature"
                                value={data.waterTemperature}
                                withSpaceBetween
                            />
                        )}
                        {data.airTemperature && (
                            <Info
                                label="form.label.airTemperature"
                                value={data.airTemperature}
                                withSpaceBetween
                            />
                        )}
                        {data.waterVisibility && (
                            <Info
                                label="form.label.waterVisibility"
                                value={data.waterVisibility}
                                withSpaceBetween
                            />
                        )}
                        {data.waterCurrent && (
                            <Info
                                label="form.label.waterCurrent"
                                value={data.waterCurrent}
                                withSpaceBetween
                            />
                        )}
                        {waterQualities.length > 0 && (
                            <Info
                                label="form.label.waterQualities"
                                value={waterQualities}
                                color="gray"
                                withSpaceBetween
                            />
                        )}
                        {bottomConditions.length > 0 && (
                            <Info
                                label="form.label.bottomConditions"
                                value={bottomConditions}
                                color="gray"
                                withSpaceBetween
                            />
                        )}
                        {data.windConditions && (
                            <Info
                                label="form.label.windConditions"
                                value={data.windConditions}
                                withSpaceBetween
                            />
                        )}
                        {data.nightTimeDive && (
                            <Info
                                label="form.label.nightTimeDive"
                                value={
                                    data.nightTimeDive
                                        ? t('general.yes')
                                        : t('general.no')
                                }
                                withSpaceBetween
                            />
                        )}
                    </div>
                )}
        </CardContainer>
    )
}

GeneralInformationSummary.propTypes = {
    data: PropTypes.object,
    diveRecordId: PropTypes.number,
    unitImperial: PropTypes.bool,
    verification: PropTypes.bool,
}

export default GeneralInformationSummary
