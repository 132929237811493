import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'
import { MOMENT_FORMATS, formatDate } from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'

import { ACCESS_CONTROL_STATUSES, ALERT_TYPES } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import Loader from 'components/Loader'

const { NEW, REQUESTED_AGAIN, REJECTED } = ACCESS_CONTROL_STATUSES

const RequestAccess = ({
    module,
    title,
    status,
    canRequestAccess,
    profile,
    checkAccessControl,
    requestTime,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [isLoading, setIsLoading] = useState(false)

    const accessRequest = async () => {
        try {
            setIsLoading(true)
            await createEntityService(ENTITIES.DATA_ACCESS_REQUEST, {
                module,
                target: { id: profile.id, entityType: ENTITIES.USER },
            })
            checkAccessControl()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    const requestAgain = async () => {
        try {
            setIsLoading(true)

            await createEntityService(
                `${ENTITIES.DATA_ACCESS_RESEND}/${profile.id}?module=${module}`,
                {},
                false
            )
            checkAccessControl()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="m-profileTabs">
            {title &&
                <div className="aligned-center a-tabName">
                    <span className="a-bodyTextMedium ">{t(title)}</span>
                </div>
            }
            {canRequestAccess && (
                <Fragment>
                    <p className="a-mediumText a-lightText -mt20">
                    {t('general.sendRequestForDataAccess')}
                    </p>
                    <div className="-mt30">
                        <Button
                            disabled={isLoading}
                            label="button.sendRequest"
                            onClick={accessRequest}
                        />
                    </div>
                </Fragment>
            )}
            {status === NEW.id && (
                <p className="a-mediumText a-lightText -mt20">
                    {t('general.accessRequestPending')}
                </p>
            )}
            {status === REJECTED.id && (
                <Fragment>
                    <p className="a-mediumText a-lightText -mt20">
                        {`${t('general.accessRequestRejected')} ${formatDate(
                            requestTime
                        )} ${t('general.at')} ${formatDate(
                            requestTime,
                            MOMENT_FORMATS.TIME
                        )}.`}
                    </p>
                    <div className="-mt30">
                        <Button
                            disabled={isLoading}
                            label="button.requestAgain"
                            onClick={requestAgain}
                        />
                    </div>
                </Fragment>
            )}
            {status === REQUESTED_AGAIN.id && (
                <p className="a-mediumText a-lightText -mt20">
                    {t('general.accessRequestedAgainPending')}
                </p>
            )}
            {isLoading && <Loader />}
        </div>
    )
}

RequestAccess.propTypes = {
    dataAccessItem: PropTypes.object,
    canRequestAccess: PropTypes.bool,
    status: PropTypes.number,
    profile: PropTypes.object,
    checkAccessControl: PropTypes.func,
    requestTime: PropTypes.string,
}

export default RequestAccess
