import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'

import ExperienceForm from './ExperienceForm'

const EditExperience = ({
    data,
    open,
    setOpen,
    fetchData,
    refetchData,
    showActions,
    industries,
}) => {
    const t = useTranslate()

    const handleSubmit = async (formData) => {
        await editEntityService(ENTITIES.EXPERIENCE, data.id, formData)
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <ExperienceForm
            title="general.editExperience"
            initialData={data}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            industries={industries}
        />
    )
}

EditExperience.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    showActions: PropTypes.bool,
    industries: PropTypes.array,
}

EditExperience.defaultProps = {
    showActions: false,
}

export default EditExperience
