import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import CertificateCard from './CertificateCard'
import EditCertificate from './EditCertificate'
import NoData from 'components/NoData'
import CardHeader from 'components/card/CardHeader'

const CertificateList = ({
    data,
    isLoading,
    fetchData,
    showActions,
    profilePage,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [edit, setEdit] = useState(null)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.TRAINING_CERTIFICATE, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            title: 'general.deleteTrainingCertificate',
            message: 'message.areYouSureDeleteTrainingCertificate',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    return (
        <Fragment>
            {data.map((item) => (
                <div key={item.id} className="-mt20">
                    <CardContainer>
                        <CardHeader
                            title={`${item.name}`}
                            item={item}
                            actions={
                                showActions
                                    ? [
                                          {
                                              handleAction: handleEdit,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.DARK_BLUE,
                                              tooltip:
                                                  'button.editTrainingCertificate',
                                          },
                                          {
                                              handleAction: handleDelete,
                                              icon: ICONS.DELETE,
                                              iconColor: COLORS.RED,
                                              tooltip:
                                                  'button.deleteTrainingCertificate',
                                          },
                                      ]
                                    : []
                            }
                            fullTitle
                        />
                        <CertificateCard item={item} />
                    </CardContainer>
                </div>
            ))}

            {!!edit && (
                <EditCertificate
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                />
            )}

            {!showActions && data?.length === 0 && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t('general.nothingHereRightNow')}
                />
            )}
        </Fragment>
    )
}

CertificateList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    profilePage: PropTypes.bool,
}

CertificateList.defaultProps = {
    showActions: false,
}

export default CertificateList
