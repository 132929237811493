import React from 'react'

const CardInfo = ({ children, title }) => {
    if (!children) return null

    return (
        <div className="a-mediumText -grayWithBcg a-status" title={title}>
            {children}
        </div>
    )
}

export default CardInfo
