import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const ProjectAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.PROJECT}
            accessTypeLabel="form.label.selectAccessForProjects"
            dataAccessAttribute="accessProject"
            title="general.diveProjects"
            noFiles={true}
        />
    )
}

export default ProjectAccessControl
