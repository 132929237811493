import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import FilterIconButton from 'components/FilterIconButton'

const TableFilters = ({ FilterComponent }) => {
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <FilterIconButton setOpen={setOpen} />
            {open && <FilterComponent open={open} setOpen={setOpen} />}
        </Fragment>
    )
}

TableFilters.propTypes = {
    FilterComponent: PropTypes.elementType,
}

export default TableFilters
