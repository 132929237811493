import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import heic2any from 'heic2any'

import uppyConfig from 'utils/uppyConfig'
import { BASE_URL } from 'utils/axiosClient'

import { getUserToken } from 'services/localStorage.service'
import { AlertContext } from 'contexts/AlertContext'

import { UPLOAD_STATUS } from 'screens/common/gallery/constants/galleryConstants'

import { UPPY_EVENTS } from 'constants/uppy'
import { ALERT_TYPES } from 'constants/enums'

const useUppy = (uploadCompletedCallback = () => {}) => {
    const token = getUserToken()
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [progressObject, setProgressObject] = useState({
        numberOfFiles: 0,
        percentage: 0,
        files: {},
        status: UPLOAD_STATUS.UPLOADING,
    })

    const [hidePercentageBar, setHidePercentageBar] = useState(true)

    const [uppy] = useState(
        new Uppy().use(Tus, {
            endpoint: `${BASE_URL}/api/tus`,
            fieldName: 'files[]',
            headers: {
                Authorization: `Bearer ${token}`, // Add the auth token here
            },
            ...uppyConfig,
        })
    )

    useEffect(() => {
        uppy.on(UPPY_EVENTS.UPLOAD, () => {
            setHidePercentageBar(false)
        })

        uppy.on(UPPY_EVENTS.UPLOAD_PROGRESS, (file, progress) => {
            addFileToProgress(
                file,
                (progress.bytesUploaded / progress.bytesTotal) * 100
            )
        })

        uppy.on(UPPY_EVENTS.COMPLETE, (result) => {
            uploadCompletedCallback && uploadCompletedCallback()
            if (result.failed.length > 0) {
                handleFailedUploads(result.failed)
            } else {
                reset()
            }
        })

        uppy.on(UPPY_EVENTS.ERROR, (error) => {
            reset()
        })

        return () => {
            uppy && reset()
        }
    }, [uppy])

    const reset = () => {
        clearTusLocalStorage()
        uppy.clear()
    }

    const clearTusLocalStorage = () => {
        const regex = new RegExp('^tus::')
        for (const key in localStorage) {
            if (key.match(regex)) {
                delete localStorage[key]
            }
        }
    }

    const uppyUpload = () => {
        uppy.upload()
    }

    const uppyAddFiles = async (files) => {
        setProgressObject((progress) => ({
            ...progress,
            files: {},
        }))
        for (let file of files) {
            if (
                !['image/heic', 'image/heif', 'image/gif'].includes(file.type)
            ) {
                uppy.addFile({
                    name: file.name,
                    type: file.type,
                    data: file,
                    meta: {
                        filename: file.name,
                        originalName: file.name,
                    },
                })

                addFileToProgress(file)
            } else if (['image/gif'].includes(file.type)) {
                setAlert(t('general.gif'), ALERT_TYPES.ERROR, t)
                return
            } else {
                const { convertedImage, name } = await convertHeicFile(file)
                let convertedFile = new File([convertedImage], name)

                uppy.addFile({
                    name: name,
                    type: 'image/jpeg',
                    data: convertedFile,
                    meta: {
                        filename: name,
                        originalName: file.name,
                    },
                })

                addFileToProgress({ ...file, name: name })
            }
        }
    }

    const convertHeicFile = async (file) => {
        const convertedImage = await heic2any({
            blob: file,
            toType: 'image/jpeg', // Convert to JPEG format
        })

        return { convertedImage, name: file.name.replace('.heic', '.jpeg') }
    }

    const addFileToProgress = (file, percentage = 0) => {
        setProgressObject((progressObj) => {
            const files = progressObj.files
            files[file.name] = {
                name: file.name,
                percentage,
                size: file.size,
                error: files[file.name]?.error || null,
            }

            return {
                ...progressObj,
                files: files,
            }
        })
    }

    const handleFailedUploads = (failedFiles) => {
        setProgressObject((progressObj) => {
            const files = { ...progressObj.files }
            failedFiles.forEach((file) => {
                if (files[file.name]) {
                    files[file.name] = {
                        ...files[file.name],
                        error: 'Upload failed. Please try again.',
                    }
                }
            })

            return {
                ...progressObj,
                files: files,
            }
        })
    }

    return {
        uppy,
        uppyAddFiles,
        uppyUpload,
        progressObject,
        setProgressObject,
        hidePercentageBar,
        setHidePercentageBar,
        reset,
    }
}

export { useUppy }
