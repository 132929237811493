import React from 'react'

const UpdateOrganizationProfile = () => {

    return (
        <div className={'guidde'}>
            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to update organization profile</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/vdXJ64k31oPa7ghwWDoRhx"
                        title="How to update Organization profile"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen={true}
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Keeping your organization’s profile up-to-date on SkillNDepth is crucial
                        for ensuring that all your information is accurate and current. Don’t
                        worry; the process is straightforward, and this guide will walk you
                        through each step with ease. Let’s get started!
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Introduction
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’re logged in, take a look at the top right corner of the page.
                        You’ll see your profile picture or name there.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2Fb2A9UT5tjY8sypZ1MgbbNL_doc.png?alt=media&token=5d7bd8be-e91e-4062-a892-635f21bd3350"
                    alt="Introduction"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Select your profile
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Give it a click to open a dropdown menu where you’ll find all sorts of
                        options.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FvSU4REMHpXGsr1Uc8cjZVw_doc.png?alt=media&token=2f51a996-525e-4ccf-b8f1-5400df060dfc"
                    alt="Select your profile"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Click "Profile"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        From the dropdown menu, select the "Profile" option. This will take you to
                        your organization’s profile page where all your important details live.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FpCD2tpJwSLpGkzvDFsXGCD_doc.png?alt=media&token=ec4843dc-5c0e-43d6-a163-660c99ff4465"
                    alt="Click 'Profile'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Open Company Profile For Editing
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        On the company profile page, look for an edit icon—it looks like a pencil.
                        Click this icon to switch your profile into editing mode. Now, you can
                        make all the changes you need.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FwsbJCN3xVHeSRyCPNcxysc_doc.png?alt=media&token=629051bd-a904-4d6e-b80a-9e7dd58d8404"
                    alt="Open Company Profile For Editing"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Upload The Profile Photo
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        In the "Edit company information" section, you’ll see an area for your
                        profile photo. Click on the edit icon.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F2bJPzge2JpMTwiTyQiZE8A_doc.png?alt=media&token=3cdd4b72-849e-4cbd-b93c-f81106731314"
                    alt="Upload The Profile Photo"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Click "Upload new photo"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Choose the Upload new photo option. Pick a fresh, professional image from
                        your computer, and upload it. This is your chance to give your profile a
                        new look!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FmHZbaUYf4kP8DHNSJiKY11_doc.png?alt=media&token=91a57e92-4fb1-44a0-94f7-9b8376655d9a"
                    alt="Click 'Upload new photo'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Adjust the name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Double-check the name field to ensure your company’s name is correctly
                        displayed.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F1Uv3dQWaHpYw313dRoxE9u_doc.png?alt=media&token=e7d4032e-53f0-46da-88a4-422d2b3b1568"
                    alt="Adjust the name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Adjust size of the company
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Choose the correct size category for your company from the dropdown list.
                        For instance, if your company has grown, you might select "101-200
                        employees." Growth is good!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FnSoiozMPr5YXyFakG4jkP5_doc.png?alt=media&token=3c9926dc-8f1c-47f1-869e-0397a273c468"
                    alt="Adjust size of the company"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Select a country
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, select the country where your company is based.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FcXuTv2DXY4VK9XfRDyFMtt_doc.png?alt=media&token=d8d25fb0-3336-4fa7-974e-7537b47a5a20"
                    alt="Select a country"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Select date when company was founded
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Enter the date your company was founded. This adds a nice historical touch
                        to your profile, showing how established your company is. Select the
                        month, day, and year from the dropdown menus.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FrMsEWwqJKNtePkm13FVJ7K_doc.png?alt=media&token=9d58d181-1260-46a2-a821-bd0515e9992d"
                    alt="Select date when company was founded"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. Enter TIN/VAT number
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        In this step, provide your company’s TIN or VAT number. This is essential
                        for maintaining accurate records and ensuring compliance.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FfyddxKfAuVB5M1wusneEik_doc.png?alt=media&token=0a5c09f5-4937-424a-b5c7-797c52f051d1"
                    alt="Enter TIN/VAT number"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Adjust company email address
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Make sure the company email address listed is up-to-date
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FkaVrgor8DsTmKMQsQnuAmQ_doc.png?alt=media&token=54d4aca6-35cc-487f-8648-cd978b0d04b8"
                    alt="Adjust company email address"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    13. Adjust phone number
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click on the phone number field and enter the correct contact number for
                        your company
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FogQS3W9Hq3oA4VL9aYu4jm_doc.png?alt=media&token=7903702c-4139-4d78-b459-b111d5d0ac3b"
                    alt="Adjust phone number"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    14. Enter the website of your company
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Type in your company’s website URL in the designated field. Your website
                        is your digital front door, so make sure it’s accurate and functional.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FjGFBYDBuhapXmshJL8RVb2_doc.png?alt=media&token=5be6c4f7-3329-428b-9b96-840e89a35b0c"
                    alt="Enter the website of your company"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    15. Adjust contact person name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Update the name of the primary contact person for your company. If John
                        Doe is no longer the contact, make sure the new person’s name is listed
                        correctly.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FfwhCd5K5a7zg93dfw5Jtxb_doc.png?alt=media&token=783afa91-3c97-4dd2-9206-0746fee6a7fb"
                    alt="Adjust contact person name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    16. Enter more details about the company
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Head over to the "About" section and enter a brief description or update
                        the existing information about your company. This is a great place to tell
                        your story and showcase what makes your company special.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F8q2U5s2xmVozkZ57x5tZ7L_doc.png?alt=media&token=48efcae8-dad6-4815-a246-9c6801747a3d"
                    alt="Enter more details about the company"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    17. Select industries
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Choose the relevant industries that your company operates in, such as
                        "Civil Engineering-Infrastructure." This helps others understand what
                        fields you specialize in.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FnTz3fK3XcEsKrM1CfY5Gdk_doc.png?alt=media&token=cea63016-c9e0-4051-94bb-32ce7db079ae"
                    alt="Select industries"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    18. Select family of provided services
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        From the list, choose the family of services your company offers, like
                        "Close Visual Inspection." This ensures that your profile accurately
                        reflects what you do.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F2MoAggW4s3Zn5F1KDwVqF9_doc.png?alt=media&token=e0d8bc1f-ccfa-41de-8ce8-d5bafa5e14ad"
                    alt="Select family of provided services"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    19. Adjust location of your services
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Specify the geographic regions where your services are available. For
                        instance, you might select "South Asia (Indian)" if that’s where you
                        operate.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FoNANfeQAAq4ncWDAFpM9R3_doc.png?alt=media&token=a8ba2dac-c3db-46f2-a9a8-41ae4fdc9d4f"
                    alt="Adjust location of your services"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    20. Showcase if your company owns/operates any vessels
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        If your company owns or operates any vessels, this is the place to
                        highlight them. For example, you might list "DSV Toisa Pegasus." It’s a
                        great way to showcase your assets.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2Ffbk1jrB2wbbASAhmAYbUyQ_doc.png?alt=media&token=5cb0638a-8538-4f11-b9a5-65c2b4ddcead"
                    alt="Showcase if your company owns/operates any vessels"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    21. Showcase companies you are working with
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Add any key companies you partner with. This can boost your credibility
                        and show the strength of your partnerships.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F7zDzTTtUupr5Xwnd7oZqxp_doc.png?alt=media&token=51d0eec4-93b9-4554-9004-c0094bff4704"
                    alt="Showcase companies you are working with"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    22. Click "Save company information"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Finally, once all the changes have been made, don’t forget to click on
                        "Save company information." This ensures all your hard work is captured
                        and your profile is up-to-date.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FeZFJ7bTGH4ehiGgYh4qnwP_doc.png?alt=media&token=e2c1920b-8524-443d-b767-17ef0d4455aa"
                    alt="Click 'Save company information'"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Congratulations! You’ve successfully updated your organization’s profile
                        on Skill N Depth. By keeping your profile current, you’re helping to
                        ensure that your company is accurately represented on the platform. If you
                        ever need to make more changes or have any questions, the Skill N Depth
                        support team is always here to help.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default UpdateOrganizationProfile
