import { useContext, useMemo } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

const useProjectAccess = (project) => {
    const { isContractor, currentCompany } = useContext(CurrentUserContext)

    const projectAccess = useMemo(() => {
        const isProjectOwner =
            isContractor && project?.company.id === currentCompany?.id

        const isProjectClient =
            isContractor &&
            currentCompany &&
            project?.clientOrganizations?.some(
                (item) => item.id === currentCompany.id
            )

        return { isProjectOwner, isProjectClient }
    }, [project, isContractor, currentCompany])

    return projectAccess
}

export default useProjectAccess
