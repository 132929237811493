import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import COLORS from 'constants/colors'
import { BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import CancelTermsAndConditions from './CancelTermsAndConditions'
import DeactivateAccount from './DeactivateAccount'
import DeleteAccount from './DeleteAccount'

const AccountManagement = () => {
    const t = useTranslate()

    const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(
        false
    )
    const [deactivateAccount, setDeactivateAccount] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState(false)

    const handleDeactivateAccount = () => {
        setDeactivateAccount(true)
    }

    const handleCancelTermsAndConditions = () => {
        setTermsAndConditionsModal(true)
    }

    const handleDeleteAccount = () => {
        setDeleteAccount(true)
    }

    return (
        <div style={{ textAlign: 'justify' }}>
            <div className="-mb20">
                <span className="a-bodyTextBold">
                    {t('general.accountManagement')}
                </span>
            </div>

            <div className="-mb20">
                <div className="-mb10">
                    <span className="a-mediumTextSemiBold">
                        {t('general.deactivateAccount')}
                    </span>
                </div>
                <div className="-mb20">
                    <p className="a-mediumText a-darkGrayText">
                        {t('general.deactivateAccountDesc')}
                    </p>
                </div>
                <div className="justify-end">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        label="button.deactivateAccount"
                        icon={ICONS.ARROW_RIGHT}
                        iconPosition={ICON_POSITION.RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        onClick={() => handleDeactivateAccount()}
                    />
                </div>
            </div>

            <div className="-mb20">
                <div className="-mb10">
                    <span className="a-mediumTextSemiBold">
                        {t('general.cancelTermsAndConditions')}
                    </span>
                </div>
                <div className="-mb20">
                    <span className="a-mediumText a-darkGrayText">
                        {t('general.cancelingThe')}{' '}
                    </span>
                    <Link
                        to="https://skillndepth.com/terms-of-service"
                        className="a-mediumText"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('general.termsOfService')}{' '}
                    </Link>
                    <span className="a-mediumText a-darkGrayText">
                        {t('general.cancelTermsAndConditionsDesc')}
                    </span>
                </div>
                <div className="justify-end">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        label="button.cancelTermsAndConditions"
                        icon={ICONS.ARROW_RIGHT}
                        iconPosition={ICON_POSITION.RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        onClick={() => handleCancelTermsAndConditions()}
                    />
                </div>
            </div>

            <div className="-mb20">
                <div className="-mb10">
                    <span className="a-mediumTextSemiBold">
                        {t('general.deleteAccount')}
                    </span>
                </div>
                <div className="-mb20">
                    <p className="a-mediumText a-darkGrayText">
                        {t('general.deleteAccountDesc')}
                    </p>
                </div>
                <div className="justify-end">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        label="button.deleteAccount"
                        icon={ICONS.ARROW_RIGHT}
                        iconPosition={ICON_POSITION.RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        onClick={() => handleDeleteAccount()}
                    />
                </div>
            </div>
            {termsAndConditionsModal && (
                <CancelTermsAndConditions
                    termsAndConditionsModal={termsAndConditionsModal}
                    setTermsAndConditionsModal={setTermsAndConditionsModal}
                />
            )}
            {deactivateAccount && (
                <DeactivateAccount
                    deactivateAccount={deactivateAccount}
                    setDeactivateAccount={setDeactivateAccount}
                />
            )}
            {deleteAccount && (
                <DeleteAccount
                    deleteAccount={deleteAccount}
                    setDeleteAccount={setDeleteAccount}
                />
            )}
        </div>
    )
}

export default AccountManagement
