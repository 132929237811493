import React from 'react'
import { useTranslate } from 'react-polyglot'

import { ACCESS_CONTROL_STATUSES } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const {
    NEW,
    REJECTED,
    GRANTED_WITH_FILES,
    REQUESTED_AGAIN,
} = ACCESS_CONTROL_STATUSES

const GrantAccessAction = ({ handleChangeAccess, status }) => {
    const t = useTranslate()

    if (![NEW.id, REJECTED.id, REQUESTED_AGAIN.id].includes(status)) return null

    return (
        <div
            className="a-access__select"
            onClick={() => handleChangeAccess(GRANTED_WITH_FILES.id)}
        >
            <Icon name={ICONS.GRANT_ACCESS} />
            {t('general.grantAccess')}
        </div>
    )
}

export default GrantAccessAction
