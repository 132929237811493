import { useEffect, useRef } from 'react'

const { useFormikContext } = require('formik')

const ProjectFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { industry } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (!firstRender.current) {
            resetField('projectIndustryType', null)
        }
    }, [industry])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

export default ProjectFormContext
