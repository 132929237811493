import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import COLORS from 'constants/colors'

const Step = ({
    index,
    id,
    name,
    currentStep,
    numberOfSteps,
    allowedStep,
    handleClick,
    progress,
}) => {
    const t = useTranslate()
    const { isSmallerThanXlScreen } = useWindowDimensions()

    return (
        <div
            className={`-singleStep ${id === currentStep ? '-active' : ''} ${
                handleClick && id <= allowedStep ? '-clickable' : ''
            }`}
            onClick={() => {
                if (handleClick) {
                    handleClick(id)
                }
            }}
        >
            {isSmallerThanXlScreen && (
                <div className="-progress">
                    <svg height="40" width="40">
                        <circle
                            r="19"
                            cx="20"
                            cy="20"
                            fill={COLORS.LIGHT_BLUE_05}
                            stroke={COLORS.LIGHT_BLUE}
                            strokeWidth={progress > 0 ? '2' : '0'}
                            strokeDasharray={`${progress} 999`}
                            strokeLinecap="round"
                        />
                    </svg>
                    <span className="-percentage">{progress}%</span>
                </div>
            )}
            {!isSmallerThanXlScreen && (
                <div
                    className={`-iconNumber ${
                        id === currentStep ? '-active' : ''
                    } ${
                        handleClick && id <= allowedStep && id !== currentStep
                            ? '-complete'
                            : ''
                    }`}
                >
                    {index + 1}
                </div>
            )}
            <div
                className={`-text ${
                    isSmallerThanXlScreen ? 'column aligned-start' : ''
                }`}
            >
                {isSmallerThanXlScreen && (
                    <span className="a-captionsTextRegular a-lightText -opacity-60">
                        {`${t('general.step')} ${index + 1}`} {t('general.of')}{' '}
                        {numberOfSteps}{' '}
                    </span>
                )}
                <span
                    className={`-name ${
                        id === currentStep ? '-active' : null
                    } a-mediumTextSemiBold`}
                >
                    {t(name)}
                </span>
            </div>
        </div>
    )
}

Step.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    numberOfSteps: PropTypes.number,
    allowedStep: PropTypes.number,
    handleClick: PropTypes.func,
    progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Step
