import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'

import Button from 'components/Button'
import { InputField } from 'components/formFields'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const ContactPersonInfo = ({ personData, setPersonData, setStep }) => {
    const t = useTranslate()

    const initialValues = {
        firstName: personData.firstName ?? '',
        lastName: personData.lastName ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        firstName: Yup.string()
            .trim()
            .max(255, t('form.error.maximumCharacters'))
            .required(requiredMessage),
        lastName: Yup.string()
            .trim()
            .max(255, t('form.error.maximumCharacters'))
            .required(requiredMessage),
    })

    const handleSubmit = (formData) => {
        setPersonData((data) => ({ ...data, ...formData }))
        setStep((step) => step + 1)
    }

    return (
        <div className="m-form -login">
            <h1 className="-title -mb40">{t('general.contactPerson')}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <InputField
                            name="firstName"
                            label="form.label.contactPersonFirstName"
                            placeholder="form.placeholder.contactPersonFirstName"
                            required
                        />
                        <InputField
                            name="lastName"
                            label="form.label.contactPersonLastName"
                            placeholder="form.placeholder.contactPersonLastName"
                            required
                        />
                        <div className="-mt30">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.next"
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

ContactPersonInfo.propTypes = {
    personData: PropTypes.object.isRequired,
    setPersonData: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
}

export default ContactPersonInfo
