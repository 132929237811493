import ICONS from 'constants/icons'

const getVerificationLevels = (t) => [
    {
        id: 0,
        icon: ICONS.LEVEL_0,
        title: t('verificationLevels.level0.title'),
        description: t('verificationLevels.level0.description'),
        riskLevel: t('verificationLevels.riskLevel.critical'),
        color: '-redText',
    },
    {
        id: 1,
        icon: ICONS.LEVEL_1,
        title: t('verificationLevels.level1.title'),
        description: t('verificationLevels.level1.description'),
        process: [
            t('verificationLevels.level1.process1'),
            t('verificationLevels.level1.process2'),
        ],
        riskLevel: t('verificationLevels.riskLevel.high'),
        color: '-orangeText',
    },
    {
        id: 2,
        icon: ICONS.LEVEL_2,
        title: t('verificationLevels.level2.title'),
        description: t('verificationLevels.level2.description'),
        process: [
            t('verificationLevels.level2.process1'),
            t('verificationLevels.level2.process2'),
            t('verificationLevels.level2.process3'),
            t('verificationLevels.level2.process4'),
        ],
        riskLevel: t('verificationLevels.riskLevel.medium'),
        color: '-purpleText',
    },
    {
        id: 3,
        icon: ICONS.LEVEL_3,
        title: t('verificationLevels.level3.title'),
        description: t('verificationLevels.level3.description'),
        process: [
            t('verificationLevels.level3.process1'),
            t('verificationLevels.level3.process2'),
            t('verificationLevels.level3.process3'),
            t('verificationLevels.level3.process4'),
        ],
        processForSupervisor: [
            t('verificationLevels.level3.processForSupervisor1'),
            t('verificationLevels.level3.processForSupervisor2'),
            t('verificationLevels.level3.processForSupervisor3'),
            t('verificationLevels.level3.processForSupervisor4'),
        ],
        riskLevel: t('verificationLevels.riskLevel.low'),
        color: '-blueText',
    },
    {
        id: 4,
        icon: ICONS.LEVEL_4,
        title: t('verificationLevels.level4.title'),
        description: t('verificationLevels.level4.description'),
        process: [
            t('verificationLevels.level4.process1'),
            t('verificationLevels.level4.process2'),
            t('verificationLevels.level4.process3'),
            t('verificationLevels.level4.process4'),
            t('verificationLevels.level4.process5'),
            t('verificationLevels.level4.process6'),
        ],
        riskLevel: t('verificationLevels.riskLevel.minimal'),
        color: '-greenText',
    },
    {
        id: 5,
        icon: ICONS.LEVEL_5,
        title: t('verificationLevels.level5.title'),
        description: t('verificationLevels.level5.description'),
        process: [
            t('verificationLevels.level5.process1'),
            t('verificationLevels.level5.process2'),
            t('verificationLevels.level5.process3'),
            t('verificationLevels.level5.process4'),
            t('verificationLevels.level5.process5'),
        ],
        riskLevel: t('verificationLevels.riskLevel.minimal'),
        color: '-greenText',
    },
]

export { getVerificationLevels }
