import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const TransparentModal = ({ open, children }) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
            return () => {
                document.body.style.overflow = 'unset'
            }
        }
    }, [open])

    return (
        <div className={`m-transparentModal ${open ? '-show' : ''}`}>
            <div className="wrapper">
                <div className="m-transparentModal__content">
                    <div>{children}</div>
                </div>
            </div>
        </div>
    )
}

TransparentModal.propTypes = {
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

TransparentModal.defaultProps = {
    open: true,
}

export default TransparentModal
