import React from 'react'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import TravelDocumentForm from './TravelDocumentForm'

const EditTravelDocument = ({ data, open, setOpen, fetchData }) => {
    const handleSubmit = async (newData) => {
        await editEntityService(
            ENTITIES.TRAVEL_DOCUMENTATION,
            data.id,
            newData,
            true,
            ['visas', 'recordInformations']
        )
        fetchData()
    }

    return (
        <TravelDocumentForm
            title="general.editTravelDocument"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
        />
    )
}

export default EditTravelDocument
