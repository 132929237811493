import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES } from 'constants/enums'

import GasCylindersForm from './GasCylindersForm'

const CreateGasCylinders = ({ gasCylinders, setGasCylinders, setCreate }) => {
    const { setAlert } = useContext(AlertContext)
    const t = useTranslate()

    const handleClose = () => {
        setCreate(false)
    }

    const handleSubmit = (values, { setSubmitting }) => {
        try {
            setSubmitting(true)
            setGasCylinders([
                ...gasCylinders,
                {
                    ...values,
                },
            ])
            setCreate(false)
            setAlert(t('message.successGasCylinderCreate'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(t('message.errorGasCylinderEdit'), ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div scroll-attribute="availablePositionsErrorScroll">
            <GasCylindersForm
                gasCylinders={gasCylinders}
                setGasCylinders={setGasCylinders}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                title={t('general.addGasCylinder')}
            />
        </div>
    )
}

export default CreateGasCylinders
