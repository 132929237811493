import React, { Fragment, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { DiveRecordContext } from './contexts/DiveRecordContext'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'

import StepButtons from 'components/StepButtons'
import DiveProfileSummary from './components/diveProfile/DiveProfileSummary'

const DiveProfileVerification = () => {
    const { diveRecordId } = useParams()

    const { diveRecord, goPreviousStep, goToNextStep } =
        useContext(DiveRecordContext)

    const { data: diveData } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordGeneralInformation',
                'diveRecordGeneralInformation.divingMode',
                'diveRecordDiveData.diveEvents',
                'diveRecordDiveData.surfaceDecompressionEvents',
                'diveRecordDiveData.chamberPressurizationEvents',
                'diveRecordDiveData.chamberDecompressionEvents',
                'diveRecordDiveData.bellRunEvents',
                'diveRecordDiveData.lockOutEvents',
                'diveRecordDiveData.saturationDailyEvents',
            ],
        }
    )

    const data = diveRecordId ? diveData : diveRecord.data

    if (!data) {
        return null
    }

    return (
        <Fragment>
            <div className="ofs_l2 _l8 -diveRecordContent">
                <DiveProfileSummary
                    data={data.diveRecordDiveData}
                    diveRecordId={diveRecordId ? data.id : data._id}
                    unitImperial={data.unitImperial}
                    verification
                    divingMode={data.diveRecordGeneralInformation.divingMode}
                />
            </div>
            <StepButtons
                handleBack={goPreviousStep}
                handleNext={goToNextStep}
                canSkip={false}
            />
        </Fragment>
    )
}

export default DiveProfileVerification
