import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useFetchDataById from 'hooks/useFetchDataById'
import useFetchData from 'hooks/useFetchData'
import useQueryParams from 'hooks/useQueryParams'

import { filterDiveRecordSteps } from 'utils/diveRecordFunctions'
import { formatUrl } from 'utils/jsonApiFormatters'

import { getLastScreenURL } from 'services/localStorage.service'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTES from 'constants/routes'
import ENTITIES from 'constants/entities'
import { DIVE_RECORD_STATUS, DIVE_RECORD_TYPE } from 'constants/enums'

export const DiveRecordContext = React.createContext()

const DiveRecordContextProvider = ({ children }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { projectId, diveRecordId, verificationToken } = useParams()
    const { filterParams } = useQueryParams()
    const { role, divingMode } = filterParams

    const formRef = useRef()
    const goToStepRef = useRef()

    const [allSteps, setAllSteps] = useState([])

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { userId } = useContext(CurrentUserContext)

    const diveRecordData = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include:
                'diveRecordGeneralInformation,diveRecordGeneralInformation.divingMode,diveRecordEquipment,diveRecordDiveData,diveRecordDiveData.diveEvents,diveRecordDiveData.diveEvents.decompressionType,diveRecordWorkPerformed,diveRecordDiveSummary,diveRecordVerification',
        },
        !!diveRecordId,
        diveRecordId
    )

    const historicalDiveRecordForVerification = useFetchData(
        ENTITIES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION,
        {
            include: [
                'diveRecordGeneralInformation',
                'diveRecordGeneralInformation.bodyOfWater',
                'diveRecordGeneralInformation.region',
                'diveRecordGeneralInformation.bottomConditions',
                'diveRecordGeneralInformation.country',
                'diveRecordGeneralInformation.divingMode',
                'diveRecordGeneralInformation.divingPlatform',
                'diveRecordGeneralInformation.industry',
                'diveRecordGeneralInformation.locationType',
                'diveRecordGeneralInformation.waterQualities',
                'diveRecordGeneralInformation.supervisor',
                'diveRecordGeneralInformation.divePhysicalCharacteristics',
                'diveRecordGeneralInformation.projectIndustryType',
                'diveRecordGeneralInformation.breathingApparatusType',
                'diveRecordEquipment',
                'diveRecordEquipment.divingApparelProtection',
                'diveRecordEquipment.otherDivingApparelProtections',
                'diveRecordEquipment.breathingApparatusType',
                'diveRecordEquipment.buoyancyFlotations',
                'diveRecordEquipment.otherEquipmentOutfittings',
                'diveRecordEquipment.gasCylinders',
                'diveRecordEquipment.gasCylinders.gasCylinderPurpose',
                'diveRecordEquipment.gasCylinders.gasContents',
                'diveRecordDiveData',
                'diveRecordDiveData.diveEvents',
                'diveRecordDiveData.diveEvents.breathingMixture',
                'diveRecordDiveData.diveEvents.decoBreathingMixture',
                'diveRecordDiveData.diveEvents.decompressionType',
                'diveRecordDiveData.diveEvents.decompressionTableType',
                'diveRecordDiveData.diveEvents.breathingMixtureGasContents',
                'diveRecordDiveData.diveEvents.decoBreathingMixtureGasContents',
                'diveRecordDiveData.surfaceDecompressionEvents',
                'diveRecordDiveData.surfaceDecompressionEvents.gasContents',
                'diveRecordDiveData.chamberPressurizationEvents',
                'diveRecordDiveData.chamberDecompressionEvents',
                'diveRecordDiveData.bellRunEvents',
                'diveRecordDiveData.bellRunEvents.bellRunDivePosition',
                'diveRecordDiveData.lockOutEvents',
                'diveRecordDiveData.saturationDailyEvents',
                'diveRecordDiveData.saturationDailyEvents.bellTeamOneMembers',
                'diveRecordDiveData.saturationDailyEvents.bellTeamTwoMembers',
                'diveRecordDiveData.saturationDailyEvents.bellTeamThreeMembers',
                'diveRecordWorkPerformed',
                'diveRecordWorkPerformed.tasks',
                'diveRecordWorkPerformed.supervisorTasks',
                'diveRecordWorkPerformed.objects',
                'diveRecordWorkPerformed.activities',
                'diveRecordWorkPerformed.tools',
                'diveRecordDiveSummary',
                'diveRecordDiveSummary.mentalWellnessElements',
                'diveRecordDiveSummary.traumaInjuryLevel',
                'diveRecordDiveSummary.decompressionIllness',
                'diveRecordDiveSummary.pressureInjuries',
                'diveRecordDiveSummary.equipmentFailures',
                'diveRecordDiveSummary.closeCall',
                'diveRecordDiveSummary.files',
                'diveRecordVerification',
            ],
            token: verificationToken,
        },
        !!verificationToken,
        verificationToken
    )

    useEffect(() => {
        if (historicalDiveRecordForVerification.error) {
            navigate(ROUTES.NOT_FOUND_PAGE)
        }
    }, [historicalDiveRecordForVerification.error])

    const diveRecord = diveRecordId
        ? diveRecordData
        : historicalDiveRecordForVerification
    const id = diveRecordId || historicalDiveRecordForVerification.data._id

    const { data: divingModes, isLoading: isLoadingDivingModes } = useFetchData(
        ENTITIES.DIVING_MODE,
        {},
        !verificationToken
    )
    const choosedDivingMode = divingModes.find(
        (item) => item.id === Number(divingMode)
    )

    const {
        data: diveSupervisors,
        isLoading: isLoadingDiveSupervisors,
        isReady,
    } = useFetchData(
        ENTITIES.PROJECT_SUPERVISOR,
        {
            project: projectId,
        },
        !verificationToken
    )

    const isProjectSupervisor = diveSupervisors.some(
        (item) => item.id === userId
    )

    const isSupervisor =
        !!(
            diveRecord?.data?.diveRecordGeneralInformation?.supervisor?.id ===
                userId &&
            (diveRecord.data?.status === DIVE_RECORD_STATUS.SUBMITTED.id ||
                diveRecord.data?.status ===
                    DIVE_RECORD_STATUS.RE_SUBMITTED.id) &&
            diveRecord?.data?.creator.id !== userId
        ) ||
        !!(
            verificationToken &&
            diveRecord.data?.status === DIVE_RECORD_STATUS.COMPLETED.id &&
            diveRecord?.data?.creator.id !== userId
        )

    const isSupervisorDiveRecord =
        (isProjectSupervisor &&
            (!diveRecord?.data ||
                diveRecord?.data.length === 0 ||
                diveRecord?.data?.creator?.id === userId)) ||
        Number(diveRecord?.data?.diveRecordType || role) ===
            DIVE_RECORD_TYPE.SUPERVISOR_RECORD.id

    const canEdit = () => {
        if (diveRecord?.data?.creator?.id !== userId) {
            return false
        }

        return (
            diveRecord.data?.status === DIVE_RECORD_STATUS.DRAFT.id ||
            diveRecord.data?.status === DIVE_RECORD_STATUS.REJECTED.id
        )
    }

    const activeStepIndex = allSteps.findIndex((item) =>
        pathname.includes(item.route)
    )

    useEffect(() => {
        if (
            (isReady || historicalDiveRecordForVerification.isReady) &&
            (!id || (id && diveRecord.data))
        ) {
            const steps = filterDiveRecordSteps(
                diveRecord?.data?.diveRecordGeneralInformation?.divingMode ||
                    choosedDivingMode,
                isSupervisor,
                isSupervisorDiveRecord
            )
            setAllSteps(steps)

            // Protect dive record from being modified by users other than the creator,
            // or verified by users other than te supervisor
            if (diveRecord.data && !Array.isArray(diveRecord.data)) {
                // if a dive record creator tries to verify their dive record they are redirected to access denied page
                if (
                    verificationToken &&
                    diveRecord.data?.status ===
                        DIVE_RECORD_STATUS.COMPLETED.id &&
                    diveRecord?.data?.creator.id === userId
                ) {
                    navigate(ROUTES.ACCESS_DENIED, {
                        state: {
                            title: 'general.diveRecordVerification',
                            description:
                                'general.diveRecordVerificationAccessDenied',
                        },
                    })
                    return
                }

                if (
                    !(
                        isSupervisor &&
                        (pathname.includes(ROUTES.DIVE_RECORD_SUMMARY) ||
                            pathname.includes(
                                ROUTES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION
                            ))
                    ) &&
                    !(
                        canEdit() &&
                        !pathname.includes(ROUTES.DIVE_RECORD_SUMMARY) &&
                        !pathname.includes(
                            ROUTES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION
                        )
                    )
                ) {
                    diveRecord?.data?.creator.id === userId
                        ? navigate(
                              `/${ROUTES.DIVE_RECORDS}/${diveRecord.data.id}`
                          )
                        : navigate(ROUTES.NOT_FOUND_PAGE)
                }
            }
        }
    }, [
        isReady,
        diveRecord?.data?.diveRecordGeneralInformation?.divingMode,
        isSupervisor,
        isSupervisorDiveRecord,
        choosedDivingMode,
        divingMode,
        historicalDiveRecordForVerification.isReady,
    ])

    const goToNextStep = (newDiveRecordId) => {
        const nextStepRoute = allSteps[activeStepIndex + 1]?.route
        if (id) {
            navigate(formatUrl(nextStepRoute, filterParams))
        } else {
            navigate(
                formatUrl(`${newDiveRecordId}/${nextStepRoute}`, filterParams)
            )
        }
    }

    const goPreviousStep = () => {
        if (activeStepIndex) {
            navigate(
                formatUrl(allSteps[activeStepIndex - 1].route, filterParams)
            )
        } else if (isSupervisor) {
            goToProjectPage()
        } else {
            showConfirmationModal({
                message: 'message.stepChangesWillBeLost',
                title: 'message.areYouSureLeaveCurrentStep',
                handleConfirm,
                handleCancel,
            })
        }
    }

    const handleConfirm = () => {
        const lastScreenURL = getLastScreenURL()

        navigate(lastScreenURL)
        closeConfirmationModal()
    }

    const handleCancel = () => closeConfirmationModal()

    // NOTE:: issue with go back and cancel button navigation
    const goToProjectPage = () => {
        projectId
            ? navigate(`${ROUTES.PROJECT}/${projectId}`)
            : navigate(`/${ROUTES.DIVE_RECORDS}`)
    }

    const goBack = () => {
        const lastScreenURL = getLastScreenURL()

        if (window.history.state.idx !== 0) {
            window.history.back()
        } else {
            navigate(lastScreenURL)
        }
    }

    const handleGoToSpecificStep = (id) => {
        const specificStep = allSteps.find((item) => item.id === id).route
        navigate(formatUrl(specificStep, filterParams))
    }

    const handlePostSaveAction = (complete, goBack, goToStep) => {
        // NOTE: If we navigate to step 3 and there is no data for step 3 (diveRecordDiveData),
        // the data should not be fetched because it will be fetched when diveRecordDiveData is created
        // Also, the data should not be fetched when supervisor complete verification because the response is "Not Found"
        if (
            !(
                activeStepIndex === 1 &&
                complete === true &&
                !diveRecord.data?.diveRecordDiveData
            ) &&
            !(goToStep === 3 && !diveRecord.data?.diveRecordDiveData) &&
            !(isSupervisor && !complete && !goBack && !goToStep)
        ) {
            diveRecord.fetchData()
        }
        if (goToStep) {
            goToStepRef.current = null
            handleGoToSpecificStep(goToStep)
            return
        }
        if (complete) goToNextStep()
        else if (goBack) goPreviousStep()
        else goToProjectPage()
    }

    const stepIndex = activeStepIndex === -1 ? 0 : activeStepIndex

    const contextValue = useMemo(
        () => ({
            diveRecord,
            diveSupervisors,
            isLoadingDiveSupervisors,
            divingModes,
            isLoadingDivingModes,
            isSupervisor,
            isSupervisorDiveRecord,
            goToNextStep,
            goPreviousStep,
            goToProjectPage,
            goBack,
            handlePostSaveAction,
            handleGoToSpecificStep,
            activeStepIndex,
            activeStep: allSteps.length ? allSteps[stepIndex].id : 1,
            allSteps,
            setAllSteps,
            formRef,
            goToStepRef,
            diveRecordId: id,
        }),
        [diveRecord, pathname]
    )

    return (
        <DiveRecordContext.Provider value={contextValue}>
            {children}
        </DiveRecordContext.Provider>
    )
}

export default DiveRecordContextProvider
