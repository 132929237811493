import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import Note from 'components/Note'

const SettingsItem = ({ title, note, description, children }) => {
    const t = useTranslate()

    return (
        <div>
            <p className="a-mediumTextSemiBold -mb10">{t(title)}</p>
            {note && <Note note={note} />}
            {description && (
                <span className="a-lightText a-mediumText">
                    {t(description)}
                </span>
            )}
            {children}
        </div>
    )
}

SettingsItem.propTypes = {
    title: PropTypes.string,
    note: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    description: PropTypes.string,
}

export default SettingsItem
