const formatByName = (items) => {
    return items?.map(({ name }) => `${name}`)
}

const formatByNumberOfPills = (items, count = 5) => {
    if (items.length <= count) {
        return items
    }

    return [...items.slice(0, count), `+${items.length - count}`]
}

const formatTooltipText = (items, count = 5) => {
    if (items.length <= count) {
        return null
    }

    const formattedItems = items.slice(count, items.length)

    return (
        <div className="aligned-center flex-wrap -tooltip a-captionsTextRegular">
            {formattedItems.map((item, index) => (
                <span key={index}>
                    {item.name || item}
                    {index < formattedItems.length - 1 && (
                        <span className="separatorDisc">&#x2022;</span>
                    )}
                </span>
            ))}
        </div>
    )
}

export { formatByName, formatByNumberOfPills, formatTooltipText }
