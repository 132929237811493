import React from 'react'
import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import CertificateForm from './CertificateForm'

const EditCertificate = ({ data, open, setOpen, fetchData }) => {
    const handleSubmit = async (formData) => {
        await editEntityService(
            ENTITIES.TRAINING_CERTIFICATE,
            data.id,
            formData,
            true
        )
        fetchData()
    }

    return (
        <CertificateForm
            title="general.editTrainingCertificate"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
        />
    )
}

EditCertificate.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
}

export default EditCertificate
