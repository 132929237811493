import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import useWindowDimensions from 'hooks/useWindowDimension'

import CardPlaceholder from 'components/CardPlaceholder'
import EditTravelDocument from './EditTravelDocument'
import TravelDocumentCard from './TravelDocumentCard'

const TravelDocuments = ({
    data,
    fetchData,
    isLoading,
    profilePage,
    showActions,
    refetchData,
}) => {
    const { isSmallScreen } = useWindowDimensions()
    const [edit, setEdit] = useState(null)

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    const dataOdd = data.filter((num, index) => index % 2 !== 0)
    const dataEven = data.filter((num, index) => index % 2 === 0)

    const getTravelDocumentCard = (data) =>
        data?.map((item) => {
            return (
                <TravelDocumentCard
                    item={item}
                    key={item.id}
                    showActions={showActions}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    isLoading={isLoading}
                    edit={edit}
                    setEdit={setEdit}
                />
            )
        })

    return (
        <div className="_wr">
            <div className="_w">
                {!isSmallScreen && !profilePage && (
                    <Fragment>
                        <div className="_6">
                            {getTravelDocumentCard(dataEven)}
                        </div>
                        <div className="_6">
                            {getTravelDocumentCard(dataOdd)}
                        </div>
                    </Fragment>
                )}
                {(isSmallScreen || profilePage) && (
                    <div
                        className={`_12 ${
                            profilePage ? 'profileWrapper' : '-mt20'
                        } `}
                    >
                        {getTravelDocumentCard(data)}
                    </div>
                )}
                {!!edit && (
                    <EditTravelDocument
                        data={edit}
                        setOpen={setEdit}
                        fetchData={fetchData}
                        refetchData={refetchData}
                    />
                )}
            </div>
        </div>
    )
}

TravelDocuments.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    dataLength: PropTypes.number,
    refetchData: PropTypes.func,
}

TravelDocuments.defaultProps = {
    profilePage: false,
    showActions: false,
}

export default TravelDocuments
