import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

const GasCylindersFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { gasContents } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (!firstRender.current) {
            resetField(`oxygen`, '')
            resetField(`helium`, '')
            resetField(`nitrogen`, '')
        } else {
            firstRender.current = false
        }
    }, [gasContents])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

export default GasCylindersFormContext
