import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Modal from 'components/Modal'
import TabNavigation from 'components/tabs/TabNavigation'
import CardHeader from 'components/card/CardHeader'

const DiveTeamMembersList = ({ diveTeam, isLoading, isProjectOwner, isProjectClient }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)

    const { tab } = useQueryParams()

    const { id } = useParams()

    const [activeTabContent, setActiveTabContent] = useState()

    useEffect(() => {
        if (tab) {
            const activeTabList = diveTeam.filter(
                (item) => item.positionTitleCode === tab
            )
            setActiveTabContent(activeTabList)
        }
    }, [tab])

    useEffect(() => {
        const firstPositionName = diveTeam[0]?.positionTitleCode
        const firstPositionContent = diveTeam.filter(
            (item) => item.positionTitleCode === firstPositionName
        )

        setActiveTabContent(firstPositionContent)
    }, [diveTeam])

    const renderTabContent = () => {
        if (activeTabContent) {
            return activeTabContent.map((item) => {
                return (
                    <div className="_12 _l4 -mb10" key={item.id}>
                        <CardContainer
                            link={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                        >
                            <CardHeader
                                title={item.fullName}
                                subtitle={[
                                    item.positionTitle,
                                    item.countryName,
                                ]}
                                avatar={item.avatarPath}
                                placeholderIcon={ICONS.USER}
                                verifiedBadge={!!item.identityVerified}
                                onlyHeader={true}
                            />
                        </CardContainer>
                    </div>
                )
            })
        }
        return null
    }

    const handleViewDiveTeam = () => {
        navigate(`${ROUTES.PROJECT}/${id}${ROUTES.DIVE_TEAM}`)
    }

    const getContent = (employees) => {
        if (!employees.length) {
            return <Fragment />
        }

        return employees.slice(0, 5).map((item) => (
            <div className="-mt20 fullWidth" key={item.id}>
                <CardContainer
                    noBorderCard
                    link={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                >
                    <CardHeader
                        title={item.fullName}
                        subtitle={item.positionTitle}
                        avatar={item.avatarPath}
                        placeholderIcon={ICONS.USER}
                        verifiedBadge={!!item.identityVerified}
                        link={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                        onlyHeader={true}
                    />
                </CardContainer>
            </div>
        ))
    }

    const tabData = diveTeam.reduce((positions, position) => {
        const existingItem = positions.find(
            (item) => item.key === position.positionTitleCode
        )

        if (!existingItem) {
            const count = diveTeam.filter(
                (item) => item.positionTitleCode === position.positionTitleCode
            ).length

            const tabItem = {
                key: position.positionTitleCode,
                title: `${count} ${
                    count > 1
                        ? `${position.positionTitle}s`
                        : position.positionTitle
                }`,
                hidden: count === 0,
            }

            positions.push(tabItem)
        }

        return positions
    }, [])

    return (
        <Fragment>
            <div className="column fullWidth">
                <span className="a-bodyTextMedium">
                    {t('general.teamMembers')}
                </span>
                {diveTeam.length !== 0 && (
                    <p className="a-mediumText a-lightText -opacity-60 -mt10">
                        {t(
                            `${
                                diveTeam.length === 1
                                    ? 'general.thereIs'
                                    : 'general.thereAre'
                            }`
                        )}{' '}
                        {diveTeam.length}{' '}
                        {t(
                            `${
                                diveTeam.length === 1
                                    ? 'general.teamMemberOnProject'
                                    : 'general.teamMembersOnProject'
                            }`
                        )}
                    </p>
                )}

                {getContent(diveTeam)}
                {diveTeam.length > 5 && !isProjectOwner && (
                    <div className="-mt20">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                            label="button.viewTeamMembers"
                            onClick={() => {
                                setOpenModal(true)
                            }}
                            icon={ICONS.ARROW_RIGHT}
                            iconColor={COLORS.LIGHT_BLUE}
                            iconPosition={ICON_POSITION.RIGHT}
                        />
                    </div>
                )}

                {diveTeam.length === 0 && !isLoading && (
                    <span className="a-mediumText a-lightText fullWidth -mt10">
                        {t('general.noTeamMembers')}
                    </span>
                )}
            </div>
            <div>
                {(isProjectOwner || isProjectClient) && (
                    <div className="-mt20">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                            label="button.goToTeamManagement"
                            onClick={handleViewDiveTeam}
                            icon={ICONS.ARROW_RIGHT}
                            iconColor={COLORS.LIGHT_BLUE}
                            iconPosition={ICON_POSITION.RIGHT}
                        />
                    </div>
                )}
            </div>
            {openModal && (
                <Modal
                    open={openModal}
                    setOpen={setOpenModal}
                    closeOnClickOutside={false}
                    customClass="-diveTeamMembersList"
                    title="general.teamMembers"
                >
                    <div className="fullWidth">
                        <div className="_12">
                            <TabNavigation
                                tabs={tabData}
                                tabQueryParam="tab"
                                translate={false}
                            />
                        </div>

                        <div className="fullWidth aligned-center flex-wrap">
                            {renderTabContent()}
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

DiveTeamMembersList.propTypes = {
    projectId: PropTypes.number,
}

export default DiveTeamMembersList
