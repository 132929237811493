import useQueryParams from 'hooks/useQueryParams'
import ProjectList from 'components/projects/ProjectList'
import { Fragment } from 'react'
import TabNavigation from 'components/tabs/TabNavigation'
import { useTranslate } from 'react-polyglot'
import { useLocation } from 'react-router-dom'
import HistoricalProjectList from 'components/projects/HistoricalProjectList'
import PropTypes from 'prop-types'

const PROJECT_TYPES = {
    REGULAR: 'regular',
    HISTORICAL: 'historical',
}

const ProjectListWrapper = (props) => {
    const { subTab } = useQueryParams()
    const t = useTranslate()
    const {search} = useLocation()

    const renderTabContent = () => {
        if (subTab === PROJECT_TYPES.HISTORICAL) {
            return (
                <div>
                    <HistoricalProjectList {...props}/>
                </div>
            )
        } else {
            return (
                <ProjectList {...props} profilePage={true}></ProjectList>
            )
        }
    }

    return (
        <>
            <div
                className={'m-profileTabs -projects'}
            >
                <div>
                    <div>
                        <div className="-mb20">
                            <div className="aligned-center a-tabName">
                                <span className="a-bodyTextMedium ">
                                    {t('general.diveProjects')}
                                </span>
                            </div>
                        </div>
                        <TabNavigation
                            tabs={[
                                {
                                    key: PROJECT_TYPES.REGULAR,
                                    title: 'general.diveProjects',
                                    queryParams: search,
                                },
                                {
                                    key: PROJECT_TYPES.HISTORICAL,
                                    title: 'general.historicalDiveProjects',
                                    queryParams: search,
                                },
                            ]}
                            tabQueryParam="subTab"
                        />
                        {renderTabContent()}
                    </div>
                </div>
            </div>
        </>
    )
}

ProjectListWrapper.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    companyID: PropTypes.number,
    projectStatus: PropTypes.array,
    myProfile: PropTypes.bool,
    isHistoricalProject: PropTypes.bool,
}

ProjectListWrapper.defaultProps = {
    profilePage: false,
    myProfile: false,
    isHistoricalProject: false
}

export default ProjectListWrapper
