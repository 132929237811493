import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 1.3.0',
    introText:
        'We’re excited to roll out the latest updates, packed with features to improve your experience on our platform. From dive record verifications to user-friendly interface enhancements, here’s what’s new and upcoming:',
    items: [
        {
            title: 'Client Organizations',
            description:
                'We are thrilled to introduce Client Organizations, empowering companies to manage their presence and interactions on the platform. Key features include:',
            icon: ICONS.BUILDING,
            descriptionList: [
                {
                    title: 'Client Organization Signup and Profile Management',
                    description:
                        'Organizations can now create accounts and maintain detailed profiles.',
                },
                {
                    title: 'Multiple Client Organizations on a Project',
                    description:
                        'Collaborate seamlessly with multiple organizations involved in a single project.',
                },
                {
                    title: 'Project and Candidate Viewing',
                    description:
                        'Client organizations can explore projects and review potential candidates.',
                },
                {
                    title: 'Messaging',
                    description:
                        'Communicate effortlessly within the platform for better collaboration.',
                },
                {
                    title: 'Client Organization’s Role in Hiring',
                    description:
                        'Facilitate smooth hiring processes through client accounts.',
                },
                {
                    title: 'Dive Record Visibility',
                    description:
                        'Access specific dive records, ensuring transparency and informed decision-making.',
                },
            ],
        },
        {
            title: 'Historical Dive Projects',
            description:
                'You can now access a detailed record of your past dive projects! Reflect on your professional journey with ease, track accomplishments, and maintain a comprehensive view of your dive history. This feature not only helps you stay organized but also allows you to showcase your dive projects in your professional CV. Present a comprehensive and polished record of your expertise, demonstrating your skills and experience to potential employers or collaborators.',
            icon: ICONS.DIVE_PROJECTS_OUTLINE,
        },
        {
            title: 'UI Improvements & Skills and Experience Enhancements',
            description:
                'We’ve made further refinements to the app’s interface, streamlining your ability to showcase and manage your skills and experience. Navigating and updating your professional profile has never been smoother.',
            icon: ICONS.UI_UX_IMPROVEMENTS,
        },
        {
            title: 'Data Access Enhancements',
            description: 'Take control of your data with these new features:',
            icon: ICONS.DATA_PROTECTION,
            descriptionList: [
                {
                    title: 'Custom Data Sharing',
                    description:
                        'Decide what information to share related to your dive projects.',
                },
                {
                    title: 'Date and Time Stamps',
                    description: 'View when data access requests are made.',
                },
                {
                    title: 'Request Again Option',
                    description:
                        'Easily resend data access requests when needed.',
                },
            ],
        },
        {
            title: 'Notifications',
            description:
                'Stay updated with new notifications for dive record verifications. Keeping your records accurate and verified is now more convenient than ever!',
            icon: ICONS.NOTIFICATION_BELL,
        },
        {
            title: 'Help and Info Section Enhancements',
            description: 'We’ve enriched the Help and Info section with:',
            icon: ICONS.HELP_AND_INFO,
            descriptionList: [
                {
                    title: 'FAQ',
                    description: 'A detailed FAQ to answer common questions.',
                },
                {
                    title: 'Support',
                    description:
                        'An improved Support system to assist you better.',
                },
            ],
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingSectionDescription: 'Here’s a sneak peek at what’s coming soon:',
    upcomingItems: [
        {
            title: 'Upload Dive Records as Files:',
            description:
                'Simplify record-keeping by uploading your dive logs directly as files.',
        },
        {
            title: 'Feedback Mechanism:',
            description:
                'Share your thoughts and help us improve the platform.',
        },
        {
            title: 'Total Bottom Times and Days Summary:',
            description: 'Get a comprehensive summary of your diving activity.',
        },
    ],
    outroText:
        'Thank you for being part of our community! Your feedback and support drive us to create a better platform for divers everywhere. We look forward to continuing to serve your diving needs and enhancing your experience. Stay tuned!',
}

const Release130 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release130
