export const formatSize = (fileSize) => {
    const kbSize = 1024;
    const mbSize = 1024 * 1024;

    const sizeInMb = Math.round((fileSize / mbSize) * 100 / 100);
    const sizeInKb = Math.round((fileSize / kbSize) * 100 / 100);

    return (sizeInMb < 1) ? `${sizeInKb}KB` : `${sizeInMb}MB`
}

export default formatSize;