import { useEffect, useRef } from 'react'

const { useFormikContext } = require('formik')

const RecordVerificationFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { accurate } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (!firstRender.current) {
            resetField('safetyMindedness', '')
            resetField('comfortInWater', '')
            resetField('comfortInTask', '')
            resetField('taskCompletion', '')
            resetField('useOfTools', '')
            resetField('useOfTechnique', '')
            resetField('communication', '')
            resetField('takingDirection', '')
            resetField('independentWork', '')
            resetField('comment', '')
        }
    }, [accurate])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

export default RecordVerificationFormContext
