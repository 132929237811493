import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'
import { formatDate, formatMonthYearDate } from 'services/moment.service'

import { refetchProfile } from '../../utils/fetchHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import { EditPersonalEquipment } from './..'
import CardHeader from 'components/card/CardHeader'

const PersonalEquipmentList = ({
    data,
    fetchData,
    isLoading,
    profilePage,
    showActions,
    refetchData,
    filteredData,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [edit, setEdit] = useState(null)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (item) => {
        try {
            await deleteEntityService(ENTITIES.PERSONAL_EQUIPMENT, item.id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()

            const isNonRetiredEquipment = !item?.removedFromService

            refetchData &&
                refetchProfile(
                    isNonRetiredEquipment,
                    filteredData,
                    refetchData,
                    false,
                    true
                )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = (item) =>
        showConfirmationModal({
            message: 'message.areYouSureDeletePersonalEquipment',
            title: 'general.deletePersonalEquipment',
            handleConfirm: () => handleConfirm(item),
            handleCancel,
        })

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            profilePage ? '_l12 profileWrapper' : '_l6'
                        } _12 -mt20`}
                    >
                        <CardContainer>
                            <CardHeader
                                title={item.equipmentType.name}
                                item={item}
                                actions={
                                    showActions
                                        ? [
                                              {
                                                  handleAction: handleEdit,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip:
                                                      'button.editPersonalEquipment',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip:
                                                      'button.deletePersonalEquipment',
                                              },
                                          ]
                                        : []
                                }
                                fullTitle
                            />
                            <Info
                                label="form.label.equipmentBrand"
                                value={item.equipmentBrand.name}
                            />
                            <Info
                                label="form.label.equipmentModel"
                                value={item.model}
                            />
                            <Info
                                label="form.label.serialNumber"
                                value={item.serialNumber}
                            />
                            {item.dateOfManufacture && (
                                <Info
                                    label="form.label.dateOfManufacture"
                                    value={formatMonthYearDate(
                                        item.dateOfManufacture
                                    )}
                                />
                            )}
                            {item.datePutInService && (
                                <Info
                                    label="form.label.datePutInService"
                                    value={formatMonthYearDate(
                                        item.datePutInService
                                    )}
                                />
                            )}
                            <Info
                                label="form.label.dateRemovedFromService"
                                value={
                                    item.removedFromService
                                        ? formatMonthYearDate(
                                              item.dateRemovedFromService
                                          )
                                        : t('general.stillInUse')
                                }
                                textColor={`${
                                    item.dateRemovedFromService
                                        ? 'gray'
                                        : 'green'
                                }`}
                            />

                            <InfoFiles
                                label="general.maintenanceRecordFiles"
                                files={item.files}
                            />
                        </CardContainer>
                    </div>
                ))}

                {!!edit && (
                    <EditPersonalEquipment
                        data={edit}
                        setOpen={setEdit}
                        fetchData={fetchData}
                        refetchData={refetchData}
                        filteredData={filteredData}
                    />
                )}
            </div>
        </div>
    )
}

PersonalEquipmentList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
}

PersonalEquipmentList.defaultProps = {
    profilePage: false,
    showActions: false,
}

export default PersonalEquipmentList
