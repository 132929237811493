import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { INFO_VALUE_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import Separator from 'components/Separator'
import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'

const FeedbackSummary = ({
    data,
    firstLevelVerification,
    diveRecordId,
    verification,
    diveRecord,
}) => {
    const t = useTranslate()

    if (!data) return null

    const { firstLevelVerificationToken, verifiedLevel } = diveRecord

    return (
        <CardContainer noBorderCard>
            <CardHeader
                title={
                    verification
                        ? `${t('general.diveRecord')} No ${diveRecordId}`
                        : null
                }
            />
            <span className="-mb5 a-bodyTextRegular">
                {t('general.proficiencyScoringOfDivingAttributes')}
            </span>
            <span className="-mb20 a-mediumText a-lightText">
                {t('general.ratingDescription')}
            </span>
            <Info
                label="form.label.safetyMindedness"
                value={data.safetyMindedness}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.comfortInWater"
                value={data.comfortInWater}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.comfortInTask"
                value={data.comfortInTask}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.taskCompletion"
                value={data.taskCompletion}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.useOfTools"
                value={data.useOfTools}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.useOfTechnique"
                value={data.useOfTechnique}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.communication"
                value={data.communication}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.takingDirection"
                value={data.takingDirection}
                withSpaceBetween
                withBorder
            />
            <Info
                label="form.label.independentWork"
                value={data.independentWork}
                withSpaceBetween
                withBorder
            />
            {data.comment && (
                <Info
                    label="general.additionalRemarks"
                    value={data.comment}
                    column
                />
            )}
            {verifiedLevel === 1 && firstLevelVerification && (
                <Fragment>
                    <Separator fullWidth />
                    <span className="-mb20 a-bodyTextRegular">
                        {t('general.verifiedBy')}
                    </span>
                    <Info
                        label="form.label.fullName"
                        value={`${firstLevelVerification.firstName} ${firstLevelVerification.lastName}`}
                        withBorder
                        withSpaceBetween
                    />
                    {firstLevelVerification.company && (
                        <Info
                            label="form.label.company"
                            value={firstLevelVerification.company}
                            withBorder
                            withSpaceBetween
                        />
                    )}
                    <Info
                        label="general.signature"
                        value={firstLevelVerification.signature}
                        valueType={INFO_VALUE_TYPE.SIGNATURE}
                        withBorder
                        withSpaceBetween
                    />
                    <InfoFiles
                        label="form.label.supervisorCard"
                        files={[firstLevelVerification.file]}
                        fetchEntity={`${ENTITIES.FILE}/${ENTITIES.DIVE_RECORD_FIRST_VERIFICATION_UPLOAD}/${firstLevelVerificationToken}?fileId=`}
                        withSpaceBetween
                    />
                </Fragment>
            )}
        </CardContainer>
    )
}

FeedbackSummary.propTypes = {
    data: PropTypes.object,
    diveRecordId: PropTypes.number,
    verification: PropTypes.bool,
    diveRecord: PropTypes.object,
}

export default FeedbackSummary
