import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const ExportCvAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.USER_CV}
            accessTypeLabel="form.label.selectAccessForUserCV"
            dataAccessAttribute="accessCv"
            title="general.exportProfileAsCV"
            noFiles={true}
        />
    )
}

export default ExportCvAccessControl
