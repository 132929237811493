const changeUserArray = (item, users, setUsers, divers) => {
    const index = users?.findIndex((user) => user.id === item.id)
    const usersCopy = [...(divers ? divers : users)]
    usersCopy[index] = {
        ...usersCopy[index],
        favoriteUser: !item.favoriteUser,
    }
    setUsers(usersCopy)
}

export { changeUserArray }
