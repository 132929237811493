import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import HistoricalProjectModalForm
    from 'screens/diver/profile/diverInformation/modals/HistoricalProjectModalForm'
import { editEntityService } from 'services/entity.service'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import { AlertContext } from 'contexts/AlertContext'
import { useTranslate } from 'react-polyglot'

const EditHistoricalProjectModal = ({ fetchHistoricalProjects, initialData, setOpenHistoricalProjectModal, openHistoricalModal, setHistoricalItem }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const handleSubmit = async (formData) => {
        try {
            await editEntityService(
                ENTITIES.HISTORICAL_PROJECT,
                initialData?.id,
                formData
            )
            setAlert(
                t('message.successfullySavedHistoricalProject'),
                ALERT_TYPES.SUCCESS
            )
            fetchHistoricalProjects()
        } catch (e) {
            setAlert(
                t('message.errorWhileSavingHistoricalProject'),
                ALERT_TYPES.ERROR
            )
        }
        setHistoricalItem(null)
        setOpenHistoricalProjectModal(false)
    }

    return (
        <div className="fullWidth">
            {openHistoricalModal && (
                <HistoricalProjectModalForm
                    setOpenCreateHistoricalProjectModal={setOpenHistoricalProjectModal}
                    initialData={initialData}
                    title={'button.editHistoricalDiveProject'}
                    reloadHistoricalProjects={fetchHistoricalProjects}
                    handleSubmit={handleSubmit}
                    setHistoricalItem={setHistoricalItem}
                />
            )}
        </div>
    )
}

EditHistoricalProjectModal.propTypes = {
    fetchHistoricalProjects: PropTypes.func,
    initialData: PropTypes.object
}

EditHistoricalProjectModal.defaultProps = {
}

export default EditHistoricalProjectModal
