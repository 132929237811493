import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { BUTTON_STATUS } from 'constants/enums'

import Button from 'components/Button'

const ActionButton = ({
    title,
    handleAction,
    icon,
    label,
    iconColor,
    iconPosition,
    tooltip,
    confirmationMessage,
    hasCondition,
    condition,
    btnClass,
    item,
    stopPropagation,
}) => {
    const t = useTranslate()

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleClick = (event) => {
        stopPropagation && event.stopPropagation()
        if (confirmationMessage) {
            showConfirmationModal({
                title,
                message: renderText(confirmationMessage),
                handleConfirm: (event) => handleConfirm(event),
                handleCancel: (event) => handleCancel(event),
            })
        } else {
            handleAction(item)
        }
    }

    const handleConfirm = (event) => {
        stopPropagation && event.stopPropagation()
        closeConfirmationModal()
        handleAction(item)
    }

    const handleCancel = (event) => {
        stopPropagation && event.stopPropagation()
        closeConfirmationModal()
    }

    const getIconName = () => {
        if (hasCondition && item) {
            const iconName = icon.split(',')
            const name = item[condition] ? iconName[0] : iconName[1]
            return name
        }

        return icon
    }

    const renderText = (value, translate) => {
        if (hasCondition && item) {
            const iconTooltip = value.split(',')
            const title = item[condition] ? iconTooltip[0] : iconTooltip[1]
            return translate ? t(title) : title
        }

        if (value) return translate ? t(value) : value

        return null
    }

    if (!handleAction) return null

    return (
        <Button
            label={label}
            onClick={handleClick}
            tooltip={renderText(tooltip)}
            icon={getIconName()}
            iconColor={iconColor}
            btnClass={btnClass}
            iconPosition={iconPosition}
        />
    )
}

ActionButton.propTypes = {
    title: PropTypes.string,
    handleAction: PropTypes.func,
    icon: PropTypes.string,
    label: PropTypes.string,
    iconColor: PropTypes.string,
    iconPosition: PropTypes.string,
    tooltip: PropTypes.string,
    confirmationMessage: PropTypes.string,
    hasCondition: PropTypes.bool,
    condition: PropTypes.string,
    btnClass: PropTypes.string,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    stopPropagation: PropTypes.bool,
}

ActionButton.defaultProps = {
    hasCondition: false,
    btnClass: BUTTON_STATUS.PRIMARY,
    item: null,
}

export default ActionButton
