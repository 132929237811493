import React from 'react'

const VerifyDiveRecordAsASupervisorLevelOne = () => {

    return (
        <div className={'guidde'}>
            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to verify dive record as a supervisor - Level 1</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/g9BAxatzfmTg9fRyxKPCKS"
                        title="How to verify dive record as a supervisor - Level 1"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen={true}
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Welcome to the Skill N Depth dive record verification guide for
                        supervisors! If a colleague has sent you a link asking for your help in
                        verifying their dive record, you're in the right place. This process is
                        quick and straightforward, and your verification will play a crucial role
                        in helping them complete their profile. By the end of this guide, you’ll
                        not only have verified a record but might even feel inspired to join Skill
                        N Depth yourself. Let’s get started!
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Introduction
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Start by navigating to the Skill N Depth platform where you’ll verify the
                        dive record. Just open up the link in your browser, and you’re ready to
                        begin.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2F9Bh3pnmS3FAEskwqtRr76B_doc.png?alt=media&token=96957052-103d-469a-871a-e66609310bab"
                    alt="Introduction"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Check all data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Carefully review the dive personnel and project information. This is your
                        chance to ensure all the details are correct before moving forward.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2F3KV74SVxYzMUuc3NHYpmiu_doc.png?alt=media&token=e1c61c49-fce5-40fe-a315-f3c5fcf84e83"
                    alt="Check all data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’re satisfied with the information, click "Next" to proceed to the
                        next step in the verification process.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2Fnms1Ggqs5gYDDHH3h7VXbL_doc.png?alt=media&token=617e7fa8-31e5-4aa9-b1d0-7ecb066dfccc"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Verify the equipment used
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Here, you’ll need to view the breathing apparatus and diving equipment
                        used during the dive. Make sure the selected items match what was actually
                        used.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FvnoeEonB9X46j1nWDN1Ux4_doc.png?alt=media&token=7793c170-c922-4fc2-a135-6a23f246a952"
                    alt="Verify the equipment used"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        With the equipment verified, click "Next" to move on to the next section.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2F9erpSAYDBwwq9XLsL3DBH6_doc.png?alt=media&token=ef1b7779-681c-45e9-a6b0-e989a285bd12"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Check all Dive profile data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Review the dive event details, including decompression information. This
                        is crucial for maintaining accurate records.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FegCkF7vMgXTLDHbUVzbMLp_doc.png?alt=media&token=14830e2f-e7de-429f-aa09-17da43660b5c"
                    alt="Check all Dive profile data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Check all tabs
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Remember, different diving techniques may have varying amounts of data.
                        Make sure to check all relevant tabs to ensure nothing is missed.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FuHxQ94br3GdjVtajHo1VY4_doc.png?alt=media&token=51e1f0ff-9963-44f0-9806-9d8cefab5c41"
                    alt="Check all tabs"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’ve reviewed all the data, click "Next" to continue.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FxuM1WNPr6zF9naz6BDJ3Bb_doc.png?alt=media&token=f0aef846-8409-428d-b0bd-4b40fc8b6f5a"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Check all date about work performed
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Verify the tasks and activities that were performed during the dive.
                        Ensure everything is documented accurately.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2Fc2r7rDHFGdBuHsbQMiTdC1_doc.png?alt=media&token=b8c976c2-eb93-4430-a26a-b006bdf84e74"
                    alt="Check all date about work performed"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        After confirming the work performed, click "Next" to proceed.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FgEugh1qqLBNaUsMXTqXGB5_doc.png?alt=media&token=4f7ed1a7-e027-4cf6-bb36-a5ff8ee8c618"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. Click "Yes"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        At this stage, provide your feedback on the record. If everything checks
                        out, click "Yes." If something seems off, you have the option to reject
                        the verification.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2F978WU3HzUKp3PWCdu6ZV3o_doc.png?alt=media&token=d8345dec-4b32-4bf5-99e3-3d32a4457168"
                    alt="Click 'Yes'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Provide a score for all elements
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Here, you can explore and score different aspects of the dive record,
                        offering a more detailed evaluation.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FusAEcD7AzTSzsYkhdmYjuF_doc.png?alt=media&token=1b1014dc-b1f7-44f0-953e-88e3f254c5c4"
                    alt="Provide a score for all elements"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    13. Click "Write your first name"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Enter your first name in the designated field. This will be used as part
                        of your verification signature.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2Fuut2gG2sEd8GtaR7Lzz7cA_doc.png?alt=media&token=b50395f8-714a-4914-bcb8-3ee5b023fd7c"
                    alt="Click 'Write your first name'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    14. Click "Write your last name"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, input your last name to complete your verification identity.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FgJZPzLfSyRzCbnrtysg5gR_doc.png?alt=media&token=077cb04e-7586-42a0-b085-dc236b4a7f1e"
                    alt="Click 'Write your last name'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    15. Draw your signature
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Use this box to enter your signature. This step is essential as it
                        officially attaches your name to the record.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FkKKqiWDvpbzNAucEXeMgi4_doc.png?alt=media&token=41583397-a753-4190-a12f-fe25fdc3a661"
                    alt="Draw your signature"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    16. Click "Click here or drag &amp; drop file(s)"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Upload any files that prove your identity. This is a security measure to
                        ensure the integrity of the verification process.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FvcPWzqU6dYxqd3P2CowTdJ_doc.png?alt=media&token=1bad475b-e140-4639-99f4-0b9a47bd87de"
                    alt="Click 'Click here or drag & drop file(s)'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    17. Click "Verify"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Finally, click the "Verify" button. This will initiate an internal
                        process, and if everything is in order, your colleague will receive a
                        notification, and the dive record will be marked as verified.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fg9BAxatzfmTg9fRyxKPCKS%2FpjbDMtPBT7uhL24kkg9rfM_doc.png?alt=media&token=6d57e097-a24f-4d94-a8bf-ee6399903daf"
                    alt="Click 'Verify'"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Congratulations! You’ve successfully completed the dive record
                        verification process. Your careful review and approval are now part of
                        your colleague's professional profile. If you enjoyed this process and
                        find the Skill N Depth platform useful, why not consider joining us? Thank
                        you for your time and effort in ensuring the accuracy and integrity of
                        dive records. Happy diving!
                    </p>
                </div>
            </div>

        </div>
    )
}

export default VerifyDiveRecordAsASupervisorLevelOne
