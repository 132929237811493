import React from 'react'
import { useField } from 'formik'

import ToggleSwitch, {
    ToggleSwitchMainPropTypes,
} from 'components/ToggleSwitch'

const ToggleSwitchField = (props) => {
    const [field] = useField(props)

    return <ToggleSwitch {...field} {...props} />
}

ToggleSwitchField.propTypes = {
    ...ToggleSwitchMainPropTypes,
}

export default ToggleSwitchField
