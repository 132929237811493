import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ICONS from 'constants/icons'

import Icon from './Icon'

const VerificationBadge = ({ label }) => {
    const t = useTranslate()

    return (
        <div className="a-verificationBadge">
            <Icon name={ICONS.VERIFICATION} />
            <span className="a-mediumText a-lightText">{t(label)}</span>
        </div>
    )
}

VerificationBadge.propTypes = {
    label: PropTypes.string,
}

VerificationBadge.defaultProps = {
    label: 'general.verified',
}

export default VerificationBadge
