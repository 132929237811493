import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import { refetchProfile } from '../../utils/fetchHelper'

import { PRIMARY_TRAINING_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import TrainingCourseForm from './TrainingCourseForm'

const CreateTrainingCourse = ({
    open,
    setOpen,
    fetchData,
    refetchData,
    filteredData,
    showActions,
}) => {
    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.TRAINING_COURSE, formData, true, [
            'trainingCertificates',
        ])
        fetchData()

        const condition = [
            PRIMARY_TRAINING_TYPE.DIVE_SCHOOL_TRAINING,
            PRIMARY_TRAINING_TYPE.OTHER_TRAINING,
        ].includes(formData?.primaryTrainingType?.id)

        refetchData && refetchProfile(condition, filteredData, refetchData)
    }
    return (
        <TrainingCourseForm
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            showActions={showActions}
            fetchData={fetchData}
            refetchData={refetchData}
        />
    )
}

CreateTrainingCourse.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
    showActions: PropTypes.bool,
}

CreateTrainingCourse.defaultProps = {
    showActions: false,
}

export default CreateTrainingCourse
