import { getEntityFilters } from 'services/localStorage.service'

import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

export const getProjectBreadcrumbs = (route, pathname) => {
    if (pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
        return [
            {
                label: 'general.myDiveProjects',
                to: ROUTES.MY_DIVE_PROJECTS,
                icon: ICONS.HOMEPAGE_BREADCRUMB,
            },
            {
                label:
                    route === ROUTES.CREATE
                        ? 'general.createDiveProject'
                        : 'general.editDiveProject',
                to: `${ROUTES.MY_DIVE_PROJECTS}${route}`,
            },
        ]
    } else {
        return [
            {
                label: 'general.homepage',
                to: ROUTES.HOME,
                icon: ICONS.HOMEPAGE_BREADCRUMB,
                queryParams: getEntityFilters(FILTERS.DIVE_PROJECTS),
            },
            {
                label: 'general.diveProjects',
                to: ROUTES.HOME,
                queryParams: getEntityFilters(FILTERS.DIVE_PROJECTS),
            },
            {
                label:
                    route === ROUTES.CREATE
                        ? 'general.createDiveProject'
                        : 'general.editDiveProject',
                to: `${ROUTES.PROJECT}${route}`,
            },
        ]
    }
}
