import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { InputField, SelectField } from 'components/formFields'

const ExperienceForm = ({
    title,
    open,
    setOpen,
    initialData,
    handleSubmit,
    industries,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const requiredMessage = t('form.error.required')

    const initialValues = {
        industry: initialData?.industry ?? null,
        totalYears: initialData?.totalYears ?? '',
    }

    const validation = Yup.object({
        industry: Yup.object().required(requiredMessage),
        totalYears: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(1, t('form.error.invalidNumber'))
            .max(99, t('form.error.maxNumberIs99'))
            .required(requiredMessage),
    })

    const onSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await handleSubmit(formData)
            setOpen(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <Modal
                            open={open}
                            setOpen={setOpen}
                            closeOnClickOutside={false}
                            title={title}
                            smallModalWithDropdowns
                            isSubmitting={isSubmitting}
                            smallModal
                            buttons={{
                                nextBtn: {
                                    label: 'button.saveExperience',
                                },
                            }}
                        >
                            <div className="_wr">
                                <div className="-form _w">
                                    <div className="fullWidth">
                                        <div className="_12">
                                            <SelectField
                                                name="industry"
                                                defaultOptions={industries}
                                                required
                                            />
                                        </div>
                                        <div className="_12">
                                            <InputField
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                name="totalYears"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isSubmitting && <Loader />}
                        </Modal>
                    </Form>
                )
            }}
        </Formik>
    )
}

ExperienceForm.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    initialData: PropTypes.object,
    handleSubmit: PropTypes.func,
    industries: PropTypes.array,
}

export default ExperienceForm
