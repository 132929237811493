import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

const DiveSummaryFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext

    const {
        mentalWellnessInfluenced,
        anyIncidentsOnDive,
        abortedDive,
        traumaInjuryPresent,
        decompressionInjuryPresent,
        pressureInjuriesPresent,
        equipmentFailuresPresent,
        closeCallPresent,
    } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (!mentalWellnessInfluenced) {
            resetField('mentalWellnessDescription', '')
            resetField('mentalWellnessElements', [])
        }

        if (!abortedDive) {
            resetField('abortedDescription', '')
        }

        if (!traumaInjuryPresent) {
            resetField('traumaInjuryLevel', null)
            resetField('traumaInjuryDescription', '')
        }

        if (!decompressionInjuryPresent) {
            resetField('decompressionIllness', null)
            resetField('decompressionIllnessDescription', '')
        }

        if (!pressureInjuriesPresent) {
            resetField('pressureInjuries', [])
            resetField('pressureInjuryDescription', '')
        }

        if (!equipmentFailuresPresent) {
            resetField('equipmentFailures', [])
            resetField('equipmentFailureDescription', '')
        }

        if (!closeCallPresent) {
            resetField('closeCall', null)
            resetField('closeCallPresentDescription', '')
        }

        if (!anyIncidentsOnDive) {
            ;[
                'abortedDescription',
                'traumaInjuryDescription',
                'decompressionIllnessDescription',
                'pressureInjuryDescription',
                'equipmentFailureDescription',
                'closeCallPresentDescription',
            ].forEach((item) => resetField(item, ''))
            ;['pressureInjuries', 'equipmentFailures'].forEach((item) =>
                resetField(item, [])
            )
            ;['traumaInjuryLevel', 'decompressionIllness', 'closeCall'].forEach(
                (item) => resetField(item, null)
            )
            ;[
                'abortedDive',
                'traumaInjuryPresent',
                'decompressionInjuryPresent',
                'pressureInjuriesPresent',
                'equipmentFailuresPresent',
                'closeCallPresent',
            ].forEach((item) => resetField(item, false))
        }
    }, [
        mentalWellnessInfluenced,
        anyIncidentsOnDive,
        abortedDive,
        traumaInjuryPresent,
        decompressionInjuryPresent,
        pressureInjuriesPresent,
        equipmentFailuresPresent,
        closeCallPresent,
    ])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

export default DiveSummaryFormContext
