import React, { useContext, useEffect, useState } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

const useChannelUsers = () => {
    const { chatClient } = useContext(CurrentUserContext)

    const [channelUsers, setChannelUsers] = useState([])

    useEffect(() => {
        if (!chatClient) return

        const handleChangeChannelUsers = (event) => {
            const eventTypes = ['user.watching.start', 'user.watching.stop']

            if (eventTypes.includes(event.type)) {
                setChannelUsers((state) => {
                    const index = state.findIndex(
                        (user) => user.id === event.user.id
                    )
                    if (index !== -1) {
                        const onlineUsers = [...state]
                        onlineUsers[index] = event.user
                        return [...onlineUsers]
                    } else {
                        return [...state, event.user]
                    }
                })
            }
        }

        const channelUsersEventListener = chatClient.on((event) =>
            handleChangeChannelUsers(event)
        )

        return () => {
            channelUsersEventListener.unsubscribe()
        }
    }, [chatClient])

    return {
        channelUsers,
    }
}

export default useChannelUsers
