import { USER_SUBSCRIPTION_TYPE } from './enums'

const FEATURES_CATEGORIES = {
    PERSONAL_ACCOUNT: 'personalAccount',
    JOBS: 'jobs',
    DIVE_RECORD: 'diveRecords',
    SEARCH: 'search',
    OTHER: 'other',
}

const FEATURES = {
    PERSONAL_INFO: {
        key: 'personalInfo',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    DIVE_RECORDS: {
        key: 'diveRecords',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    MEDICAL_INFO: {
        key: 'medicalInfo',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    IDENTIFICATION_AND_TRAVEL: {
        key: 'identificationAndTravel',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    EDUCATION_AND_TRAINING: {
        key: 'educationAndTraining',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    PERSONAL_EQUIPMENT: {
        key: 'personalEquipment',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    PAST_EMPLOYMENT: {
        key: 'pastEmployment',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    SKILLS_AND_EXPERIENCE: {
        key: 'skillsAndExperience',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    PERSONA_VERIFICATION: {
        key: 'personaVerification',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    VIEW_CV_EXAMPLE: {
        key: 'viewCvExample',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [USER_SUBSCRIPTION_TYPE.FREE],
    },
    EXPORT_PROFILE_TO_CV: {
        key: 'exportProfileToCV',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    VERIFYING_CERTIFICATION_DATA: {
        key: 'verifyingCertificationData',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    VERIFYING_OTHER_DATA: {
        key: 'verifyingOtherData',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [USER_SUBSCRIPTION_TYPE.ENTERPRISE],
    },
    DATA_ACCESS: {
        key: 'dataAccessControl',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    GALLERY_SIZE: {
        key: 'gallerySize',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
        values: ['10 Mb', '25 Mb', '100 Mb'],
    },
    ACCOUNT_CUSTOMIZATION: {
        key: 'accountCustomization',
        category: FEATURES_CATEGORIES.PERSONAL_ACCOUNT,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    SEARCH_FOR_JOB_ADS: {
        key: 'searchForJobsAds',
        category: FEATURES_CATEGORIES.JOBS,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    APPLY_FOR_JOB_ADS: {
        key: 'applyForJobsAds',
        category: FEATURES_CATEGORIES.JOBS,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    CAN_BE_INVITED: {
        key: 'canBeInvited',
        category: FEATURES_CATEGORIES.JOBS,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    CAN_ACCEPT_INVITATION: {
        key: 'canAcceptInvitation',
        category: FEATURES_CATEGORIES.JOBS,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    LOGBOOK: {
        key: 'logbook',
        category: FEATURES_CATEGORIES.DIVE_RECORD,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    DIVE_RECORD_VERIFICATION_LEVEL_1: {
        key: 'diveRecordVerificationLevel1',
        category: FEATURES_CATEGORIES.DIVE_RECORD,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    DIVE_RECORD_VERIFICATION_LEVEL_2: {
        key: 'diveRecordVerificationLevel2',
        category: FEATURES_CATEGORIES.DIVE_RECORD,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    PDF_SCANNED_LOGS: {
        key: 'PDFScannedLogs',
        category: FEATURES_CATEGORIES.DIVE_RECORD,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    LEGACY_DIGITALISATION_LOGBOOK: {
        key: 'legacyDigitalisationOfLogbook',
        category: FEATURES_CATEGORIES.DIVE_RECORD,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    SND_DIGITALISE_FOR_YOU: {
        key: 'SNDDigitaliseForYou',
        category: FEATURES_CATEGORIES.DIVE_RECORD,
        packages: [USER_SUBSCRIPTION_TYPE.ENTERPRISE],
    },
    VIEW_USER_PROFILE: {
        key: 'viewUserProfile',
        category: FEATURES_CATEGORIES.SEARCH,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    VIEW_CONTRACTOR: {
        key: 'viewContractor',
        category: FEATURES_CATEGORIES.SEARCH,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    NOTIFICATION_SYSTEM: {
        key: 'notificationSystem',
        category: FEATURES_CATEGORIES.OTHER,
        packages: [
            USER_SUBSCRIPTION_TYPE.FREE,
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    CHAT: {
        key: 'chat',
        category: FEATURES_CATEGORIES.OTHER,
        packages: [
            USER_SUBSCRIPTION_TYPE.PRO,
            USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        ],
    },
    COMPETENCY_VERIFICATION: {
        key: 'competencyVerification',
        category: FEATURES_CATEGORIES.OTHER,
        packages: [USER_SUBSCRIPTION_TYPE.ENTERPRISE],
    },
}

export { FEATURES_CATEGORIES, FEATURES }
