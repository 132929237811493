import PropTypes from 'prop-types'
import {
    JOBS_SKILL_CATEGORY_TYPE_KEY,
    SKILL_TYPE_KEY,
} from 'constants/projectFilterKeys'
import useFormikFieldChange from 'hooks/useFormikFieldChange'

const DiversFilterFormikContext = ({ emptyValues }) => {
    const skillChangedHandler = (values, setFieldValue) => {
        setFieldValue(SKILL_TYPE_KEY, emptyValues[SKILL_TYPE_KEY])
    }

    useFormikFieldChange([JOBS_SKILL_CATEGORY_TYPE_KEY], skillChangedHandler)
}

DiversFilterFormikContext.propTypes = {
    emptyValues: PropTypes.shape({
        [SKILL_TYPE_KEY]: PropTypes.array,
    }),
}

export default DiversFilterFormikContext
