import {
    formatCheckboxMessage,
    formatDivingModeExperience,
    formatFromToRange,
    formatLanguages,
    formatSkills,
} from 'utils/filterButtonFormatters'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

const userFilters = {
    availability: {
        type: 'boolean',
        message: 'general.currentlyAvailableDivers',
        formatter: formatCheckboxMessage,
    },
    previouslyEmployed: {
        type: 'boolean',
        message: 'general.previouslyEmployed',
        formatter: formatCheckboxMessage,
    },
    yearsOfExperience: {
        type: 'range',
        unit: 'general.yearsOfExperience',
        translateUnit: true,
        formatter: formatFromToRange,
    },
    experienceDivingMode: {
        entityType: ENTITIES.DIVING_MODE,
        fetchAttribute: 'divingMode',
        formatter: formatDivingModeExperience,
    },
    country: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    region: {
        entityType: ENTITIES.REGION,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    role: {
        entityType: ENTITIES.DIVER_POSITION,
        displayAttribute: 'name',
    },
    skill: {
        entityType: ENTITIES.SKILL,
        displayAttribute: 'name',
    },
    skillCategory: {
        entityType: ENTITIES.SKILL_CATEGORY,
        displayAttribute: 'name',
    },
    skillRecord: {
        entityType: [ENTITIES.SKILL_CATEGORY, ENTITIES.SKILL],
        fetchAttribute: ['skillCategory', 'skill'],
        formatter: formatSkills
    },
    language: {
        entityType: [ENTITIES.LANGUAGE, ENTITIES.LANGUAGE_PROFICIENCY],
        fetchAttribute: ['language', 'languageProficiency'],
        formatter: formatLanguages,
    },
    age: {
        type: 'range',
        unit: 'years old',
        formatter: formatFromToRange,
    },
}

export { userFilters }
