import { useContext, useEffect, useState } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import { ChatComponent } from 'components/chat'
import Breadcrumbs from 'components/Breadcrumbs'

export const ChatPage = () => {
    const [reload, setReload] = useState(true)

    const { chatClient: client } = useContext(CurrentUserContext)

    const handleChangeReload = (setState) => {
        setState((state) => !state)
    }

    useEffect(() => {
        if (!client) return

        let timeoutId

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                timeoutId = setTimeout(() => {
                    handleChangeReload(setReload)
                }, 5000)
            } else {
                clearTimeout(timeoutId)
                handleChangeReload(setReload)
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
        }
    }, [client])

    if (!client?.user) return null

    return (
        <div>
            <div className="m-boxes">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'menuItems.chat',
                                to: ROUTES.CHAT,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                            },
                        ]}
                    />
                </div>

                <div className="m-boxes fullWidth">
                    <ChatComponent fullScreen={true} />
                </div>
            </div>
        </div>
    )
}
