import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const CustomEmailListItem = ({ title, email, handleSelect, setOpen }) => {
    const t = useTranslate()

    const handleClick = () => {
        handleSelect(email)
        setOpen(false)
    }

    return (
        <li
            className={`-result -customResult -gap10 ${
                !email ? '-noHover' : ''
            }`}
            onClick={email ? handleClick : null}
        >
            {email && (
                <div className="-iconWrapper aligned-center justify-center">
                    <Icon
                        name={ICONS.MAIL}
                        size={ICON_SIZE.SIZE16}
                        color={COLORS.DARK_BLUE_60}
                    />
                </div>
            )}
            <div className="column">
                <span className="a-captionsTextRegular">{title}</span>
                {email && (
                    <span className="a-captionsTextRegular a-lightText -opacity-60">
                        {t('general.enterEmailAddress')}
                    </span>
                )}
            </div>
        </li>
    )
}

CustomEmailListItem.propTypes = {
    title: PropTypes.string,
    email: PropTypes.string,
    handleSelect: PropTypes.func,
    setOpen: PropTypes.func,
}

export default CustomEmailListItem
