import PropTypes from 'prop-types'

import TransparentModal from './TransparentModal'

const TransparentImagePreview = ({ openImage, imageUrl, childRef }) => {
    return (
        <TransparentModal open={openImage}>
            <div className="m-transparentModal__image">
                <div
                    className={`m-transparentModal__image--wrapper`}
                    style={{
                        backgroundImage: imageUrl,
                    }}
                    ref={childRef}
                ></div>
            </div>
        </TransparentModal>
    )
}

TransparentImagePreview.propTypes = {
    openImage: PropTypes.bool,
    imageUrl: PropTypes.string,
}

TransparentImagePreview.defaultProps = {
    openImage: false,
    imageUrl: '',
}

export default TransparentImagePreview
