import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'

const usePremiumFeature = () => {
    const navigate = useNavigate()

    const { currentUser, isContractor } = useContext(CurrentUserContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const hasFeatureAccess = (feature) =>
        isContractor ||
        feature.packages.includes(
            currentUser?.userSubscription?.userSubscriptionType
        )

    const handleUpgradeToPremium = (confirmAction) => {
        if (confirmAction) confirmAction()
        navigate(ROUTES.PACKAGES)
        closeConfirmationModal()
    }

    const handleCancelPremium = (cancelAction) => {
        if (cancelAction) cancelAction()
        closeConfirmationModal()
    }

    const handlePremiumFeature = (actions = {}) => {
        const { confirmAction, cancelAction } = actions

        showConfirmationModal({
            title: 'general.unlockMoreWithPro',
            message: 'message.featureIsAvailableInTheProVersion',
            icon: ICONS.PRO,
            iconSize: ICON_SIZE.SIZE60,
            iconColor: COLORS.YELLOW,
            confirmLabel: 'button.upgradeToPro',
            handleConfirm: () => handleUpgradeToPremium(confirmAction),
            handleCancel: () => handleCancelPremium(cancelAction),
            confirmIcon: ICONS.PRO,
            confirmIconColor: COLORS.YELLOW,
        })
    }

    return {
        hasFeatureAccess,
        handlePremiumFeature,
    }
}

export default usePremiumFeature
