import axios, {
    DELETE,
    GET,
    PATCH,
    POST,
    RESPONSE_TYPE,
} from 'utils/axiosClient'
import {
    formatUrl,
    formatRequestData,
    formatResponseData,
} from 'utils/jsonApiFormatters'

export const getEntityService = async (
    entity,
    params,
    isJSONAPI = true,
    handleError = false,
    apiUrl = process.env.REACT_APP_API_HOST,
    responseType = RESPONSE_TYPE.JSON,
    requestType = GET
) => {
    try {
        const PATH = `/${entity}`
        const url = requestType === GET ? formatUrl(PATH, params) : PATH
        const response = await axios(
            requestType,
            url,
            requestType === GET ? null : params,
            isJSONAPI,
            apiUrl,
            responseType
        )
        if (isJSONAPI) {
            const { data, meta } = formatResponseData(
                response.data,
                params?.include
            )
            return { data, meta, error: null }
        }

        return { data: response.data, error: null }
    } catch (error) {
        if (handleError) {
            return { error, data: null, meta: null }
        }
        throw error
    }
}

export const getEntityByIdService = async (
    entity,
    id,
    params,
    isJSONAPI = true,
    handleError = false,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    try {
        const PATH = `/${entity}/${id}`
        const url = formatUrl(PATH, params)
        const response = await axios(GET, url, null, isJSONAPI, apiUrl)
        if (isJSONAPI) {
            const { data } = formatResponseData(response.data, params?.include)
            return { data, error: null }
        }

        return { data: response.data, error: null }
    } catch (error) {
        if (handleError) {
            return { error, data: null }
        }
        throw error
    }
}

export const createEntityService = async (
    entity,
    formData,
    isJSONAPI = true,
    notRelationshipKeys = [],
    apiUrl = process.env.REACT_APP_API_HOST,
    hasEmptyResponse = false
) => {
    try {
        const requestData = isJSONAPI
            ? formatRequestData(entity, formData, notRelationshipKeys)
            : formData
        const response = await axios(
            POST,
            `/${entity}`,
            requestData,
            isJSONAPI,
            apiUrl
        )
        if (!isJSONAPI) {
            return response.data
        } else if (!hasEmptyResponse) {
            const { data, meta } = formatResponseData(response.data)
            return { data, meta }
        }
    } catch (error) {
        throw error
    }
}

export const editEntityService = async (
    entity,
    id,
    formData,
    isJSONAPI = true,
    notRelationshipKeys = [],
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    try {
        const requestData = isJSONAPI
            ? formatRequestData(entity, formData, notRelationshipKeys)
            : formData
        const response = await axios(
            PATCH,
            `/${entity}${id ? `/${id}` : ''}`,
            requestData,
            isJSONAPI,
            apiUrl
        )
        if (!isJSONAPI) {
            return response.data
        } else {
            const { data, meta } = formatResponseData(response.data)
            return { data, meta }
        }
    } catch (error) {
        throw error
    }
}

export const editService = async (
    entity,
    formData,
    isJSONAPI = true,
    notRelationshipKeys = [],
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    try {
        const requestData = isJSONAPI
            ? formatRequestData(entity, formData, notRelationshipKeys)
            : formData

        await axios(PATCH, `/${entity}`, requestData, isJSONAPI, apiUrl)
    } catch (error) {
        throw error
    }
}

export const deleteEntityService = async (
    entity,
    id,
    params,
    isJSONAPI = true,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    try {
        const url = formatUrl(`/${entity}/${id}`, params)
        await axios(DELETE, url, null, isJSONAPI, apiUrl)
    } catch (error) {
        throw error
    }
}

export const persistEntityService = async (
    entity,
    formData,
    id,
    notRelationshipKeys = []
) => {
    if (id) {
        return editEntityService(
            entity,
            id,
            formData,
            true,
            notRelationshipKeys
        )
    } else {
        return createEntityService(entity, formData, true, notRelationshipKeys)
    }
}
