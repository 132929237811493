import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'

import { PastEmploymentForm } from './..'

const EditPastEmployment = ({ data, open, setOpen, fetchData }) => {
    const handleSubmit = async (newData) => {
        await editEntityService(
            ENTITIES.PAST_EMPLOYMENT,
            data.id,
            newData,
            true
        )
        fetchData()
    }

    return (
        <PastEmploymentForm
            title="general.editNewPastEmployment"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
        />
    )
}

export default EditPastEmployment
