import { useEffect, useRef } from 'react'

const { useFormikContext } = require('formik')

const DiveEventFormContext = ({ timeDifference, setTimeDifference }) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { repetitiveDive, timeLeftSurface, timeLeftBottom } = values

    const firstRender = useRef(true)

    const convertTo24Hour = (time) => {
        let [timePart] = time.split(' ')
        let [hours, minutes] = timePart.split(':').map(Number)

        return [hours, minutes]
    }

    const calculateDifference = (time1, time2) => {
        const [hours1, minutes1] = convertTo24Hour(time1)
        const [hours2, minutes2] = convertTo24Hour(time2)

        const date1 = new Date()
        const date2 = new Date()

        date1.setHours(hours1, minutes1, 0)
        date2.setHours(hours2, minutes2, 0)

        // Handle case where time2 is earlier than time1 by adding a day to time2
        if (date2 < date1) {
            date2.setDate(date2.getDate() + 1)
        }

        // Calculate the difference in milliseconds
        const diffInMillis = date2 - date1

        // Convert milliseconds to hours and minutes
        const diffInMinutes = Math.floor(diffInMillis / (1000 * 60))
        const diffHours = Math.floor(diffInMinutes / 60)
        const diffMinutes = diffInMinutes % 60

        setTimeDifference({ hours: diffHours, minutes: diffMinutes })
    }

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (timeLeftBottom && timeLeftSurface) {
            calculateDifference(timeLeftSurface, timeLeftBottom)
        }
    }, [timeLeftBottom, timeLeftSurface])

    useEffect(() => {
        setFieldValue('bottomTimeHours', timeDifference?.hours)
        setFieldValue('bottomTimeMinutes', timeDifference?.minutes)
    }, [timeDifference])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (!firstRender.current && !repetitiveDive) {
            resetField('siSinceLastDiveHours', '')
            resetField('siSinceLastDiveMinutes', 0)
            resetField('beginningRGD', '')
            resetField('rntPenaltyHours', '')
            resetField('rntPenaltyMinutes', 0)
        }
    }, [repetitiveDive])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

export default DiveEventFormContext
