import React from 'react'

import DiveRecordContextProvider from './contexts/DiveRecordContext'

import DiveRecordSteps from './DiveRecordSteps'

const DiveRecord = () => {
    return (
        <DiveRecordContextProvider>
            <DiveRecordSteps />
        </DiveRecordContextProvider>
    )
}

export default DiveRecord
