import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'
import { formatDate, MOMENT_FORMATS } from 'services/moment.service'

import { ICON_SIZE, ALERT_TYPES } from 'constants/enums'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import InfoRow from 'components/InfoRow'
import { EditPastEmployment } from './..'
import CardHeader from 'components/card/CardHeader'

const PastEmployments = ({
    data,
    fetchData,
    isLoading,
    profilePage,
    showActions,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [edit, setEdit] = useState(null)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.PAST_EMPLOYMENT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeletePastEmployment',
            title: 'general.deletePastEmployment',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            profilePage ? '_l12 profileWrapper' : '_l6'
                        } _12 -mt20`}
                    >
                        <CardContainer>
                            <CardHeader
                                title={
                                    item?.pastEmploymentPosition.name +
                                    ', ' +
                                    item?.employer.name
                                }
                                item={item}
                                actions={
                                    showActions
                                        ? [
                                              {
                                                  handleAction: handleEdit,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip:
                                                      'button.editPastEmployment',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip:
                                                      'button.deletePastEmployment',
                                              },
                                          ]
                                        : []
                                }
                                fullTitle
                            />
                            <Info
                                label="form.label.location"
                                value={item.location}
                            />
                            {item.contactPerson && (
                                <Info
                                    label="form.label.contactPerson"
                                    value={item.contactPerson}
                                />
                            )}
                            {(item.contactEmail || item.contactPhone) && (
                                <InfoRow>
                                    {item.contactEmail && (
                                        <Info
                                            label="form.label.contactPersonEmail"
                                            value={item.contactEmail}
                                        />
                                    )}
                                    {item.contactPhone && (
                                        <Info
                                            label="form.label.contactPersonPhone"
                                            value={item.contactPhone}
                                        />
                                    )}
                                </InfoRow>
                            )}
                            <Info
                                label="form.label.position"
                                value={item.pastEmploymentPosition.name}
                            />
                            {/* NOTE:: remove ? after BE implementation */}
                            <Info
                                label="form.label.industry"
                                value={item?.industry?.name}
                            />
                            <InfoRow>
                                <Info
                                    label="form.label.startedDate"
                                    value={formatDate(
                                        item.startedDate,
                                        MOMENT_FORMATS.DATE_MONTHYEAR
                                    )}
                                />
                                <Info
                                    label="form.label.finishedDate"
                                    value={
                                        item.stillEmployed === true
                                            ? t(
                                                  'form.placeholder.stillEmployed'
                                              )
                                            : formatDate(
                                                  item.finishedDate,
                                                  MOMENT_FORMATS.DATE_MONTHYEAR
                                              )
                                    }
                                    textColor={
                                        item.stillEmployed ? 'green' : 'gray'
                                    }
                                />
                            </InfoRow>
                            <Info
                                label="form.label.jobDuties"
                                value={item.jobDuties}
                                column={true}
                            />

                            <InfoFiles
                                label="general.pastEmploymentFiles"
                                files={item.files}
                            />
                        </CardContainer>
                    </div>
                ))}

                {!!edit && (
                    <EditPastEmployment
                        data={edit}
                        setOpen={setEdit}
                        fetchData={fetchData}
                    />
                )}
            </div>
        </div>
    )
}

PastEmployments.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
}

PastEmployments.defaultProps = {
    profilePage: false,
    showActions: false,
}

export default PastEmployments
