import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

const CategoryNameRow = ({ title }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const emptyCells = Array(3)
        .fill()
        .map((_, index) => (
            <th key={index}>
                <div></div>
            </th>
        ))

    return (
        <tr className="-categoryRow" key={title}>
            <th colSpan={isTablet ? 2 : 0}>
                <div className="a-mediumTextSemiBold a-lightText -pt10 -pb10 a-allCaps">
                    {t(`featuresCategory.${title}`)}
                </div>
            </th>
            {!isTablet && emptyCells}
        </tr>
    )
}

CategoryNameRow.propTypes = {
    title: PropTypes.string,
}

export default CategoryNameRow
