import { Form, Formik, useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'

import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { MultiselectField, SelectField } from 'components/formFields'

const SkillRecordFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { skillCategory } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            if (skillCategory) {
                setFieldValue('skills', [])
                setFieldTouched('skills', false)
            }
        } else {
            firstRender.current = false
        }
    }, [skillCategory])

    return null
}

const SkillRecordForm = ({
    handleSubmit,
    savedData,
    setOpen,
    open,
    title,
    initialData,
}) => {
    const t = useTranslate()

    const initialValues = {
        skillCategory: initialData
            ? { id: initialData.id, name: initialData.skillCategoryName }
            : null,
        skills: initialData?.skills ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        skillCategory: Yup.object().required(requiredMessage),
        skills: Yup.array().min(1, requiredMessage).required(requiredMessage),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values: { skillCategory } }) => (
                <Form>
                    <FocusError />
                    <SkillRecordFormContext />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title={title}
                        smallModalWithDropdowns
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.saveSkills',
                            },
                        }}
                        smallModal
                        customClass="-widerSmallModal"
                    >
                        <div className="_wr">
                            <div className="-form _w">
                                <div className="_12 _m6">
                                    <SelectField
                                        name="skillCategory"
                                        entityType={ENTITIES.SKILL_CATEGORY}
                                        disabledItems={
                                            savedData && [...savedData]
                                        }
                                        disabled={!!initialData}
                                        required
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="skills"
                                        entityType={ENTITIES.SKILL}
                                        params={
                                            skillCategory
                                                ? {
                                                      ['skillCategories.id']:
                                                          skillCategory.id,
                                                  }
                                                : null
                                        }
                                        reload={skillCategory?.id}
                                        disabled={!skillCategory?.id}
                                        required
                                        searchable
                                        createNew
                                        createNewParams={{
                                            related_entity:
                                                ENTITIES.SKILL_CATEGORY,
                                            related_entity_id:
                                                skillCategory?.id,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

SkillRecordForm.propTypes = {
    handleSubmit: PropTypes.func,
    savedData: PropTypes.array,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    initialData: PropTypes.object,
}

export default SkillRecordForm
