import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

const PageSelector = ({
    options,
    itemsPerPage,
    setItemsPerPage,
    totalItems,
}) => {
    const t = useTranslate()

    const { isSmallerThanXlScreen } = useWindowDimensions()

    const handleChangeItems = (event) => {
        const { value } = event.target
        setItemsPerPage(Number(value))
    }

    return (
        <div className="pagination-selector">
            <span>{t('table.pagination.showing')}</span>
            <div>
                <select defaultValue={itemsPerPage} onClick={handleChangeItems}>
                    {options.map((item) => (
                        <option key={item}>{item}</option>
                    ))}
                </select>
            </div>
            {!isSmallerThanXlScreen && (
                <span>
                    {`${t('table.pagination.of')} `}
                    {totalItems}{' '}
                    <span className="-entities">
                        {t('table.pagination.entities')}
                    </span>
                </span>
            )}
        </div>
    )
}

PageSelector.propTypes = {
    options: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    setItemsPerPage: PropTypes.func.isRequired,
    totalItems: PropTypes.number,
}

export default PageSelector
