import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import EditExperience from './EditExperience'
import CardHeader from 'components/card/CardHeader'
import Info from 'components/Info'

const ExperienceList = ({
    data,
    isLoading,
    fetchData,
    refetchData,
    showActions,
    industries,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [edit, setEdit] = useState(null)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.EXPERIENCE, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteExperience',
            title: 'general.deleteExperience',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={1} />
    }

    return (
        <Fragment>
            {data &&
                !!data.length &&
                data.map((item) => (
                    <div key={item.id} className="12 -mt20 -experienceCard">
                        <CardContainer>
                            <CardHeader
                                title={item.industry?.name}
                                item={item}
                                actions={
                                    showActions
                                        ? [
                                              {
                                                  handleAction: handleEdit,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip:
                                                      'button.editExperience',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip:
                                                      'button.deleteExperience',
                                              },
                                          ]
                                        : []
                                }
                            />
                            <Info
                                value={`${item.totalYears} ${
                                    item.totalYears === 1
                                        ? t('general.year')
                                        : t('general.years')
                                }`}
                                color="purple"
                            />
                        </CardContainer>
                    </div>
                ))}

            {!!edit && (
                <EditExperience
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    showActions={showActions}
                    industries={industries.filter(
                        (industry) =>
                            !data.some(
                                (item) => item?.industry?.id === industry.id
                            ) || industry.id === edit?.industry.id
                    )}
                />
            )}
        </Fragment>
    )
}

ExperienceList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    showActions: PropTypes.bool,
    industries: PropTypes.array,
}

ExperienceList.defaultProps = {
    showActions: false,
}

export default ExperienceList
