import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import ROUTES from 'constants/routes'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'

import useFetchDataById from 'hooks/useFetchDataById'

import { getEntityFilters } from 'services/localStorage.service'

import Breadcrumbs from 'components/Breadcrumbs'
import DiveRecordList from '../diveRecordList/DiveRecordList'
import RESPONSE_CODES from '../../../constants/responseCodes'
import { AlertContext } from '../../../contexts/AlertContext'
import { useTranslate } from 'react-polyglot'

const ProjectDiveRecords = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { setAlert } = useContext(AlertContext)
    const { id } = useParams()
    const { pathname } = useLocation()

    const isMyProjects = pathname.includes(ROUTES.MY_DIVE_PROJECTS)
    const projectPath = isMyProjects ? ROUTES.MY_DIVE_PROJECTS : ROUTES.PROJECT
    const diveRecordPath = isMyProjects
        ? ROUTES.MY_PROJECT_RECORDS
        : ROUTES.DIVE_RECORDS

    const { data: project, error } = useFetchDataById(ENTITIES.PROJECT, id, {
        include: [
            'country',
            'company',
            'region',
            'locationType',
            'projectType',
            'divingMode',
            'countryOfMobilization',
            'projectStatus',
            'positions',
            'creator',
        ],
    })

    /** Handle forbidden  */
    useEffect(() => {
        if (error?.response?.status === RESPONSE_CODES.FORBIDDEN) {
            const msg = error?.response?.data?.message
            if (msg) {
                setAlert(t(msg), ALERT_TYPES.ERROR)
            }
            navigate(`${ROUTES.HOME}`)
        }
    }, [error])

    if (!project) return null

    return (
        <div className="_wr">
            <div className="_w">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.homepage',
                                to: ROUTES.HOME,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: 'general.diveProjects',
                                to: isMyProjects
                                    ? ROUTES.MY_DIVE_PROJECTS
                                    : ROUTES.HOME,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: project.name,
                                to: `${projectPath}/${id}`,
                                translate: false,
                                showAlways: true,
                            },
                            {
                                label: 'general.diveRecords',
                                to: `${projectPath}/${id}/${diveRecordPath}`,
                                showAlways: true,
                            },
                        ]}
                    />
                </div>

                <div className="m-boxes fullWidth">
                    <div className="m-boxes__main -fullWidth">
                        <DiveRecordList projectId={parseInt(id)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectDiveRecords
