import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import Badge from 'components/Badge'

const BadgeContent = ({ data, paragraph }) => {
    const t = useTranslate()

    const renderCriteria = (criteria) => {
        return (
            <ul>
                {criteria.map((item, index) => {
                    if (typeof item === 'string') {
                        return (
                            <li
                                key={index}
                                className="a-mediumText a-lightText -liDisc"
                            >
                                {item}
                            </li>
                        )
                    } else if (item.type === 'list') {
                        return (
                            <li key={index}>
                                <ul className="subcriteria-list">
                                    {item.items.map((subItem, subIndex) => (
                                        <li
                                            key={subIndex}
                                            className="a-mediumText a-lightText -liDisc"
                                        >
                                            {subItem}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        )
                    } else {
                        return null
                    }
                })}
            </ul>
        )
    }

    return (
        <Fragment>
            <div className="-mt20 -mb20">
                <span className="a-mediumText a-lightText">{t(paragraph)}</span>
            </div>
            {data.map((badge) => {
                return (
                    <Fragment key={badge.key}>
                        <div className="-mb20" id={`badge-${badge.key}`}>
                            <div>
                                <Badge badgeKey={badge.icon} size={80} />
                            </div>
                            <span className=" a-mediumTextSemiBold">
                                {badge.title}
                            </span>

                            <div className="-mt10">
                                <span className="a-mediumText a-lightText">
                                    {badge.description}
                                </span>
                            </div>
                            {badge?.criteria && (
                                <div className="-mt10">
                                    <div>{renderCriteria(badge.criteria)}</div>
                                </div>
                            )}
                        </div>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

export default BadgeContent
