import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'

import { persistEntityService } from 'services/entity.service'

import {
    positiveNumberAndLessThan100Validation,
    validate24Hours,
    validateHours,
    validateMinutes,
} from 'utils/yupValidations'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { InputField, SelectField } from 'components/formFields'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'

const BellRunEventFormModal = ({ initialData, fetchData, closeModal }) => {
    const t = useTranslate()

    const { currentUser } = useContext(CurrentUserContext)

    const { diveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)

    const {
        data: { diveRecordDiveData },
    } = diveRecord

    const isImperialUnit =
        diveRecord.data?.unitImperial ?? currentUser.userSetting.unitImperial

    const unitOfMeasurementLabel = isImperialUnit
        ? 'form.label.ft'
        : 'form.label.m'

    const initialValues = {
        bellRun: initialData?.bellRun ?? '',
        bellRunDivePosition: initialData?.bellRunDivePosition ?? null,
        bellDiveStartTimeHours: initialData?.bellDiveStartTimeHours ?? '',
        bellDiveStartTimeMinutes: initialData?.bellDiveStartTimeMinutes ?? '',
        bellDeepestDepth: initialData?.bellDeepestDepth ?? '',
        bellShallowestDepth: initialData?.bellShallowestDepth ?? '',
        bellAtmosphereO2: initialData?.bellAtmosphereO2 ?? '',
        bellDiveEndTimeHours: initialData?.bellDiveEndTimeHours ?? '',
        bellDiveEndTimeMinutes: initialData?.bellDiveEndTimeMinutes ?? '',
        comment: initialData?.comment ?? '',
    }

    const requiredMessage = t('form.error.required')

    const positiveNumberValidation = Yup.number()
        .integer(t('form.error.invalidNumber'))
        .required(requiredMessage)
        .positive(t('form.error.positiveNumber'))

    const validation = Yup.object({
        bellRun: positiveNumberValidation,
        bellRunDivePosition: Yup.object().required(requiredMessage),
        bellDiveStartTimeHours: validate24Hours(t),
        bellDiveStartTimeMinutes: validateMinutes(t),
        bellDeepestDepth: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .test('bellDeepestDepth', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.bellShallowestDepth)) {
                    return createError({
                        message: t(
                            'form.error.mustBeMoreThanBellShallowestDepth'
                        ),
                        path: 'bellDeepestDepth',
                    })
                }
                return true
            })
            .required(requiredMessage),
        bellShallowestDepth: positiveNumberValidation,
        bellAtmosphereO2: positiveNumberAndLessThan100Validation(
            t,
            requiredMessage
        ).notRequired(),
        bellDiveEndTimeHours: validateHours(t),
        bellDiveEndTimeHours: validate24Hours(t),
        bellDiveEndTimeMinutes: validateMinutes(t),
        comment: Yup.string(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                ENTITIES.BELL_RUN_EVENT,
                {
                    ...formData,
                    diveRecordDiveData: diveRecordDiveData,
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting }) => {
                return (
                    <Form>
                        <FocusError />
                        <Modal
                            open={true}
                            setOpen={closeModal}
                            closeOnClickOutside={false}
                            title="general.bellRunEvent"
                            isSubmitting={isSubmitting}
                            buttons={{
                                prevBtn: {
                                    handleClick: closeModal,
                                },
                                nextBtn: {
                                    handleClick: handleSubmit,
                                },
                            }}
                        >
                            <div className="_wr">
                                <div className="_w">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="bellRun"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            min={'0'}
                                            required
                                        />
                                    </div>

                                    <div className="_12 _l4">
                                        <SelectField
                                            name="bellRunDivePosition"
                                            label="form.label.divePosition"
                                            placeholder="form.placeholder.divePosition"
                                            entityType={
                                                ENTITIES.BELL_RUN_POSITIONS
                                            }
                                            searchable
                                            required
                                        />
                                    </div>

                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t(
                                                'form.label.bellDiveStartTimeHours'
                                            )}
                                            <span className="redStar"> *</span>
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="bellDiveStartTimeHours"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                                required
                                            />
                                            <InputField
                                                name="bellDiveStartTimeMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="_w">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="bellDeepestDepth"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            min={'0'}
                                            required
                                        />
                                    </div>

                                    <div className="_12 _l4">
                                        <InputField
                                            name="bellShallowestDepth"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            min={'0'}
                                            required
                                        />
                                    </div>

                                    <div className="_12 _l4">
                                        <InputField
                                            name="bellAtmosphereO2"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={'form.label.%'}
                                            min={'0'}
                                        />
                                    </div>
                                </div>

                                <div className="_w">
                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t(
                                                'form.label.bellDiveEndTimeHours'
                                            )}
                                            <span className="redStar"> *</span>
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="bellDiveEndTimeHours"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                                required
                                            />
                                            <InputField
                                                name="bellDiveEndTimeMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12">
                                        <TextAreaField name="comment" />
                                    </div>
                                </div>
                            </div>

                            {isSubmitting && <Loader />}
                        </Modal>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default BellRunEventFormModal
