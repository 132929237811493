import { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { getEntityService } from 'services/entity.service'

import renderLoader from '../../utils/loaderHelper'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import AddNew from '../../AddNew'
import ExperienceList from './ExperienceList'
import NoData from 'components/NoData'
import CreateExperience from './CreateExperience'

const ExperienceWrapper = ({
    data,
    isLoading,
    fetchData,
    showActions,
    refetchData,
}) => {
    const t = useTranslate()

    const [addExperience, setAddExperience] = useState(false)
    const [industries, setIndustries] = useState([])

    useEffect(() => {
        getEntityService(ENTITIES.INDUSTRY, { pagination: false }).then(
            (response) => {
                setIndustries(response.data)
            }
        )
    }, [])

    const enabledIndustries = industries.filter(
        (industry) => !data.some((item) => item?.industry?.id === industry.id)
    )

    return (
        <>
            {showActions && (
                <AddNew
                    handleAction={() => {
                        setAddExperience(true)
                    }}
                    label="general.addExperience"
                />
            )}
            {!showActions && data.length === 0 && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t('general.nothingHereRightNow')}
                />
            )}
            {addExperience && (
                <CreateExperience
                    open={addExperience}
                    setOpen={setAddExperience}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    industries={enabledIndustries}
                    showActions={showActions}
                />
            )}
            <ExperienceList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                industries={industries}
                showActions={showActions}
                refetchData={refetchData}
            />

            {renderLoader(isLoading, true)}
        </>
    )
}

ExperienceWrapper.propTypes = {
    showActions: PropTypes.bool,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
}

ExperienceWrapper.defaultProps = {
    showActions: false,
}

export default ExperienceWrapper
