import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ENTITIES from 'constants/entities'

import { createEntityService } from 'services/entity.service'

import DivingModeExperienceForm from './DivingModeExperienceForm'

const CreateDivingModeExperience = ({
    open,
    setOpen,
    handleClose,
    fetchData,
    showActions,
    refetchData,
    divingMode,
}) => {
    const t = useTranslate()

    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.EXPERIENCE, formData, true, [
            'experienceDivingModes',
        ])
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <DivingModeExperienceForm
            title={`${t('general.addExperienceIn')} ${divingMode.name} ${t(
                'general.divingMode'
            )}`}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            divingMode={divingMode}
        />
    )
}

CreateDivingModeExperience.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleClose: PropTypes.func,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
    divingMode: PropTypes.object,
}

CreateDivingModeExperience.defaultProps = {
    showActions: false,
}

export default CreateDivingModeExperience
