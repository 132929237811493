import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Breadcrumbs from 'components/Breadcrumbs'
import DiveRecordList from '../../common/diveRecordList/DiveRecordList'
import CreateDiveRecordModal from 'screens/common/CreateDiveRecordModal'
import { ProjectListSidebar } from 'components/projects'

const MyDiveRecords = () => {
    const t = useTranslate()

    const { currentUser } = useContext(CurrentUserContext)
    const { isSmallScreen } = useWindowDimensions()

    if (!currentUser) return null

    return (
        <div>
            <div className="m-boxes">
                <div className="m-boxes__breadcrumbs -sectionOrderSmallDevices">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.myDiveRecords',
                                to: `/${ROUTES.DIVE_RECORDS}`,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                            },
                        ]}
                    />
                </div>
                <div className="m-boxes__main">
                    <DiveRecordList />
                </div>
                <div
                    className={`m-boxes__side -button -sectionOrderSmallDevices ${
                        isSmallScreen ? '-mb20' : ''
                    }`}
                >
                    <div className="m-boxes__side">
                        <span className=" a-bodyTextMedium">
                            {t('general.createDiveRecordForPastProject')}
                        </span>
                        <p className="-mb20 -mt10 a-mediumText a-lightText -opacity-60">
                            {t('general.createDiveRecordForPastProjectInfo')}
                        </p>

                        <CreateDiveRecordModal
                            route={`/${ROUTES.DIVE_RECORDS}`}
                        />
                    </div>
                    <div className="m-boxes__side -projectListSidebar -mt20 column">
                        <span className="-mb10 a-bodyTextMedium">
                            {t('general.yourActiveProjects')}
                        </span>
                        <ProjectListSidebar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyDiveRecords
