import React from 'react'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
    ICON_SIZE,
} from 'constants/enums'

import Icon from 'components/Icon'
import Button from 'components/Button'

const AccessDeniedPage = () => {
    const t = useTranslate()

    const navigate = useNavigate()
    const { state } = useLocation()

    const goToApp = () => {
        navigate(ROUTES.HOME)
    }

    return (
        <div className="m-emailLink">
            <div className="m-emailLink__background">
                <div className="m-emailLink__logo" onClick={goToApp}></div>
                <div className="m-emailLink__content">
                    <div className="column aligned-center">
                        <Icon
                            name={ICONS.ALERT_ERROR}
                            size={ICON_SIZE.SIZE36}
                            color={COLORS.SECONDARY}
                        />
                        <h6 className="a-bodyTextBold a-centeredText -mt20">
                            {t(state?.title || 'general.accessDenied')}
                        </h6>
                        <p className="m-emailLink__text a-centeredText -mt10 -mb20">
                            {t(
                                state?.description ||
                                    'general.accessDeniedDescription'
                            )}
                        </p>
                        <Button
                            label="button.goToApp"
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.LARGE}
                            icon={ICONS.ARROW_RIGHT}
                            iconPosition={ICON_POSITION.RIGHT}
                            iconColor={COLORS.SECONDARY}
                            onClick={goToApp}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessDeniedPage
