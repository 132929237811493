import { Fragment, useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import { mockPopularDivingOrganizations } from 'data/mockData'

import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'

const MostPopularOrganisations = () => {
    const t = useTranslate()

    const { isVerified } = useContext(CurrentUserContext)

    const { data } = useFetchData(
        ENTITIES.MOST_POPULAR_ORGANISATIONS,
        {
            itemsPerPage: 5,
        },
        isVerified
    )

    const mostPopularOrganisations = isVerified
        ? data
        : mockPopularDivingOrganizations

    const getSubtitle = (org) =>
        [
            org.hqLocationCountry,
            org.industries &&
                org.industries.map(({ value }) => value).join(', '),
        ].filter((item) => item)

    return (
        <div className="fullWidth">
            <p className="a-bodyTextMedium">
                {t('general.mostPopularDivingOrganizations')}
            </p>
            <div className={!isVerified ? `a-blurredContainer` : ''}>
                {mostPopularOrganisations
                    ? mostPopularOrganisations.map((org) => {
                          return (
                              <Fragment key={org.id}>
                                  <div className="-mt20">
                                      <CardContainer
                                          link={
                                              isVerified
                                                  ? `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${org.profileHash}`
                                                  : null
                                          }
                                          noBorderCard
                                      >
                                          <CardHeader
                                              avatar={org.logo}
                                              title={org.companyName}
                                              placeholderIcon={ICONS.BUILDING}
                                              subtitle={getSubtitle(org)}
                                              smallerFontSize
                                          />
                                      </CardContainer>
                                  </div>
                              </Fragment>
                          )
                      })
                    : null}
            </div>
        </div>
    )
}

export default MostPopularOrganisations
