import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import DiveTeamListItemCommentsModal from './DiveTeamListItemCommentsModal'

const DiveTeamListItemComments = ({ diveTeamItem, refetchData }) => {
    const t = useTranslate()

    const [openModal, setOpenModal] = useState(false)

    if (!diveTeamItem) {
        return null
    }

    return (
        <Fragment>
            <Button
                btnClass={BUTTON_STATUS.TERTIARY}
                buttonSize={BUTTON_SIZE.XSMALL}
                label={
                    t('general.comments') +
                    ' (' +
                    (diveTeamItem.numberOfComments || 0) +
                    ')'
                }
                onClick={() => {
                    setOpenModal(true)
                }}
                icon={ICONS.ARROW_RIGHT}
                iconColor={COLORS.LIGHT_BLUE}
                iconPosition={ICON_POSITION.RIGHT}
                noTranslateLabel
            />

            {openModal && (
                <DiveTeamListItemCommentsModal
                    diveTeamItem={diveTeamItem}
                    refetchData={refetchData}
                    open={openModal}
                    setOpen={setOpenModal}
                />
            )}
        </Fragment>
    )
}

DiveTeamListItemComments.propTypes = {
    diveTeamItem: PropTypes.object,
    refetchData: PropTypes.func,
}

export default DiveTeamListItemComments
