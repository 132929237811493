import PropTypes from 'prop-types'
import { useContext } from 'react'
import { AlertContext } from 'contexts/AlertContext'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'

import SkillRecordForm from './SkillRecordForm'

const EditSkillRecord = ({
    open,
    setOpen,
    fetchData,
    showActions,
    refetchData,
    edit,
}) => {
    const { setAlert } = useContext(AlertContext)

    const handleSubmit = async (formData, { setSubmitting }) => {
        const id = formData.skillCategory.id
        const skillsData = {
            data: {
                type: ENTITIES.SKILL_RECORD,
                attributes: {
                    skills: formData.skills.map((skill) => {
                        if (skill.id) {
                            return {
                                skill: { id: skill.id },
                            }
                        }
                    }),
                },
            },
        }
        try {
            setSubmitting(true)
            await editEntityService(
                ENTITIES.SKILL_RECORD,
                id,
                skillsData,
                false,
                ['experienceDivingModes']
            )
            fetchData()
            showActions && refetchData && refetchData()
            setOpen(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <SkillRecordForm
            title="general.editSkills"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={edit}
        />
    )
}

EditSkillRecord.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
    edit: PropTypes.object,
}

EditSkillRecord.defaultProps = {
    showActions: false,
}

export default EditSkillRecord
