import { useContext } from 'react'

import { clearStorage } from 'services/localStorage.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

const useLogout = () => {
    const {
        setCurrentUser,
        setCurrentCompany,
        setUnreadMessages,
        chatClient,
        setChatClient,
    } = useContext(CurrentUserContext)

    const disconnectChatClient = async () => {
        await chatClient.disconnectUser()
        setChatClient(chatClient)
    }

    const handleLogout = () => {
        if (chatClient?.user) {
            disconnectChatClient()
        }
        setCurrentUser(null)
        setCurrentCompany(null)
        setUnreadMessages(0)
        clearStorage()
    }

    return { handleLogout }
}

export default useLogout
