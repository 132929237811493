import { useState, useEffect, useContext } from 'react'

import { AlertContext } from 'contexts/AlertContext'

import { getEntityByIdService } from 'services/entity.service'

import { ALERT_TYPES } from 'constants/enums'

const responseInitialState = {
    data: null,
    isLoading: false,
    error: null,
}

const useFetchDataById = (
    entity,
    id,
    params = {},
    condition = true,
    reload = false,
    isJSONAPI = true,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    const { setAlert } = useContext(AlertContext) || {}

    const [response, setResponse] = useState(responseInitialState)

    const allowedFetch = entity && id && condition

    useEffect(() => {
        if (allowedFetch) {
            fetchData()
        }
    }, [reload])

    // Clean response on entity change
    useEffect(() => {
        return () => setResponse(responseInitialState)
    }, [entity])

    const fetchData = async (fetchParams) => {
        try {
            if (!allowedFetch) return

            setResponse((res) => ({ ...res, isLoading: true }))

            const requestParams = {
                ...params,
                ...fetchParams,
            }

            if (requestParams.include && Array.isArray(requestParams.include)) {
                requestParams.include = requestParams.include.join(',')
            }

            const { data } = await getEntityByIdService(
                entity,
                id,
                requestParams,
                isJSONAPI,
                false,
                apiUrl
            )

            setResponse({
                data,
                isLoading: false,
                error: null,
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
            setResponse({ ...responseInitialState, error })
        }
    }

    return { ...response, fetchData }
}

export default useFetchDataById
