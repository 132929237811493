import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const TrainingRecordsAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.TRAINING_RECORDS}
            accessTypeLabel="form.label.selectAccessForTrainingRecords"
            dataAccessAttribute="accessTrainingRecords"
            title="general.educationAndTraining"
        />
    )
}

export default TrainingRecordsAccessControl
