import { Fragment } from 'react'
import { formatByNumberOfPills } from 'utils/cardHelper'

const SkillsList = (item) => {
    return (
        <Fragment>
            <div className="a-skillsList">
                {formatByNumberOfPills(item.item.projectPositionSkill, 3).map(
                    (position, index) => {
                        return (
                            <span
                                key={index}
                                className="-value -blue -withColor -mb5"
                            >
                                {position?.skillCategory?.name &&
                                position?.skill?.name
                                    ? position?.skillCategory.name +
                                      '-' +
                                      position?.skill.name
                                    : position}
                            </span>
                        )
                    }
                )}
            </div>
        </Fragment>
    )
}

export default SkillsList
