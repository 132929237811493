import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import useQueryParams from 'hooks/useQueryParams'

import { validateGteLteValues } from 'utils/yupValidations'

import ENTITIES from 'constants/entities'
import { INPUT_FILED_TYPE } from 'constants/enums'

import Separator from 'components/Separator'
import Modal from 'components/Modal'
import { InputField, MultiselectField } from 'components/formFields'
import JobsFilterFormikContext from './JobsFilterFormikContext'
import Loader from 'components/Loader'
import FilterIconButton from 'components/FilterIconButton'

const JobsFilters = ({ activeFilters }) => {
    const t = useTranslate()

    const { replaceFilterParams } = useQueryParams()

    const [open, setOpen] = useState(false)

    const emptyValues = {
        projectName: '',
        company: [],
        countryOfWork: [],
        geographicalRegion: [],
        locationType: [],
        countryOfMobilization: [],
        bodyOfWater: [],
        industry: [],
        projectType: [],
        modeOfDive: [],
        toDurationDays: { gte: '', lte: '' },
        fromPayRate: '',
        positionType: [],
        skillCategory: [],
        skill: [],
    }

    const initialValues = {
        projectName: activeFilters.projectName ?? emptyValues.projectName,
        company: activeFilters.company ?? emptyValues.company,
        countryOfWork: activeFilters.countryOfWork ?? emptyValues.countryOfWork,
        geographicalRegion:
            activeFilters.geographicalRegion ?? emptyValues.geographicalRegion,
        locationType: activeFilters.locationType ?? emptyValues.locationType,
        countryOfMobilization:
            activeFilters.countryOfMobilization ??
            emptyValues.countryOfMobilization,
        bodyOfWater: activeFilters.bodyOfWater ?? emptyValues.bodyOfWater,
        industry: activeFilters.industry ?? emptyValues.industry,
        projectType: activeFilters.projectType ?? emptyValues.projectType,
        modeOfDive: activeFilters.modeOfDive ?? emptyValues.modeOfDive,
        toDurationDays:
            activeFilters.toDurationDays ?? emptyValues.toDurationDays,
        fromPayRate: activeFilters.fromPayRate ?? emptyValues.fromPayRate,
        positionType: activeFilters.positionType ?? emptyValues.positionType,
        skillCategory: activeFilters.skillCategory ?? emptyValues.skillCategory,
        skill: activeFilters.skill ?? emptyValues.skill,
    }

    const validation = Yup.object({
        toDurationDays: validateGteLteValues(
            t,
            'form.error.toDurationGreaterThanFromDuration',
            'toDurationDays.lte'
        ),
        fromPayRate: Yup.number()
            .integer(t('form.error.onlyIntegerNumber'))
            .min(0, t('form.error.invalidNumber')),
    })

    const handleFilter = (filters, { setSubmitting }) => {
        setSubmitting(true)
        replaceFilterParams(filters)
        setSubmitting(false)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    return (
        <Fragment>
            <FilterIconButton setOpen={setOpen} />
            {open && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleFilter}
                >
                    {({ values, setFieldValue, setTouched, isSubmitting }) => (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                title="general.filterJobs"
                                isSubmitting={isSubmitting}
                                values={values}
                                buttons={{
                                    middleBtn: {
                                        handleClick: () =>
                                            handleReset(
                                                values,
                                                setFieldValue,
                                                setTouched
                                            ),
                                    },
                                    nextBtn: {
                                        label: 'button.filter',
                                    },
                                }}
                            >
                                <JobsFilterFormikContext
                                    emptyValues={emptyValues}
                                />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.divingContractor')}
                                </span>
                                <div className="_12 _m6">
                                    <InputField
                                        name="projectName"
                                        label="form.label.projectName"
                                        placeholder="form.placeholder.projectName"
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="company"
                                        label="form.label.divingContractor"
                                        placeholder="form.placeholder.divingContractor"
                                        entityType={ENTITIES.COMPANY}
                                        searchable
                                    />
                                </div>

                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.locationInfo')}
                                </span>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="countryOfWork"
                                        label="form.label.countryOfWork"
                                        placeholder="form.placeholder.selectOneOrMoreCountries"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="geographicalRegion"
                                        label="form.label.geographicalRegion"
                                        placeholder="form.placeholder.selectRegion"
                                        entityType={ENTITIES.REGION}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="locationType"
                                        label="form.label.locationType"
                                        placeholder="form.placeholder.selectLocationType"
                                        entityType={ENTITIES.LOCATION_TYPE}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="countryOfMobilization"
                                        label="form.label.countryOfMobilization"
                                        placeholder="form.placeholder.selectCountries"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="bodyOfWater"
                                        label="form.label.bodyOfWater"
                                        placeholder="form.placeholder.selectBodyOfWater"
                                        entityType={ENTITIES.BODY_OF_WATER}
                                        searchable
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.projectIndustryAndDiveInfo')}
                                </span>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="industry"
                                        label="form.label.industry"
                                        placeholder="form.placeholder.selectIndustries"
                                        entityType={ENTITIES.INDUSTRY}
                                        params={{
                                            onlyUser: false
                                        }}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="projectType"
                                        label="form.label.projectType"
                                        placeholder="form.placeholder.selectProjectTypes"
                                        entityType={
                                            ENTITIES.PROJECT_INDUSTRY_TYPES
                                        }
                                        params={
                                            values.industry.length > 0
                                                ? {
                                                      'industries.id':
                                                          values.industry.map(
                                                              (industry) =>
                                                                  industry.id
                                                          ),
                                                  }
                                                : null
                                        }
                                        reload={values.industry.length}
                                        disabled={!values.industry.length}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="modeOfDive"
                                        label="form.label.modeOfDive"
                                        placeholder="form.placeholder.selectModeOfDive"
                                        entityType={ENTITIES.DIVING_MODE}
                                        searchable
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.projectDurationInDays')}
                                </span>
                                <div className="_6 first-field noPadding">
                                    <InputField
                                        name="toDurationDays.gte"
                                        label="form.label.from"
                                        placeholder="form.placeholder.durationFrom"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <div className="_6 second-field">
                                    <InputField
                                        name="toDurationDays.lte"
                                        label="form.label.to"
                                        placeholder="form.placeholder.durationTo"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('form.label.positionType')}
                                </span>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="positionType"
                                        label="form.label.position"
                                        placeholder="form.placeholder.selectPositionTypes"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        searchable
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('form.label.payRate')}
                                </span>
                                <div className="_12 _m6">
                                    <InputField
                                        name="fromPayRate"
                                        label="form.label.from"
                                        placeholder="form.placeholder.rateFrom"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>

                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('form.label.requiredSkills')}
                                </span>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="skillCategory"
                                        label="form.label.skillsCategory"
                                        placeholder="form.placeholder.skillsCategory"
                                        entityType={ENTITIES.SKILL_CATEGORY}
                                        searchable
                                        dropup
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="skill"
                                        label="form.label.skills"
                                        placeholder="form.placeholder.skills"
                                        entityType={ENTITIES.SKILL}
                                        params={
                                            values.skillCategory.length > 0
                                                ? {
                                                      'skillCategories.id':
                                                          values.skillCategory.map(
                                                              (category) =>
                                                                  category.id
                                                          ),
                                                  }
                                                : null
                                        }
                                        reload={values.skillCategory.length}
                                        disabled={!values.skillCategory.length}
                                        searchable
                                        dropup
                                    />
                                </div>
                                {isSubmitting && <Loader />}
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </Fragment>
    )
}

JobsFilters.propTypes = {
    activeFilters: PropTypes.object,
}

export default JobsFilters
