import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useFixedOnScroll = () => {
    const [fixedComponents, setFixedComponents] = useState(false)
    const location = useLocation()

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar)

        return () => {
            window.removeEventListener('scroll', stickNavbar)
        }
    }, [])

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY
            setFixedComponents(windowHeight > 30)
        }
    }

    const contentElement = document.querySelector('.-diveRecordContent')
    const contentElementHeight = contentElement?.offsetHeight

    useEffect(() => {
        const footerElement = document.querySelector(
            '.-diveRecordFooter .m-step__buttons'
        )
        const footerSection = document.querySelector('.-diveRecordFooter')
        const contentElement = document.querySelector('.-diveRecordContent')
        const stepElement = document.querySelector('.m-step')

        const updatePadding = () => {
            if (
                footerElement &&
                contentElement &&
                footerSection &&
                stepElement &&
                !fixedComponents
            ) {
                const footerHeight = footerElement.offsetHeight
                //NOTE: this is to prevent the footer section from being invisible on window sizes
                // that are nearly the same height as the content, with padding equal to the footer height
                const heightSafeZone = footerHeight + 20
                if (
                    stepElement.offsetHeight <
                    window.innerHeight + heightSafeZone
                ) {
                    footerSection.classList.add('-active')
                } else {
                    footerSection.classList.remove('-active')
                }

                contentElement.style.paddingBottom = footerHeight + 20 + 'px'
            }
        }

        updatePadding()

        window.addEventListener('resize', updatePadding)

        return () => {
            window.removeEventListener('resize', updatePadding)
        }
    }, [location, contentElementHeight])

    return {
        fixedComponents,
    }
}

export default useFixedOnScroll
