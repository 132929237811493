import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import useWindowDimensions from 'hooks/useWindowDimension'

import ICONS from 'constants/icons'
import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import { Stepper } from 'components/stepper'

const DiveRecordHeader = ({
    goBack,
    diveRecordName,
    allSteps,
    activeStepIndex,
    currentStep,
    allowedStep,
    handleClick,
    fixedHeader,
    activeFixedHeader,
}) => {
    const { verificationToken } = useParams()

    const { isTablet } = useWindowDimensions()

    const renderBackButton = () => {
        if (verificationToken && activeStepIndex === 0)
            return <div className="-fakeSpace"></div>
        return (
            <Button
                btnClass={BUTTON_STATUS.TERTIARY}
                icon={ICONS.ARROW_LEFT}
                iconColor={COLORS.LIGHT_BLUE}
                label="button.goBackFromDiveRecord"
                onClick={goBack}
                buttonSize={BUTTON_SIZE.XSMALL}
            />
        )
    }
    return (
        <div
            className={`${fixedHeader ? '-diveRecordHeader' : ''} ${
                activeFixedHeader ? '-active' : ''
            }`}
        >
            <div className="_wr">
                <div className="_w">
                    {!isTablet && (
                        <div className="_12 m-step__backButton aligned-center space-between -gap20">
                            {!fixedHeader && renderBackButton()}
                            {!fixedHeader && <h3>{diveRecordName()}</h3>}
                            <div className="-fakeSpace"></div>
                        </div>
                    )}
                    <div className="_12">
                        <div className="aligned-center">
                            {(fixedHeader || isTablet) && (
                                <div className="-backBtn">
                                    {renderBackButton()}
                                </div>
                            )}
                            <Stepper
                                steps={allSteps}
                                activeStepIndex={activeStepIndex}
                                currentStep={currentStep}
                                allowedStep={allowedStep}
                                handleClick={handleClick}
                            />
                        </div>
                    </div>
                    {isTablet && !fixedHeader && (
                        <div className="_12 justify-center -mb20">
                            <h3>{diveRecordName()}</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

DiveRecordHeader.propTypes = {
    goBack: PropTypes.func,
    diveRecordName: PropTypes.func,
    allSteps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    activeStepIndex: PropTypes.number,
    currentStep: PropTypes.number,
    allowedStep: PropTypes.number,
    handleClick: PropTypes.func,
    fixedHeader: PropTypes.bool,
    activeFixedHeader: PropTypes.bool,
}

DiveRecordHeader.defaultProps = {
    fixedHeader: false,
    activeFixedHeader: false,
}

export default DiveRecordHeader
