import ProfileContextProvider from './context/ProfileContext'

import Profile from './Profile'

const ProfileScreen = () => {
    return (
        <ProfileContextProvider>
            <Profile />
        </ProfileContextProvider>
    )
}

export default ProfileScreen
