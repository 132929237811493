import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ENTITIES from 'constants/entities'

import { createEntityService } from 'services/entity.service'

import ExperienceForm from './ExperienceForm'

const CreateExperience = ({
    open,
    setOpen,
    fetchData,
    refetchData,
    industries,
    showActions,
}) => {
    const t = useTranslate()

    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.EXPERIENCE, formData, true)
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <ExperienceForm
            title="general.addExperience"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            industries={industries}
        />
    )
}

CreateExperience.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    industries: PropTypes.array,
    showActions: PropTypes.bool,
}

CreateExperience.defaultProps = {
    showActions: false,
}

export default CreateExperience
