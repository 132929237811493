import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const PersonalEquipmentAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.PERSONAL_EQUIPMENT}
            accessTypeLabel="form.label.selectAccessForPersonalEquipment"
            dataAccessAttribute="accessPersonalEquipment"
            title="general.personalEquipment"
        />
    )
}

export default PersonalEquipmentAccessControl
