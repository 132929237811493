import { Fragment, useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate, useParams } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    DIVING_PERSONNEL_POSITIONS,
    ICON_POSITION,
} from 'constants/enums'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import CreateDiveRecordModal from 'screens/common/CreateDiveRecordModal'
import DiveRecordListCard from 'screens/common/diveRecordList/components/DiveRecordListCard'

const LatestDiveRecordsList = ({
    data,
    fetchData,
    canCreateDiveRecord,
    positionOnCurrentProject,
    showEmptyValueMessage,
    isProjectOwner,
    isProjectClient
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { id } = useParams()
    const { isDiver, userId } = useContext(CurrentUserContext)

    const cantViewRecords = () => {
        if (
            canCreateDiveRecord ||
            (isDiver &&
                positionOnCurrentProject ===
                    DIVING_PERSONNEL_POSITIONS.DIVE_SUPERVISOR.code)
        ) {
            return true
        }

        return !isDiver && (isProjectOwner || isProjectClient)
    }

    return (
        <Fragment>
            <div className="column">
                <span className="-mb20 a-bodyTextMedium">
                    {t(
                        `${
                            isDiver
                                ? 'general.yourLatestDiveRecords'
                                : 'general.diveRecords'
                        }`
                    )}
                </span>
            </div>
            {data.slice(0, 5).map((item, index) => (
                <div className="-mb10 -diveRecordSideCard" key={item.id}>
                    <DiveRecordListCard
                        diveRecord={item}
                        fetchData={fetchData}
                        sidebarList={true}
                    />
                </div>
            ))}
            {showEmptyValueMessage && (
                <span className="a-mediumText a-lightText fullWidth">
                    {t('general.noDiveRecordsCreatedYet')}
                </span>
            )}
            {cantViewRecords() && data.length > 0 && (
                <div className="-mt20">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.SMALL}
                        icon={ICONS.ARROW_RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        label="button.viewDiveRecords"
                        onClick={() =>
                            navigate(
                                `${ROUTES.PROJECT}/${id}/${ROUTES.DIVE_RECORDS}`
                            )
                        }
                    />
                </div>
            )}
            {canCreateDiveRecord && (
                <div className="-mt20">
                    <CreateDiveRecordModal
                        route={`${ROUTES.PROJECT}/${id}`}
                        positionOnCurrentProject={positionOnCurrentProject}
                        title="button.createNewDiveRecord"
                    />
                </div>
            )}
        </Fragment>
    )
}

LatestDiveRecordsList.propTypes = {}

export default LatestDiveRecordsList
