import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import { formatByName, formatByNumberOfPills } from 'utils/cardHelper'
import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import SearchAndFilter from 'components/SearchAndFilter'
import { CompanyListFilters } from './index'
import NoData from 'components/NoData'

import CardFooter from 'components/card/CardFooter'
import CardHeader from 'components/card/CardHeader'
import InfoRow from 'components/InfoRow'

const filters = {}

const CompanyList = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const {
        data: companies,
        meta,
        isLoading,
        fetchData,
        loadMore,
    } = useFetchDataByQueryParams(
        ENTITIES.COMPANY_ELASTIC,
        { include: 'locationOfServices,regions,countries' },
        true
    )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.COMPANIES
    )

    const condition =
        companies.length === 0 &&
        Object.keys(activeFilters).length === 0 &&
        !isLoading

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    const handleCompanyClick = (item) => {
        navigate(`${ROUTES.DIVING_CONTRACTOR_PROFILE}/${item.profileHash}`)
    }

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchContractors"
                activeFilters={activeFilters}
                filters={filters}
                showFilterButton={false}
                showDefaultKey={false}
            >
                <CompanyListFilters />
            </SearchAndFilter>
            {companies.map((item) => {
                return (
                    <div key={item.id} className="-mb10">
                        <CardContainer
                            link={`${ROUTES.DIVING_CONTRACTOR_PROFILE}/${item.profileHash}`}
                        >
                            <CardHeader
                                title={item.name}
                                item={item}
                                subtitle={item.hqCountry}
                                avatar={item.imagePath}
                                placeholderIcon={ICONS.BUILDING}
                            />
                            {item.industries && (
                                <Info
                                    label="form.label.industry"
                                    value={formatByNumberOfPills(
                                        formatByName(item.industries)
                                    )}
                                    color="darkYellow"
                                />
                            )}
                            {(item.regions?.length > 0 ||
                                item.countries?.length > 0) && (
                                <InfoRow>
                                    <Info label="general.locationsOfServices" />
                                    {item.regions?.length > 0 && (
                                        <Info
                                            value={formatByNumberOfPills(
                                                formatByName(item.regions)
                                            )}
                                            color="green"
                                        />
                                    )}
                                    {item.countries?.length > 0 && (
                                        <Info
                                            value={formatByNumberOfPills(
                                                formatByName(item.countries)
                                            )}
                                            color="green"
                                        />
                                    )}
                                </InfoRow>
                            )}
                            <CardFooter withTopMargin>
                                <Button
                                    label="button.view"
                                    onClick={() => handleCompanyClick(item)}
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.ARROW_RIGHT}
                                    iconColor={COLORS.LIGHT_BLUE}
                                    iconPosition={ICON_POSITION.RIGHT}
                                    buttonSize={BUTTON_SIZE.XSMALL}
                                />
                            </CardFooter>
                        </CardContainer>
                    </div>
                )
            })}
            {companies.length === 0 &&
                Object.keys(activeFilters).length !== 0 &&
                !isLoading && (
                    <NoData
                        icon={ICONS.SEARCH_ICON}
                        title={t('general.noResults')}
                        description={t('general.noSearchResultsDesc')}
                    />
                )}
            {showEmptyValueMessage &&
                Object.keys(activeFilters).length === 0 && (
                    <>
                        {Object.keys(activeFilters).length === 0 && (
                            <NoData
                                icon={ICONS.NO_DATA}
                                title={t('general.noDataResults')}
                                description={t('general.noProjectResultsDesc')}
                            />
                        )}
                    </>
                )}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {renderLoader(isLoading, null, isLoadingFilters)}
        </div>
    )
}

export default CompanyList
