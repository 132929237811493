import React from 'react'
import { useTranslate } from 'react-polyglot'

import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import ICONS from 'constants/icons'

import NoData from './NoData'

const EmptyList = ({ icon, title, description, showSearchResult }) => {
    const t = useTranslate()

    const { showEmptyValueMessage } = useEmptyValueMessage(!showSearchResult)

    if (showSearchResult)
        return (
            <NoData
                icon={ICONS.SEARCH_ICON}
                title={t('general.noResults')}
                description={t('general.noSearchResultsDesc')}
            />
        )

    if (showEmptyValueMessage)
        return <NoData icon={icon} title={title} description={description} />

    return null
}

export default EmptyList
