import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import useFetchData from 'hooks/useFetchData'
import useWindowDimensions from 'hooks/useWindowDimension'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'
import DiveEventFormContext from '../../formContext/DiveEventFormContext'

import COLORS from 'constants/colors'
import { YES_NO_BOOLEAN_OPTIONS } from 'constants/constants'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    ICON_SIZE,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
    TOOLTIP_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'

import { persistEntityService } from 'services/entity.service'
import { MOMENT_FORMATS } from 'services/moment.service'

import {
    divingEventHasInputsForGasContents,
    filterGasContents,
    isReBreather,
    shouldShowHelium,
    shouldShowNitrogen,
    shouldShowOxygen,
} from 'utils/diveRecordFunctions'
import { convertTimeFormat } from 'utils/timeFormatter'
import {
    validate24Hours,
    validate24HoursNotRequired,
    validateBreathingMixtureOxygen,
    validateHelium,
    validateMinutes,
    validateMinutesNotRequired,
    validateNitrogen,
    validateOxygen,
} from 'utils/yupValidations'

import Icon from 'components/Icon'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import Tooltip from 'components/Tooltip'
import {
    DateTimeField,
    InputField,
    RadioGroupField,
    SelectField,
} from 'components/formFields'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'

const DiveEventFormModal = ({ initialData, fetchData, closeModal }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const { diveRecord, isSupervisorDiveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)
    const [timeDifference, setTimeDifference] = useState({
        hours: initialData?.bottomTimeHours ?? 0,
        minutes: initialData?.bottomTimeMinutes ?? 0,
    })

    const {
        data: {
            diveRecordDiveData,
            diveRecordEquipment,
            diveRecordGeneralInformation,
            unitImperial,
        },
    } = diveRecord

    const { divingMode } = diveRecordGeneralInformation

    const shouldShowFullMixInputs =
        divingEventHasInputsForGasContents(divingMode) || isSupervisorDiveRecord
    const isReBreatherType = isReBreather(divingMode)

    const { data: gasContents } = useFetchData(ENTITIES.GAS_CONTENTS, {})

    const initialValues = {
        repetitiveDive: initialData?.repetitiveDive ?? false,
        siSinceLastDiveHours: initialData?.siSinceLastDiveHours ?? '',
        siSinceLastDiveMinutes: initialData?.siSinceLastDiveMinutes ?? 0,
        beginningRGD: initialData?.beginningRGD ?? '',
        rntPenaltyHours: initialData?.rntPenaltyHours ?? '',
        rntPenaltyMinutes: initialData?.rntPenaltyMinutes ?? 0,
        timeLeftSurface: initialData?.timeLeftSurface ?? '',
        maxDepthOfDive: initialData?.maxDepthOfDive ?? '',
        timeLeftBottom: initialData?.timeLeftBottom ?? '',
        firstDecoStopDepth: initialData?.firstDecoStopDepth ?? '',
        lastDecoStopDepth: initialData?.lastDecoStopDepth ?? '',
        totalDecoTimeHours: initialData?.totalDecoTimeHours ?? '',
        totalDecoTimeMinutes: initialData?.totalDecoTimeMinutes ?? 0,
        timeReachedSurface: initialData?.timeReachedSurface ?? '',
        bottomTimeHours: initialData?.bottomTimeHours ?? timeDifference.hours,
        bottomTimeMinutes:
            initialData?.bottomTimeMinutes ?? timeDifference.minutes,
        totalBottomTimeHours: initialData?.totalBottomTimeHours ?? '',
        totalBottomTimeMinutes: initialData?.totalBottomTimeMinutes ?? 0,
        endingRGD: initialData?.endingRGD ?? '',
        decompressionType: initialData?.decompressionType ?? null,
        decompressionTableType: initialData?.decompressionTableType ?? null,
        decoScheduleUsedMeters: initialData?.decoScheduleUsedMeters ?? '',
        decoScheduleUsedMinutes: initialData?.decoScheduleUsedMinutes ?? 0,
        comment: initialData?.comment ?? '',
        breathingMixture: initialData?.breathingMixture ?? null,
        decoBreathingMixture: initialData?.decoBreathingMixture ?? null,
        breathingMixtureGasContents:
            initialData?.breathingMixtureGasContents ?? null,
        breathingMixtureOxygen: initialData?.breathingMixtureOxygen ?? '',
        breathingMixtureHelium: initialData?.breathingMixtureHelium ?? '',
        breathingMixtureNitrogen: initialData?.breathingMixtureNitrogen ?? '',
        decoBreathingMixtureGasContents:
            initialData?.decoBreathingMixtureGasContents ?? null,
        decoBreathingMixtureOxygen:
            initialData?.decoBreathingMixtureOxygen ?? '',
        decoBreathingMixtureHelium:
            initialData?.decoBreathingMixtureHelium ?? '',
        decoBreathingMixtureNitrogen:
            initialData?.decoBreathingMixtureNitrogen ?? '',
    }

    const requiredMessage = t('form.error.required')
    const positiveNumberValidation = Yup.number()
        .integer(t('form.error.invalidNumber'))
        .positive(t('form.error.positiveNumber'))
        .required(requiredMessage)

    const validation = Yup.object({
        siSinceLastDiveHours: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .when('repetitiveDive', {
                is: (val) => val === true,
                then: () => validate24HoursNotRequired(t),
                otherwise: () => Yup.number(),
            }),
        siSinceLastDiveMinutes: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .when('repetitiveDive', {
                is: (val) => val === true,
                then: () => validateMinutesNotRequired(t),
                otherwise: () => Yup.number(),
            }),
        beginningRGD: Yup.string().when('repetitiveDive', {
            is: (val) => val === true,
            then: () =>
                Yup.string()
                    .length(1, t('form.error.mustBeExactlyOneLetter'))
                    .matches(/^[a-zA-Z]$/, t('form.error.numbersNotAllowed')),
            otherwise: () => Yup.string(),
        }),
        rntPenaltyHours: Yup.number().when('repetitiveDive', {
            is: (val) => val === true,
            then: () => validate24HoursNotRequired(t),
            otherwise: () => Yup.number(),
        }),
        rntPenaltyMinutes: Yup.number().when('repetitiveDive', {
            is: (val) => val === true,
            then: () => validateMinutesNotRequired(t),
            otherwise: () => Yup.number(),
        }),
        timeLeftSurface: Yup.string().required(requiredMessage),
        maxDepthOfDive: positiveNumberValidation,
        timeLeftBottom: Yup.string().required(requiredMessage),
        firstDecoStopDepth: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .positive(t('form.error.positiveNumber')),
        lastDecoStopDepth: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .positive(t('form.error.positiveNumber')),
        totalDecoTimeHours: validate24Hours(t),
        totalDecoTimeMinutes: validateMinutes(t),
        timeReachedSurface: Yup.string().required(requiredMessage),
        bottomTimeHours: validate24Hours(t),
        bottomTimeMinutes: validateMinutes(t),
        totalBottomTimeHours: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .max(23, t('form.error.maxHoursIs23')),
        totalBottomTimeMinutes: validateMinutes(t),
        endingRGD: Yup.string()
            .length(1, t('form.error.mustBeExactlyOneLetter'))
            .matches(/^[a-zA-Z]$/, t('form.error.numbersNotAllowed')),
        decompressionType: Yup.object().required(requiredMessage),
        decompressionTableType: Yup.object().required(requiredMessage),
        decoScheduleUsedMeters: positiveNumberValidation,
        decoScheduleUsedMinutes: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.minutesError'))
            .nullable()
            .required(requiredMessage),
        comment: Yup.string(),
        breathingMixture: shouldShowFullMixInputs
            ? Yup.object().notRequired()
            : Yup.object().required(requiredMessage),
        decoBreathingMixture: Yup.object().notRequired(),
        breathingMixtureGasContents: shouldShowFullMixInputs
            ? Yup.object().required(requiredMessage)
            : Yup.object().notRequired(),
        breathingMixtureOxygen: shouldShowFullMixInputs
            ? validateBreathingMixtureOxygen(
                  'breathingMixtureGasContents',
                  t,
                  requiredMessage
              )
            : Yup.number(),
        breathingMixtureHelium: shouldShowFullMixInputs
            ? validateHelium('breathingMixtureGasContents', t, requiredMessage)
            : Yup.number(),
        breathingMixtureNitrogen: shouldShowFullMixInputs
            ? validateNitrogen(
                  'breathingMixtureGasContents',
                  t,
                  requiredMessage
              )
            : Yup.number(),
        decoBreathingMixtureGasContents: Yup.object().notRequired(),
        decoBreathingMixtureOxygen: shouldShowFullMixInputs
            ? validateOxygen(
                  'decoBreathingMixtureGasContents',
                  t,
                  requiredMessage
              )
            : Yup.number(),
        decoBreathingMixtureHelium: shouldShowFullMixInputs
            ? validateHelium(
                  'decoBreathingMixtureGasContents',
                  t,
                  requiredMessage
              )
            : Yup.number(),
        decoBreathingMixtureNitrogen: shouldShowFullMixInputs
            ? validateNitrogen(
                  'decoBreathingMixtureGasContents',
                  t,
                  requiredMessage
              )
            : Yup.number(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                ENTITIES.DIVE_EVENT,
                {
                    ...formData,
                    diveRecordDiveData: diveRecordDiveData,
                    timeLeftSurface: convertTimeFormat(
                        formData.timeLeftSurface
                    ),
                    timeLeftBottom: convertTimeFormat(formData.timeLeftBottom),
                    timeReachedSurface: convertTimeFormat(
                        formData.timeReachedSurface
                    ),
                },
                initialData ? initialData.id : null
            )
            fetchData()
            diveRecord.fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    const unitOfMeasurementBarOrAta = unitImperial
        ? 'form.label.ata'
        : 'form.label.bar'

    if (!gasContents || !gasContents.length) {
        return <></>
    }

    const enabledGasContents = filterGasContents(gasContents, divingMode)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({
                handleSubmit,
                isSubmitting,
                values: {
                    repetitiveDive,
                    breathingMixtureGasContents,
                    decoBreathingMixtureGasContents,
                    decompressionType,
                },
            }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={closeModal}
                        closeOnClickOutside={false}
                        title={divingMode.name}
                        noTranslateTitle
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {
                                handleClick: closeModal,
                            },
                            nextBtn: {
                                handleClick: handleSubmit,
                            },
                        }}
                    >
                        <DiveEventFormContext
                            timeDifference={timeDifference}
                            setTimeDifference={setTimeDifference}
                        />

                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _l4">
                                    <RadioGroupField
                                        name="repetitiveDive"
                                        defaultOptions={YES_NO_BOOLEAN_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                    />
                                </div>
                            </div>
                            {repetitiveDive && (
                                <div className="_w -mt5">
                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t(
                                                'form.label.siSinceLastDiveHours'
                                            )}
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="siSinceLastDiveHours"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                            />
                                            <InputField
                                                name="siSinceLastDiveMinutes"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                            />
                                        </div>
                                    </div>

                                    <div className="_12 _l4">
                                        <InputField name="beginningRGD" />
                                    </div>

                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t('form.label.rntPenaltyHours')}
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="rntPenaltyHours"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                            />
                                            <InputField
                                                name="rntPenaltyMinutes"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!shouldShowFullMixInputs && (
                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        <SelectField
                                            name="breathingMixture"
                                            entityType={ENTITIES.GAS_CYLINDER}
                                            params={{
                                                'diveRecordEquipment.id':
                                                    diveRecordEquipment?.id,
                                            }}
                                            displayAttribute={'displayName'}
                                            required
                                            searchable
                                        />
                                    </div>
                                    <div className="_12 _l4">
                                        <SelectField
                                            name="decompressionType"
                                            entityType={
                                                ENTITIES.DECOMPRESSION_TYPE
                                            }
                                            searchable
                                            required
                                        />
                                    </div>
                                </div>
                            )}

                            {shouldShowFullMixInputs && (
                                <div className="_w">
                                    <div className="_12 _m6 _l4">
                                        <SelectField
                                            name="breathingMixtureGasContents"
                                            label="form.label.breathingMixtureGasContents"
                                            placeholder="form.placeholder.gasContents"
                                            defaultOptions={enabledGasContents}
                                            required
                                        />
                                    </div>

                                    {breathingMixtureGasContents &&
                                        shouldShowOxygen(
                                            breathingMixtureGasContents,
                                            divingMode
                                        ) && (
                                            <div className="_12 _m4 ">
                                                <InputField
                                                    name="breathingMixtureOxygen"
                                                    label={
                                                        isReBreatherType
                                                            ? 'form.label.oxygenPPO2'
                                                            : 'form.label.oxygen'
                                                    }
                                                    placeholder={
                                                        isReBreatherType
                                                            ? 'form.placeholder.oxygenPPO2'
                                                            : 'form.placeholder.oxygen'
                                                    }
                                                    units={
                                                        isReBreatherType
                                                            ? unitOfMeasurementBarOrAta
                                                            : null
                                                    }
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                    {breathingMixtureGasContents &&
                                        shouldShowNitrogen(
                                            breathingMixtureGasContents,
                                            divingMode
                                        ) && (
                                            <div className="_12 _m4 ">
                                                <InputField
                                                    name="breathingMixtureNitrogen"
                                                    label={
                                                        isReBreatherType
                                                            ? 'form.label.nitrogenPPN'
                                                            : 'form.label.nitrogen'
                                                    }
                                                    placeholder={
                                                        isReBreatherType
                                                            ? 'form.placeholder.nitrogenPPN'
                                                            : 'form.placeholder.nitrogen'
                                                    }
                                                    units={
                                                        isReBreatherType
                                                            ? unitOfMeasurementBarOrAta
                                                            : null
                                                    }
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                    {breathingMixtureGasContents &&
                                        shouldShowHelium(
                                            breathingMixtureGasContents
                                        ) && (
                                            <div className="_12 _m4 ">
                                                <InputField
                                                    name="breathingMixtureHelium"
                                                    label={
                                                        isReBreatherType
                                                            ? 'form.label.heliumPPHe'
                                                            : 'form.label.helium'
                                                    }
                                                    placeholder={
                                                        isReBreatherType
                                                            ? 'form.placeholder.heliumPPHe'
                                                            : 'form.placeholder.helium'
                                                    }
                                                    units={
                                                        isReBreatherType
                                                            ? unitOfMeasurementBarOrAta
                                                            : null
                                                    }
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}
                                    <div className="_12 _l4">
                                        <SelectField
                                            name="decompressionType"
                                            entityType={
                                                ENTITIES.DECOMPRESSION_TYPE
                                            }
                                            searchable
                                            required
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <DateTimeField
                                        name="timeLeftSurface"
                                        label="form.label.timeLeftSurfaceHours"
                                        dateFormat="HH:mm"
                                        valueFormat={MOMENT_FORMATS.TIME}
                                        timePicker={true}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="maxDepthOfDive"
                                        placeholder="form.placeholder.zero"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        min={'0'}
                                        required
                                    />
                                </div>

                                <div className="_12 _l4">
                                    <DateTimeField
                                        name="timeLeftBottom"
                                        label="form.label.timeLeftBottomHours"
                                        dateFormat="HH:mm"
                                        valueFormat={MOMENT_FORMATS.TIME}
                                        timePicker={true}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4 fakeLabel multipleInputs z-index">
                                    <div className="aligned-center">
                                        <span className="a-mediumText a-lightText">
                                            {t('form.label.bottomTimeHours')}
                                            <span className="redStar"> *</span>
                                        </span>
                                        <span className="help-icon">
                                            <Tooltip
                                                tooltip={t(
                                                    'form.label.bottomTimeTooltipText'
                                                )}
                                                position={
                                                    isTablet
                                                        ? TOOLTIP_POSITION.BOTTOM
                                                        : TOOLTIP_POSITION.RIGHT
                                                }
                                            >
                                                <Icon
                                                    name={ICONS.HELP_AND_INFO}
                                                    color={COLORS.LIGHT_BLUE}
                                                    size={ICON_SIZE.SIZE16}
                                                />
                                            </Tooltip>
                                        </span>
                                    </div>
                                    <div className="space-between">
                                        <InputField
                                            name="bottomTimeHours"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                            required
                                        />
                                        <InputField
                                            name="bottomTimeMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                            required
                                        />
                                    </div>
                                </div>
                                {decompressionType?.name !==
                                    'No decompression' && (
                                    <div className="_12 _l4">
                                        <InputField
                                            name="firstDecoStopDepth"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            min={'0'}
                                        />
                                    </div>
                                )}
                                {!shouldShowFullMixInputs && (
                                    <div className="_12 _l4">
                                        <SelectField
                                            name="decoBreathingMixture"
                                            entityType={ENTITIES.GAS_CYLINDER}
                                            params={{
                                                'diveRecordEquipment.id':
                                                    diveRecordEquipment?.id,
                                            }}
                                            displayAttribute={'displayName'}
                                            searchable
                                        />
                                    </div>
                                )}
                            </div>
                            {shouldShowFullMixInputs && (
                                <div className="_w">
                                    <div className="_12 _m6 _l4">
                                        <SelectField
                                            name="decoBreathingMixtureGasContents"
                                            label="form.label.decoBreathingMixtureGasContents"
                                            placeholder="form.placeholder.gasContents"
                                            defaultOptions={enabledGasContents}
                                        />
                                    </div>

                                    {decoBreathingMixtureGasContents &&
                                        shouldShowOxygen(
                                            decoBreathingMixtureGasContents,
                                            divingMode
                                        ) && (
                                            <div className="_12 _m4 ">
                                                <InputField
                                                    name="decoBreathingMixtureOxygen"
                                                    label={
                                                        isReBreatherType
                                                            ? 'form.label.oxygenPPO2'
                                                            : 'form.label.oxygen'
                                                    }
                                                    placeholder="form.placeholder.oxygenPPO2"
                                                    units={
                                                        isReBreatherType
                                                            ? unitOfMeasurementBarOrAta
                                                            : null
                                                    }
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                    {decoBreathingMixtureGasContents &&
                                        shouldShowNitrogen(
                                            decoBreathingMixtureGasContents,
                                            divingMode
                                        ) && (
                                            <div className="_12 _m4 ">
                                                <InputField
                                                    name="decoBreathingMixtureNitrogen"
                                                    label={
                                                        isReBreatherType
                                                            ? 'form.label.nitrogenPPN'
                                                            : 'form.label.nitrogen'
                                                    }
                                                    placeholder="form.placeholder.nitrogenPPN"
                                                    units={
                                                        isReBreatherType
                                                            ? unitOfMeasurementBarOrAta
                                                            : null
                                                    }
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                    {decoBreathingMixtureGasContents &&
                                        shouldShowHelium(
                                            decoBreathingMixtureGasContents
                                        ) && (
                                            <div className="_12 _m4 ">
                                                <InputField
                                                    name="decoBreathingMixtureHelium"
                                                    label={
                                                        isReBreatherType
                                                            ? 'form.label.heliumPPHe'
                                                            : 'form.label.helium'
                                                    }
                                                    placeholder="form.placeholder.heliumPPHe"
                                                    units={
                                                        isReBreatherType
                                                            ? unitOfMeasurementBarOrAta
                                                            : null
                                                    }
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}
                                </div>
                            )}

                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="lastDecoStopDepth"
                                        placeholder="form.placeholder.zero"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        min={'0'}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <DateTimeField
                                        name="timeReachedSurface"
                                        label="form.label.timeReachedSurfaceHours"
                                        dateFormat="HH:mm"
                                        valueFormat={MOMENT_FORMATS.TIME}
                                        timePicker={true}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <span className="a-mediumText a-lightText">
                                        {t('form.label.totalDecoTimeHours')}
                                        <span className="redStar"> *</span>
                                    </span>
                                    <div className="space-between">
                                        <InputField
                                            name="totalDecoTimeHours"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                            required
                                        />
                                        <InputField
                                            name="totalDecoTimeMinutes"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <div className="aligned-center">
                                        <span className="a-mediumText a-lightText">
                                            {t(
                                                'form.label.totalBottomTimeHours'
                                            )}
                                        </span>
                                        <span className="help-icon">
                                            <Tooltip
                                                tooltip={t(
                                                    'form.label.totalBottomTimeTooltipText'
                                                )}
                                                position={
                                                    isTablet
                                                        ? TOOLTIP_POSITION.BOTTOM
                                                        : TOOLTIP_POSITION.RIGHT
                                                }
                                            >
                                                <Icon
                                                    name={ICONS.HELP_AND_INFO}
                                                    color={COLORS.LIGHT_BLUE}
                                                    size={ICON_SIZE.SIZE16}
                                                />
                                            </Tooltip>
                                        </span>
                                    </div>
                                    <div className="space-between">
                                        <InputField
                                            name="totalBottomTimeHours"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                        />
                                        <InputField
                                            name="totalBottomTimeMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                        />
                                    </div>
                                </div>

                                <div className="_12 _l4">
                                    <InputField name="endingRGD" />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <Separator />
                            </div>

                            <div className="_w">
                                <span className="a-bodyTextRegular -mb20 _12">
                                    {t('general.decompressionProcedure')}
                                </span>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="decompressionTableType"
                                        entityType={
                                            ENTITIES.DECOMPRESSION_TABLE_TYPE
                                        }
                                        searchable
                                        required
                                        createNew
                                        dropup
                                    />
                                </div>

                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <span className="a-mediumText a-lightText">
                                        {t('form.label.decoScheduleUsedMeters')}
                                        <span className="redStar"> *</span>
                                    </span>
                                    <div className="space-between">
                                        <InputField
                                            name="decoScheduleUsedMeters"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            min={'0'}
                                            required
                                        />
                                        <InputField
                                            name="decoScheduleUsedMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12">
                                    <TextAreaField
                                        name="comment"
                                        showPlaceholder={false}
                                        largeTextarea
                                    />
                                </div>
                            </div>
                        </div>

                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default DiveEventFormModal
