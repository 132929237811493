import COLORS from 'constants/colors'
import React from 'react'
import PropTypes from 'prop-types'

const SaturationBellDivingBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                d="M24.3715 0.11713C24.6416 -0.0390432 24.9743 -0.0390434 25.2443 0.11713L46.1023 12.18C46.3723 12.3362 46.5387 12.6248 46.5387 12.9371V37.0629C46.5387 37.3752 46.3723 37.6638 46.1023 37.82L25.2443 49.8829C24.9743 50.039 24.6416 50.039 24.3715 49.8829L3.51354 37.82C3.2435 37.6638 3.07715 37.3752 3.07715 37.0629V12.9371C3.07715 12.6248 3.2435 12.3362 3.51354 12.18L24.3715 0.11713Z"
                fill={COLORS.ORANGE}
            />
            <g filter="url(#filter0_i_21877_20957)">
                <path
                    d="M24.2297 4.18089C24.5873 3.97386 25.0283 3.97386 25.3859 4.18089L42.5013 14.0898C42.8576 14.2961 43.077 14.6766 43.077 15.0884V34.9117C43.077 35.3234 42.8576 35.704 42.5013 35.9103L25.3859 45.8192C25.0283 46.0262 24.5873 46.0262 24.2297 45.8192L7.1143 35.9103C6.75797 35.704 6.53857 35.3234 6.53857 34.9117V15.0884C6.53857 14.6766 6.75797 14.2961 7.1143 14.0898L24.2297 4.18089Z"
                    fill={COLORS.ORANGE_10}
                />
            </g>
            <mask
                id="mask0_21877_20957"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="6"
                y="3"
                width="38"
                height="44"
            >
                <path
                    d="M24.3721 4.09843C24.6416 3.9424 24.974 3.9424 25.2435 4.09843L42.6432 14.1719C42.9117 14.3274 43.077 14.6142 43.077 14.9245V35.0756C43.077 35.3859 42.9117 35.6727 42.6432 35.8282L25.2435 45.9016C24.974 46.0577 24.6416 46.0577 24.3721 45.9016L6.97246 35.8282C6.70392 35.6727 6.53857 35.3859 6.53857 35.0756V14.9245C6.53857 14.6142 6.70392 14.3274 6.97246 14.1719L24.3721 4.09843Z"
                    fill={COLORS.DARK_BLUE_CUSTOM}
                />
            </mask>
            <g mask="url(#mask0_21877_20957)">
                <path
                    opacity="0.15"
                    d="M1.75516 12.7486L-4.86747 15.6233C-5.14141 15.7422 -5.40134 15.891 -5.6425 16.0672L-7.21671 17.2168C-8.20861 17.9411 -8.40081 19.3442 -7.64023 20.3086C-7.04496 21.0634 -6.02436 21.3356 -5.13037 20.9822C-2.61265 19.987 0.825898 18.6623 1.5651 18.5093C2.46901 18.3222 5.99369 17.1343 7.96781 17.1839C15.1954 16.9969 14.8357 20.9514 21.3388 20.111C22.9256 19.906 24.2548 19.413 25.3894 18.7707C30.278 16.0029 34.9184 13.4689 40.4234 14.5892C44.8002 14.8614 49.3923 12.379 49.2676 11.7764C49.1116 11.0231 47.7346 8.16603 47.358 8.244C47.1963 8.27749 44.6614 7.274 41.8324 6.29214C37.6456 4.83906 33.2506 6.0071 29.7708 8.75145C25.5232 12.1013 19.9591 13.2847 14.7159 11.9534L13.9697 11.7639C9.90142 10.731 5.60541 11.0773 1.75516 12.7486Z"
                    fill={COLORS.ORANGE_80}
                />
                <path
                    opacity="0.15"
                    d="M45.7192 30.3659L52.6978 32.2158C52.9865 32.2923 53.2658 32.4005 53.5306 32.5385L55.2593 33.4393C56.3486 34.0068 56.7488 35.3653 56.1414 36.4328C55.6659 37.2683 54.6976 37.6902 53.7608 37.4748C51.1224 36.8682 47.5241 36.0736 46.7703 36.0331C45.8486 35.9835 42.1857 35.3372 40.2413 35.682C33.0674 36.5801 34.0155 40.4361 27.4599 40.5797C25.8603 40.6147 24.4722 40.3265 23.2542 39.8614C18.0061 37.8574 13.0383 36.0473 7.76338 37.9798C3.47676 38.9048 -1.43548 37.1385 -1.40244 36.524C-1.36113 35.7559 -0.427795 32.7247 -0.0437332 32.7454C0.121216 32.7542 2.47706 31.3823 5.12706 29.9876C9.04885 27.9235 13.5692 28.4199 17.421 30.6118C22.1225 33.2873 27.8011 33.6236 32.7856 31.5218L33.4951 31.2226C37.3626 29.5917 41.662 29.2904 45.7192 30.3659Z"
                    fill={COLORS.ORANGE_80}
                />
            </g>
            <path
                d="M18.0243 12.3077L16.888 15.7168H14.6152V29.3532H16.888L18.0243 33.8986L14.6152 37.3077H35.0698L31.6607 33.8986L32.7971 29.3532H35.0698V15.7168H32.7971L31.6607 12.3077H18.0243Z"
                fill="white"
            />
            <circle
                cx="24.843"
                cy="22.535"
                r="2.08673"
                fill="url(#paint0_linear_21877_20957)"
                stroke={COLORS.ORANGE}
                strokeWidth="0.940171"
            />
            <circle
                cx="17.1721"
                cy="22.535"
                r="2.08673"
                fill="url(#paint1_linear_21877_20957)"
                stroke={COLORS.ORANGE}
                strokeWidth="0.940171"
            />
            <circle
                cx="32.5129"
                cy="22.535"
                r="2.08673"
                fill="url(#paint2_linear_21877_20957)"
                stroke={COLORS.ORANGE}
                strokeWidth="0.940171"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.888 12.3077V13.4441H17.5862L16.8174 15.7168H14.6152V29.3532H16.4986L17.8441 33.3305H16.888V34.4668H17.451L15.7778 36.1714H14.6152V37.3077H35.0698V36.1714H33.9516L32.2784 34.4668H32.7971V33.3305H31.8409L33.1864 29.3532H35.0698V28.2168H21.4334V16.8532H28.2516V28.2168H29.388V16.8532H33.9334V28.2168L35.0698 28.2168V15.7168L32.8676 15.7168L32.0988 13.4441H32.7971V12.3077H16.888ZM30.8992 13.4441H28.5761L29.3449 15.7168H31.668L30.8992 13.4441ZM31.9867 29.3532H29.3693L28.3389 33.3305H30.6413L31.9867 29.3532ZM30.686 34.4668H28.3839L28.9605 36.1714H32.3592L30.686 34.4668ZM28.1697 29.3532L27.1392 33.3305H22.6984L21.668 29.3532H28.1697ZM20.4683 29.3532L21.4987 33.3305H19.0437L17.6983 29.3532H20.4683ZM17.3702 36.1714L19.0434 34.4668H21.4537L20.8771 36.1714H17.3702ZM22.0767 36.1714L22.6533 34.4668H27.1842L27.7609 36.1714H22.0767ZM28.1453 15.7168L27.3765 13.4441H22.4611L21.6923 15.7168H28.1453ZM18.7858 13.4441H21.2615L20.4927 15.7168H18.017L18.7858 13.4441ZM15.7516 16.8532H20.2971V28.2168H15.7516V16.8532Z"
                fill={COLORS.ORANGE}
            />
            <defs>
                <filter
                    id="filter0_i_21877_20957"
                    x="6.53857"
                    y="4.02563"
                    width="36.9232"
                    height="42.3335"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.384615" dy="0.384615" />
                    <feGaussianBlur stdDeviation="0.192308" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_20957"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_21877_20957"
                    x1="24.843"
                    y1="19.9781"
                    x2="24.843"
                    y2="25.0918"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor={COLORS.ORANGE_30} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_21877_20957"
                    x1="17.1721"
                    y1="19.9781"
                    x2="17.1721"
                    y2="25.0918"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor={COLORS.ORANGE_30} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_21877_20957"
                    x1="32.5129"
                    y1="19.9781"
                    x2="32.5129"
                    y2="25.0918"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor={COLORS.ORANGE_30} />
                </linearGradient>
            </defs>
        </svg>
    )
}

SaturationBellDivingBadge.propTypes = {
    size: PropTypes.number,
}

SaturationBellDivingBadge.defaultProps = {
    size: 50,
}

export default SaturationBellDivingBadge
