import { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { BUTTON_STATUS, BUTTON_TYPE, SELECT_VALUE_TYPE } from 'constants/enums'

import Button from 'components/Button'
import Select from 'components/Select'
import FieldError from 'components/FieldError'
import Multiselect from 'components/Multiselect'
import Separator from './Separator'

const SelectMultiselectGroup = ({
    error,
    title,
    smallTitle,
    value,
    setValue,
    setTouched,
    description,
    required,
    fieldsName,
    fieldsDefaultOptions,
    fieldsPlaceholder,
    fieldsEntityType,
    fieldsParams,
    fieldsSearchable,
    fieldsIsJSONAPI,
    fieldsApiUrl,
    fieldsDisabled,
    fieldsDisplayAttribute,
    fieldsSearchAttribute,
    secondFieldRelation,
    fieldsRequired,
    fieldsValueType,
    buttonLabel,
    reload,
    fieldsShowLabel,
    fieldsShowPlaceholder,
    listingTitle,
    disableOneField,
    color,
    reset,
    dropup,
}) => {
    const t = useTranslate()

    const [firstField, setFirstField] = useState(null)
    const [secondField, setSecondField] = useState([])
    const [disableSecondField, setDisableSecondField] = useState(false)
    const [firstFieldName, secondFieldName] = fieldsName
    const [disabledItems, setDisabledItems] = useState([])

    useEffect(() => {
        if (firstField === null) {
            setDisableSecondField(true)
        } else {
            setDisableSecondField(false)
            setDisabledItems(
                value
                    .filter((item) => item.skillCategory.id === firstField.id)
                    .map((obj) => obj.skill)
            )
        }
    }, [firstField])

    useEffect(() => {
        resetFields()
    }, [reset])

    const resetFields = () => {
        setFirstField(null)
        setSecondField([])
    }

    const handleAdd = () => {
        const newObjectArray = []
        for (const item of secondField) {
            newObjectArray.push({
                [firstFieldName]: firstField,
                [secondFieldName]: item,
            })
        }
        setValue([...value, ...newObjectArray])
        const filteredValues = newObjectArray.filter(
            (item) =>
                !value.some(
                    (el) =>
                        el[firstFieldName].id === item[firstFieldName].id &&
                        el[secondFieldName].id === item[secondFieldName].id
                )
        )

        setValue([...value, ...filteredValues])
        resetFields()
    }

    const handleRemove = (e, itemId, groupById) => {
        e.stopPropagation()
        const newValue = value.filter(
            (item) =>
                item[secondFieldName].id !== itemId ||
                item[firstFieldName].id !== groupById
        )
        setValue(newValue)
    }

    const handleChangeFirstField = (value) => {
        setFirstField(value)
        setSecondField([])
    }

    const handleTouch = () => setTouched(true)

    // Group skills by category
    const groupedItems = value.reduce((acc, item) => {
        const groupBy = item[firstFieldName]
        const singleItem = item[secondFieldName]
        if (!acc[groupBy.priority]) {
            acc[groupBy.priority] = {
                groupBy,
                items: [],
            }
        }
        acc[groupBy.priority].items.push(singleItem)
        return acc
    }, {})

    return (
        <div className="m-selectGroup -multiple">
            {(title || description) && (
                <div className="m-selectGroup__title">
                    {title && (
                        <span
                            className={`${
                                smallTitle
                                    ? 'a-mediumText a-lightText'
                                    : 'a-bodyTextRegular'
                            }`}
                        >
                            {t(title)}{' '}
                            {required && <span className="a-redStar">*</span>}
                        </span>
                    )}
                    {description && (
                        <span className="a-mediumText a-lightText -mt5 -m10">
                            {t(description)}
                        </span>
                    )}
                </div>
            )}
            <div className="m-selectGroup__field">
                <div className="-firstField">
                    <Select
                        name={fieldsName[0]}
                        value={firstField}
                        setValue={handleChangeFirstField}
                        placeholder={fieldsPlaceholder[0]}
                        defaultOptions={fieldsDefaultOptions[0]}
                        entityType={fieldsEntityType[0]}
                        params={fieldsParams[0]}
                        searchable={fieldsSearchable[0]}
                        isJSONAPI={fieldsIsJSONAPI[0]}
                        apiUrl={fieldsApiUrl[0]}
                        disabled={fieldsDisabled[0]}
                        displayAttribute={fieldsDisplayAttribute[0]}
                        searchAttribute={fieldsSearchAttribute[0]}
                        valueType={fieldsValueType[0]}
                        required={fieldsRequired[0]}
                        showLabel={fieldsShowLabel[0]}
                        showPlaceholder={fieldsShowPlaceholder[0]}
                        onBlur={handleTouch}
                        error={error}
                        customError={true}
                        reload={reload[0]}
                        dropup={dropup}
                    />
                </div>
                <div className="aligned-start -secondFieldAndButton -multiselect">
                    <Multiselect
                        name={fieldsName[1]}
                        value={secondField}
                        setValue={setSecondField}
                        placeholder={fieldsPlaceholder[1]}
                        defaultOptions={fieldsDefaultOptions[1]}
                        entityType={fieldsEntityType[1]}
                        params={
                            secondFieldRelation
                                ? {
                                      ...fieldsParams[1],
                                      [secondFieldRelation]: firstField?.id,
                                  }
                                : fieldsParams[1]
                        }
                        searchable={fieldsSearchable[1]}
                        isJSONAPI={fieldsIsJSONAPI[1]}
                        apiUrl={fieldsApiUrl[1]}
                        disabled={
                            disableOneField[1]
                                ? disableSecondField
                                : fieldsDisabled[1]
                        }
                        displayAttribute={fieldsDisplayAttribute[1]}
                        searchAttribute={fieldsSearchAttribute[1]}
                        valueType={fieldsValueType[1]}
                        required={fieldsRequired[1]}
                        showLabel={fieldsShowLabel[1]}
                        showPlaceholder={fieldsShowPlaceholder[1]}
                        onBlur={handleTouch}
                        error={error}
                        customError={true}
                        reload={secondFieldRelation ? firstField : reload[1]}
                        disabledItems={disabledItems}
                        dropup={dropup}
                    />
                    <Button
                        btnClass={BUTTON_STATUS.SECONDARY}
                        type={BUTTON_TYPE.BUTTON}
                        label={buttonLabel}
                        onClick={handleAdd}
                        disabled={!firstField || !secondField.length}
                    />
                </div>
            </div>
            {value.length > 0 && (
                <div className="m-multiselectGroup__items">
                    {listingTitle && (
                        <div className="-mt20 -listingTitle a-bodyTextMedium -mb20">
                            {listingTitle}
                        </div>
                    )}
                    <Fragment>
                        {Object.values(groupedItems).map(
                            ({ groupBy, items }) => (
                                <div
                                    key={groupBy.id}
                                    className="fullWidth -mt10 -pb10 -selectedSkills"
                                >
                                    <div className="a-bodyTextRegular -mb10">
                                        {groupBy.name}
                                    </div>
                                    <ul className="aligned-center -skills">
                                        {items.map((item, index) => (
                                            <div
                                                key={item.id}
                                                className={`m-multiselectGroup__item ${
                                                    color ? `-${color}` : ''
                                                }`}
                                            >
                                                <li className="label a-mediumText">
                                                    {item.name}
                                                </li>
                                                <span
                                                    className="removeItemIcon"
                                                    onClick={(e) =>
                                                        handleRemove(
                                                            e,
                                                            item.id,
                                                            groupBy.id
                                                        )
                                                    }
                                                ></span>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            )
                        )}
                    </Fragment>
                </div>
            )}
            {error && <FieldError error={error} />}
        </div>
    )
}

export const SelectMultiselectGroupMainPropTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
    fieldsName: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDefaultOptions: PropTypes.arrayOf(PropTypes.array),
    fieldsPlaceholder: PropTypes.arrayOf(PropTypes.string),
    fieldsEntityType: PropTypes.arrayOf(PropTypes.string),
    fieldsParams: PropTypes.arrayOf(PropTypes.object),
    fieldsSearchable: PropTypes.arrayOf(PropTypes.bool),
    fieldsIsJSONAPI: PropTypes.arrayOf(PropTypes.bool),
    reload: PropTypes.arrayOf(PropTypes.bool),
    fieldsApiUrl: PropTypes.arrayOf(PropTypes.string),
    fieldsDisabled: PropTypes.arrayOf(PropTypes.bool),
    fieldsDisplayAttribute: PropTypes.arrayOf(PropTypes.string),
    fieldsSearchAttribute: PropTypes.arrayOf(PropTypes.string),
    secondFieldRelation: PropTypes.string,
    fieldsRequired: PropTypes.arrayOf(PropTypes.bool),
    fieldsValueType: PropTypes.arrayOf(PropTypes.string),
    buttonLabel: PropTypes.string,
    fieldsShowLabel: PropTypes.arrayOf(PropTypes.bool),
    fieldsShowPlaceholder: PropTypes.arrayOf(PropTypes.bool),
    disableOneField: PropTypes.arrayOf(PropTypes.bool),
    color: PropTypes.string,
    reset: PropTypes.bool,
    dropup: PropTypes.bool,
}

SelectMultiselectGroup.propTypes = {
    ...SelectMultiselectGroupMainPropTypes,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    smallTitle: PropTypes.bool,
}

SelectMultiselectGroup.defaultProps = {
    fieldsDefaultOptions: [[], []],
    fieldsPlaceholder: ['', ''],
    fieldsEntityType: [null, null],
    fieldsParams: [{}, {}],
    fieldsSearchable: [false, false],
    fieldsIsJSONAPI: [undefined, undefined],
    fieldsApiUrl: [undefined, undefined],
    fieldsDisabled: [false, false],
    reload: [false, false],
    fieldsDisplayAttribute: ['name', 'name'],
    fieldsSearchAttribute: ['name', 'name'],
    secondFieldRelation: null,
    fieldsRequired: [false, false],
    fieldsValueType: [SELECT_VALUE_TYPE.OBJECT, SELECT_VALUE_TYPE.OBJECT],
    buttonLabel: 'button.add',
    fieldsShowLabel: [true, true],
    fieldsShowPlaceholder: [true, true],
    disableOneField: [false, false],
    smallTitle: false,
    color: '',
    dropup: false,
}

export default SelectMultiselectGroup
