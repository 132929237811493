export const WAVE_HEIGHT_METRIC = [
    'Calm',
    'Ripples',
    '> .2 m wavelets',
    '.6 m large wavelets beginning to break',
    '1 m small waves',
    '1.8 m moderate waves',
    '3 m large waves',
    '4 m mounting sea, White foam streaks',
    '> 5.5 m moderate high waves',
    '> 7 m high waves',
    '> 9 m very high waves',
]

export const WAVE_HEIGHT_IMPERIAL = [
    'Calm',
    'Ripples',
    '> 1 ft wavelets',
    '1 ft large wavelets beginning to break',
    '1-4 ft small waves',
    '4-8 ft moderate waves',
    '8-13 ft large waves',
    '13-19 ft mounting sea, White foam streaks',
    '> 19 ft moderate high waves',
    '> 25 ft high waves',
    '> 29 ft very high waves',
]

export const WATER_TEMPERATURE_METRIC = [
    '0 - 4 °C',
    '5 - 10 °C',
    '11 - 15 °C',
    '16 - 20 °C',
    '21 - 25 °C',
    '26 - 30 °C',
    '> 31 °C',
]

export const WATER_TEMPERATURE_IMPERIAL = [
    '32 - 40 °F',
    '41 - 50 °F',
    '51 - 60 °F',
    '61 - 70 °F',
    '71 - 80 °F',
    '81 - 90 °F',
    '> 91 °F',
]

export const AIR_TEMPERATURE_METRIC = [
    '-29 to -17 °C',
    '-16 to -6 °C',
    '-5 to 4 °C',
    '5 to 15 °C',
    '16 to 26 °C',
    '27 to 37 °C',
    '> 37 °C',
]

export const AIR_TEMPERATURE_IMPERIAL = [
    '-20 - 0 °F',
    '1 - 20 °F',
    '21 - 40 °F',
    '41 - 60 °F',
    '61 - 80 °F',
    '81 - 100 °F',
    '> 100 °F',
]

export const WATER_VISIBILITY_METRIC = [
    'None',
    'Poor < 1 m',
    'Fair 2 - 5 m',
    'Good 5 - 10 m',
    'Great 10 - 20 m',
    'Excellent > 20 m',
]

export const WATER_VISIBILITY_IMPERIAL = [
    'None',
    'Poor < 3 ft',
    'Fair 6 - 15 ft',
    'Good 16 - 30 ft',
    'Great 31 - 60 ft',
    'Excellent > 60 ft',
]

export const WATER_CURRENT = [
    'None 0 KTS',
    'Slight 0 - .5 KTS',
    'Moderate .5 - 1.0 KTS',
    'Rapid 1.0 - 1.5 KTS',
    'Strong 1.5 - 2.0 KTS',
    'Heavy 2.0 - 3.0 KTS',
    'Difficult > 3.0 KTS',
]

export const BOTTOM_CONDITION = [
    'Hard',
    'Firm',
    'Soft',
    'Sand',
    'Mud',
    'Clay',
    'Shell',
    'Coral',
    'Gravel - Rock',
    'Mixed',
]

export const SIZE_METRIC = [
    '< 3 L',
    '3 L',
    '5 L',
    '6 L',
    '7 L',
    '10 L',
    '11 L',
    '12 L',
    '13 L',
    '15 L',
    '18 L',
    '> 18 L',
]

export const SIZE_IMPERIAL = [
    '3 FT³',
    '6 FT³',
    '19 FT³',
    '40 FT³',
    '50 FT³',
    '63 FT³',
    '72 FT³',
    '80 FT³',
    '100 FT³',
    '105 FT³',
    '108 FT³',
    '117 FT³',
    '120 FT³',
    '133 FT³',
    '149 FT³',
    '> 149 FT³',
]

export const WIND_CONDITIONS = [
    '< 1 KTS - Calm',
    '1-3 KTS - Light Air',
    '4-6 KTS - Light Breeze',
    '7-10 KTS - Gentle Breeze',
    '11-16 KTS - Moderate Breeze',
    '17-21 KTS - Fresh Breeze',
    '22-27 KTS - Strong Breeze',
    '28-33 KTS - Near Gale',
    '34-40 KTS - Gale',
    '41-47 KTS - Strong Gale',
    '48-55 KTS - Storm',
    '56-63 KTS - Violent Storm',
    '64+ KTS - Hurricane',
]

export const WETSUIT_THICKNESS = [
    '2 mm',
    '3 mm',
    '4 mm',
    '5 mm',
    '6 mm',
    '7 mm',
    '8 mm',
    '9 mm',
    '10 mm',
    '> 10 mm',
]
