import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import AddNew from '../../AddNew'
import FieldError from 'components/FieldError'
import CreateFormalEducation from './CreateFormalEducation'
import FormalEducationList from './FormalEducationList'

const FormalEducation = ({
    data,
    isLoading,
    fetchData,
    profilePage,
    showActions,
    refetchData,
    error,
}) => {
    const [create, setCreate] = useState(false)

    const handleOpenModal = () => setCreate(true)

    return (
        <Fragment>
            {showActions && (
                <>
                    <AddNew
                        handleAction={handleOpenModal}
                        label="general.addNewFormalEducation"
                        title={`${
                            profilePage ? '' : 'general.formalEducation'
                        }`}
                    />

                    {error && <FieldError error={error} />}
                </>
            )}

            {create && (
                <CreateFormalEducation
                    open={create}
                    setOpen={setCreate}
                    fetchData={fetchData}
                    dataLength={data.length}
                    showActions={showActions}
                    refetchData={refetchData}
                />
            )}

            <FormalEducationList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                profilePage={profilePage}
                showActions={showActions}
                dataLength={data.length}
                refetchData={refetchData}
            />
        </Fragment>
    )
}

FormalEducation.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    profilePage: PropTypes.bool,
    refetchData: PropTypes.func,
    error: PropTypes.string,
}

FormalEducation.defaultProps = {
    showActions: false,
}

export default FormalEducation
