import PropTypes from 'prop-types'

import Button from './Button'

import ICONS from 'constants/icons'
import { BUTTON_STATUS, ICON_POSITION, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'

const FilterIconButton = ({ setOpen, disabled }) => {
    return (
        <Button
            onClick={() => setOpen(true)}
            icon={ICONS.FILTER}
            iconSize={ICON_SIZE.SIZE16}
            iconColor={COLORS.DARK_BLUE_60}
            btnClass={BUTTON_STATUS.TERTIARY}
            iconPosition={ICON_POSITION.RIGHT}
            additionalClasses="-filterIconButton"
            disabled={disabled}
        />
    )
}

FilterIconButton.propTypes = {
    setOpen: PropTypes.func,
    disabled: PropTypes.bool,
}

FilterIconButton.defaultProps = {
    disabled: false,
}

export default FilterIconButton
