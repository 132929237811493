import React, { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'

import useToggleDropdown from 'hooks/useToggleDropdown'

import ICONS from 'constants/icons'

import Icon from 'components/Icon'
import CellItem from '../CellItem'
import DropdownActions from 'components/DropdownActions'
import CellHeader from './CellHeader'

const TableCard = ({ headerItems, item, rowActions, itemIndex }) => {
    const t = useTranslate()

    const { contentRef, open, setOpen } = useToggleDropdown()

    const headerItem = headerItems.find((item) =>
        open ? item.smallScreenFirstItem : item.smallScreenHeader
    )

    useEffect(() => {
        setOpen(false)
    }, [headerItems])

    return (
        <div className="m-accordion">
            <div className="-wrapper">
                <div
                    onClick={() => {
                        setOpen(!open)
                    }}
                    className={`m-accordion__card ${
                        open ? '-open' : '-hidden'
                    }`}
                >
                    <Icon name={ICONS.CHEVRON_DOWN_CIRCLE} />
                    <CellHeader
                        headerKey={headerItem.key}
                        title={headerItem.title}
                        headerItem={headerItem}
                        item={item}
                    />
                </div>
                {rowActions.filter((action) => !action.ActionComponent).length >
                    0 && (
                    <DropdownActions
                        actions={rowActions}
                        item={item}
                        itemIndex={itemIndex}
                    />
                )}
            </div>
            <div
                className={`m-accordion__content ${
                    open ? '-visible' : '-hidden'
                }`}
                ref={contentRef}
            >
                <div className="m-accordion__content--elements">
                    {headerItems
                        .filter((item) => !item.smallScreenFirstItem)
                        .map(({ key, title, ...headerItem }) => (
                            <div key={key} className="-wrapper">
                                <div className="a-captionsTextBold -titleItem">
                                    {title && t(title)}
                                </div>
                                <div className="a-mediumText -cellItem">
                                    <CellItem
                                        headerKey={key}
                                        headerItem={headerItem}
                                        item={item}
                                    />
                                </div>
                            </div>
                        ))}
                    {rowActions
                        .filter((action) => action.ActionComponent)
                        .map(({ ActionComponent, ...action }, index) => (
                            <div key={index} className="-wrapper">
                                <div className="a-captionsTextBold -titleItem">
                                    {t('general.actions')}
                                </div>
                                <div className="a-mediumText">
                                    <ActionComponent {...action} item={item} />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default TableCard
