import { Formik, Form } from 'formik'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { useState } from 'react'
import * as Yup from 'yup'

import { positionCategoryIds } from 'utils/positionHelper'

import {
    BUTTON_STATUS,
    BUTTON_TYPE,
    DIVER_POSITION_CATEGORIES,
    ICON_SIZE,
    TOOLTIP_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import MultiselectWithIcon from 'components/MultiselectWithIcon'
import useFetchData from 'hooks/useFetchData'

const DiverPositions = ({ userData, setUserData, setStep }) => {
    const t = useTranslate()
    const [errorMessage, setErrorMessage] = useState(false)

    const { data: positions } = useFetchData(ENTITIES.DIVER_POSITION, {
        pagination: false,
    })

    const initialPositionValues = Object.fromEntries(
        Object.entries(positionCategoryIds).map(([key, id]) => [
            key,
            userData.diverPositions?.filter(
                (item) => item.diverPositionCategory.id === id
            ) ?? [],
        ])
    )

    const initialValues = {
        occupationalDivingPersonnel:
            initialPositionValues.occupationalDivingPersonnel,
        marineMobileOffshoreUnitPersonnel:
            initialPositionValues.marineMobileOffshoreUnitPersonnel,
        rovPersonnel: initialPositionValues.rovPersonnel,
        offshoreSurveyPersonnel: initialPositionValues.offshoreSurveyPersonnel,
        projectMissionPersonnel: initialPositionValues.projectMissionPersonnel,
        projectOperationsManagementPersonnel:
            initialPositionValues.projectOperationsManagementPersonnel,
    }

    const validation = Yup.object({
        positions: Yup.object()
            .shape({
                occupationalDivingPersonnel: Yup.array().notRequired(),
                marineMobileOffshoreUnitPersonnel: Yup.array().notRequired(),
                rovPersonnel: Yup.array().notRequired(),
                offshoreSurveyPersonnel: Yup.array().notRequired(),
                projectMissionPersonnel: Yup.array().notRequired(),
                projectOperationsManagementPersonnel: Yup.array().notRequired(),
            })
            .test('positions', (value, { parent }) => {
                if (
                    parent.occupationalDivingPersonnel.length === 0 &&
                    parent.marineMobileOffshoreUnitPersonnel.length === 0 &&
                    parent.rovPersonnel.length === 0 &&
                    parent.offshoreSurveyPersonnel.length === 0 &&
                    parent.projectMissionPersonnel.length === 0 &&
                    parent.projectOperationsManagementPersonnel.length === 0
                ) {
                    return setErrorMessage(true)
                }
                setErrorMessage(false)
                return true
            }),
    })

    const handleSubmit = (formData) => {
        const diverPositions = Object.values(formData)
            .flat()
            .map((role) => role)
        setUserData((data) => ({ ...data, diverPositions: diverPositions }))
        setStep((step) => step + 1)
    }

    const placeholder = 'form.label.selectYourRoles'
    const iconSize = ICON_SIZE.SIZE50

    if (!positions.length) return null

    return (
        <div className="m-form -register -mb20 -selectRolesScreen">
            <h1 className="-title -mb50">{t('general.selectHowToSignUp')}</h1>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <div className="column -gap20">
                            {/* OCCUPATIONAL_DIVING_PERSONNEL */}
                            <MultiselectWithIcon
                                icon={{
                                    iconName:
                                        ICONS.OCCUPATIONAL_DIVING_PERSONNEL,
                                    iconColor: COLORS.LIGHT_BLUE,
                                    iconSize: iconSize,
                                }}
                                multiselectProps={{
                                    name: 'occupationalDivingPersonnel',
                                    label: 'form.label.occupationalDivingPersonnel',
                                    placeholder: placeholder,
                                    entityType: ENTITIES.DIVER_POSITION,
                                    params: {
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .DIVING_PERSONNEL.id,
                                    },
                                    searchable: true,
                                    tooltip:
                                        'form.label.occupationalDivingPersonnelTooltip',
                                }}
                            />
                            {/* MARINE_MOBILE_OFFSHORE_UNIT_PERSONNEL */}
                            <MultiselectWithIcon
                                icon={{
                                    iconName:
                                        ICONS.MARINE_MOBILE_OFFSHORE_UNIT_PERSONNEL,
                                    iconColor: COLORS.LIGHT_BLUE,
                                    iconSize: iconSize,
                                }}
                                multiselectProps={{
                                    name: 'marineMobileOffshoreUnitPersonnel',
                                    label: 'form.label.marineMobileOffshoreUnitPersonnel',
                                    placeholder: placeholder,
                                    entityType: ENTITIES.DIVER_POSITION,
                                    params: {
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .MARINE_MOU_PERSONNEL.id,
                                    },
                                    searchable: true,
                                    tooltip:
                                        'form.label.marineMobileOffshoreUnitPersonnelTooltip',
                                }}
                            />
                            {/* ROV_PERSONNEL */}
                            <MultiselectWithIcon
                                icon={{
                                    iconName: ICONS.ROV_PERSONNEL,
                                    iconColor: COLORS.LIGHT_BLUE,
                                    iconSize: iconSize,
                                }}
                                multiselectProps={{
                                    name: 'rovPersonnel',
                                    label: 'form.label.rovPersonnel',
                                    placeholder: placeholder,
                                    entityType: ENTITIES.DIVER_POSITION,
                                    params: {
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .ROV_PERSONNEL.id,
                                    },
                                    searchable: true,
                                    tooltip: 'form.label.rovPersonnelTooltip',
                                }}
                            />
                            {/* OFFSHORE_SURVEY_PERSONNEL */}
                            <MultiselectWithIcon
                                icon={{
                                    iconName: ICONS.OFFSHORE_SURVEY_PERSONNEL,
                                    iconColor: COLORS.LIGHT_BLUE,
                                    iconSize: iconSize,
                                }}
                                multiselectProps={{
                                    name: 'offshoreSurveyPersonnel',
                                    label: 'form.label.offshoreSurveyPersonnel',
                                    placeholder: placeholder,
                                    entityType: ENTITIES.DIVER_POSITION,
                                    params: {
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .SURVEY_PERSONNEL.id,
                                    },
                                    searchable: true,
                                    tooltip:
                                        'form.label.offshoreSurveyPersonnelTooltip',
                                }}
                            />
                            {/* PROJECT_MISSION_PERSONNEL */}
                            <MultiselectWithIcon
                                icon={{
                                    iconName: ICONS.PROJECT_MISSION_PERSONNEL,
                                    iconColor: COLORS.LIGHT_BLUE,
                                    iconSize: iconSize,
                                }}
                                multiselectProps={{
                                    name: 'projectMissionPersonnel',
                                    label: 'form.label.projectMissionPersonnel',
                                    placeholder: placeholder,
                                    entityType: ENTITIES.DIVER_POSITION,
                                    params: {
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .PROJECT_MISSION_PERSONNEL.id,
                                    },
                                    searchable: true,
                                    tooltip:
                                        'form.label.projectMissionPersonnelTooltip',
                                }}
                            />
                            {/* PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL */}
                            <MultiselectWithIcon
                                icon={{
                                    iconName:
                                        ICONS.PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL,
                                    iconColor: COLORS.LIGHT_BLUE,
                                    iconSize: iconSize,
                                }}
                                multiselectProps={{
                                    name: 'projectOperationsManagementPersonnel',
                                    label: 'form.label.projectOperationsManagementPersonnel',
                                    placeholder: placeholder,
                                    entityType: ENTITIES.DIVER_POSITION,
                                    params: {
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
                                                .id,
                                    },
                                    searchable: true,
                                    tooltip:
                                        'form.label.projectOperationsManagementPersonnelTooltip',
                                    tooltipPosition: TOOLTIP_POSITION.LEFT,
                                }}
                            />
                            <span
                                className={`errorMessage -mainErrorMessage  ${
                                    errorMessage ? '-active' : ''
                                }`}
                            >
                                {t('form.error.selectAtLeastOneRole')}
                            </span>
                        </div>

                        <div className="-mt30 justify-center -roleNextBtn">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.next"
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

DiverPositions.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
}

export default DiverPositions
