import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ToggleSwitchField from 'components/formFields/ToggleSwitchField'

const SwitchSettingsItem = ({ name, label, disabled, tooltip }) => {
    const t = useTranslate()

    return (
        <div className="space-between aligned-center">
            <span className="a-mediumText">{t(label)}</span>
            <ToggleSwitchField
                name={name}
                showLabel={false}
                disabled={disabled}
                tooltip={tooltip}
            />
        </div>
    )
}
SwitchSettingsItem.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
}

export default SwitchSettingsItem
