import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const IconWithTooltip = ({
    tooltip,
    icon,
    iconColor,
    iconSize,
    noTranslate,
}) => {
    const t = useTranslate()

    return (
        <div className="a-iconWithTooltip aligned-center">
            <Icon size={iconSize} name={icon} color={iconColor} />
            <p className="-tooltip a-captionsTextRegular">
                {noTranslate ? tooltip : t(tooltip)}
            </p>
        </div>
    )
}

IconWithTooltip.propTypes = {
    iconColor: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconSize: PropTypes.number,
    tooltip: PropTypes.string,
    noTranslate: PropTypes.bool,
}

IconWithTooltip.defaultProps = {
    iconSize: ICON_SIZE.SIZE20,
    noTranslate: false,
}

export default IconWithTooltip
