import React from 'react'

import { createEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import SkillsForm from '../SkillsForm'

const CreateSkill = ({ open, setOpen, fetchData }) => {
    const handleSubmit = async (data) => {
        try {
            await createEntityService(ENTITIES.SKILL, data)
            fetchData()
        } catch (error) {
            throw error
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Modal open={open} setOpen={setOpen} title="button.createSkill">
            <SkillsForm handleSubmit={handleSubmit} handleClose={handleClose} />
        </Modal>
    )
}

export default CreateSkill
