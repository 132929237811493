import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'

import Button from './Button'

const ArrayCustomGroup = ({
    value,
    setValue,
    itemValue,
    setItemValue,
    title,
    required,
    buttonLabel,
    itemInitialValue,
    error,
    children,
    canAddItem,
    renderItem,
}) => {
    const t = useTranslate()

    const handleAdd = () => {
        setValue([...value, itemValue])
        setItemValue(itemInitialValue)
    }
    const handleRemove = (index) => {
        const newValue = [...value]
        newValue.splice(index, 1)
        setValue(newValue)
    }

    const canAdd = () => !error && canAddItem(itemValue, value)

    return (
        <div className="m-selectGroup -multiple">
            <div className="m-selectGroup__title">
                {title && (
                    <span className="a-bodyTextRegular -mb5">
                        {t(title)}{' '}
                        {required && <span className="redStar">*</span>}
                    </span>
                )}
            </div>
            <div className="m-selectGroup__field">
                {children}
                <Button
                    btnClass={BUTTON_STATUS.SECONDARY}
                    type={BUTTON_TYPE.BUTTON}
                    label={buttonLabel}
                    onClick={handleAdd}
                    disabled={!canAdd()}
                />
            </div>
            {value.length > 0 && (
                <div className="m-multiselectGroup__items">
                    {value.map((item, index) => {
                        return (
                            <div
                                className="m-multiselectGroup__item"
                                key={index}
                            >
                                <span className="label a-mediumText">
                                    {renderItem(item)}
                                </span>
                                <span
                                    className="removeItemIcon"
                                    onClick={() => handleRemove(index)}
                                ></span>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export const ArrayCustomGroupMainPropTypes = {
    name: PropTypes.string,
    itemName: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.bool,
    buttonLabel: PropTypes.string,
    unit: PropTypes.string,
    itemInitialValue: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    canAddItem: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
}

ArrayCustomGroup.propTypes = {
    ...ArrayCustomGroupMainPropTypes,
    value: PropTypes.array,
    setValue: PropTypes.func,
    itemValue: PropTypes.object,
    setItemValue: PropTypes.func,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
    ]),
}

ArrayCustomGroup.defaultProps = {
    buttonLabel: 'button.add',
}

export default ArrayCustomGroup
