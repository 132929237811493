import React from 'react'
import { useTranslate } from 'react-polyglot'

import Button from 'components/Button'

const RowActions = ({ actions, item }) => {
    const t = useTranslate()

    return (
        <div className={`m-table__rowActions ${item ? '-show' : ''}`}>
            <span className="m-table__rowActionsTitle">Selected item</span>
            <div className="m-table__actions">
                {item &&
                    actions.length > 0 &&
                    actions.map(
                        (
                            {
                                handleAction,
                                icon,
                                label,
                                tooltip,
                                btnClass,
                                disabled,
                            },
                            index
                        ) => (
                            <Button
                                key={index}
                                label={label}
                                onClick={() => handleAction(item)}
                                tooltip={tooltip ? t(tooltip) : ''}
                                icon={icon}
                                btnClass={btnClass}
                                disabled={disabled}
                            />
                        )
                    )}
            </div>
        </div>
    )
}

export default RowActions
