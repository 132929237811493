import {
    DIVER_POSITION_CATEGORIES,
    DIVING_PERSONNEL_POSITIONS,
} from 'constants/enums'
import ICONS from 'constants/icons'

const getDivingPersonnelImage = (position) => {
    switch (position) {
        case DIVING_PERSONNEL_POSITIONS.SURFACE_DIVER.code:
        case DIVING_PERSONNEL_POSITIONS.BELL_SAT_DIVER.code:
            return ICONS.POSITION_DIVER
        case DIVING_PERSONNEL_POSITIONS.MEDIC.code:
            return ICONS.POSITION_DIVING_MEDIC
        case DIVING_PERSONNEL_POSITIONS.DIVE_SUPERVISOR.code:
            return ICONS.POSITION_DIVING_SUPERVISOR
        case DIVING_PERSONNEL_POSITIONS.DIVE_EQUIPMENT_TECHNICIAN.code:
            return ICONS.POSITION_EQUIPMENT_TECHNICIAN
        case DIVING_PERSONNEL_POSITIONS.LIFE_SUPPORT_SUPERVISOR.code:
        case DIVING_PERSONNEL_POSITIONS.LIFE_SUPPORT_TECHNICIAN.code:
            return ICONS.POSITION_LIFE_SUPPORT
        case DIVING_PERSONNEL_POSITIONS.DIVE_SUPERINTENDENT.code:
            return ICONS.POSITION_DIVE_SUPERINTENDENT
        case DIVING_PERSONNEL_POSITIONS.OFFSHORE_MANAGER_DIVING.code:
            return ICONS.POSITION_OFFSHORE_MANAGER_DIVING
        default:
            break
    }
}

const getPositionImage = (positionCategory, position) => {
    if (
        positionCategory === DIVER_POSITION_CATEGORIES.DIVING_PERSONNEL.code ||
        (positionCategory ===
            DIVER_POSITION_CATEGORIES.MARINE_MOU_PERSONNEL.code &&
            position === DIVING_PERSONNEL_POSITIONS.MEDIC.code)
    ) {
        return getDivingPersonnelImage(position)
    }

    switch (positionCategory) {
        case DIVER_POSITION_CATEGORIES.MARINE_MOU_PERSONNEL.code:
            return ICONS.MARINE_MOBILE_OFFSHORE_UNIT_PERSONNEL
        case DIVER_POSITION_CATEGORIES.ROV_PERSONNEL.code:
            return ICONS.ROV_PERSONNEL
        case DIVER_POSITION_CATEGORIES.SURVEY_PERSONNEL.code:
            return ICONS.OFFSHORE_SURVEY_PERSONNEL
        case DIVER_POSITION_CATEGORIES.PROJECT_MISSION_PERSONNEL.code:
            return ICONS.PROJECT_MISSION_PERSONNEL
        case DIVER_POSITION_CATEGORIES.PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
            .code:
            return ICONS.PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
        default:
            break
    }
}

export { getPositionImage }
