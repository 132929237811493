import React from 'react'
import { useTranslate } from 'react-polyglot'

const EmptyChannels = () => {
    const t = useTranslate()

    return (
        <span className={'no-channels-found'}>
            <p>{t('chat.noChannelsFound')}</p>
        </span>
    )
}

export default EmptyChannels
