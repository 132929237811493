import React from 'react'

const SignUpAsOrganization = () => {

    return (
        <div className={'guidde'}>
            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to sign up for Skill N Depth account as an organization</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/kBvmuHYA5dtM5Spdv5qg98"
                        title="How to sign up for Skill N Depth account as an organization"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen={true}
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        We’re thrilled that you’ve decided to join Skill N Depth! Our platform is
                        designed to make life easier for organizations like yours, and we can't
                        wait for you to experience all the benefits. To get started, follow these
                        simple steps to create your account. Don’t worry—we’ll be with you every
                        step of the way!
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Introduction
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Whether you love using Chrome, Firefox, Safari, or any other browser, just
                        open it up and get ready to start your journey with us.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FeTk1LRM7cYY4DmrSpvXK1v_doc.png?alt=media&token=6be33159-72d5-46fe-b06e-4ba96d737d30"
                    alt="Introduction"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Click "Sign Up"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        On the login page, you’ll see a welcoming “Sign Up” button. Give it a
                        click to begin creating your brand-new account with Skill N Depth.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2Fv6nUmjcApHnfCmtZAPQfAd_doc.png?alt=media&token=a448f072-ac97-481c-85e8-e9e969398e81"
                    alt="Click 'Sign Up'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Enter data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        The first thing you’ll need to do is enter your organization’s email
                        address. Make sure it’s one you check regularly since we’ll be sending you
                        important information. Next up, you’ll need to create a strong password.
                        This is your key to accessing the platform, so pick something secure but
                        memorable. Type it into the "Enter your password" field. To double-check
                        that you didn’t make any typos, re-enter the same password in the "Confirm
                        password" field.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FqwK1dE6LsHr6GCfhpRPjHk_doc.png?alt=media&token=f73fa985-7172-405c-97b2-00e2d097669f"
                    alt="Enter data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Click "Agree and Join"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click the "Agree and Join" button to proceed with the registration
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F97KqdXr5mWEdunTV1oXcJR_doc.png?alt=media&token=cc62cc4b-6b32-4bb8-89a4-917a276b1f1d"
                    alt="Click 'Agree and Join'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Enter verification code
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        We’ve sent a verification code to the email address you provided. This is
                        just to make sure everything’s secure. Head over to your inbox, find our
                        email, and grab the code. Once you have the code, type it into the
                        verification field on the sign-up page. And just like that, you’re almost
                        there!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F1MZjRK11b4DbC3faLYNwdH_doc.png?alt=media&token=ea98483c-61de-42b7-9a40-82f451f09f32"
                    alt="Enter verification code"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Select Diving organization
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, we need to select which type of account we need. In our case, we
                        will select Diving organization
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FmE7ybj1gNywAqRV9F181Bp_doc.png?alt=media&token=42a5e90b-420f-4295-9a2f-4aa41b1ad31e"
                    alt="Select Diving organization"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        After making your selection, click “Next” to continue.{" "}
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FsMwWavE4Td4Nf62XUN2L2j_doc.png?alt=media&token=1e02acc7-e7e0-43cf-adb8-152c88a3f566"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Enter first name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        This part’s important. We need to know who we should reach out to if we
                        have questions or need to get in touch. Start by entering the first name
                        of the main contact person in your organization. Click on the “Enter
                        contact person first name” field and type it in.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FakN69oWmnBifSc7wPk4PpB_doc.png?alt=media&token=eeb7fafa-2f16-4d13-9362-24edfecd43c7"
                    alt="Enter first name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Enter last name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Then, do the same for their last name in the “Enter contact person last
                        name” field.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FoQ84rURrY8zzuFS5BnpV9G_doc.png?alt=media&token=cf64f936-169b-4999-b3d2-0073e4e05749"
                    alt="Enter last name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Proceed by clicking "Next.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FutFvUg3yeL4pdFoW1ruLNu_doc.png?alt=media&token=0c16e272-e77e-4bda-8e59-12db85eea49d"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. Enter name of the company
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now, let’s move on to your company details. Start by typing in your
                        company’s name.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FeDJMbJfcf3g71BBN4d5CGw_doc.png?alt=media&token=a555ce19-49d4-4280-beb0-2cc2d23b57fb"
                    alt="Enter name of the company"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Enter address
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        We also need to know where you’re based. Please provide the full address
                        of your headquarters
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F7CpRXJgnq14fejQEUnTrKD_doc.png?alt=media&token=98cf8523-19f0-4bba-8e01-c312953c5690"
                    alt="Enter address"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    13. Select company size
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        We’d love to know how big your company is, so select the size range that
                        best describes your organization. It helps us tailor our services to suit
                        your needs.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F7sW9yHkw286o5Q9kX9U3Jp_doc.png?alt=media&token=58b302fe-f55d-447c-90fb-71f017b7a18d"
                    alt="Select company size"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    14. Select industry
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, let us know what industry your company operates in. Whether you’re
                        in "Oil and Gas" or another field, select the industry that best fits your
                        business.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F1J5412yXF9e7cH64nTM2n8_doc.png?alt=media&token=69d3dd93-6ec9-4aab-9598-73b872da826e"
                    alt="Select industry"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    15. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Proceed by clicking "Next.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F1tderXY3KLo5nKce3m1JSp_doc.png?alt=media&token=d20c6817-51c5-4b7f-91b2-ba702a636d77"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    16. Select family of services
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Choose all the relevant service families that apply to your organization
                        (e.g., "General Marine Services").
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F5BTAB9AtUzxAdF78bkrVVw_doc.png?alt=media&token=35b5c345-47e4-4559-b0f9-aaf79fa54bc0"
                    alt="Select family of services"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    17. Select regions where your services are available
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Also, tell us where you offer your services by selecting the regions where
                        your services are available, such as “Asia Pacific &amp; Oceania.”
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FgSg9tEnDd7GXc4XYUEUrJw_doc.png?alt=media&token=a7cf006e-a005-41dd-a015-83351f0fcc62"
                    alt="Select regions where your services are available"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    18. Click "Finish"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click the “Finish” button, and that’s it—you’re all signed up!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2Fe4eAKmK3cwFdpPTZXeYtBg_doc.png?alt=media&token=b8e83ffb-f26d-45a1-a667-d24786a1d994"
                    alt="Click 'Finish'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    19. And that is it. Welcome to Skill N Depth
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Congratulations! You’re now part of a community that’s revolutionizing the
                        diving industry. Take a moment to review the welcome message and follow
                        any additional instructions we provide.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F9r6TBggpZkpteNRG7U39Wr_doc.png?alt=media&token=3c47cdab-6074-4ef6-8fbb-300d2ec3e45e"
                    alt="And that is it. Welcome to Skill N Depth"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        We’re so excited to have you on board and can’t wait to see what we’ll
                        achieve together. If you ever need a hand, don’t hesitate to reach
                        out—we’re always here to help!
                    </p>
                </div>
            </div>

        </div>
    )
}

export default SignUpAsOrganization
