import React from 'react'

const CreateAProject = () => {

    return (
        <div className={'guidde'}>

            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to create a project</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/nvPwExdR8Uo9qzN8AsZVZA"
                        title="How to create a project"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen={true}
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Welcome to this guide on how to create a project on the Skill N Depth
                        platform! Whether you're new to the platform or just need a quick
                        refresher, this step-by-step guide will walk you through everything you
                        need to know to get your project up and running. We’ll cover everything
                        from setting up basic details to adding positions and saving your project.
                        Let’s dive in and make your project a success!
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Introduction
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        First things first, open your browser and head over to
                        app.skillndepth.com. This is where all the magic happens. Once you’re on
                        the site, you'll be ready to start creating your new project.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2F4X3WQCvumvcxyjgtXUrjaL_doc.png?alt=media&token=af4cdebf-c1a6-428f-9fa9-fd15bdb03b73"
                    alt="Introduction"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Click "Create project"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’re logged in, find the “Create Project” button and give it a
                        click. This will open up a new form where you can start entering details
                        about your project. Think of this as the foundation for everything else,
                        so let's make sure we start strong!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FbEQTzHs6zzB9YDVNUippTX_doc.png?alt=media&token=eba1499f-ee8e-4290-a6be-76205db758ae"
                    alt="Click 'Create project'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Enter general information
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now it’s time to enter some general information about your project. This
                        includes details like the project’s status and name. While not all fields
                        are required, we highly recommend filling out as much as possible. The
                        more information you provide, the better your project will stand out to
                        potential employees.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fort4gbmpZ1LGAnhuAfdwH8_doc.png?alt=media&token=a8f0af52-141d-4b58-89ed-cf5fb7a7003f"
                    alt="Enter general information"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Hide project if needed
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        If you want to keep your project under wraps, you can choose to hide it.
                        When a project is hidden, only you and the people you invite will be able
                        to see it. This is a great option if you're working on something sensitive
                        or just want to keep things private.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2F7AsrKuPDCdywQMSdP1opwp_doc.png?alt=media&token=46c6d644-6f5f-4bd5-8439-632f3f9ff489"
                    alt="Hide project if needed"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Enter date range
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        You can also define the start and end dates of your project. This step is
                        optional, but it provides potential employees with more accurate
                        information about the timeline, which could help in attracting the right
                        candidates.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fug8ApnMNnm197qLw3SKSAY_doc.png?alt=media&token=d214a3b1-6c75-43e5-99e3-399c14bcc06f"
                    alt="Enter date range"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Enter geographical information
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next up, it’s time to add some geographical data. You’ll need to enter
                        details like the country, region, and specific location of your project.
                        One of the cool features here is the ability to place a marker on a map,
                        giving you the option to pinpoint an exact or approximate location.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FtC7RrQnAb6b3DnbcbxMtBB_doc.png?alt=media&token=3e956ec6-ab13-4350-86f6-855e4a009f0b"
                    alt="Enter geographical information"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Enter data about planed work
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        This section allows you to provide more details about the actual work that
                        will take place during the project. Be as specific as possible to ensure
                        that potential employees know what to expect.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FbqnPv16Cww8iEoyDxbweP6_doc.png?alt=media&token=18dcfaa8-b4bf-428f-96fd-62c0e9fcad3e"
                    alt="Enter data about planed work"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Enter details
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now, you’ll need to provide some additional details, such as the name of
                        the person in charge. This is the only required field in this section, but
                        feel free to add more information if you have it.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fh74KdxqvztfGPMZAHjqtYg_doc.png?alt=media&token=d3887f6d-8350-4238-9280-d212accdcdc3"
                    alt="Enter details"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Add postions
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        The last major step is all about positions. Start by specifying the type
                        of position and the number of workers needed. You can enhance the job
                        posting by adding details like the pay rate, position description, and
                        required skills. The more information you provide, the easier it will be
                        to attract the right candidates.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Ff4NWWUmDPBGVWnaph4GK1r_doc.png?alt=media&token=e4a4e06e-4420-4f84-8bf2-f7b9456770cb"
                    alt="Add postions"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Click "Add position"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’ve entered all the necessary information for a position, click
                        “Add Position.” This will save the position to your project.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2F4fc12SS8e3pPdMGfmWmCeq_doc.png?alt=media&token=2bf99ec6-e1b7-4f0c-8085-97f3ba1b9630"
                    alt="Click 'Add position'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. View added positions
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        After adding positions, you’ll be able to see them listed at the bottom of
                        the page. This gives you a chance to review and make any necessary edits
                        before finalizing the project.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FnasrS4STzzPSKJJ4jC56Ca_doc.png?alt=media&token=55a3efb8-7b91-4489-8dd4-3e752eab6fa5"
                    alt="View added positions"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Click "Save project"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Finally, when everything looks good, click “Save Project.” Your project is
                        now live and ready to start attracting talent!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fai2jvGBx6U5wbyWWXZLR18_doc.png?alt=media&token=c04a2104-c010-4c70-83a7-7527fa07db76"
                    alt="Click 'Save project'"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        And that’s it! You’ve successfully created a project on the Skill N Depth
                        platform. With just a few steps, you’ve set the stage for finding the
                        perfect team for your next big endeavor. If you ever need to make changes,
                        remember that you can always go back and edit your project. Good luck, and
                        happy hiring!
                    </p>
                </div>
            </div>


        </div>
    )
}

export default CreateAProject
