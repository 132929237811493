import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import useQueryParams from 'hooks/useQueryParams'

import TabNavigation from 'components/tabs/TabNavigation'
import CardContainer from 'components/CardContainer'
import DiveEventWrapper from '../diveEvent/DiveEventWrapper'
import SurfaceDecoEventWrapper from '../surfaceDecoEvent/SurfaceDecoEventWrapper'
import ChamberPressurizationEventWrapper from '../chamberPressurizationEvent/ChamberPressurizationEventWrapper'
import ChamberDecompressionEventWrapper from '../chamberDecompressionEvent/ChamberDecompressionEventWrapper'
import BellRunEventWrapper from '../bellRunEvent/BellRunEventWrapper'
import LockOutEventWrapper from '../lockOutEvent/LockOutEventWrapper'
import { isChamberPressurizationOnly } from 'utils/diveRecordFunctions'
import SaturationEventWrapper from '../saturationEvent/SaturationEventWrapper'

const DiveProfileSummary = ({
    data,
    diveRecordId,
    unitImperial,
    tabQueryParam,
    verification,
    divingMode,
}) => {
    const t = useTranslate()
    const searchParams = useLocation().search
    const queryParams = useQueryParams()
    const tab = queryParams[tabQueryParam]

    if (!data) {
        return null
    }

    const isChamberOnly = isChamberPressurizationOnly(divingMode)

    const TABS = {
        DIVE_EVENT: 'diver_event',
        SURFACE_DECOMPRESSION_EVENT: 'surface_decompression_event',
        CHAMBER_PRESSURIZATION_EVENT: 'chamber_pressurization_event',
        CHAMBER_DECOMPRESSION_EVENT: 'chamber_decompression_event',
        BELL_RUN_EVENT: 'bell_run_event',
        LOCK_OUT_EVENT: 'lock_out_event',
        SATURATION_DAILY_EVENT: 'saturation_daily_event',
    }

    const TAB_COMPONENTS = {
        [TABS.DIVE_EVENT]: DiveEventWrapper,
        [TABS.SURFACE_DECOMPRESSION_EVENT]: SurfaceDecoEventWrapper,
        [TABS.CHAMBER_PRESSURIZATION_EVENT]: ChamberPressurizationEventWrapper,
        [TABS.CHAMBER_DECOMPRESSION_EVENT]: ChamberDecompressionEventWrapper,
        [TABS.BELL_RUN_EVENT]: BellRunEventWrapper,
        [TABS.LOCK_OUT_EVENT]: LockOutEventWrapper,
        [TABS.SATURATION_DAILY_EVENT]: SaturationEventWrapper,
    }

    const tabData = [
        {
            key: TABS.DIVE_EVENT,
            title: 'general.diveEvent',
            show: !!data.diveEvents,
            queryParams: searchParams,
        },
        {
            key: TABS.SATURATION_DAILY_EVENT,
            title: 'general.saturationDailyEvent',
            show: !!data.saturationDailyEvents,
            queryParams: searchParams,
        },
        {
            key: TABS.SURFACE_DECOMPRESSION_EVENT,
            title: isChamberOnly
                ? 'chamberRecompressionOnlyEvent.title'
                : 'general.surfaceDecompressionEvent',
            show: !!data.surfaceDecompressionEvents,
            queryParams: searchParams,
        },
        {
            key: TABS.CHAMBER_PRESSURIZATION_EVENT,
            title: 'saturationChamberPressurizationEvent.title',
            show: !!data.chamberPressurizationEvents,
            queryParams: searchParams,
        },
        {
            key: TABS.CHAMBER_DECOMPRESSION_EVENT,
            title: 'saturationChamberDecompressionEvent.title',
            show: !!data.chamberDecompressionEvents,
            queryParams: searchParams,
        },
        {
            key: TABS.BELL_RUN_EVENT,
            title: 'general.bellRunEvent',
            show: !!data.bellRunEvents,
            queryParams: searchParams,
        },
        {
            key: TABS.LOCK_OUT_EVENT,
            title: 'lockOutEvent.title',
            show: !!data.lockOutEvents,
            queryParams: searchParams,
        },
    ].filter((item) => item.show)

    const renderTabContent = () => {
        if (!tabData.length) return
        const TabComponent =
            TAB_COMPONENTS[tab] || TAB_COMPONENTS[tabData[0]?.key]
        return (
            <TabComponent
                diveRecordDiveDataId={data.id}
                hideActions={true}
                unitImperial={unitImperial}
                divingMode={divingMode}
                diveRecordDiveData={data}
            />
        )
    }

    return (
        <CardContainer noBorderCard={!verification}>
            <TabNavigation tabs={tabData} tabQueryParam={tabQueryParam} />
            {renderTabContent()}
        </CardContainer>
    )
}

DiveProfileSummary.propTypes = {
    data: PropTypes.object,
    diveRecordId: PropTypes.number,
    unitImperial: PropTypes.bool,
    tabQueryParam: PropTypes.string,
    verification: PropTypes.bool,
    divingMode: PropTypes.object,
}

DiveProfileSummary.defaultProps = {
    tabQueryParam: 'tab',
}

export default DiveProfileSummary
