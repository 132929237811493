import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    createContext,
} from 'react'

import { ROLES } from 'constants/enums'
import { CurrentUserContext } from './CurrentUserContext'

export const FeatureAccessContext = createContext()

const FeatureAccessContextProvider = (props) => {
    const { currentUser } = useContext(CurrentUserContext)

    const [canCreateProject, setCanCreateProject] = useState(null)

    const checkIfCanCreateProject = (user) => {
        const isDivingCompany =
            user?.role?.name === ROLES.ROLE_DIVING_CONTRACTOR.name

        // only diving company can create projects
        setCanCreateProject(isDivingCompany)
    }

    useEffect(() => {
        if (currentUser) {
            checkIfCanCreateProject(currentUser)
        }
    }, [currentUser])

    const contextValue = useMemo(
        () => ({
            canCreateProject,
        }),
        [canCreateProject]
    )

    return (
        <FeatureAccessContext.Provider value={contextValue}>
            {props.children}
        </FeatureAccessContext.Provider>
    )
}

export default FeatureAccessContextProvider
