import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE, TOOLTIP_POSITION } from 'constants/enums'

import Icon from './Icon'
import Tooltip from './Tooltip'

const FieldLabel = ({
    label,
    htmlFor,
    className,
    required,
    translate,
    tooltip,
    tooltipPosition,
}) => {
    const t = useTranslate()

    return (
        <label
            htmlFor={htmlFor || undefined}
            className={`a-mediumText a-lightText ${className || ''} ${
                tooltip ? 'aligned-center -gap3' : ''
            }`}
        >
            {translate ? t(label) : label}
            {required && <span className="redStar"> *</span>}
            {tooltip && (
                <div className="help-icon aligned-center">
                    <Tooltip
                        tooltip={t(tooltip)}
                        position={tooltipPosition || TOOLTIP_POSITION.BOTTOM}
                    >
                        <Icon
                            name={ICONS.HELP_AND_INFO}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE20}
                        />
                    </Tooltip>
                </div>
            )}
        </label>
    )
}

FieldLabel.propTypes = {
    label: PropTypes.string,
    htmlFor: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    translate: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipPosition: PropTypes.string,
}

FieldLabel.defaultProps = {
    translate: true,
}

export default FieldLabel
