import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { useContext } from 'react'
import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { editEntityService } from 'services/entity.service'

import {
    NOTIFICATION_LEVEL,
    notificationNavigation,
} from 'utils/notificationHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'

const Notification = ({
    notification,
    fetchUnreadNotifications,
    isVerified,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)
    const { currentUser } = useContext(CurrentUserContext)

    const { WARNING, DANGER } = NOTIFICATION_LEVEL

    const {
        id,
        description,
        title,
        notificationLevel,
        entityId,
        notificationType,
        seen,
        additionalData,
    } = notification

    const isWarning = notificationLevel === WARNING
    const isDanger = notificationLevel === DANGER

    const handleCancel = async (itemID, seen) => {
        try {
            await editEntityService(ENTITIES.NOTIFICATIONS, itemID, {
                seen: !seen,
            })
            fetchUnreadNotifications()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClickNotification = () => {
        const newUrl = notificationNavigation(
            entityId,
            notificationType,
            currentUser.profileHash,
            additionalData?.projectPositionId,
            additionalData
        )

        if (!isVerified && newUrl.includes(ROUTES.PROJECT)) {
            setAlert(
                t('message.onlyAllowedForVerifiedUsers'),
                ALERT_TYPES.NOTIFICATION
            )
            return
        }

        navigate(newUrl)
    }

    return (
        <div
            className={`m-notification ${isWarning ? '-warning' : ''} ${
                isDanger ? '-danger' : ''
            }`}
        >
            <div className="-contentBox">
                <span
                    onClick={(e) => {
                        handleCancel(id, seen)
                    }}
                    className="removeItemIcon"
                ></span>
                <div className="m-notification__content fullWidth">
                    <span className="-title a-bodyTextMedium">{title}</span>
                    <p
                        className="-details a-mediumText -mt10"
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                    <div className="-mt20">
                        <Button
                            label="button.viewDetails"
                            icon={ICONS.ARROW_RIGHT}
                            iconPosition={ICON_POSITION.RIGHT}
                            btnClass={BUTTON_STATUS.TERTIARY}
                            iconColor={COLORS.LIGHT_BLUE}
                            onClick={handleClickNotification}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

Notification.propTypes = {
    notification: PropTypes.object,
    isVerified: PropTypes.bool,
}

export default Notification
