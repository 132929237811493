export const formatPayRate = (item) => {
    return item || null
}

export const formatFromToValues = (from, to, unit='') => {
    const fromValue = from ? formatPayRate(from) : unit ? '0-' : 'to '
    const toValue = to ? formatPayRate(to) : ''
    let formattedValue = null
    if (from && to) {
        formattedValue = unit + fromValue + ' - ' + unit + toValue
    } else if (from && !to) {
        formattedValue = 'from ' + fromValue + unit
    } else {
        formattedValue = unit + fromValue + unit + toValue
    }

    return formattedValue
}
