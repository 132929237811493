import React from 'react'
import PropTypes from 'prop-types'

const InfoRow = ({
    children,
    withButtons,
    withColumns,
    alignRight,
    gap,
    alwaysSeparated,
}) => {
    return (
        <div
            className={`-infoRow ${
                alwaysSeparated
                    ? 'space-between aligned-center -alwaysSeparated'
                    : ''
            } ${withButtons ? '-withButtonsWrapper' : ''} ${
                withColumns ? '-withColumns' : ''
            } -gap${gap} ${alignRight ? '-alignRight' : ''}`}
        >
            {children}
        </div>
    )
}

InfoRow.propTypes = {
    withButtons: PropTypes.bool,
    withColumns: PropTypes.bool,
    gap: PropTypes.number,
    alignRight: PropTypes.bool,
    alwaysSeparated: PropTypes.bool,
}

InfoRow.defaultProps = {
    withButtons: false,
    withColumns: false,
    gap: 15,
    alwaysSeparated: false,
}

export default InfoRow
