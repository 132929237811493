import React from 'react'

import { MOMENT_FORMATS } from 'services/moment.service'
import moment from 'moment'

const DateCell = ({ value }) => {
    const date = value  ? moment(value).format(MOMENT_FORMATS.DATETIME) : '';

    return (
        <div className={`a-captionsTextRegular`}>
            {date}
        </div>
    )
}

export default DateCell
