import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

const BellSaturationSupervisorBadge = ({ size }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.418 12.505a.957.957 0 0 1 .458-.812L25 0v50c-6.294-3.521-13.427-9.437-16.783-16.197C5.25 27.83 5.342 19.585 5.404 14.022c.006-.532.011-1.04.014-1.518ZM44.582 12.505a.957.957 0 0 0-.458-.812L25 0v50c6.294-3.521 13.427-9.437 16.783-16.197 2.966-5.973 2.875-14.218 2.813-19.781a283.87 283.87 0 0 1-.014-1.518Z"
                fill={COLORS.PURPLE}
            />
            <g filter="url(#a)" fill="#F3F4F5">
                <path d="M8.49 14.427a.812.812 0 0 1 .38-.687l15.938-9.894v42.308c-5.245-2.98-11.19-7.985-13.986-13.705-2.472-5.054-2.396-12.03-2.345-16.738.005-.45.01-.88.012-1.284ZM41.126 14.427a.812.812 0 0 0-.381-.687L24.808 3.846v42.308c5.244-2.98 11.188-7.985 13.986-13.705 2.47-5.054 2.395-12.03 2.344-16.738-.005-.45-.01-.88-.012-1.284Z" />
            </g>
            <path
                d="m19.93 14.615-.874 2.623h-1.748v10.49h1.748l.874 3.496-2.622 2.622h15.734l-2.622-2.622.874-3.497h1.748v-10.49h-1.748l-.874-2.622H19.93Z"
                fill="#fff"
            />
            <circle
                cx="25.175"
                cy="22.482"
                r="1.497"
                fill="url(#paint1_linear_21877_21002)"
                stroke={COLORS.PURPLE}
                strokeWidth=".94"
            />
            <circle
                cx="19.274"
                cy="22.482"
                r="1.497"
                fill="url(#paint1_linear_21877_21002)"
                stroke={COLORS.PURPLE}
                strokeWidth=".94"
            />
            <circle
                cx="31.075"
                cy="22.482"
                r="1.497"
                fill="url(#paint1_linear_21877_21002)"
                stroke={COLORS.PURPLE}
                strokeWidth=".94"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.056 14.615v.874h.537l-.591 1.749h-1.694v10.49h1.448l1.035 3.059h-.735v.874h.433l-1.287 1.31h-.894v.875h15.734v-.874h-.86l-1.287-1.311h.399v-.874h-.736l1.035-3.06h1.449v-.874h-10.49v-8.741h5.245v8.741h.874v-8.741h3.497v8.741h.874v-9.615h-1.694l-.591-1.749h.537v-.874H19.056Zm10.778.874h-1.787l.591 1.749h1.787l-.591-1.749Zm.836 12.238h-2.013l-.793 3.06h1.771l1.035-3.06Zm-1 3.934h-1.771l.444 1.31h2.614l-1.287-1.31Zm-1.936-3.934-.793 3.06h-3.415l-.793-3.06h5.001Zm-5.924 0 .793 3.06h-1.889l-1.035-3.06h2.131Zm-2.383 5.245 1.287-1.311h1.854l-.444 1.31h-2.697Zm3.62 0 .444-1.311h3.485l.444 1.31h-4.373Zm4.668-15.734-.591-1.749h-3.781l-.591 1.749h4.963Zm-7.2-1.749h1.905l-.591 1.749h-1.905l.592-1.749Zm-2.333 2.623h3.496v8.741h-3.496v-8.741Z"
                fill={COLORS.PURPLE}
            />
            <defs>
                <linearGradient
                    id="b"
                    x1="25.175"
                    y1="20.516"
                    x2="25.175"
                    y2="24.449"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6EAFC" />
                    <stop offset="1" stopColor="#C684EF" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1="19.274"
                    y1="20.516"
                    x2="19.274"
                    y2="24.449"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6EAFC" />
                    <stop offset="1" stopColor="#C684EF" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1="31.075"
                    y1="20.516"
                    x2="31.075"
                    y2="24.449"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6EAFC" />
                    <stop offset="1" stopColor="#C684EF" />
                </linearGradient>
                <filter
                    id="a"
                    x="8.461"
                    y="3.846"
                    width="33.077"
                    height="42.692"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx=".385" dy=".385" />
                    <feGaussianBlur stdDeviation=".192" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0" />
                    <feBlend
                        in2="shape"
                        result="effect1_innerShadow_21877_21002"
                    />
                </filter>
            </defs>
        </svg>
    )
}

BellSaturationSupervisorBadge.propTypes = {
    size: PropTypes.number,
}

BellSaturationSupervisorBadge.defaultProps = {
    size: 50,
}

export default BellSaturationSupervisorBadge
