import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import Modal from './Modal'
import Icon from './Icon'
import Loader from './Loader'

const ConfirmationModal = () => {
    const t = useTranslate()

    const {
        confirmationModal: {
            message,
            customText,
            title,
            icon,
            iconColor,
            iconSize,
            confirmLabel = 'button.confirm',
            cancelLabel = 'button.cancel',
            handleConfirm,
            handleCancel,
            translateMessage = true,
            hideCancel = false,
            isConfirming,
            confirmIcon,
            confirmIconColor,
        },
    } = useContext(ConfirmationModalContext)

    if (!message && !customText) return null

    return (
        <Modal
            open={true}
            setOpen={handleCancel}
            buttons={{
                prevBtn: {
                    label: cancelLabel,
                    handleClick: handleCancel,
                    disabled: isConfirming,
                },
                nextBtn: {
                    label: confirmLabel,
                    handleClick: handleConfirm,
                    icon: confirmIcon,
                    iconColor: confirmIconColor,
                    disabled: isConfirming,
                },
            }}
            closeButton={handleCancel}
            hidePrevBtn={hideCancel}
            smallModal
            customClass="-confirmationModal"
        >
            {icon && (
                <div className="_12 -pt20">
                    <div className="justify-center -mb20">
                        <Icon name={icon} color={iconColor} size={iconSize} />
                    </div>
                </div>
            )}
            {title && (
                <div className={`_12 -mb20 ${!icon ? '-mt20' : ''}`}>
                    <div className="justify-center centered-text">
                        <h3 className="a-boldText"> {t(title)}</h3>
                    </div>
                </div>
            )}
            <div className={`_12 ${!icon && !title ? '-pt20' : ''}`}>
                <div className="m-modal__content--text">
                    {message && (
                        <span className="a-bodyTextRegular">
                            {translateMessage ? t(message) : message}
                        </span>
                    )}
                    {customText}
                </div>
            </div>
            {isConfirming && <Loader />}
        </Modal>
    )
}

export default ConfirmationModal
