const BADGE = {
    VERIFIED: 'verified',
    NUMBER_OF_DIVES: 'numberOfDives',
    NUMBER_OF_DIVES_BRONZE: 'numberOfDivesBronze',
    NUMBER_OF_DIVES_SILVER: 'numberOfDivesSilver',
    NUMBER_OF_DIVES_GOLD: 'numberOfDivesGold',
    NUMBER_OF_DIVES_PLATINUM: 'numberOfDivesPlatinum',
    SCUBA_DIVING: 'scubaDiving',
    SURFACE_SUPPLIED: 'surfaceSupplied',
    SATURATION_BELL_DIVING: 'saturationBellDiving',
    AIR_DIVING_SUPERVISOR: 'airDivingSupervisor',
    BELL_SATURATION_SUPERVISOR: 'bellSaturationSupervisor',
    DIVER_MEDIC: 'diverMedic',
    OIL_GAS_INDUSTRY: 'oilGasIndustry',
    RENEWABLE_ENERGY: 'renewableEnergy',
    CIVIL_ENGINEERING: 'civilEngineering',
    CERTIFIED_NDT_INSPECTION: 'certifiedNdtInspection',
    CONSTRUCTION_DIVER: 'constructionDiver',
}
export default BADGE
