import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import axios, { GET } from 'utils/axiosClient'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    ALERT_TYPES,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import useFetchDataById from 'hooks/useFetchDataById'

import Button from 'components/Button'
import { AlertContext } from 'contexts/AlertContext'
import IdentityVerificationModal from './IdentityVerificationModal'
import Loader from 'components/Loader'

const IdentityVerification = ({
    profilePage,
    setCloseVerificationBanner,
    settingsPage,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { currentUser, isDiver } = useContext(CurrentUserContext)

    const [verificationUrl, setVerificationUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { data: userProfile } = useFetchDataById(
        ENTITIES.DIVER_PROFILE,
        currentUser.id,
        {}
    )

    if (!currentUser || currentUser.identityVerified === true) return null

    if (!isDiver) {
        return null
    }

    const incompleteSteps =
        userProfile?.completionProgress && Object.values(userProfile.completionProgress).some(item => item === 0)

    if (!userProfile || incompleteSteps) return null

    const openVerificationIdentityModal = () => {
        setIsLoading(true)
        axios(GET, `/identity-verification/get-link`, {})
            .then(async ({ data }) => {
                if (data && data.url) {
                    setVerificationUrl(data.url)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setAlert(error, ALERT_TYPES.ERROR, t)
                setIsLoading(false)
            })
    }

    const handleClose = async () => {
        setCloseVerificationBanner(true)
    }

    return (
        <div
            className={`${settingsPage ? '' : 'm-boxes__main -mb20 fullWidth'}`}
        >
            {!profilePage && !settingsPage && (
                <span
                    className="removeItemIcon -large"
                    onClick={handleClose}
                ></span>
            )}
            <div>
                {!settingsPage && (
                    <Fragment>
                        <span className="a-bodyTextMedium">
                            {t('identityVerification.title')}
                        </span>
                        <p className="-mt10 -mb20 a-mediumText">
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('identityVerification.boxTextFirstPart')}
                            </span>
                            <span className="a-mediumTextSemiBold">
                                {t('identityVerification.boxTextBold')}
                            </span>
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('identityVerification.boxTextSecondPart')}
                            </span>
                        </p>
                    </Fragment>
                )}
                <Button
                    type={BUTTON_TYPE.BUTTON}
                    btnClass={
                        settingsPage
                            ? BUTTON_STATUS.TERTIARY
                            : BUTTON_STATUS.PRIMARY
                    }
                    icon={settingsPage ? ICONS.ARROW_RIGHT : ''}
                    iconColor={settingsPage ? COLORS.SECONDARY : ''}
                    iconPosition={settingsPage ? ICON_POSITION.RIGHT : null}
                    label={`${
                        settingsPage
                            ? 'button.startVerificationProcess'
                            : 'identityVerification.startButton'
                    }`}
                    onClick={openVerificationIdentityModal}
                    buttonSize={
                        settingsPage ? BUTTON_SIZE.XSMALL : BUTTON_SIZE.SMALL
                    }
                />
                <IdentityVerificationModal
                    verificationUrl={verificationUrl}
                    setVerificationUrl={setVerificationUrl}
                />
            </div>
            {isLoading && <Loader />}
        </div>
    )
}

IdentityVerification.propTypes = {
    profilePage: PropTypes.bool,
    settingsPage: PropTypes.bool,
}

IdentityVerification.defaultProps = {
    profilePage: false,
    settingsPage: false,
}

export default IdentityVerification
