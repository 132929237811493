import React from 'react'
import { useTranslate } from 'react-polyglot'

import CellItem from '../CellItem'
import { getValueWithKey } from 'utils/dataFormatters'

const CellHeader = ({ item, headerKey, title, headerItem }) => {
    const t = useTranslate()
    const value = getValueWithKey(headerKey, item)

    if (value)
        return (
            <div className="m-accordion__card--header">
                <span className="a-captionsTextBold">{title && t(title)}</span>
                <span className="a-mediumText">
                    <CellItem
                        headerKey={headerKey}
                        headerItem={headerItem}
                        item={item}
                    />
                </span>
            </div>
        )
}

export default CellHeader
