import { Form, Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import {
    MOMENT_FORMATS,
    checkIfExpired,
    getIsDateSameOrAfter,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    isDateAfter,
} from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { formatRequestData } from 'utils/jsonApiFormatters'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    PRIMARY_TRAINING_TYPE,
    PRIMARY_TRAINING_TYPE_CATEGORY,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    DateTimeField,
    FileUploadField,
    InputField,
    MultiselectField,
    SelectField,
} from 'components/formFields'
import ModalButtons from 'components/modal/ModalButtons'
import ModalTitle from 'components/modal/ModalTitle'
import useQueryParams from 'hooks/useQueryParams'
import { getTrainingTypeForSubTab } from 'screens/diver/profile/diverInformation/constants/trainingRecordOptions'
import CertificateCard from './CertificateCard'
import CertificateForm from './CertificateForm'
import CardHeader from 'components/card/CardHeader'
import Note from 'components/Note'
// import EditCertificate from './EditCertificate'

const { DIVE_SCHOOL_TRAINING, OTHER_TRAINING } = PRIMARY_TRAINING_TYPE
const { COMMERCIAL, MILITARY, OTHER } = PRIMARY_TRAINING_TYPE_CATEGORY

const TrainingCourseFormContext = ({ setTrainingSchoolName }) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const {
        primaryTrainingType,
        primaryTrainingTypeCategory,
        diveSchool,
        diveSchoolName,
    } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            setFieldValue('primaryTrainingTypeCategory', null)
            setFieldTouched('primaryTrainingTypeCategory', false)
        }
    }, [primaryTrainingType])

    useEffect(() => {
        if (!firstRender.current) {
            if (primaryTrainingTypeCategory?.id === MILITARY) {
                setFieldValue('schoolAddress', '')
                setFieldTouched('schoolAddress', false)
            }

            if (primaryTrainingTypeCategory?.id !== MILITARY) {
                setFieldValue('country', null)
                setFieldTouched('country', false)

                setFieldValue('branch', null)
                setFieldTouched('branch', false)
            }

            setFieldValue('diveSchool', null)
            setFieldTouched('diveSchool', false)

            setFieldValue('diveSchoolName', '')
            setFieldTouched('diveSchoolName', false)

            setFieldValue('trainingTypes', [])
            setFieldTouched('trainingTypes', false)
        } else {
            firstRender.current = false
        }
    }, [primaryTrainingTypeCategory])

    useEffect(() => {
        setTrainingSchoolName(
            primaryTrainingType?.id === DIVE_SCHOOL_TRAINING
                ? diveSchool?.name
                : diveSchoolName
        )
    }, [primaryTrainingType, diveSchool, diveSchoolName])

    return null
}

const TrainingCourseForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    showActions,
    fetchData,
    refetchData,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { subTab } = useQueryParams()

    const [primaryTrainingType] = useState(getTrainingTypeForSubTab(subTab))
    const [submitted, setSubmitted] = useState(false)

    const formRef = useRef()

    const [certificates, setCertificates] = useState(
        initialData?.trainingCertificates ?? []
    )
    const [showCertificateForm, setShowCertificateForm] = useState(false)
    const [trainingSchoolName, setTrainingSchoolName] = useState(
        initialData?.primaryTrainingType?.id === DIVE_SCHOOL_TRAINING
            ? initialData?.diveSchool?.name
            : initialData?.diveSchoolName
    )

    // NOTE:: implement later (also needed for visas and recordInformations)
    // const [edit, setEdit] = useState(null)

    // const handleEdit = (item) => {
    //     setEdit(item)
    // }

    const handleConfirm = async (index) => {
        try {
            const certificatesCopy = [...certificates]
            certificatesCopy.splice(index, 1)
            setCertificates(certificatesCopy)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = (index) => {
        showConfirmationModal({
            title: 'general.deleteTrainingCertificate',
            message: 'message.areYouSureDeleteTrainingCertificate',
            handleConfirm: () => handleConfirm(index),
            handleCancel,
        })
    }

    const initialValues = {
        primaryTrainingType:
            initialData?.primaryTrainingType ??
            getTrainingTypeForSubTab(subTab),
        primaryTrainingTypeCategory:
            initialData?.primaryTrainingTypeCategory ?? null,
        diveSchool: initialData?.diveSchool ?? null,
        diveSchoolName: initialData?.diveSchoolName ?? '',
        country: initialData?.country ?? null,
        branch: initialData?.branch ?? null,
        schoolAddress: initialData?.schoolAddress ?? '',
        courseName: initialData?.courseName ?? '',
        dateOfEnrolled: initialData?.dateOfEnrolled ?? '',
        dateOfCompleted: initialData?.dateOfCompleted ?? '',
        trainingTypes: initialData?.trainingTypes ?? [],
        proficiencyLevel: initialData?.proficiencyLevel ?? null,
        files: initialData?.files ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        primaryTrainingType: Yup.object().required(requiredMessage),
        primaryTrainingTypeCategory: Yup.object().required(requiredMessage),
        diveSchool: Yup.string().when(
            ['primaryTrainingType', 'primaryTrainingTypeCategory'],
            {
                is: (primaryTrainingType, primaryTrainingTypeCategory) =>
                    primaryTrainingType?.id !== OTHER_TRAINING &&
                    (!primaryTrainingTypeCategory ||
                        primaryTrainingTypeCategory?.id === COMMERCIAL ||
                        primaryTrainingTypeCategory?.id === OTHER),
                then: () => Yup.object().required(requiredMessage),
                otherwise: () => Yup.object().nullable(),
            }
        ),
        diveSchoolName: Yup.string().when(
            ['primaryTrainingType', 'primaryTrainingTypeCategory'],
            {
                is: (primaryTrainingType) =>
                    primaryTrainingType?.id === OTHER_TRAINING,
                then: () => Yup.string().trim().required(requiredMessage),
                otherwise: () => Yup.string(),
            }
        ),
        schoolAddress: Yup.string().when('primaryTrainingTypeCategory', {
            is: (primaryTrainingTypeCategory) =>
                primaryTrainingTypeCategory?.id !== MILITARY,
            then: () => Yup.string().trim().required(requiredMessage),
            otherwise: () => Yup.string(),
        }),
        country: Yup.object().when('primaryTrainingTypeCategory', {
            is: (primaryTrainingTypeCategory) =>
                primaryTrainingTypeCategory?.id === MILITARY,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        branch: Yup.object().when('primaryTrainingTypeCategory', {
            is: (primaryTrainingTypeCategory) =>
                primaryTrainingTypeCategory?.id === MILITARY,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        courseName: Yup.string().trim(),
        dateOfEnrolled: Yup.date().test(
            'dateOfEnrolled',
            (value, { parent, createError }) => {
                if (
                    isDateAfter(
                        value,
                        parent.dateOfCompleted,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.dateOfEnrolledMustBeBefore'),
                        path: 'dateOfEnrolled',
                    })
                }
                if (isDateAfter(value, getTodaysDate(), MOMENT_FORMATS.DATE)) {
                    return createError({
                        message: t(
                            'form.error.dateOfEnrolledMustBeBeforeCurrentDate'
                        ),
                        path: 'dateOfEnrolled',
                    })
                }
                return true
            }
        ),

        dateOfCompleted: Yup.date()
            .test('dateOfCompleted', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                } else {
                    return createError({
                        message: t('form.error.completedDateInFuture'),
                        path: 'dateOfCompleted',
                    })
                }
            })
            .test('dateOfCompleted', (value, { parent, createError }) => {
                if (!parent.dateOfEnrolled) {
                    return true
                }

                if (
                    getIsDateSameOrAfter(
                        value,
                        parent.dateOfEnrolled,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return true
                } else {
                    return createError({
                        message: t('form.error.completedAfterEnrolledDate'),
                        path: 'dateOfCompleted',
                    })
                }
            })
            .required(requiredMessage),
        trainingTypes: Yup.array()
            .min(1, t('form.error.atLeastOneTrainingType'))
            .required(requiredMessage),
        proficiencyLevel: Yup.object().notRequired(),
        files: Yup.array().notRequired(),
    })

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            setSubmitted(true)

            await handleSubmit({
                ...formData,
                trainingCertificates: certificates.map((item) =>
                    formatRequestData(ENTITIES.TRAINING_CERTIFICATE, item)
                ),
                user: { id: userId, entityType: ENTITIES.USER },
            })

            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
            setSubmitted(false)
        }
    }

    const handleClose = () => setOpen(false)

    const getModalTitle = () => {
        const isNew = !initialData
        const isProfessionalTraining =
            primaryTrainingType && primaryTrainingType.id === 1

        if (isNew && isProfessionalTraining) {
            return t('general.addProfessionalTraining')
        }

        if (isNew && !isProfessionalTraining) {
            return t('general.addVocationalTraining')
        }

        if (isProfessionalTraining) {
            return t('general.editProfessionalTraining')
        }

        return t('general.editVocationalTraining')
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            closeOnClickOutside={false}
            formInForm
        >
            <ModalTitle title={getModalTitle()} noTranslate />

            <div className="_wr">
                <div className="-body">
                    <div className="_w -bodyContent">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validation}
                            onSubmit={onSubmit}
                            innerRef={formRef}
                        >
                            {({
                                values: {
                                    primaryTrainingType,
                                    primaryTrainingTypeCategory,
                                    dateOfEnrolled,
                                    dateOfCompleted,
                                },
                                isSubmitting,
                            }) => {
                                const showSchoolSelect =
                                    primaryTrainingType?.id !==
                                        OTHER_TRAINING &&
                                    (!primaryTrainingTypeCategory ||
                                        primaryTrainingTypeCategory?.id !==
                                            MILITARY)
                                const showSchoolInput =
                                    primaryTrainingType?.id === OTHER_TRAINING
                                const showSchoolAddress =
                                    primaryTrainingTypeCategory?.id !== MILITARY
                                const showMilitaryFields =
                                    primaryTrainingTypeCategory?.id === MILITARY

                                return (
                                    <Form>
                                        <FocusError />
                                        <TrainingCourseFormContext
                                            setTrainingSchoolName={
                                                setTrainingSchoolName
                                            }
                                        />
                                        <Fragment>
                                            <div className="_12 _m6">
                                                <SelectField
                                                    name="primaryTrainingTypeCategory"
                                                    entityType={
                                                        ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY
                                                    }
                                                    params={{
                                                        'primaryTrainingType.id':
                                                            primaryTrainingType?.id,
                                                    }}
                                                    reload={
                                                        primaryTrainingType?.id
                                                    }
                                                    required
                                                />
                                            </div>
                                            <Separator />

                                            {showSchoolSelect && (
                                                <div className="_12 _m6">
                                                    <SelectField
                                                        name="diveSchool"
                                                        entityType={
                                                            ENTITIES.DIVE_SCHOOL
                                                        }
                                                        params={{
                                                            'primaryTrainingTypeCategory.id':
                                                                primaryTrainingTypeCategory?.id,
                                                        }}
                                                        reload={
                                                            primaryTrainingTypeCategory?.id
                                                        }
                                                        required
                                                        searchable
                                                        createNew
                                                        createNewParams={{
                                                            related_entity:
                                                                ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY,
                                                            related_entity_id:
                                                                primaryTrainingTypeCategory?.id,
                                                        }}
                                                        disabled={
                                                            !primaryTrainingTypeCategory?.id
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {showSchoolInput && (
                                                <div className="_12 _m6">
                                                    <InputField
                                                        name="diveSchoolName"
                                                        required
                                                    />
                                                </div>
                                            )}
                                            {showSchoolAddress && (
                                                <div className="_12 _m6">
                                                    <InputField
                                                        name="schoolAddress"
                                                        required
                                                        icon={ICONS.LOCATION}
                                                        iconColor={
                                                            COLORS.DARK_BLUE_60
                                                        }
                                                        size={ICON_SIZE.SIZE20}
                                                    />
                                                </div>
                                            )}
                                            {showMilitaryFields && (
                                                <Fragment>
                                                    <div className="_12 _m6">
                                                        <SelectField
                                                            name="country"
                                                            entityType={
                                                                ENTITIES.COUNTRY
                                                            }
                                                            searchable
                                                            required
                                                        />
                                                    </div>
                                                    <div className="_12 _m6">
                                                        <SelectField
                                                            name="branch"
                                                            entityType={
                                                                ENTITIES.BRANCH
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                </Fragment>
                                            )}
                                            <Separator />
                                            <div className="_12 _m6">
                                                <InputField name="courseName" />
                                            </div>
                                            <div className="_w">
                                                <div className="_12 _m6">
                                                    <DateTimeField
                                                        name="dateOfEnrolled"
                                                        dateFormat="MMM/y"
                                                        monthPicker
                                                        maxDate={
                                                            dateOfCompleted
                                                                ? new Date(
                                                                      dateOfCompleted
                                                                  )
                                                                : getTodaysDate()
                                                        }
                                                    />
                                                </div>
                                                <div className="_12 _m6 calendarMovedToRight">
                                                    <DateTimeField
                                                        name="dateOfCompleted"
                                                        dateFormat="MMM/y"
                                                        monthPicker
                                                        required
                                                        maxDate={getTodaysDate()}
                                                        minDate={
                                                            new Date(
                                                                dateOfEnrolled
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="_12 _m6">
                                                    <MultiselectField
                                                        name="trainingTypes"
                                                        entityType={
                                                            ENTITIES.TRAINING_TYPE
                                                        }
                                                        params={
                                                            primaryTrainingType?.id ===
                                                            OTHER_TRAINING
                                                                ? {
                                                                      'primaryTrainingTypeCategory.id':
                                                                          primaryTrainingTypeCategory?.id,
                                                                  }
                                                                : {
                                                                      'primaryTrainingType.id':
                                                                          primaryTrainingType?.id,
                                                                  }
                                                        }
                                                        reload={
                                                            primaryTrainingTypeCategory?.id
                                                        }
                                                        required
                                                        searchable
                                                        createNew
                                                        createNewParams={{
                                                            related_entity:
                                                                primaryTrainingType?.id ===
                                                                OTHER_TRAINING
                                                                    ? ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY
                                                                    : ENTITIES.PRIMARY_TRAINING_TYPE,
                                                            related_entity_id:
                                                                primaryTrainingType?.id ===
                                                                OTHER_TRAINING
                                                                    ? primaryTrainingTypeCategory?.id
                                                                    : primaryTrainingType?.id,
                                                        }}
                                                        disabled={
                                                            !primaryTrainingTypeCategory?.id
                                                        }
                                                    />
                                                </div>
                                                <div className="_12 _m6">
                                                    <SelectField
                                                        name="proficiencyLevel"
                                                        entityType={
                                                            ENTITIES.PROFICIENCY_LEVEL
                                                        }
                                                    />
                                                </div>
                                                <Separator />
                                                <FileUploadField
                                                    name="files"
                                                    label="form.label.trainingCourseFiles"
                                                />
                                                <div className="_12 -mt20">
                                                    <Note note="message.verificationOfTraining" />
                                                </div>
                                            </div>

                                            {certificates.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="-mt20"
                                                >
                                                    <CardContainer>
                                                        <CardHeader
                                                            title={`${item.name}`}
                                                            item={item}
                                                            hideShowRecord={checkIfExpired(
                                                                item.expiresDate
                                                            )}
                                                            actions={
                                                                showActions
                                                                    ? [
                                                                          {
                                                                              handleAction:
                                                                                  () =>
                                                                                      handleDelete(
                                                                                          index
                                                                                      ),
                                                                              icon: ICONS.DELETE,
                                                                              iconColor:
                                                                                  COLORS.RED,
                                                                              tooltip:
                                                                                  'button.deleteTrainingCertificate',
                                                                          },
                                                                      ]
                                                                    : []
                                                            }
                                                        />
                                                        <CertificateCard
                                                            item={item}
                                                        />
                                                    </CardContainer>
                                                </div>
                                            ))}
                                        </Fragment>

                                        {!showCertificateForm && (
                                            <div className="_w -mt30 -pb20">
                                                <div className="_12 ofs_m4 _m4 justify-center">
                                                    <Button
                                                        btnClass={
                                                            BUTTON_STATUS.SECONDARY
                                                        }
                                                        type={
                                                            BUTTON_TYPE.BUTTON
                                                        }
                                                        label="button.addCertificate"
                                                        icon={ICONS.PLUS}
                                                        iconColor={
                                                            COLORS.LIGHT_BLUE
                                                        }
                                                        onClick={() =>
                                                            setShowCertificateForm(
                                                                true
                                                            )
                                                        }
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {isSubmitting && <Loader />}
                                    </Form>
                                )
                            }}
                        </Formik>

                        {/* {!!edit && (
                <EditCertificate
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    showActions={showActions}
                />
            )} */}

                        {showCertificateForm && (
                            <div className="-pb20">
                                <CertificateForm
                                    modal={false}
                                    title={'general.certificates'}
                                    setShowCertificateForm={
                                        setShowCertificateForm
                                    }
                                    setCertificates={setCertificates}
                                    trainingSchoolName={trainingSchoolName}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ModalButtons
                isSubmitting={submitted}
                setOpen={setOpen}
                buttons={{
                    nextBtn: {
                        handleClick: () => formRef?.current?.handleSubmit(),
                    },
                }}
            />
        </Modal>
    )
}

TrainingCourseForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    showActions: PropTypes.bool,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
}

export default TrainingCourseForm
