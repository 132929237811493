import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import useWindowDimensions from 'hooks/useWindowDimension'

import { SUPPORT } from 'constants/constants'

import Note from 'components/Note'

const SupportNoteRow = () => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    return (
        <tr className="-supportNoteRow">
            {!isTablet && (
                <Fragment>
                    <td></td>
                    <td></td>
                </Fragment>
            )}
            <td colSpan={2}>
                <div className="-mt20">
                    <Note>
                        <span>
                            {t('general.pleaseContactSupportAt')}{' '}
                            <Link
                                onClick={() =>
                                    (window.location = `mailto:${SUPPORT.EMAIL}`)
                                }
                                title={SUPPORT.EMAIL}
                            >
                                {SUPPORT.EMAIL}
                            </Link>{' '}
                            {t('general.forMoreDetails')}
                        </span>
                    </Note>
                </div>
            </td>
        </tr>
    )
}

export default SupportNoteRow
