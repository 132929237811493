import { hasValue } from 'utils/form'

const GasContents = ({ item: item }) => {
    const { gasContents, helium, nitrogen, oxygen } = item

    const formatGasData = (helium, nitrogen, oxygen) => {
        const gases = []

        if (hasValue(oxygen)) {
            gases.push(`Oxygen ${oxygen}%`)
        }
        if (hasValue(helium)) {
            gases.push(`Helium ${helium}%`)
        }
        if (hasValue(nitrogen)) {
            gases.push(`Nitrogen ${nitrogen}%`)
        }

        return !!gases.length
            ? `${gasContents.name}(${gases.join(', ')})`
            : gasContents.name
    }

    return (
        <div>
            <p>{formatGasData(helium, nitrogen, oxygen)}</p>
        </div>
    )
}

export default GasContents
