import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { formatUrl } from 'utils/jsonApiFormatters'

import ENTITIES from 'constants/entities'
import useFetchData from 'hooks/useFetchData'

import {
    DIVE_RECORD_TYPE,
    DIVING_PERSONNEL_POSITIONS,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ROUTES from 'constants/routes'
import { DIVING_MODES } from '../constants/divingModes'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { RadioGroupField, SelectField } from 'components/formFields'
import FocusError from '../../../../components/FocusError'

const CreateDiveRecordModalForm = ({
    setOpenCreateDiveRecordModal,
    positionOnCurrentProject,
    profile,
    projectId,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { id } = useParams()

    const formRef = useRef()

    const { data, isLoading } = useFetchData(ENTITIES.DIVING_MODE)

    const identificator = id || projectId

    const enabledDivingModes = (role) => {
        return data.filter(
            (mode) => mode.code !== DIVING_MODES.ATMOSPHERIC_DIVING_SUIT.code
        )
    }

    const ROLE_OPTIONS = Object.keys(DIVE_RECORD_TYPE)
        .map((key) => DIVE_RECORD_TYPE[key])
        .filter(
            (item) =>
                item.id === DIVE_RECORD_TYPE.DIVER_RECORD.id ||
                item.id === DIVE_RECORD_TYPE.SUPERVISOR_RECORD.id
        )

    const handleSubmit = (formData) => {
        identificator && !profile
            ? navigate(
                  formatUrl(
                      `${ROUTES.PROJECT}/${identificator}/${ROUTES.DIVE_RECORD}/${ROUTES.GENERAL_INFORMATION}`,
                      {
                          role: findRole(),
                          divingMode: formData.divingMode.id,
                      }
                  )
              )
            : navigate(
                  formatUrl(
                      `/${ROUTES.DIVE_RECORD}/${ROUTES.GENERAL_INFORMATION}`,
                      {
                          role: formData.diveRecordType,
                          divingMode: formData.divingMode.id,
                      }
                  )
              )
    }

    const handleCancel = () => {
        setOpenCreateDiveRecordModal(false)
    }

    if (!data.length) return null
    if (isLoading) return <Loader />

    const initialValues = {
        diveRecordType: null,
        divingMode: null,
    }

    const validation = Yup.object({
        diveRecordType:
            identificator && !profile
                ? Yup.object().nullable()
                : Yup.string().required(t('form.error.required')),
        divingMode: Yup.object().required(t('form.error.required')),
    })

    const findRole = () => {
        const positionKey = Object.keys(DIVING_PERSONNEL_POSITIONS).find(
            (key) =>
                DIVING_PERSONNEL_POSITIONS[key].code ===
                positionOnCurrentProject
        )
        return DIVING_PERSONNEL_POSITIONS[positionKey]?.id
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
            innerRef={formRef}
        >
            {({ isSubmitting, values }) => (
                <Form className="-modalElements">
                    <Modal
                        closeOnClickOutside={false}
                        smallModalWithDropdowns={true}
                        title={
                            identificator && !profile
                                ? 'general.createDiveRecord'
                                : 'general.createHistoricalDiveRecord'
                        }
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: handleCancel,
                            },
                            nextBtn: {
                                label: 'button.continue',
                            },
                        }}
                    >
                        <FocusError />
                        {(!identificator || profile) && (
                            <div className="_12 aligned-start -mb5">
                                <RadioGroupField
                                    name="diveRecordType"
                                    label="form.label.creatingDiveRecordAs"
                                    defaultOptions={ROLE_OPTIONS}
                                    valueType={SELECT_VALUE_TYPE.STRING}
                                    required
                                />
                            </div>
                        )}
                        <div className="_12">
                            <SelectField
                                name="divingMode"
                                label="form.label.divingModeTechnique"
                                placeholder="form.placeholder.divingModeTechnique"
                                defaultOptions={enabledDivingModes(
                                    values.diveRecordType
                                )}
                                required
                            />
                            {isSubmitting && <Loader />}
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

CreateDiveRecordModalForm.propTypes = {
    setOpenCreateDiveRecordModal: PropTypes.func,
    route: PropTypes.string,
    isSupervisorOnCurrentProject: PropTypes.bool,
    profile: PropTypes.bool,
}

CreateDiveRecordModalForm.defaultProps = {
    profile: false,
}

export default CreateDiveRecordModalForm
