import React from 'react'

import usePremiumFeature from 'hooks/usePremiumFeature'

import ICONS from 'constants/icons'
import { BUTTON_TYPE } from 'constants/enums'
import COLORS from 'constants/colors'

import Button from './Button'

const PremiumButton = ({
    feature,
    type,
    icon,
    iconColor,
    tooltip,
    onClick,
    ...props
}) => {
    const { hasFeatureAccess, handlePremiumFeature } = usePremiumFeature()

    const hasAccess = hasFeatureAccess(feature)

    return (
        <Button
            {...props}
            type={hasAccess ? type : BUTTON_TYPE.BUTTON}
            icon={hasAccess ? icon : ICONS.PRO}
            iconColor={hasAccess ? iconColor : COLORS.YELLOW}
            tooltip={hasAccess ? tooltip : 'general.featureForProUsers'}
            onClick={hasAccess ? onClick : handlePremiumFeature}
        />
    )
}

export default PremiumButton
