import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'

import { persistEntityService } from 'services/entity.service'

import {
    positiveNumberAndLessThan100Validation,
    validate24Hours,
    validateMinutes,
} from 'utils/yupValidations'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { InputField } from 'components/formFields'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'
import ENTITIES from '../../../../../constants/entities'

const ChamberEventFormModal = ({ initialData, closeModal, modalData }) => {
    const t = useTranslate()

    const { diveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)

    const {
        data: { diveRecordDiveData, unitImperial },
    } = diveRecord

    const {
        entity,
        fetchData,
        timeStartLabel,
        timeEndLabel,
        holdDeptsTimeLabel,
        cardTitle,
    } = modalData

    const initialValues = {
        chamberInitialDepth: initialData?.chamberInitialDepth ?? '',
        timeStartHours: initialData?.timeStartHours ?? '',
        timeStartMinutes: initialData?.timeStartMinutes ?? '',
        chamberInitialO2: initialData?.chamberInitialO2 ?? '',
        chamberFinalDepth: initialData?.chamberFinalDepth ?? '',
        timeStopHours: initialData?.timeStopHours ?? '',
        timeStopMinutes: initialData?.timeStopMinutes ?? '',
        chamberFinalO2: initialData?.chamberFinalO2 ?? '',
        holdDepthsTime: initialData?.holdDepthsTime ?? '',
        comment: initialData?.comment ?? '',
    }

    const requiredMessage = t('form.error.required')

    const positiveNumberValidation = Yup.number()
        .integer(t('form.error.invalidNumber'))
        .required(requiredMessage)
        .positive(t('form.error.positiveNumber'))

    const minZeroNumberValidation = Yup.number()
        .required(requiredMessage)
        .min(0, t('form.error.minimumNumberIs0'))

    const validation = Yup.object({
        chamberInitialDepth: minZeroNumberValidation,
        timeStartHours: validate24Hours(t).notRequired(),
        timeStartMinutes: validateMinutes(t).notRequired(),
        chamberInitialO2: positiveNumberAndLessThan100Validation(
            t,
            requiredMessage
        ).notRequired(),
        chamberFinalDepth: positiveNumberValidation,
        timeStopHours: validate24Hours(t).notRequired(),
        timeStopMinutes: validateMinutes(t).notRequired(),
        chamberFinalO2: positiveNumberAndLessThan100Validation(
            t,
            requiredMessage
        ).notRequired(),
        holdDepthsTime: Yup.string().trim(),
        comment: Yup.string(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                entity,
                {
                    ...formData,
                    diveRecordDiveData: diveRecordDiveData,
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    const chamberFinalDepthLabel = entity === ENTITIES.CHAMBER_DECOMPRESSION_EVENT
        ? 'form.label.chamberShallowestDepth'
        : 'form.label.chamberFinalDepth'

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={closeModal}
                        closeOnClickOutside={false}
                        title={cardTitle}
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {
                                handleClick: closeModal,
                            },
                            nextBtn: {
                                handleClick: handleSubmit,
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberInitialDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        min={'0'}
                                        required
                                    />
                                </div>

                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <span className="a-mediumText a-lightText">
                                        {t(timeStartLabel)}
                                    </span>
                                    <div className="space-between">
                                        <InputField
                                            name="timeStartHours"
                                            label={timeStartLabel}
                                            placeholder={'form.placeholder.0'}
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                        />
                                        <InputField
                                            name="timeStartMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.0"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                        />
                                    </div>
                                </div>

                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberInitialO2"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units="form.label.%"
                                        min={'0'}
                                    />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberFinalDepth"
                                        label={chamberFinalDepthLabel}
                                        placeholder={chamberFinalDepthLabel}
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        min={'0'}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <span className="a-mediumText a-lightText">
                                        {t(timeEndLabel)}
                                    </span>
                                    <div className="space-between">
                                        <InputField
                                            name="timeStopHours"
                                            label={timeEndLabel}
                                            placeholder={'form.placeholder.0'}
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                        />
                                        <InputField
                                            name="timeStopMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.0"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                        />
                                    </div>
                                </div>

                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberFinalO2"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units="form.label.%"
                                        min={'0'}
                                    />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="holdDepthsTime"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        label={
                                            'form.label.' + holdDeptsTimeLabel
                                        }
                                        placeholder={
                                            'form.placeholder.' +
                                            holdDeptsTimeLabel
                                        }
                                    />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12">
                                    <TextAreaField name="comment" />
                                </div>
                            </div>
                        </div>

                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default ChamberEventFormModal
