import React from 'react'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import SkillsForm from '../SkillsForm'
import { editEntityService } from 'services/entity.service'

const EditSkill = ({ open: id, setOpen, fetchData }) => {
    const { data } = useFetchDataById(ENTITIES.SKILL, id)

    const handleSubmit = async (data) => {
        try {
            await editEntityService(ENTITIES.SKILL, id, data)
            fetchData()
        } catch (error) {
            throw error
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    if (!data) return null

    return (
        <Modal open={id} setOpen={setOpen} title="button.editSkill">
            <SkillsForm
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                initialData={data}
            />
        </Modal>
    )
}

export default EditSkill
