import React, { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import { getChannelUserId, getIsOnline } from './ChatComponent'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'

import IconButton from 'components/IconButton'
import Icon from 'components/Icon'

const ChatAvatar = ({
    image,
    client,
    activeChannel,
    channelUsers,
    currentUser,
    setActiveChannel,
    handleEditChannel,
    createdByMe,
    handleDeleteChannel,
}) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()
    const { setAlert } = useContext(AlertContext)
    const [activeDropdown, setActiveDropdown] = useState(false)

    const userId = getChannelUserId(activeChannel, currentUser)
    const online = getIsOnline(userId, client, channelUsers)

    const handleBackButton = () => {
        setActiveChannel(null)
    }

    const handleLeaveChannel = async () => {
        try {
            await activeChannel.removeMembers([`${currentUser?.id}`])
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            setActiveChannel(null)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleHideChannel = async () => {
        try {
            await activeChannel.hide(null, true)
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            setActiveChannel(null)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const actions = [
        {
            label: 'chat.editChannel',
            iconName: ICONS.EDIT,
            handleClick: handleEditChannel,
            createdByMe: createdByMe,
        },
        {
            label: 'chat.leaveAndDeleteChannel',
            iconName: ICONS.DELETE,
            handleClick: (e) => {
                handleDeleteChannel(e, activeChannel)
            },
            createdByMe: createdByMe,
        },
        {
            label: 'chat.viewDetails',
            iconName: ICONS.INFO_CIRCLE,
            handleClick: handleEditChannel,
            createdByMe: !createdByMe,
        },
        {
            label: 'chat.leaveChannel',
            iconName: ICONS.LOGOUT,
            handleClick: handleLeaveChannel,
            createdByMe: !createdByMe,
        },
    ]

    return (
        <div>
            <div className="chatAvatar">
                {isTablet && (
                    <div className="-backBtn">
                        <IconButton
                            icon={ICONS.ARROW_LEFT}
                            iconColor={COLORS.DARK_BLUE}
                            onClick={handleBackButton}
                        />
                    </div>
                )}
                <div className="-image">
                    {image ? (
                        <img alt="" src={image} />
                    ) : (
                        <span className="-icon">
                            <Icon
                                name={ICONS.USER}
                                color={COLORS.DARK_BLUE_40}
                                size={ICON_SIZE.SIZE20}
                            />
                        </span>
                    )}
                </div>
                {online && <div className="-onlineIcon"></div>}
            </div>

            <div
                className="-actionIcon"
                onClick={(e) => {
                    activeChannel?.type === 'team'
                        ? setActiveDropdown(!activeDropdown)
                        : handleHideChannel()
                }}
            >
                {activeChannel?.type === 'team' ? (
                    <Icon name={ICONS.DOTS} />
                ) : (
                    <Icon name={ICONS.DELETE} />
                )}
            </div>
            <div className={`m-avatar__popup ${activeDropdown ? '-open' : ''}`}>
                {actions
                    .filter((item) => item.createdByMe)
                    .map((item, index) => {
                        return (
                            <span
                                className="m-avatar__item a-mediumText"
                                onClick={item.handleClick}
                                key={index}
                            >
                                <Icon
                                    name={item.iconName}
                                    color={COLORS.LIGHT_BLUE}
                                />
                                {t(`${item.label}`)}
                            </span>
                        )
                    })}
            </div>
        </div>
    )
}

export default ChatAvatar
