import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

const AirDivingSupervisorBadge = ({ size }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.418 12.505a.957.957 0 0 1 .458-.812L25 0v50c-6.294-3.521-13.427-9.437-16.783-16.197C5.25 27.83 5.342 19.585 5.404 14.022c.006-.532.011-1.04.014-1.518ZM44.582 12.505a.957.957 0 0 0-.458-.812L25 0v50c6.294-3.521 13.427-9.437 16.783-16.197 2.966-5.973 2.875-14.218 2.813-19.781-.006-.532-.011-1.04-.014-1.518Z"
                fill={COLORS.PURPLE}
            />
            <g filter="url(#a)" fill="#F3F4F5">
                <path d="M8.49 14.427a.812.812 0 0 1 .38-.687l15.938-9.894v42.308c-5.245-2.98-11.19-7.985-13.986-13.705-2.472-5.054-2.396-12.03-2.345-16.738.005-.45.01-.88.012-1.284ZM41.126 14.427a.812.812 0 0 0-.381-.687L24.808 3.846v42.308c5.244-2.98 11.188-7.985 13.986-13.705 2.47-5.054 2.395-12.03 2.344-16.738-.005-.45-.01-.88-.012-1.284Z" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.953 12.692h1.989v3.42h-1.99v-3.42Zm-2.388.855h1.591v1.71h-1.591v-1.71Zm3.183 3.419a3.979 3.979 0 0 0-3.979 3.979v3.713h7.958v-3.713a3.979 3.979 0 0 0-3.979-3.98Zm-3.979 11.111v3.714a3.979 3.979 0 1 0 7.958 0v-3.714h-7.958Zm8.753-14.53h-2.387v1.71h2.387v-1.71Zm2.786-.855h-1.99v3.42h1.99v-3.42Z"
                fill={COLORS.PURPLE}
            />
            <defs>
                <filter
                    id="a"
                    x="8.461"
                    y="3.846"
                    width="33.077"
                    height="42.692"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx=".385" dy=".385" />
                    <feGaussianBlur stdDeviation=".192" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0" />
                    <feBlend
                        in2="shape"
                        result="effect1_innerShadow_21877_20973"
                    />
                </filter>
            </defs>
        </svg>
    )
}

AirDivingSupervisorBadge.propTypes = {
    size: PropTypes.number,
}

AirDivingSupervisorBadge.defaultProps = {
    size: 50,
}

export default AirDivingSupervisorBadge
