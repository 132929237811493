import { useTranslate } from 'react-polyglot'

import { Fragment, useContext, useState } from 'react'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getEntityFilters } from 'services/localStorage.service'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import FILTERS from 'constants/filters'

import useQueryParams from 'hooks/useQueryParams'
import useWindowDimensions from 'hooks/useWindowDimension'

import Breadcrumbs from 'components/Breadcrumbs'
import { ProjectList } from 'components/projects'
import TabNavigation from 'components/tabs/TabNavigation'
import NotificationList from './components/NotificationList'
import { CompanyList } from '../../../components/companyList'
import CompleteDiverProfile from './components/CompleteDiverProfile'
import CreateDiveRecordModal from 'screens/common/CreateDiveRecordModal'
import MostPopularOrganisations from './components/MostPopularOrganisations'
import { JobList } from 'components/jobList'
import TopJobPics from './components/TopJobPics'
import IdentityVerification from './components/IdentityVerification'
import SupportBanner from 'screens/common/SupportBanner'

const TAB = {
    COMPANIES: 'companies',
    DIVE_PROJECTS: 'diveProjects',
    JOBS: 'jobs',
}

const Homepage = () => {
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    const { tab } = useQueryParams()

    const { currentUser, isVerified } = useContext(CurrentUserContext)
    const [closeVerificationBanner, setCloseVerificationBanner] =
        useState(false)

    if (!currentUser) return null

    const renderTabContent = () => {
        if (!isVerified) return <ProjectList />

        switch (tab) {
            case TAB.JOBS:
                return <JobList />
            case TAB.COMPANIES:
                return <CompanyList />
            case TAB.DIVE_PROJECTS:
            default:
                return <ProjectList />
        }
    }

    return (
        <div>
            <div className="m-boxes">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.homepage',
                                to: ROUTES.HOME,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                            },
                        ]}
                    />
                </div>
                <div className="m-boxes__main -diverHomepage">
                    {currentUser.showUpgradeProfileInfo && (
                        <CompleteDiverProfile />
                    )}

                    {!closeVerificationBanner && (
                        <IdentityVerification
                            setCloseVerificationBanner={
                                setCloseVerificationBanner
                            }
                        />
                    )}

                    {isSmallScreen && (
                        <NotificationList isVerified={isVerified} />
                    )}

                    {isSmallScreen && (
                        <div className="m-boxes__white -mt20 -createDiveRecord column">
                            <span className=" a-bodyTextMedium">
                                {t('general.createDiveRecordForPastProject')}
                            </span>
                            <p className="-mt10 -mb20 a-mediumText a-lightText -opacity-60">
                                {t(
                                    'general.createDiveRecordForPastProjectInfo'
                                )}
                            </p>
                            <CreateDiveRecordModal route={`/${ROUTES.HOME}`} />
                        </div>
                    )}

                    {isSmallScreen && (
                        <div className="m-boxes__white -mt20 -smallerPadding ">
                            <SupportBanner />
                        </div>
                    )}

                    <div
                        className={`m-boxes__white ${
                            isSmallScreen ? '-mt20' : ''
                        }`}
                    >
                        <h3 className="-mb20">{t('general.discover')}</h3>
                        <TabNavigation
                            tabs={[
                                {
                                    key: TAB.DIVE_PROJECTS,
                                    title: 'general.diveProjects',
                                    queryParams: getEntityFilters(
                                        FILTERS.DIVE_PROJECTS
                                    ),
                                },
                                {
                                    key: TAB.COMPANIES,
                                    title: 'general.contractors',
                                    queryParams: getEntityFilters(
                                        FILTERS.COMPANIES
                                    ),
                                    disabled: !isVerified,
                                },
                                {
                                    key: TAB.JOBS,
                                    title: 'general.jobs',
                                    queryParams: getEntityFilters(FILTERS.JOBS),
                                    disabled: !isVerified,
                                },
                            ]}
                            tabQueryParam="tab"
                        />
                        {renderTabContent()}
                    </div>
                </div>
                <div className="m-boxes__side -button">
                    {!isSmallScreen && (
                        <Fragment>
                            <div className="m-boxes__side -textContent column">
                                <span className="a-bodyTextMedium">
                                    {t(
                                        'general.createDiveRecordForPastProject'
                                    )}
                                </span>
                                <p className="-mt10 -mb20 a-mediumText a-lightText -opacity-60">
                                    {t(
                                        'general.createDiveRecordForPastProjectInfo'
                                    )}
                                </p>
                                <CreateDiveRecordModal
                                    route={`/${ROUTES.HOME}`}
                                />
                            </div>
                            <div className="-mt20 m-boxes__side -button">
                                <NotificationList isVerified={isVerified} />
                            </div>
                            <div className="-mt20 m-boxes__side -smallerPadding column">
                                <SupportBanner />
                            </div>
                        </Fragment>
                    )}
                    <div className="m-boxes__side -textContent -mt20 column">
                        <MostPopularOrganisations />
                    </div>
                    <div className="m-boxes__side -textContent -mt20">
                        <TopJobPics />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage
