import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ENTITIES from 'constants/entities'

import useFetchData from 'hooks/useFetchData'

import AddNew from '../../../DiverOnboarding/AddNew'
import DiveEventList from './DiveEventList'
import DiveEventFormModal from './DiveEventFormModal'

const DiveEventWrapper = ({
    diveRecordDiveDataId,
    hideActions,
    unitImperial,
    fetchDiveRecord,
    diveRecordDiveData,
}) => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const [create, setCreate] = useState(false)
    const [editItem, setEditItem] = useState(null)

    const {
        data: diveEventData,
        isLoading,
        fetchData: fetchDiveData,
    } = useFetchData(
        ENTITIES.DIVE_EVENT,
        {
            'diveRecordDiveData.id': diveRecordDiveDataId,
            include:
                'breathingMixture,decoBreathingMixture,decompressionType,decompressionTableType,breathingMixtureGasContents,decoBreathingMixtureGasContents',
        },
        !!diveRecordId,
        false
    )

    const data = diveRecordId ? diveEventData : diveRecordDiveData.diveEvents

    const fetchData = () => {
        fetchDiveData()
        fetchDiveRecord()
    }

    if (hideActions)
        return (
            <DiveEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                hideActions={hideActions}
                unitImperial={unitImperial}
            />
        )

    return (
        <div className="-contentElements">
            <h2 className="-mt35 _12 a-centeredText">
                {t('general.diveEvent')}
            </h2>

            <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                {t('general.pleaseAddDiveEvent')}
            </p>

            <div className="_12">
                <AddNew
                    label="general.addDiveEvent"
                    handleAction={() => setCreate(true)}
                    disabled={hideActions || !!data.length}
                />
            </div>

            <DiveEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                unitImperial={unitImperial}
            />

            {create && (
                <DiveEventFormModal
                    closeModal={() => setCreate(false)}
                    fetchData={fetchData}
                />
            )}

            {!!editItem && (
                <DiveEventFormModal
                    closeModal={() => setEditItem(null)}
                    initialData={editItem}
                    fetchData={fetchData}
                />
            )}
        </div>
    )
}

DiveEventWrapper.propTypes = {
    diveRecordDiveDataId: PropTypes.number.isRequired,
    hideActions: PropTypes.bool,
    unitImperial: PropTypes.bool,
    fetchDiveRecord: PropTypes.func,
}

export default DiveEventWrapper
