import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'

import ChangeAccessAction from './ChangeAccessAction'
import DenyAccessAction from './DenyAccessAction'
import GrantAccessAction from './GrantAccessAction'

const AccessControlActions = ({ item, fetchData, noFiles, hasDataAccess }) => {
    const t = useTranslate()

    const { id, status } = item

    const handleChangeAccess = async (accessStatus) => {
        try {
            if (!hasDataAccess) return

            await editEntityService(ENTITIES.DATA_ACCESS_REQUEST, id, {
                status: accessStatus,
            })
            fetchData()
        } catch (error) {
            console.log('error >> ', error)
        }
    }

    return (
        <div
            className={`-accessControlActions ${
                !hasDataAccess ? '-disabled' : ''
            }`}
            title={!hasDataAccess ? t('general.featureForProUsers') : ''}
        >
            {noFiles ? (
                <GrantAccessAction
                    handleChangeAccess={handleChangeAccess}
                    status={status}
                />
            ) : (
                <ChangeAccessAction
                    handleChangeAccess={handleChangeAccess}
                    item={item}
                    hasDataAccess={hasDataAccess}
                />
            )}
            <DenyAccessAction
                handleChangeAccess={handleChangeAccess}
                status={status}
                noFiles={noFiles}
            />
        </div>
    )
}

AccessControlActions.propTypes = {
    item: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    hasDataAccess: PropTypes.bool,
}

export default AccessControlActions
