import PropTypes from 'prop-types'

import { formatByNumberOfPills, formatTooltipText } from 'utils/cardHelper'

const ListOfStatusesWithTooltip = ({ positions, count, statusColor }) => {
    return (
        <div
            className={`-mt10 listOfStatuses justify-center ${
                formatTooltipText(positions, count) ? '-withTooltip' : ''
            }`}
        >
            {formatByNumberOfPills(positions, count).map((position, index) => {
                return (
                    <span
                        className={`a-status a-captionsTextRegular ${statusColor}`}
                        key={index}
                    >
                        {position.name || position}
                    </span>
                )
            })}

            {formatTooltipText(positions, count)}
        </div>
    )
}

ListOfStatusesWithTooltip.propTypes = {
    positions: PropTypes.array,
    count: PropTypes.number,
    statusColor: PropTypes.string,
}

ListOfStatusesWithTooltip.defaultProps = {
    statusColor: '-gray',
}

export default ListOfStatusesWithTooltip
