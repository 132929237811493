import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

const FocusError = () => {
    const { errors, isSubmitting, isValidating, touched } = useFormikContext()

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            let keys = Object.keys(errors)
            if (keys.length > 0) {
                const selector = `[scroll-attribute=${keys[0]}]`
                const errorElement = document.querySelector(selector)
                if (errorElement) {
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }
        }
    }, [errors, isSubmitting, isValidating, touched])

    return null
}

export default FocusError
