import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ICONS from 'constants/icons'
import { BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import COLORS from 'constants/colors'

import InviteDiverForm from './InviteDiverForm'
import ContractorPositionCard from './ContractorPositionCard'
import NoData from 'components/NoData'
import Pagination from 'components/table/Pagination'
import Button from 'components/Button'

const ContractorPositionList = ({
    projectPositions,
    refetchData,
    isSideCard,
}) => {
    const t = useTranslate()

    const [choosedPosition, setChoosedPosition] = useState(null)

    const { data, meta, isLoading, loadMore, fetchData } = projectPositions

    const handleClose = () => setChoosedPosition(null)

    const handleOpen = (position) => setChoosedPosition(position)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    return (
        <div>
            {projectPositions?.length === 0 && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataResults')}
                    description={t('general.noProjectResultsDesc')}
                />
            )}
            {data?.map((position, index) => {
                return (
                    <div key={index}>
                        <ContractorPositionCard
                            data={position}
                            handleAction={() => handleOpen(position)}
                            isSideCard={isSideCard}
                        />
                    </div>
                )
            })}
            {data?.length === 0 &&
                (isSideCard ? (
                    <span className="a-mediumText a-lightText -opacity-60 justify-center">
                        {t('general.allPositionsAssigned')}
                    </span>
                ) : (
                    <NoData
                        icon={ICONS.NO_DATA}
                        title={t('general.noPositions')}
                        description={t('general.noPositionResultsDesc')}
                    />
                ))}
            {isSideCard ? (
                loadMore && (
                    <div className="justify-center">
                        <Button
                            label="general.loadMore"
                            btnClass={BUTTON_STATUS.TERTIARY}
                            icon={ICONS.PLUS}
                            iconColor={COLORS.LIGHT_BLUE}
                            iconPosition={ICON_POSITION.RIGHT}
                            onClick={handleLoadMore}
                        />
                    </div>
                )
            ) : (
                <Pagination
                    totalItems={meta?.totalItems}
                    meta={meta}
                    scrollToTop
                />
            )}
            {!!choosedPosition && (
                <InviteDiverForm
                    data={data}
                    choosedItem={choosedPosition}
                    setChoosedItem={setChoosedPosition}
                    handleClose={handleClose}
                    refetchData={refetchData}
                    choosedPosition={choosedPosition}
                    setChoosedPosition={setChoosedPosition}
                />
            )}
        </div>
    )
}

ContractorPositionList.propTypes = {
    projectPositions: PropTypes.object,
    refetchData: PropTypes.func,
    isSideCard: PropTypes.bool,
}

ContractorPositionList.defaultProps = {
    isSideCard: false,
}

export default ContractorPositionList
