import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

const ToggleSwitch = ({
    label,
    value,
    disabled,
    showLabel,
    tooltip,
    ...props
}) => {
    const t = useTranslate()

    return (
        <div
            className={`a-toggleSwitch ${disabled ? '-disabled' : ''} ${
                tooltip ? '-withTooltip' : ''
            }`}
        >
            <label htmlFor={props.name}>
                <input
                    {...props}
                    type="checkbox"
                    checked={value}
                    id={props.name}
                    disabled={disabled}
                />
                <span className="a-toggleSwitch__slider"></span>
                {showLabel && (
                    <span className="a-toggleSwitch__label">
                        {t(label || `form.label.${props.name}`)}
                    </span>
                )}
            </label>
            {tooltip && (
                <div className="-tooltip a-captionsTextRegular">
                    <span>{t(tooltip)}</span>
                </div>
            )}
        </div>
    )
}

export const ToggleSwitchMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
    tooltip: PropTypes.string,
}

ToggleSwitch.propTypes = {
    ...ToggleSwitchMainPropTypes,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
}

ToggleSwitch.defaultProps = {
    disabled: false,
    showLabel: true,
}

export default ToggleSwitch
