import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

const ModalTitle = ({ title, noTranslate, smallModal }) => {
    const t = useTranslate()

    return (
        <div className="_w -header">
            <span
                className={`${
                    smallModal ? '_12' : 'ofs_1 _10 ofs_m2 _m8'
                } justify-center centered-text a-bodyTextMedium`}
            >
                {noTranslate ? title : t(title)}
            </span>
        </div>
    )
}

ModalTitle.propTypes = {
    title: PropTypes.string,
    noTranslate: PropTypes.bool,
    smallModal: PropTypes.bool,
}

ModalTitle.defaultProps = {
    noTranslate: false,
    smallModal: false,
}

export default ModalTitle
