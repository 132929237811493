import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { BUTTON_TYPE, ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const IconButton = ({
    type,
    tooltip,
    iconLabel,
    onClick,
    disabled,
    icon,
    iconColor,
    iconSize,
    className,
    translate,
}) => {
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    return (
        <button
            className={`${disabled ? 'Disabled' : ''} ${
                className || 'a-iconBtn'
            }`}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            <div>
                <Icon size={iconSize} name={icon} color={iconColor} />
                {isSmallScreen && iconLabel && <span>{t(iconLabel)}</span>}
            </div>
            {tooltip && (
                <div className="-tooltip a-captionsTextRegular">
                    <p>{translate ? t(tooltip) : tooltip}</p>
                </div>
            )}
        </button>
    )
}

IconButton.propTypes = {
    type: PropTypes.oneOf([BUTTON_TYPE.BUTTON, BUTTON_TYPE.SUBMIT]),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    iconColor: PropTypes.string,
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconSize: PropTypes.number,
    tooltip: PropTypes.string,
    iconLabel: PropTypes.string,
    translate: PropTypes.bool,
}

IconButton.defaultProps = {
    type: BUTTON_TYPE.BUTTON,
    disabled: false,
    iconSize: ICON_SIZE.SIZE24,
    translate: true,
}

export default IconButton
