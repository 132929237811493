import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const FilterButton = ({ value, filter, handleRemove }) => {
    const t = useTranslate()

    const { icon, unit, translateUnit, displayAttribute, formatter } = filter

    const getFilterValue = () => {
        if (formatter) return formatter(value, filter, t)
        if (displayAttribute) return value[displayAttribute]
        return value
    }

    const getUnit = () => {
        if (!unit) return ''
        return translateUnit ? t(unit) : unit
    }

    return (
        <div className="-filter a-captionsTextRegular">
            {icon && (
                <Icon
                    name={icon}
                    size={ICON_SIZE.SIZE16}
                    iconColor={COLORS.DARK_BLUE_60}
                />
            )}
            {getFilterValue()}
            {getUnit()}
            <span className="removeItemIcon" onClick={handleRemove}></span>
        </div>
    )
}

FilterButton.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filter: PropTypes.object,
    handleRemove: PropTypes.func,
}

FilterButton.defaultProps = {
    filter: {},
}

export default FilterButton
