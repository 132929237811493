import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import { refetchProfile } from '../../utils/fetchHelper'

import ENTITIES from 'constants/entities'

import { PersonalEquipmentForm } from './..'

const EditPersonalEquipment = ({
    data,
    open,
    setOpen,
    fetchData,
    refetchData,
    filteredData,
}) => {
    const handleSubmit = async (newData) => {
        await editEntityService(
            ENTITIES.PERSONAL_EQUIPMENT,
            data.id,
            newData,
            true
        )
        fetchData()

        const isNonRetiredEquipment = !newData?.removedFromService

        refetchData &&
            refetchProfile(
                isNonRetiredEquipment,
                filteredData,
                refetchData,
                true,
                false
            )
    }

    return (
        <PersonalEquipmentForm
            title="general.editPersonalEquipment"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
        />
    )
}

EditPersonalEquipment.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
}

export default EditPersonalEquipment
