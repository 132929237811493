import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import { ALERT_TYPES, BUTTON_STATUS, ICON_SIZE } from 'constants/enums'
import { CHANNEL_TYPE_DM } from 'constants/chat'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Input from 'components/Input'
import ChatUserSearch from 'components/chat/ChatUserSearch'
import IconButton from 'components/IconButton'
import Button from 'components/Button'
import NoData from 'components/NoData'

const AddDirectMessage = ({ onCreateChannel, onCancelCreateChannel }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()
    const { setAlert } = useContext(AlertContext)
    const { chatClient: client, currentUser } = useContext(CurrentUserContext)

    const [searchingUsers, setSearchingUsers] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [loadSearchedUsers, setLoadSearchedUsers] = useState(false)

    const handleSearch = async ({ target: { value } }) => {
        setLoadSearchedUsers(true)
        setSearchValue(value)
        if (!value) {
            setSearchingUsers([])
            return
        }
        const { users } = await client.queryUsers({
            name: { $autocomplete: value },
        })
        setSearchingUsers(users)
        setLoadSearchedUsers(false)
    }

    const handleClickUser = async (user) => {
        if (user.id === currentUser.id.toString()) {
            setAlert(t('message.selfMessagingError'), ALERT_TYPES.WARNING)
            return
        }

        const newChannel = await client.channel('messaging', {
            members: [currentUser.id.toString(), user.id.toString()],
            channelType: CHANNEL_TYPE_DM,
        })

        await newChannel.watch()
        onCreateChannel(newChannel)
    }

    const cancelCreateChannel = () => {
        setSearchingUsers([])
        setSearchValue('')
        onCancelCreateChannel()
    }

    return (
        <Fragment>
            <div className="str-chat__channel-header -channels aligned-center">
                {isTablet && (
                    <div className="-backBtn">
                        <IconButton
                            icon={ICONS.ARROW_LEFT}
                            iconColor={COLORS.DARK_BLUE}
                            onClick={cancelCreateChannel}
                        />
                    </div>
                )}
                <span className="a-bodyTextMedium">{t('chat.startChat')}</span>
            </div>
            <div className={'add-dm-wrapper -edit'}>
                <div className="m-searchField -withLabel">
                    <Input
                        name="searchUsers"
                        placeholder="form.placeholder.searchUsers"
                        onChange={handleSearch}
                        required={true}
                        icon={ICONS.SEARCH}
                        iconColor={COLORS.DARK_BLUE_40}
                        size={ICON_SIZE.SIZE16}
                        value={searchValue}
                    />
                    {searchingUsers.length !== 0 && (
                        <div className={'channel-users-search'}>
                            <ul>
                                {searchingUsers.map((user) => (
                                    <ChatUserSearch
                                        key={user.id}
                                        user={{ ...user, online: false }}
                                        handleClick={handleClickUser}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                {!loadSearchedUsers &&
                    searchingUsers.length === 0 &&
                    searchValue && (
                        <NoData
                            icon={ICONS.SEARCH_ICON}
                            title={t('general.noResults')}
                            description={t('general.noSearchResultsDesc')}
                            isSmallWindow
                        />
                    )}
                <div className={'create-channel-btn-wrapper'}>
                    <Button
                        label="chat.cancel"
                        onClick={cancelCreateChannel}
                        btnClass={BUTTON_STATUS.SECONDARY}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default AddDirectMessage
