import * as Yup from 'yup'
import { GAS_CONTENTS } from '../screens/diver/diveRecord/constants/gasContents'

const validateGteLteValues = (t, message, path) =>
    Yup.object().shape({
        gte: Yup.number().min(0, t('form.error.invalidNumber')),
        lte: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .test('lte', (value, { parent, createError }) => {
                if (value < parent.gte) {
                    return createError({
                        message: t(message),
                        path,
                    })
                }
                return true
            }),
    })

const validateHoursAndMinutes = (t, minutes) =>
    Yup.number().when(minutes, {
        is: (val) => {
            return val === 0 || typeof val == 'undefined'
        },
        then: () =>
            Yup.number()
                .positive(t('form.error.enterHoursOrMinutes'))
                .required(t('form.error.required')),
        otherwise: () => Yup.number().nullable(),
    })

const validate24Hours = (t) =>
    Yup.number()
        .integer(t('form.error.invalidNumber'))
        .min(0, t('form.error.invalidNumber'))
        .max(23, t('form.error.maxHoursIs23'))
        .required(t('form.error.required'))

const validate24HoursNotRequired = (t) =>
    Yup.number()
        .integer(t('form.error.invalidNumber'))
        .min(0, t('form.error.invalidNumber'))
        .max(23, t('form.error.maxHoursIs23'))

const validateHours = (t) =>
    Yup.number()
        .min(0, t('form.error.invalidNumber'))
        .required(t('form.error.required'))

const validateMinutes = (t) =>
    Yup.number()
        .integer(t('form.error.invalidNumber'))
        .min(0, t('form.error.minutesError'))
        .max(59, t('form.error.minutesError'))
        .nullable()
        .required(t('form.error.required'))

const validateMinutesNotRequired = (t) =>
    Yup.number()
        .integer(t('form.error.invalidNumber'))
        .min(0, t('form.error.minutesError'))
        .max(59, t('form.error.minutesError'))
        .nullable()

const validateOxygen = (key, t, requiredMessage) =>
    Yup.number().when(key, {
        is: (val) =>
            val?.code === GAS_CONTENTS.NITROX ||
            val?.code === GAS_CONTENTS.HELIOX ||
            val?.code === GAS_CONTENTS.TRIMIX,
        then: () =>
            Yup.number()
                .test(key, (value, { parent, createError }) => {
                    if (parent[key].code === GAS_CONTENTS.NITROX) {
                        const { oxygen, nitrogen } = parent
                        const sum = oxygen + nitrogen
                        return checkSum(sum, 'nitrogen', createError)
                    } else if (parent[key].code === GAS_CONTENTS.HELIOX) {
                        const { oxygen, helium } = parent
                        const sum = oxygen + helium
                        return checkSum(sum, 'helium', createError)
                    } else if (parent[key].code === GAS_CONTENTS.TRIMIX) {
                        const { oxygen, helium, nitrogen } = parent
                        const sum = oxygen + helium + nitrogen
                        return checkSum(sum, 'helium', createError)
                    }
                })
                .min(1, t('form.error.invalidNumber'))
                .required(requiredMessage),

        otherwise: () => Yup.number(),
    })

const validateHelium = (key, t, requiredMessage) =>
    Yup.number().when(key, {
        is: (val) =>
            val?.code === GAS_CONTENTS.HELIOX ||
            val?.code === GAS_CONTENTS.TRIMIX,
        then: () =>
            Yup.number()
                .min(1, t('form.error.invalidNumber'))
                .required(requiredMessage),
        otherwise: () => Yup.number(),
    })

const validateNitrogen = (key, t, requiredMessage) =>
    Yup.number().when(key, {
        is: (val) =>
            val?.code === GAS_CONTENTS.NITROX ||
            val?.code === GAS_CONTENTS.TRIMIX,
        then: () =>
            Yup.number()
                .min(1, t('form.error.invalidNumber'))
                .required(requiredMessage),
        otherwise: () => Yup.number(),
    })

const checkSum = (sum, path, createError) => {
    if (sum > 100) {
        return createError({
            path: path,
            message: 'Sum of fields must not exceed 100',
        })
    }
    return true
}

const positiveNumberAndLessThan100Validation = (t, requiredMessage) =>
    Yup.number()
        .max(100, t('form.error.maximumIs100'))
        .required(requiredMessage)
        .positive(t('form.error.positiveNumber'))

const validateBreathingMixtureOxygen = (key, t, requiredMessage) =>
    Yup.number().when(key, {
        is: (val) =>
            val?.code === GAS_CONTENTS.NITROX ||
            val?.code === GAS_CONTENTS.HELIOX ||
            val?.code === GAS_CONTENTS.TRIMIX,
        then: () =>
            Yup.number()
                .test(key, (value, { parent, createError }) => {
                    if (parent[key].code === GAS_CONTENTS.NITROX) {
                        const {
                            breathingMixtureOxygen,
                            breathingMixtureNitrogen,
                        } = parent
                        const sum =
                            breathingMixtureOxygen + breathingMixtureNitrogen
                        return checkSum(
                            sum,
                            'breathingMixtureNitrogen',
                            createError
                        )
                    } else if (parent[key].code === GAS_CONTENTS.HELIOX) {
                        const {
                            breathingMixtureOxygen,
                            breathingMixtureHelium,
                        } = parent
                        const sum =
                            breathingMixtureOxygen + breathingMixtureHelium
                        return checkSum(
                            sum,
                            'breathingMixtureHelium',
                            createError
                        )
                    } else if (parent[key].code === GAS_CONTENTS.TRIMIX) {
                        const {
                            breathingMixtureOxygen,
                            breathingMixtureHelium,
                            breathingMixtureNitrogen,
                        } = parent
                        const sum =
                            breathingMixtureOxygen +
                            breathingMixtureHelium +
                            breathingMixtureNitrogen
                        return checkSum(
                            sum,
                            'breathingMixtureHelium',
                            createError
                        )
                    }
                })
                .min(1, t('form.error.invalidNumber'))
                .required(requiredMessage),

        otherwise: () => Yup.number(),
    })

export {
    validateGteLteValues,
    validateHoursAndMinutes,
    validateHours,
    validateMinutes,
    validateMinutesNotRequired,
    validateOxygen,
    validateHelium,
    validateNitrogen,
    checkSum,
    validate24Hours,
    validate24HoursNotRequired,
    positiveNumberAndLessThan100Validation,
    validateBreathingMixtureOxygen,
}
