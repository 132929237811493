import React from 'react'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import CertificateForm from './CertificateForm'

const CreateCertificate = ({ open, setOpen, fetchData }) => {
    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.TRAINING_CERTIFICATE, formData, true)
        fetchData()
    }
    return (
        <CertificateForm
            title="general.addCertificate"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

CreateCertificate.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
}

export default CreateCertificate
