import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { FeatureAccessContext } from 'contexts/FeatureAccessContext'

import {
    getEntityFilters,
    setEntityFilters,
} from 'services/localStorage.service'

import useQueryParams from 'hooks/useQueryParams'
import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchData from 'hooks/useFetchData'

import { changeUserArray } from 'utils/changeUserArray'

import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import { ProjectList } from 'components/projects'
import { DiverList } from 'components/diverList'
import TabNavigation from 'components/tabs/TabNavigation'
import ContractorHomepageStatistics from 'components/contractorHomepageStatistics/ContractorHomepageStatistics'
import FavoriteUsersList from 'components/favoriteUsersList/FavoriteUsersList'
import Breadcrumbs from 'components/Breadcrumbs'
import Loader from 'components/Loader'
import CompleteProfile from 'components/CompleteProfile'
import Button from '../../components/Button'
import { BUTTON_SIZE } from 'constants/enums'
import SupportBanner from 'screens/common/SupportBanner'
import { SUPPORT } from 'constants/constants'

const TAB = {
    DIVERS: 'divers',
    DIVE_PROJECTS: 'diveProjects',
}

const Homepage = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const searchParams = useLocation().search
    const { isSmallScreen } = useWindowDimensions()

    const { tab } = useQueryParams()

    const { currentUser, currentCompany, isVerified, isLoadingCurrentCompany } =
        useContext(CurrentUserContext)

    const { canCreateProject } = useContext(FeatureAccessContext)

    const [divers, setDivers] = useState([])

    useEffect(() => {
        setEntityFilters(FILTERS.CONTRACTOR_HOMEPAGE, searchParams)
    }, [searchParams])

    const {
        data: favorites,
        meta,
        isLoading: isLoadingFavorites,
        fetchData: fetchFavorites,
        loadMore,
    } = useFetchData(
        ENTITIES.DIVERS,
        {
            favoriteUser: true,
            itemsPerPage: 5,
        },
        isVerified,
        true,
        isVerified
    )

    if (!currentCompany) return null

    const renderTabContent = () => {
        if (!isVerified)
            return <DiverList divers={divers} setDivers={setDivers} />

        switch (tab) {
            case TAB.DIVE_PROJECTS:
                return <ProjectList />
            case TAB.DIVERS:
            default:
                return (
                    <DiverList
                        fetchFavorites={fetchFavorites}
                        divers={divers}
                        setDivers={setDivers}
                        changeUserArray={changeUserArray}
                    />
                )
        }
    }

    if (isLoadingCurrentCompany) return <Loader />

    return (
        <div className="m-boxes">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'general.homepage',
                            to: ROUTES.HOME,
                            icon: ICONS.HOMEPAGE_BREADCRUMB,
                        },
                    ]}
                />
            </div>

            <div className="m-boxes__main -diverHomepage -mb20">
                {isVerified ? (
                    currentUser.showUpgradeProfileInfo && (
                        <CompleteProfile
                            name={currentCompany.name}
                            description="general.divingOrgWelcomeDescription"
                            entityId={currentCompany.id}
                            stepsToComplete={[
                                {
                                    key: 'companyInfo',
                                    message: 'general.addMoreToCompanyInfo',
                                    icon: ICONS.OFFICE,
                                    route: ROUTES.DIVING_CONTRACTOR_PROFILE,
                                },
                            ]}
                            currentUser={currentCompany}
                        />
                    )
                ) : (
                    <div className="m-boxes__white -mb20">
                        <div className="column">
                            <span className="a-bodyTextMedium">{`${t(
                                'general.welcome'
                            )}, ${currentCompany.name}!`}</span>
                            <p className="-mt10 a-mediumText a-lightText -opacity-60 -mb15">
                                {t('general.pleaseContactSupportAt')}{' '}
                                <Link
                                    onClick={() =>
                                        (window.location = `mailto:${SUPPORT.EMAIL}`)
                                    }
                                    title={SUPPORT.EMAIL}
                                >
                                    {SUPPORT.EMAIL}
                                </Link>{' '}
                                {t('general.toVerifyYourCompanyProfile')}
                            </p>
                        </div>
                    </div>
                )}

                <div className="m-boxes__white">
                    <h3 className="-mb20">{t('general.discover')}</h3>
                    <TabNavigation
                        tabs={[
                            {
                                key: TAB.DIVERS,
                                title: 'general.individuals',
                                queryParams: getEntityFilters(FILTERS.DIVERS),
                            },
                            {
                                key: TAB.DIVE_PROJECTS,
                                title: 'general.diveProjects',
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                                disabled: !isVerified,
                            },
                        ]}
                        tabQueryParam="tab"
                    />
                    {renderTabContent()}
                </div>
            </div>

            <div
                className={`m-boxes__side -button ${
                    isSmallScreen ? '-mt0' : ''
                }`}
            >
                {canCreateProject && (
                    <div
                        className={`m-boxes__side ${
                            isSmallScreen ? '-mt0' : ''
                        } -mb20`}
                    >
                        <h6 className="a-bodyTextMedium -mb20">
                            {t('general.createProjectDescription')}
                        </h6>
                        <div
                            className={
                                !isVerified
                                    ? `a-blurredContainer__withoutBackground`
                                    : ''
                            }
                        >
                            <Button
                                label={'general.createProject'}
                                onClick={() =>
                                    isVerified
                                        ? navigate(
                                              `${ROUTES.PROJECT}${ROUTES.CREATE}`
                                          )
                                        : undefined
                                }
                                buttonSize={BUTTON_SIZE.MEDIUM}
                            />
                        </div>
                    </div>
                )}

                <div
                    className={`${
                        isSmallScreen ? '' : '-mt20'
                    } m-boxes__side -smallerPadding column`}
                >
                    <SupportBanner />
                </div>

                <div className="m-boxes__side -textContent -mt20">
                    <ContractorHomepageStatistics />
                </div>

                <div className="-mt20 m-boxes__side -textContent">
                    <FavoriteUsersList
                        favoriteDivers={favorites}
                        isLoadingFavorites={isLoadingFavorites}
                        fetchFavorites={fetchFavorites}
                        loadMore={loadMore}
                        setDivers={setDivers}
                        divers={divers}
                    />
                </div>
            </div>
        </div>
    )
}

export default Homepage
