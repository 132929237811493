import React, { useState } from 'react'

import { getUserToken } from 'services/localStorage.service'

import { ROLES } from 'constants/enums'

import VerifyAccount from './VerifyAccount'
import UserSignUp from './UserSignUp'
import SignUpAs from './SignUpAs'
import DiverOnboarding from './diver/DiverOnboarding'
import DiveOrganizationOnboarding from './diveOrganization/DiveOrganizationOnboarding'

const Register = () => {
    const [userData, setUserData] = useState({})
    const [verifyAccount, setVerifyAccount] = useState(false)
    const [showOnboarding, setShowOnboarding] = useState(false)
    const [token, setToken] = useState(getUserToken())

    const renderComponent = () => {
        if (showOnboarding) {
            return userData.role.id === ROLES.ROLE_DIVER.id ? (
                <DiverOnboarding
                    role={userData.role}
                    setShowOnboarding={setShowOnboarding}
                />
            ) : (
                <DiveOrganizationOnboarding
                    role={userData.role}
                    setShowOnboarding={setShowOnboarding}
                />
            )
        }
        if (token) {
            return (
                <SignUpAs
                    userData={userData}
                    setUserData={setUserData}
                    setShowOnboarding={setShowOnboarding}
                />
            )
        }
        if (verifyAccount) {
            return (
                <VerifyAccount
                    userData={userData}
                    setToken={setToken}
                    setVerifyAccount={setVerifyAccount}
                />
            )
        }
        return (
            <UserSignUp
                userData={userData}
                setUserData={setUserData}
                setVerifyAccount={setVerifyAccount}
            />
        )
    }

    return <div className="m-form -register">{renderComponent()}</div>
}

export default Register
