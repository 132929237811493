import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { BASE_URL } from 'utils/axiosClient'
import { BUTTON_SIZE, BUTTON_STATUS, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { SUPPORT } from 'constants/constants'

import Icon from 'components/Icon'
import CompanyInfoModal from '../modals/CompanyInfoModal'
import Button from 'components/Button'
import TransparentImagePreview from 'components/TransparentImagePreview'

const CompanyInfo = ({ myProfile, company, isVerified }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const childRef = useRef(null)

    const [openProfilePhoto, setOpenProfilePhoto] = useState(false)
    const [open, setOpen] = useState(location.state?.companyInfo || false)

    const {
        name,
        fullPhoneNumber,
        website,
        logoPath,
        founded,
        thumbnails,
        industries,
    } = company

    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const completeYourProfileDisplay = useMemo(() => {
        return !founded || !fullPhoneNumber || !website || !logoPath
    }, [founded, fullPhoneNumber, website, logoPath])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleEdit = () => {
        setOpen(true)
    }

    const handleOpenProfilePhoto = () => {
        setOpenProfilePhoto(true)
    }

    const handleClickOutside = (event) => {
        if (
            childRef?.current &&
            !childRef.current.contains(event.target) &&
            setOpenProfilePhoto
        ) {
            setOpenProfilePhoto(false)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setOpenProfilePhoto(false)
        }
    }

    // NOTE:: for later (design needs to be changed)
    // const handleShare = () => {
    //     console.log('Share')
    // }

    return (
        <div className="a-userInfo">
            <div className="a-userInfo__header space-between">
                {/* <IconButton onClick={handleShare} icon={ICONS.SHARE} /> */}

                {myProfile && (
                    <div className="-editProfileButton" onClick={handleEdit}>
                        <Icon
                            name={ICONS.EDIT}
                            color={COLORS.DARK_BLUE_40}
                            size={ICON_SIZE.SIZE20}
                        />
                    </div>
                )}
            </div>

            <div className="a-userInfo__mainInfo">
                <div className="aligned-center column -profileImgAndText -gap15">
                    <div
                        className={`a-userInfo__mainInfo--${
                            thumbnails?.md ? 'image' : 'placeholder'
                        }`}
                        style={{
                            backgroundImage: `url("${
                                thumbnails?.md ? BASE_URL + thumbnails?.md : ''
                            }")`,
                        }}
                        onClick={() => handleOpenProfilePhoto()}
                    >
                        {!logoPath && (
                            <div className="-placeholderImage">
                                <Icon
                                    name={ICONS.USER}
                                    color={COLORS.WHITE}
                                    size={ICON_SIZE.SIZE80}
                                />
                            </div>
                        )}
                    </div>
                    <div className="a-userInfo__mainInfo--info">
                        <span className="-name a-bodyTextMedium">{name}</span>
                    </div>
                </div>

                {myProfile && completeYourProfileDisplay && isVerified && (
                    <div className="-mt10 a-separator -top -pt15">
                        <h3 className="a-bodyTextMedium -mb10">
                            {t('general.incompleteProfile')}
                        </h3>
                        <Button
                            label="button.completeYourProfile"
                            buttonSize={BUTTON_SIZE.MEDIUM}
                            btnClass={BUTTON_STATUS.SECONDARY}
                            onClick={handleEdit}
                        />
                    </div>
                )}
                {myProfile && !isVerified && (
                    <div className="-mt10 a-separator a-centeredText -top -pt15">
                        <p className="-mt10 a-mediumText a-lightText -opacity-60 -mb15">
                            {t('general.pleaseContactSupportAt')}{' '}
                            <Link
                                onClick={() =>
                                    (window.location = `mailto:${SUPPORT.EMAIL}`)
                                }
                                title={SUPPORT.EMAIL}
                            >
                                {SUPPORT.EMAIL}
                            </Link>{' '}
                            {t('general.toVerifyYourCompanyProfile')}
                        </p>
                    </div>
                )}
            </div>

            {open && <CompanyInfoModal open={open} setOpen={setOpen} />}

            {thumbnails?.md && (
                <TransparentImagePreview
                    openImage={openProfilePhoto}
                    setOpenImage={setOpenProfilePhoto}
                    imageUrl={`url("${logoPath ? BASE_URL + logoPath : ''}")`}
                    childRef={childRef}
                />
            )}
        </div>
    )
}

CompanyInfo.propTypes = {
    myProfile: PropTypes.bool,
    company: PropTypes.object,
}

export default CompanyInfo
