import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'

import { persistEntityService } from 'services/entity.service'
import { MOMENT_FORMATS } from 'services/moment.service'
import {
    shouldShowHelium,
    shouldShowNitrogen,
    shouldShowOxygen,
} from 'utils/diveRecordFunctions'
import { convertTimeFormat } from 'utils/timeFormatter'
import {
    validate24Hours,
    validate24HoursNotRequired,
    validateHelium,
    validateMinutes,
    validateMinutesNotRequired,
    validateNitrogen,
    validateOxygen,
} from 'utils/yupValidations'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { DateTimeField, InputField, SelectField } from 'components/formFields'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'
import SurfaceDecoEventFormContext from '../../formContext/SurfaceDecoEventFormContext'

const SurfaceDecoEventFormModal = ({
    initialData,
    fetchData,
    closeModal,
    isChamberPressurizationOnly,
    messages,
}) => {
    const t = useTranslate()

    const { diveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)
    const { currentUser } = useContext(CurrentUserContext)

    const isImperialUnit =
        diveRecord.data?.unitImperial ?? currentUser.userSetting.unitImperial

    const unitOfMeasurementDepth = isImperialUnit
        ? 'form.label.ft'
        : 'form.label.m'

    const {
        data: { diveRecordDiveData },
    } = diveRecord

    const initialValues = {
        surfaceInterval: initialData?.surfaceInterval ?? '',
        timeReachedDepth: initialData?.timeReachedDepth ?? '',
        decoDepthInChamber: initialData?.decoDepthInChamber ?? '',
        totalTimeOnOxygenHours: initialData?.totalTimeOnOxygenHours ?? '',
        totalTimeOnOxygenMinutes: initialData?.totalTimeOnOxygenMinutes ?? 0,
        timeReachedSurface: initialData?.timeReachedSurface ?? '',
        timeInChamberHours: initialData?.timeInChamberHours ?? '',
        timeInChamberMinutes: initialData?.timeInChamberMinutes ?? 0,
        comment: initialData?.comment ?? '',
        gasContents: initialData?.gasContents ?? null,
        oxygen: initialData?.oxygen ?? '',
        helium: initialData?.helium ?? '',
        nitrogen: initialData?.nitrogen ?? '',
    }

    const requiredMessage = t('form.error.required')
    const positiveNumberValidation = Yup.number()
        .required(requiredMessage)
        .positive(t('form.error.positiveNumber'))

    const validation = Yup.object({
        surfaceInterval: isChamberPressurizationOnly
            ? Yup.number().notRequired()
            : positiveNumberValidation,
        totalTimeOnOxygenHours: validate24HoursNotRequired(t),
        totalTimeOnOxygenMinutes: validateMinutesNotRequired(t),
        timeInChamberHours: validate24Hours(t),
        timeInChamberMinutes: validateMinutes(t),
        gasContents: Yup.object().nullable(),
        decoDepthInChamber: Yup.number(),
        oxygen: validateOxygen('gasContents', t, requiredMessage),
        helium: validateHelium('gasContents', t, requiredMessage),
        nitrogen: validateNitrogen('gasContents', t, requiredMessage),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                ENTITIES.SURFACE_DECOMPRESSION_EVENT,
                {
                    ...formData,
                    diveRecordDiveData: diveRecordDiveData,
                    timeReachedDepth:
                        formData.timeReachedDepth &&
                        convertTimeFormat(formData.timeReachedDepth),
                    timeReachedSurface:
                        formData.timeReachedSurface &&
                        convertTimeFormat(formData.timeReachedSurface),
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, values }) => {
                const { gasContents } = values
                return (
                    <Form>
                        <FocusError />
                        <Modal
                            open={true}
                            setOpen={closeModal}
                            closeOnClickOutside={false}
                            title={messages.title}
                            noTranslateTitle
                            isSubmitting={isSubmitting}
                            buttons={{
                                prevBtn: {
                                    handleClick: closeModal,
                                },
                                nextBtn: {
                                    handleClick: handleSubmit,
                                },
                            }}
                        >
                            <SurfaceDecoEventFormContext />

                            <div className="_wr">
                                {!isChamberPressurizationOnly && (
                                    <div className="_w">
                                        <div className="_12 _l4">
                                            <InputField
                                                name="surfaceInterval"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="_w">
                                    <div className="_12 _m6 _l4">
                                        <SelectField
                                            name="gasContents"
                                            entityType={ENTITIES.GAS_CONTENTS}
                                            searchable
                                        />
                                    </div>

                                    {gasContents &&
                                        shouldShowOxygen(gasContents) && (
                                            <div className="_12 _m2 ">
                                                <InputField
                                                    name="oxygen"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                    {gasContents &&
                                        shouldShowNitrogen(gasContents) && (
                                            <div className="_12 _m2 ">
                                                <InputField
                                                    name="nitrogen"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                    {gasContents &&
                                        shouldShowHelium(gasContents) && (
                                            <div className="_12 _m2 ">
                                                <InputField
                                                    name="helium"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        <DateTimeField
                                            name="timeReachedDepth"
                                            label="form.label.timeReachedDepthHours"
                                            dateFormat="HH:mm"
                                            valueFormat={MOMENT_FORMATS.TIME}
                                            timePicker={true}
                                        />
                                    </div>

                                    <div className="_12 _l4">
                                        <InputField
                                            name="decoDepthInChamber"
                                            placeholder="form.placeholder.zero"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementDepth}
                                            min={'0'}
                                        />
                                    </div>

                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t(
                                                'form.label.totalTimeOnOxygenHours'
                                            )}
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="totalTimeOnOxygenHours"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                            />

                                            <InputField
                                                name="totalTimeOnOxygenMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        <DateTimeField
                                            name="timeReachedSurface"
                                            label="form.label.timeReachedSurfaceHours"
                                            dateFormat="HH:mm"
                                            valueFormat={MOMENT_FORMATS.TIME}
                                            timePicker={true}
                                        />
                                    </div>

                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t('form.label.timeInChamberHours')}
                                            <span className="redStar"> *</span>
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="timeInChamberHours"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                                required
                                            />
                                            <InputField
                                                name="timeInChamberMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.zero"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12">
                                        <TextAreaField name="comment" />
                                    </div>
                                </div>
                            </div>
                            {isSubmitting && <Loader />}
                        </Modal>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SurfaceDecoEventFormModal
