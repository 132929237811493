import React from 'react'
import PropTypes from 'prop-types'

import { BASE_URL } from 'utils/axiosClient'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const Avatar = ({ firstName, lastName, avatar, icon }) => {
    return (
        <div className="m-avatar__imgInfo">
            <div className="m-avatar__img">
                {avatar ? (
                    <img src={`${BASE_URL}${avatar}`} alt="avatar" />
                ) : (
                    <span className="m-avatar__icon">
                        <Icon
                            name={icon || ICONS.USER}
                            color={COLORS.WHITE}
                            size={ICON_SIZE.SIZE20}
                        />
                    </span>
                )}
            </div>
            <div className="m-avatar__info">
                <span className="m-avatar__name">
                    <span className="-name" title={firstName + ' ' + lastName}>
                        {firstName} {lastName}
                    </span>
                </span>
            </div>
        </div>
    )
}

Avatar.propTypes = {
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    icon: PropTypes.string,
}

export default Avatar
