import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

const ConstructionDiverBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <circle cx="25" cy="25" r="23" fill={COLORS.ORANGE} />
            <g filter="url(#filter0_i_21877_21044)">
                <circle cx="25" cy="24.9999" r="19.4615" fill={COLORS.SILVER} />
            </g>
            <path
                d="M19.5104 20.953L21.1999 19.4402L32.7788 32.3718C33.1965 32.8384 33.157 33.5552 32.6904 33.973C32.2239 34.3907 31.507 34.3512 31.0892 33.8846L19.5104 20.953Z"
                fill={COLORS.ORANGE_30}
            />
            <path
                d="M15.5095 21.1895C15.3796 21.0444 15.3914 20.8216 15.5358 20.691L19.9681 16.6821C20.2747 16.4048 20.6687 16.2436 21.0817 16.2265L24.8256 16.0713C24.9912 16.0645 25.0745 16.2686 24.9514 16.3795L17.6065 22.9993C17.461 23.1304 17.2367 23.1184 17.106 22.9725L15.5095 21.1895Z"
                fill={COLORS.ORANGE}
            />
            <path
                d="M28.0598 20.9342L29.6634 22.5378L22.2621 29.9392L20.6585 28.3355L28.0598 20.9342Z"
                fill={COLORS.ORANGE_30}
            />
            <path
                d="M26.9249 23.0559C27.0952 22.8856 27.3713 22.8856 27.5417 23.0559C27.712 23.2263 27.712 23.5024 27.5417 23.6727L23.4709 27.7435C23.3006 27.9138 23.0245 27.9138 22.8542 27.7435C22.6838 27.5731 22.6838 27.297 22.8541 27.1267L26.9249 23.0559Z"
                fill={COLORS.ORANGE}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.0575 16.6187C32.082 16.6272 32.0883 16.6589 32.0699 16.6772L30.3639 18.3832C29.9494 18.7978 29.9494 19.4699 30.3639 19.8845L30.8364 20.3569C31.2509 20.7715 31.9231 20.7715 32.3376 20.3569L34.0436 18.6509C34.062 18.6326 34.0937 18.6388 34.1022 18.6634C34.5046 19.8253 34.2419 21.1665 33.314 22.0944C32.0195 23.3888 29.9209 23.3888 28.6265 22.0944C27.332 20.8 27.332 18.7013 28.6265 17.4069C29.5544 16.479 30.8955 16.2162 32.0575 16.6187Z"
                fill={COLORS.ORANGE}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4609 34.3048C18.4365 34.2963 18.4302 34.2646 18.4485 34.2463L20.1545 32.5403C20.5691 32.1257 20.5691 31.4536 20.1545 31.0391L19.6821 30.5666C19.2675 30.152 18.5954 30.152 18.1809 30.5666L16.4748 32.2726C16.4565 32.2909 16.4248 32.2847 16.4163 32.2602C16.0138 31.0982 16.2766 29.757 17.2045 28.829C18.4989 27.5346 20.5976 27.5346 21.892 28.829C23.1864 30.1235 23.1864 32.2221 21.892 33.5166C20.9641 34.4445 19.6229 34.7072 18.4609 34.3048Z"
                fill={COLORS.ORANGE}
            />
            <defs>
                <filter
                    id="filter0_i_21877_21044"
                    x="5.53845"
                    y="5.53833"
                    width="39.2769"
                    height="39.2769"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.176923" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21044"
                    />
                </filter>
            </defs>
        </svg>
    )
}

ConstructionDiverBadge.propTypes = {
    size: PropTypes.number,
}

ConstructionDiverBadge.defaultProps = {
    size: 50,
}

export default ConstructionDiverBadge
