import React, { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import {
    resendCodeUserService,
    verificationUserService,
} from 'services/auth.service'
import { getEntityByIdService } from 'services/entity.service'

import { ALERT_TYPES, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import VerificationCode from 'components/VerificationCode'

const VerifyAccount = ({ userData, setToken, setVerifyAccount }) => {
    const t = useTranslate()

    const [code, setCode] = useState(['', '', '', ''])

    const { setAlert } = useContext(AlertContext)
    const { setCurrentUser } = useContext(CurrentUserContext)

    const handleResendCode = async () => {
        try {
            await resendCodeUserService(userData.email)
            setAlert(
                `${t('alerts.messages.emailVerificationCode')} ${t(
                    userData.email
                )}`,
                ALERT_TYPES.SUCCESS
            )
            setCode(['', '', '', ''])
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleChangeEmail = () => {
        setVerifyAccount(false)
    }

    const onSubmit = async () => {
        try {
            const {
                data: { id, token },
            } = await verificationUserService({
                email: userData.email,
                password: userData.password,
                verificationCode: code.join(''),
            })

            if (id) {
                const { data } = await getEntityByIdService(ENTITIES.USER, id, {
                    include:
                        'role,country,diverPositions,countryPhoneCode,userSetting,regions,userStatus,avatar,heroImage',
                })

                setCurrentUser(data)
            }
            setToken(token)
        } catch (error) {
            if (
                error?.response?.data?.transMessage ===
                'server.error.invalidVerificationCode'
            ) {
                setAlert(
                    t('server.error.invalidVerificationCode'),
                    ALERT_TYPES.ERROR
                )
            } else {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        }
    }

    return (
        <div className="-verification">
            <h1 className="-title -mb20">{t('noAuth.verifyAccount')}</h1>
            <span className="a-bodyTextRegular a-lightText">
                {t('noAuth.pleaseEnterConfirmationCode')}
            </span>
            <span className="m-form__text a-bodyTextRegular">
                <span className="a-bodyTextBold a-lightText">
                    {userData.email}{' '}
                    <span
                        onClick={handleChangeEmail}
                        className="a-bodyTextRegular a-blueLink -withLink "
                    >
                        {t('noAuth.changeEmail')}{' '}
                    </span>
                </span>
            </span>
            <div className="-mt50">
                <VerificationCode
                    onSubmit={onSubmit}
                    code={code}
                    setCode={setCode}
                />
            </div>
            <span className="m-form__link a-bodyTextRegular -mt30">
                {t('noAuth.didntReceiveCode')}{' '}
                <span
                    onClick={handleResendCode}
                    className="a-blueLink -withLink "
                >
                    {t('noAuth.resendCode')}
                </span>
            </span>
            <div className="-confirmButton -mt20">
                <Button
                    btnClass={BUTTON_STATUS.PRIMARY}
                    type={BUTTON_TYPE.SUBMIT}
                    label="button.confirm"
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

VerifyAccount.propTypes = {
    userData: PropTypes.object,
    setToken: PropTypes.func.isRequired,
    setVerifyAccount: PropTypes.func.isRequired,
}

export default VerifyAccount
