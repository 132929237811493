import COLORS from 'constants/colors'
import React from 'react'
import PropTypes from 'prop-types'

const SurfaceSuppliedBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                d="M24.3715 0.11713C24.6416 -0.0390432 24.9743 -0.0390434 25.2443 0.11713L46.1023 12.18C46.3723 12.3362 46.5387 12.6248 46.5387 12.9371V37.0629C46.5387 37.3752 46.3723 37.6638 46.1023 37.82L25.2443 49.8829C24.9743 50.039 24.6416 50.039 24.3715 49.8829L3.51354 37.82C3.2435 37.6638 3.07715 37.3752 3.07715 37.0629V12.9371C3.07715 12.6248 3.2435 12.3362 3.51354 12.18L24.3715 0.11713Z"
                fill={COLORS.DARK_BLUE}
            />
            <g filter="url(#filter0_i_21877_20930)">
                <path
                    d="M24.2297 4.18089C24.5873 3.97386 25.0283 3.97386 25.3859 4.18089L42.5013 14.0898C42.8576 14.2961 43.077 14.6766 43.077 15.0884V34.9117C43.077 35.3234 42.8576 35.704 42.5013 35.9103L25.3859 45.8192C25.0283 46.0262 24.5873 46.0262 24.2297 45.8192L7.1143 35.9103C6.75797 35.704 6.53857 35.3234 6.53857 34.9117V15.0884C6.53857 14.6766 6.75797 14.2961 7.1143 14.0898L24.2297 4.18089Z"
                    fill={COLORS.DARK_BLUE_20}
                />
            </g>
            <mask
                id="mask0_21877_20930"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="6"
                y="3"
                width="38"
                height="44"
            >
                <path
                    d="M24.3721 4.09843C24.6416 3.9424 24.974 3.9424 25.2435 4.09843L42.6432 14.1719C42.9117 14.3274 43.077 14.6142 43.077 14.9245V35.0756C43.077 35.3859 42.9117 35.6727 42.6432 35.8282L25.2435 45.9016C24.974 46.0577 24.6416 46.0577 24.3721 45.9016L6.97246 35.8282C6.70392 35.6727 6.53857 35.3859 6.53857 35.0756V14.9245C6.53857 14.6142 6.70392 14.3274 6.97246 14.1719L24.3721 4.09843Z"
                    fill={COLORS.DARK_BLUE_CUSTOM}
                />
            </mask>
            <g mask="url(#mask0_21877_20930)">
                <path
                    opacity="0.15"
                    d="M1.75516 12.7486L-4.86747 15.6233C-5.14141 15.7422 -5.40134 15.891 -5.6425 16.0672L-7.21671 17.2168C-8.20861 17.9411 -8.40081 19.3442 -7.64023 20.3086C-7.04496 21.0634 -6.02436 21.3356 -5.13037 20.9822C-2.61265 19.987 0.825897 18.6623 1.5651 18.5093C2.46901 18.3222 5.99369 17.1343 7.96781 17.1839C15.1953 16.9969 14.8357 20.9514 21.3388 20.111C22.9256 19.906 24.2548 19.413 25.3894 18.7707C30.278 16.0029 34.9184 13.4689 40.4234 14.5892C44.8002 14.8614 49.3923 12.379 49.2676 11.7764C49.1116 11.0231 47.7346 8.16604 47.358 8.244C47.1962 8.27749 44.6614 7.274 41.8324 6.29214C37.6456 4.83906 33.2506 6.00711 29.7708 8.75145C25.5232 12.1013 19.9591 13.2847 14.7159 11.9534L13.9697 11.7639C9.90142 10.731 5.6054 11.0773 1.75516 12.7486Z"
                    fill={COLORS.DARK_BLUE_60}
                />
                <path
                    opacity="0.15"
                    d="M45.7192 30.3659L52.6978 32.2158C52.9865 32.2923 53.2658 32.4005 53.5306 32.5385L55.2593 33.4393C56.3486 34.0068 56.7488 35.3653 56.1414 36.4328C55.6659 37.2682 54.6976 37.6902 53.7608 37.4748C51.1224 36.8682 47.5241 36.0736 46.7703 36.0331C45.8486 35.9835 42.1857 35.3372 40.2413 35.682C33.0674 36.5801 34.0155 40.4361 27.4599 40.5797C25.8603 40.6147 24.4722 40.3265 23.2542 39.8614C18.0061 37.8574 13.0383 36.0473 7.76339 37.9798C3.47676 38.9048 -1.43548 37.1385 -1.40244 36.524C-1.36113 35.7559 -0.427788 32.7247 -0.0437294 32.7454C0.12122 32.7542 2.47706 31.3823 5.12706 29.9876C9.04886 27.9235 13.5692 28.4199 17.421 30.6118C22.1225 33.2873 27.8011 33.6236 32.7856 31.5218L33.4951 31.2226C37.3626 29.5917 41.662 29.2904 45.7192 30.3659Z"
                    fill={COLORS.DARK_BLUE_60}
                />
            </g>
            <path
                d="M16.333 28.8963C16.333 28.1918 16.6301 26.3766 16.7786 26.1075L24.9487 27.4166L33.1187 26.1075C33.2673 26.3766 33.5644 28.1918 33.5644 28.8963C33.5644 29.5041 35.6319 31.2737 36.8184 31.6789C37.0731 31.7659 37.3252 31.992 37.2687 32.2551C37.1795 32.6709 36.9411 32.9416 36.5838 33.2947C36.5838 33.2947 32.9417 37.3583 25.0234 37.409C24.9985 37.409 24.9735 37.4094 24.9487 37.4093C24.9238 37.4094 24.8989 37.4094 24.8739 37.4094C16.9556 37.3588 13.3135 33.2947 13.3135 33.2947C12.9562 32.9416 12.7178 32.6709 12.6286 32.2551C12.5721 31.992 12.8242 31.7659 13.0789 31.6789C14.2654 31.2737 16.333 29.5041 16.333 28.8963Z"
                fill={COLORS.DARK_BLUE_60}
            />
            <path
                d="M15.584 25.3002V21.8343C15.584 21.2945 15.8606 20.7949 16.2956 20.4681C16.6038 20.2366 16.9108 19.9842 17.0695 19.796C17.1299 19.7245 17.204 19.4764 17.3132 19.1109C17.8759 17.2276 19.3706 12.225 24.7201 12.1636C30.0697 12.225 31.5643 17.2276 32.127 19.1109C32.2362 19.4764 32.3103 19.7245 32.3707 19.796C32.5294 19.9842 32.8364 20.2366 33.1447 20.4681C33.5796 20.7949 33.8562 21.2945 33.8562 21.8343V25.3002C33.8562 26.1711 33.0739 26.6126 31.9436 27.2504C31.2642 27.6338 30.459 28.0882 29.6224 28.7495C28.5481 29.5987 28.2676 31.5713 28.3457 33.0559C28.3687 33.4928 28.0351 33.8867 27.5976 33.8867H21.8426C21.4051 33.8867 21.0716 33.4928 21.0946 33.0559C21.1726 31.5713 20.8922 29.5987 19.8178 28.7495C18.9812 28.0882 18.176 27.6338 17.4966 27.2504C16.3663 26.6126 15.584 26.1711 15.584 25.3002Z"
                fill="white"
            />
            <path
                d="M25.4828 18.2816C25.0262 18.2153 24.5623 18.2141 24.1053 18.2781L21.3649 18.6622C20.881 18.73 20.4103 18.8702 19.969 19.078L19.4919 19.3027C17.9213 20.0423 16.9209 21.6083 16.9209 23.3274C16.9209 26.0364 19.346 28.1194 22.0611 27.7424L24.1241 27.456C24.5687 27.3943 25.0199 27.3943 25.4645 27.456L27.5578 27.7466C30.2569 28.1213 32.6677 26.0507 32.6677 23.3576C32.6677 21.6245 31.6453 20.0504 30.0507 19.3284L29.4305 19.0476C29.0158 18.8598 28.5762 18.7312 28.1248 18.6656L25.4828 18.2816Z"
                fill={COLORS.DARK_BLUE_60}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3753 19.0077C24.9891 18.9516 24.5967 18.9506 24.2102 19.0047L21.4698 19.3888C21.0605 19.4462 20.6624 19.5648 20.2892 19.7405L19.8121 19.9652C18.4999 20.583 17.6643 21.8912 17.6643 23.3274C17.6643 25.5905 19.6902 27.3306 21.9584 27.0157L24.0213 26.7293C24.5345 26.658 25.0553 26.658 25.5685 26.7293L27.6617 27.0199C29.9139 27.3325 31.9255 25.6047 31.9255 23.3576C31.9255 21.9115 31.0724 20.5981 29.7419 19.9956L29.1217 19.7148C28.7709 19.556 28.3991 19.4472 28.0173 19.3917L25.3753 19.0077ZM24.0016 17.5515C24.529 17.4776 25.0644 17.479 25.5915 17.5556L28.2335 17.9395C28.7545 18.0153 29.2619 18.1637 29.7406 18.3804L30.3608 18.6613C32.2194 19.5028 33.4111 21.3376 33.4111 23.3576C33.4111 26.4966 30.6011 28.9101 27.455 28.4734L25.3618 28.1828C24.9857 28.1306 24.6041 28.1306 24.228 28.1828L22.1651 28.4692C19.003 28.9082 16.1787 26.4823 16.1787 23.3274C16.1787 21.3253 17.3437 19.5015 19.1729 18.6402L19.6501 18.4155C20.1594 18.1757 20.7026 18.0139 21.2612 17.9356L24.0016 17.5515Z"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M23.9517 10.7693C23.7413 10.7693 23.5768 10.951 23.5865 11.1612C23.6246 11.9862 23.5664 13.2777 23.1129 13.925C22.6475 14.5892 21.8483 15.8573 21.3714 16.6267C21.2468 16.8275 21.1816 17.0591 21.1816 17.2954V18.1931C21.1816 18.3894 21.3408 18.5485 21.537 18.5485H28.1055C28.3017 18.5485 28.4608 18.3894 28.4608 18.1931V17.2954C28.4608 17.0591 28.3956 16.8275 28.2711 16.6267C27.7942 15.8573 26.995 14.5892 26.5296 13.925C26.076 13.2777 26.0178 11.9862 26.0559 11.1612C26.0657 10.951 25.9012 10.7693 25.6908 10.7693H23.9517Z"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M26.8828 31.098C26.8828 32.2324 25.951 33.1579 24.7944 33.1579C23.6377 33.1579 22.7059 32.2324 22.7059 31.098C22.7059 29.9635 23.6377 29.038 24.7944 29.038C25.951 29.038 26.8828 29.9635 26.8828 31.098Z"
                stroke={COLORS.DARK_BLUE}
                strokeWidth="0.576923"
            />
            <path
                d="M25.8061 31.0981C25.8061 31.6469 25.3551 32.0955 24.7944 32.0955C24.2337 32.0955 23.7827 31.6469 23.7827 31.0981C23.7827 30.5492 24.2337 30.1006 24.7944 30.1006C25.3551 30.1006 25.8061 30.5492 25.8061 31.0981Z"
                fill={COLORS.DARK_BLUE}
                stroke={COLORS.DARK_BLUE}
                strokeWidth="0.353543"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8458 24.918C15.874 25.5433 15.9143 26.4363 15.9553 27.8542C15.9855 28.8966 16.513 29.5558 17.2321 29.9773C17.9747 30.4126 18.916 30.5842 19.6694 30.5842V31.6117C18.7887 31.6117 17.6492 31.4164 16.7015 30.8609C15.7302 30.2916 14.9579 29.3363 14.9159 27.8835C14.8766 26.5262 14.8367 25.6298 14.8081 24.9878C14.7834 24.4323 14.7671 24.0673 14.7671 23.7591L15.807 23.759C15.807 24.0583 15.8221 24.3942 15.8458 24.918Z"
                fill={COLORS.DARK_BLUE}
            />
            <rect
                x="14.0981"
                y="21.7042"
                width="2.52543"
                height="2.49522"
                rx="1.24761"
                fill={COLORS.DARK_BLUE}
            />
            <rect
                x="12.4644"
                y="22.3646"
                width="2.22832"
                height="1.32148"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M13.5039 24.126C13.7416 22.8931 13.5039 22.0956 13.3554 21.8509L11.0528 21.5574C10.8299 21.7286 10.3843 22.2619 10.3843 23.0252C10.3843 23.7884 10.8299 24.3217 11.0528 24.4929L13.5039 24.126Z"
                fill={COLORS.DARK_BLUE}
            />
            <rect
                x="19.5952"
                y="30.364"
                width="2.9711"
                height="1.46832"
                fill={COLORS.DARK_BLUE}
            />
            <rect
                x="28.6567"
                y="30.364"
                width="2.67399"
                height="1.46832"
                fill={COLORS.DARK_BLUE}
            />
            <rect
                x="26.8745"
                y="30.8043"
                width="1.93121"
                height="0.587326"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M24.7571 22.8255C24.7695 22.8247 24.7818 22.8243 24.7942 22.8243L24.7943 22.829C25.8855 22.9638 27.0099 26.0143 27.5425 27.5212H21.9717C22.5043 26.0143 23.6286 22.9638 24.7199 22.829L24.7199 22.8243C24.7323 22.8243 24.7447 22.8247 24.7571 22.8255Z"
                fill={COLORS.DARK_BLUE}
            />
            <defs>
                <filter
                    id="filter0_i_21877_20930"
                    x="6.53857"
                    y="4.02563"
                    width="36.9232"
                    height="42.3335"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.384615" dy="0.384615" />
                    <feGaussianBlur stdDeviation="0.192308" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_20930"
                    />
                </filter>
            </defs>
        </svg>
    )
}

SurfaceSuppliedBadge.propTypes = {
    size: PropTypes.number,
}

SurfaceSuppliedBadge.defaultProps = {
    size: 50,
}

export default SurfaceSuppliedBadge
