import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'

import { getEntityService } from 'services/entity.service'

import Loader from 'components/Loader'
import { useTranslate } from 'react-polyglot'

const BottomTimeLineChart = () => {
    const t = useTranslate()

    const [chartData, setChartData] = useState([])

    useEffect(() => {
        getEntityService('analytics/bottom-time-data', {}, false).then((response) => {
            setChartData(response.data);
        })
    }, [])

    if (!chartData) return <Loader />

    const options = {
        chart: {
            title: t("analytics.bottomTimeLineChart.title"),
            subtitle: t("analytics.bottomTimeLineChart.description"),
        },
    };

    return (
        <div className="m-boxes__main -diverHomepage -mb20" style={{ width: '100%' }}>
            <div className="m-boxes__white" style={{ width: '100%', padding: 30 }}>
                {chartData && (
                    <Chart
                        chartType="Line"
                        width="100%"
                        height="400px"
                        data={chartData}
                        options={options}
                        legendToggle
                    />
                )}
            </div>
        </div>
    )
}

export default BottomTimeLineChart
