import React, { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams } from 'react-router-dom'

import { getEntityFilters } from 'services/localStorage.service'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataById from 'hooks/useFetchDataById'
import useFetchData from 'hooks/useFetchData'
import useProjectAccess from 'hooks/useProjectAccess'

import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { PROJECT_WORKER_STATUS } from 'constants/enums'

import Breadcrumbs from 'components/Breadcrumbs'
import Loader from 'components/Loader'
import TabNavigation from 'components/tabs/TabNavigation'
import DiveTeamList from './components/DiveTeamList'
import ContractorPositionList from 'screens/common/singleProject/components/ContractorPositionList'
import { userFilters } from '../../../utils/userFiltersHelper'

const ProjectDiveTeam = () => {
    const t = useTranslate()
    const { id } = useParams()

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        userFilters,
        FILTERS.DIVE_TEAM
    )

    const { data: project, isLoading: isLoadingProject } = useFetchDataById(
        ENTITIES.PROJECT,
        id,
        {
            include:
                'country,company,region,locationType,projectType,divingMode,countryOfMobilization,projectStatus,positions',
        },
        true,
        id
    )

    const { data: tabs, isLoading: isLoadingTabs } = useFetchData(
        ENTITIES.DIVER_POSITION,
        {},
        true,
        false,
        id
    )

    const projectPositions = useFetchData(
        ENTITIES.PROJECT_POSITIONS,
        {
            'project.id': id,
            include: 'diverPosition,project,project.creator',
        },
        true,
        true,
        id
    )

    const projectDiveTeam = useFetchDataByQueryParams(
        ENTITIES.PROJECT_WORKER_ELASTIC,
        {
            project: id,
            status: PROJECT_WORKER_STATUS.EMPLOYED,
            include: 'diverInviteStatus,currentUserFeedback',
            projectPosition:
                activeFilters.projectPosition ?? projectPositions.data[0]?.id,
        },
        false,
        !!projectPositions.data.length
    )

    const refetchDiveTeamAndPositions = () => {
        projectDiveTeam.fetchData()
        projectPositions.fetchData()
    }

    const { isProjectOwner, isProjectClient } = useProjectAccess(project)

    useEffect(() => {
        projectDiveTeam.fetchData()
    }, [projectPositions.data])

    const unassignedPositionsList = projectPositions.data.filter(
        (item) => item.numOfOpenPositions > 0
    )

    const unassignedPositions = {
        ...projectPositions,
        data: unassignedPositionsList,
        meta: {
            ...projectPositions.meta,
            totalItems: unassignedPositionsList.length,
        },
    }

    if (!project || !tabs || !projectPositions.data.length) return null

    if (!isProjectOwner && !isProjectClient) {
        return null
    }

    const projectPositionsTabs = projectPositions.data.map((item) => {
        return {
            positionId: item.id,
            diverPositionId: item.diverPosition.id,
            title:
                item.diverPosition.name +
                (item.description ? '(' + item.description + ')' : ''),
        }
    })

    return (
        <div className="_wr">
            <div className="_w">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.homepage',
                                to: ROUTES.HOME,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: 'general.diveProjects',
                                to: ROUTES.HOME,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: project.name,
                                to: `${ROUTES.PROJECT}/${id}`,
                                translate: false,
                            },
                            {
                                label: 'general.teamManagement',
                                to: `${ROUTES.PROJECT}/${id}${ROUTES.DIVE_TEAM}`,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_TEAM
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="m-boxes fullWidth">
                    <div className="m-boxes__main -fullHeight">
                        <h3 className="-mb20">
                            {t('general.projectTeamMembers')}
                        </h3>
                        <TabNavigation
                            tabs={projectPositionsTabs.map((item) => ({
                                key: item.positionId,
                                title: item.title,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_TEAM
                                ),
                            }))}
                            tabQueryParam="projectPosition"
                            translate={false}
                            useAsFilter={true}
                        />
                        <h6 className="-mb20">{t('general.status')}</h6>
                        <TabNavigation
                            tabs={[
                                {
                                    key: PROJECT_WORKER_STATUS.EMPLOYED,
                                    title: 'general.employed',
                                    queryParams: getEntityFilters(
                                        FILTERS.DIVE_TEAM
                                    ),
                                },
                                {
                                    key: PROJECT_WORKER_STATUS.CANDIDATES,
                                    title: 'general.candidates',
                                    queryParams: getEntityFilters(
                                        FILTERS.DIVE_TEAM
                                    ),
                                },
                                {
                                    key: PROJECT_WORKER_STATUS.APPLICANT,
                                    title: 'general.applicants',
                                    queryParams: getEntityFilters(
                                        FILTERS.DIVE_TEAM
                                    ),
                                },
                                {
                                    key: PROJECT_WORKER_STATUS.INVITED,
                                    title: 'general.invited',
                                    queryParams: getEntityFilters(
                                        FILTERS.DIVE_TEAM
                                    ),
                                },
                            ]}
                            tabQueryParam="status"
                            useAsFilter={true}
                        />

                        <DiveTeamList
                            projectDiveTeam={projectDiveTeam}
                            activeFilters={activeFilters}
                            isLoadingFilters={isLoadingFilters}
                            refetchData={refetchDiveTeamAndPositions}
                            positions={projectPositions.data}
                            defaultProjectPosition={
                                projectPositions.data[0]?.id
                            }
                            isProjectOwner={isProjectOwner}
                            isProjectClient={isProjectClient}
                            filters={userFilters}
                        />
                    </div>
                    <div className="m-boxes__side ">
                        <h3 className="-mb10">
                            {t('general.unassignedPositions')}
                        </h3>
                        {unassignedPositions.data.length > 0 && (
                            <span className="a-bodyTextRegular a-lightText">
                                {t('general.youStillHaveUnassignedPositions')}
                            </span>
                        )}
                        <div className="-mt20">
                            <ContractorPositionList
                                projectPositions={unassignedPositions}
                                refetchData={refetchDiveTeamAndPositions}
                                isSideCard={true}
                            />
                        </div>
                    </div>
                    {(isLoadingProject ||
                        isLoadingTabs ||
                        projectDiveTeam.isLoading ||
                        projectPositions.isLoading) && <Loader />}
                </div>
            </div>
        </div>
    )
}

export default ProjectDiveTeam
