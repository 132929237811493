const isEmpty = (obj) => {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false
        }
    }
    return JSON.stringify(obj) === JSON.stringify({})
}

// IMPORTANT: % must be on the first place in array
// because encoded values of other special characters have '%'
// e.g. & -> %26
const specialCharacters = [
    '%',
    '+',
    '&',
    '[',
    ']',
    '#',
    '$',
    ',',
    '/',
    ':',
    ';',
    '=',
    '?',
    '@',
    '^',
    '`',
    '{',
    '}',
    '|',
]

const encodeURLParams = (value) => {
    let encodedValue = value

    specialCharacters.forEach((character) => {
        if (encodedValue.includes(character)) {
            encodedValue = encodedValue.replaceAll(
                character,
                encodeURIComponent(character)
            )
        }
    })

    return encodedValue
}

const formatUrl = (url, urlParams) => {
    if (urlParams) {
        url = url.concat('?')
        Object.keys(urlParams).forEach((key) => {
            if (key === 'sortOrder') {
                if (urlParams[key].sortKey && urlParams[key].sortValue) {
                    const sign = urlParams[key].sortValue < 0 ? '-' : ''
                    url = url.concat(`sort=${sign}${urlParams[key].sortKey}&`)
                }
            } else if (Array.isArray(urlParams[key])) {
                urlParams[key].forEach((value, index) => {
                    if (value.id) {
                        url = url.concat(`${key}.id[]=${value.id}&`)
                    } else if (typeof value === 'object') {
                        Object.keys(value).forEach((itemKey) => {
                            if (!isEmptyAttribute(value[itemKey])) {
                                url = url.concat(
                                    `${key}[${index + 1}][${itemKey}]=${
                                        value[itemKey]?.id || value[itemKey]
                                    }&`
                                )
                            }
                        })
                    } else {
                        url = url.concat(`${key}[]=${value}&`)
                    }
                })
            } else if (
                urlParams[key] &&
                typeof urlParams[key] === 'object' &&
                urlParams[key].id
            ) {
                url = url.concat(`${key}.id=${urlParams[key].id}&`)
            } else if (urlParams[key] && typeof urlParams[key] === 'object') {
                Object.keys(urlParams[key]).forEach((itemKey) => {
                    if (!isEmptyAttribute(urlParams[key][itemKey])) {
                        url = url.concat(
                            `${key}[${itemKey}]=${urlParams[key][itemKey]}&`
                        )
                    }
                })
            } else if (
                urlParams[key] !== null &&
                urlParams[key] !== undefined &&
                urlParams[key] !== ''
            ) {
                url = url.concat(
                    `${key}=${encodeURLParams(urlParams[key].toString())}&`
                )
            }
        })
        url = url.slice(0, -1)
    }
    return url
}

const formatRequestData = (type, formData, notRelationshipKeys = []) => {
    const data = { ...formData }
    let retVal = {
        data: {
            type,
            attributes: {},
            relationships: {},
        },
    }
    if (data && typeof data === 'object') {
        if (data._id) {
            retVal.data['id'] = data._id
        }
        for (const key in data) {
            // Multiple Relationship
            if (
                data[key] &&
                Array.isArray(data[key]) &&
                !notRelationshipKeys.some((item) => item === key)
            ) {
                retVal.data.relationships[key] = {
                    data: [],
                }
                data[key].forEach((item) => {
                    if (item.id && item.entityType) {
                        retVal.data.relationships[key].data.push({
                            id: item.id,
                            type: item.entityType,
                        })
                    }
                })
                delete data[key]
                // Single Relationship
            } else if (
                data[key] &&
                typeof data[key] === 'object' &&
                !notRelationshipKeys.some((item) => item === key)
            ) {
                if (data[key].id && data[key].entityType) {
                    retVal.data.relationships[key] = {
                        data: {},
                    }
                    retVal.data.relationships[key].data.type =
                        data[key].entityType
                    retVal.data.relationships[key].data.id = Number(
                        data[key].id
                    )
                    delete data[key]
                }
                // Base Attributes
            } else {
                retVal.data.attributes[key] =
                    typeof data[key] === 'string' && data[key].trim() === ''
                        ? null
                        : data[key]
            }
        }
    }
    if (isEmpty(retVal.data.relationships)) {
        delete retVal.data.relationships
    }

    const formattedRequest = replaceEmptyStringsWithNull(retVal)
    return formattedRequest
}

function getRelationships(
    relationships,
    included,
    includeEntities = '',
    parentKey = null
) {
    let relationAttributes = {}
    Object.keys(relationships).forEach((key) => {
        // Find the value for relationship key only if we specified it inside 'include'.
        // parentKey is used for the 'include from include' and the recursive call of the getRelationships function
        const newParentKey = parentKey ? `${parentKey}.${key}` : key
        if (included && includeEntities.includes(newParentKey)) {
            if (Array.isArray(relationships[key].data)) {
                const retVal = included
                    .filter((item) => {
                        return relationships[key].data.some(
                            (relation) =>
                                relation.type === item.type &&
                                relation.id === item.id
                        )
                    })
                    .map((item) => {
                        // Avoid calling the recursive function if there is no 'include from include'
                        if (
                            item.relationships &&
                            includeEntities.includes(
                                parentKey ? `${parentKey}.${key}.` : `${key}.`
                            )
                        ) {
                            const itemRelationships = getRelationships(
                                item.relationships,
                                included,
                                includeEntities,
                                newParentKey
                            )

                            return {
                                ...item.attributes,
                                ...itemRelationships,
                                id: item.id,
                                entityType: item.type,
                            }
                        }
                        return {
                            ...item.attributes,
                            id: item.id,
                            entityType: item.type,
                            relationships: item.relationships,
                        }
                    })
                relationAttributes = { ...relationAttributes, [key]: retVal }
            } else {
                included.forEach((includedItem) => {
                    if (
                        includedItem.id === relationships[key].data.id &&
                        includedItem.type === relationships[key].data.type
                    ) {
                        // Avoid calling the recursive function if there is no 'include from include'
                        if (
                            includedItem.relationships &&
                            includeEntities.includes(
                                parentKey ? `${parentKey}.${key}.` : `${key}.`
                            )
                        ) {
                            const itemRelationships = getRelationships(
                                includedItem.relationships,
                                included,
                                includeEntities,
                                newParentKey
                            )

                            relationAttributes = {
                                ...relationAttributes,
                                [key]: {
                                    ...includedItem.attributes,
                                    ...itemRelationships,
                                    id: includedItem.id,
                                    entityType: includedItem.type,
                                },
                            }
                        } else {
                            relationAttributes = {
                                ...relationAttributes,
                                [key]: {
                                    ...includedItem.attributes,
                                    id: includedItem.id,
                                    entityType: includedItem.type,
                                    relationships: includedItem.relationships,
                                },
                            }
                        }
                    }
                })
            }
        } else {
            relationAttributes = {
                ...relationAttributes,
                [key]: relationships[key]['data'],
            }
        }
    })
    return relationAttributes
}

const formatResponseData = (data, includeEntities) => {
    let retVal = {
        data: null,
        meta: null,
    }

    let relationAttributes = null

    // GET ALL RECORDS
    if (Array.isArray(data['data'])) {
        retVal.data = []
        data['data'].forEach((item) => {
            if (item['relationships']) {
                relationAttributes = getRelationships(
                    item['relationships'],
                    data['included'],
                    includeEntities
                )
            } else {
                relationAttributes = null
            }
            retVal.data.push({
                id: item.id,
                entityType: item.type,
                ...item.attributes,
                ...relationAttributes,
            })
        })
        // GET SINGLE RECORD
    } else {
        if (data['data']['relationships']) {
            relationAttributes = getRelationships(
                data['data']['relationships'],
                data['included'],
                includeEntities
            )
        } else {
            relationAttributes = null
        }
        retVal.data = {
            id: data['data'].id,
            entityType: data['data'].type,
            ...data['data'].attributes,
            ...relationAttributes,
        }
    }

    if (data['meta']) {
        retVal.meta = { ...data['meta'] }
    }
    return retVal
}

const isEmptyAttribute = (value) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'string' && value.trim() === '') ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (Array.isArray(value) && value.length === 0)
    )
}

const removeEmptyAttributes = (obj) => {
    for (let prop in obj) {
        if (typeof obj[prop] === 'object' && !isEmptyAttribute(obj[prop])) {
            obj[prop] = removeEmptyAttributes(obj[prop])
        }
        if (isEmptyAttribute(obj[prop])) {
            delete obj[prop]
        }
    }
    return obj
}

const replaceEmptyStringsWithNull = (obj) => {
    for (let prop in obj) {
        if (typeof obj[prop] === 'object') {
            obj[prop] = replaceEmptyStringsWithNull(obj[prop])
        } else if (Array.isArray(obj[prop])) {
            for (let i = 0; i < obj[prop].length; i++) {
                obj[prop][i] = replaceEmptyStringsWithNull(obj[prop][i])
            }
        } else {
            obj[prop] =
                typeof obj[prop] === 'string' && obj[prop].trim() === ''
                    ? null
                    : obj[prop]
        }
    }
    return obj
}

export {
    formatUrl,
    formatRequestData,
    formatResponseData,
    removeEmptyAttributes,
}
