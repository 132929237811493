import React from 'react'
import { useField } from 'formik'

import TextArea, { TextAreaMainPropTypes } from 'components/TextArea'

const TextAreaField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { setValue } = helpers

    const hasError = meta.touched && meta.error

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    const defaultProps = {}
    if (!props.label) {
        defaultProps.label = 'form.label.' + props.name
    }

    if (!props.placeholder) {
        defaultProps.placeholder = 'form.placeholder.' + props.name
    }

    return (
        <TextArea
            {...field}
            {...props}
            {...defaultProps}
            onChange={handleChange}
            error={hasError}
        />
    )
}

TextAreaField.propTypes = {
    ...TextAreaMainPropTypes,
}

export default TextAreaField
