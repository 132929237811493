import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useFixedOnScroll from 'hooks/useFixedOnScroll'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import FieldError from './FieldError'

const StepButtons = ({
    backlabel,
    handleBack,
    nextlabel,
    handleNext,
    canSkip,
    skipLabel,
    handleSkip,
    children,
    disableNext,
    error,
    noBorder,
}) => {
    const t = useTranslate()

    const { fixedComponents } = useFixedOnScroll()

    return (
        <div
            className={`${
                !noBorder
                    ? `-diveRecordFooter ${fixedComponents ? '-active' : ''}`
                    : ''
            }`}
        >
            <div className="_wr ">
                <div className="_w">
                    <div
                        className={`space-between -pt20 -pb20 m-step__buttons _12  ${
                            error ? '-withError' : ''
                        }`}
                    >
                        {!!error && (
                            <div className="-mb20 -errorMesage">
                                <FieldError
                                    error={t(error)}
                                    className={'-boxError'}
                                />
                            </div>
                        )}
                        <div className="-backButton">
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                type={BUTTON_TYPE.BUTTON}
                                label={backlabel}
                                onClick={handleBack}
                                buttonSize={BUTTON_SIZE.LARGE}
                            />
                        </div>
                        {canSkip && (
                            <div className="-middleButton">
                                <Button
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label={skipLabel}
                                    icon={ICONS.ARROW_RIGHT}
                                    iconPosition={ICON_POSITION.RIGHT}
                                    iconColor={COLORS.LIGHT_BLUE}
                                    onClick={handleSkip}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                />
                            </div>
                        )}
                        <div className="-nextButton">
                            {children}
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.BUTTON}
                                label={nextlabel}
                                onClick={handleNext}
                                buttonSize={BUTTON_SIZE.LARGE}
                                disabled={disableNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

StepButtons.propTypes = {
    backlabel: PropTypes.string,
    handleBack: PropTypes.func,
    nextlabel: PropTypes.string,
    handleNext: PropTypes.func,
    canSkip: PropTypes.bool,
    skipLabel: PropTypes.string,
    handleSkip: PropTypes.func,
    disableNext: PropTypes.bool,
    error: PropTypes.string,
    noBorder: PropTypes.bool,
}

StepButtons.defaultProps = {
    backlabel: 'button.back',
    nextlabel: 'button.next',
    skipLabel: 'button.skipStep',
    canSkip: false,
    disableNext: false,
    noBorder: false,
}

export default StepButtons
