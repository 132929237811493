import React from 'react'

const CreatePersonalAccount = () => {

    return (
        <div className={'guidde'}>
            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to create a personal account on Skill N Depth</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/bkRWfmSh42yL78uYbs9Nnp"
                        title="How to create a personal account on Skill N Depth"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen={true}
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Welcome to Skill N Depth! We're excited to have you on board. Creating
                        your personal account is quick and easy. Just follow these simple steps,
                        and you'll be all set to start exploring everything our platform has to
                        offer. Open your favorite web browser and head over to our site. This is
                        where your Skill N Depth journey begins!
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    Go to{" "}
                    <a href="https://app.skillndepth.com" target="_blank">
                        app.skillndepth.com
                    </a>
                </h3>{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Click "Sign Up"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’re on the app, look for the "Sign Up" button. Give it a click to
                        begin creating your account.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FngYeJUQLTZ4hf6gL25sykV_doc.png?alt=media&token=32e20a59-9b5d-4b5e-b6dc-c48e3a45d2a9"
                    alt="Click 'Sign Up'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Click and enter email address
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        In the sign-up form, click on the email field and type in your email
                        address. Make sure it’s one you use regularly, as this is where we’ll send
                        important information.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2Ffd8QwPP6BUzNE52yzV4qZx_doc.png?alt=media&token=4bf9fa2b-537a-4a4d-9d25-2ff77fb8f328"
                    alt="Click and enter email address"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Click and enter password
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, click on the password field. Choose a strong password that you’ll
                        remember—think of something unique and safe.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2Fb4xJTagJs6qxEk1sNLC6vQ_doc.png?alt=media&token=a8142b90-0447-40aa-85d8-101d058af051"
                    alt="Click and enter password"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Confirm your password
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        To avoid any typos, we’ll ask you to enter your password again in the
                        "Confirm Password" field. Just type it in one more time.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FpjiVM3wBQyo6b7JQVK9R6R_doc.png?alt=media&token=3cb0da1e-e7aa-49a4-a2cb-2d36051fc451"
                    alt="Confirm your password"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Click "Agree and Join"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once your details are in, review the terms of service (if you’d like),
                        then click on the "Agree and Join" button. Welcome aboard!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FaRbCc31PRTp8LSxeLtc4Wo_doc.png?alt=media&token=92bb72a9-f798-4600-b084-73abe45dc9ba"
                    alt="Click 'Agree and Join'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Enter verification code
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now, check your inbox. We’ve sent you a verification email—just click on
                        the link inside to verify your account. This helps keep your account
                        secure.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FqQ3rsDfhzJDUshBF6ksQZz_doc.png?alt=media&token=afbc9496-3a11-4b88-80ef-cfa966455fda"
                    alt="Enter verification code"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Select your account type
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        The next item is selecting whether you want to create an individual or an
                        Organization account. If you are the one who is diving select individual,
                        if you are looking to hire professionals to work for you select
                        Organization account type
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F8i3gFy394k9eaw8QFDgcGV_doc.png?alt=media&token=68fcf37b-07c3-4f58-bdf2-43d51f2766be"
                    alt="Select your account type"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        The next item is selecting whether you want to create an individual or an
                        Organization account. If you are the one who is diving select individual,
                        if you are looking to hire professionals to work for you select
                        Organization account type
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FmhEGz8nDQrGX6RCRdXXbqn_doc.png?alt=media&token=bf2a4261-58e9-472a-baff-a10686799b8d"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Enter your name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Enter Your First Name
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FvHg2h7gT3rmwAGfeC9K61G_doc.png?alt=media&token=0b129e7d-0e14-492b-bc70-f6f27bd60a9c"
                    alt="Enter your name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Enter your last name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Enter Your Last Name
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FiAdDuD3RWUekEwzzxXsKqc_doc.png?alt=media&token=25ae6afe-1f08-4f19-a640-acdc1ab8444f"
                    alt="Enter your last name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click "Next" to proceed to the next part of the setup.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FnVsbjC7DJXKYAPy1hDJX2f_doc.png?alt=media&token=fc2a435f-3f77-45d8-8351-7c58e7418b0f"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Select your country and click next
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        When prompted, click on the country field and select where you’re from
                        (e.g., "Canada"). We’re a global community, and it’s great to know where
                        our members are from!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F98mbgMkAqRtc4LuhjypYM4_doc.png?alt=media&token=94d2f82f-50b3-4ad1-ab78-f6a644251d06"
                    alt="Select your country and click next"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    13. Select all your roles
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Enter your job title in the provided field (e.g., "Diver"). This helps us
                        tailor the platform to your needs.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FvkRWymzGKBFuPpXJSDeJq8_doc.png?alt=media&token=7d96257b-1f3d-4597-a04b-1bb245b6b15a"
                    alt="Select all your roles"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    14. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click on "Next"
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F8T4Tk2T6YBgDnVzVjY8JHG_doc.png?alt=media&token=829c2679-d971-4015-b568-931decaf20ed"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    15. Upload your photo
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Let’s put a face to the name! Click on "Upload new photo" and choose your
                        best picture to upload as your profile photo.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2Fpmp85yLnNUyyQJnje9BKVk_doc.png?alt=media&token=504c140b-9fd2-48bc-b25a-6570c25239e6"
                    alt="Upload your photo"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    16. Click "Finish"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once everything’s set, click the "Finish" button. And that’s it—you’re all
                        done!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F6qJVC9PRoWi2RLVMFhW9Go_doc.png?alt=media&token=715f711d-2bd5-4e7b-a9c4-fff69492f35e"
                    alt="Click 'Finish'"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Thank you for beeing a member of Skill N Depth
                    </p>
                </div>
            </div>

        </div>
    )
}

export default CreatePersonalAccount
