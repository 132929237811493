import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import FormalEducationForm from './FormalEducationForm'

const CreateFormalEducation = ({
    open,
    setOpen,
    fetchData,
    showActions,
    refetchData,
}) => {
    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.FORMAL_EDUCATION, formData, true)
        fetchData()
        showActions && refetchData && refetchData()
    }
    return (
        <FormalEducationForm
            title="general.addFormalEducation"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

CreateFormalEducation.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
}

CreateFormalEducation.defaultProps = {
    showActions: false,
}

export default CreateFormalEducation
