import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

const ScubaDivingBadge = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                d="M24.3715 0.11713C24.6416 -0.0390432 24.9743 -0.0390434 25.2443 0.11713L46.1023 12.18C46.3723 12.3362 46.5387 12.6248 46.5387 12.9371V37.0629C46.5387 37.3752 46.3723 37.6638 46.1023 37.82L25.2443 49.8829C24.9743 50.039 24.6416 50.039 24.3715 49.8829L3.51354 37.82C3.2435 37.6638 3.07715 37.3752 3.07715 37.0629V12.9371C3.07715 12.6248 3.2435 12.3362 3.51354 12.18L24.3715 0.11713Z"
                fill={COLORS.LIGHT_BLUE}
            />
            <g filter="url(#filter0_i_21877_20906)">
                <path
                    d="M24.2297 4.18089C24.5873 3.97386 25.0283 3.97386 25.3859 4.18089L42.5013 14.0898C42.8576 14.2961 43.077 14.6766 43.077 15.0884V34.9117C43.077 35.3234 42.8576 35.704 42.5013 35.9103L25.3859 45.8192C25.0283 46.0262 24.5873 46.0262 24.2297 45.8192L7.1143 35.9103C6.75797 35.704 6.53857 35.3234 6.53857 34.9117V15.0884C6.53857 14.6766 6.75797 14.2961 7.1143 14.0898L24.2297 4.18089Z"
                    fill={COLORS.SILVER}
                />
            </g>
            <mask
                id="mask0_21877_20906"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="6"
                y="3"
                width="38"
                height="44"
            >
                <path
                    d="M24.4224 4.06933C24.6608 3.9313 24.9548 3.9313 25.1932 4.06932L42.6932 14.2009C42.9308 14.3384 43.077 14.5921 43.077 14.8666V35.1335C43.077 35.4079 42.9308 35.6616 42.6932 35.7992L25.1932 45.9307C24.9548 46.0688 24.6608 46.0688 24.4224 45.9307L6.92239 35.7992C6.68484 35.6616 6.53857 35.4079 6.53857 35.1335V14.8666C6.53857 14.5921 6.68484 14.3384 6.92239 14.2009L24.4224 4.06933Z"
                    fill={COLORS.DARK_BLUE_CUSTOM}
                />
            </mask>
            <g mask="url(#mask0_21877_20906)">
                <path
                    opacity="0.5"
                    d="M1.75516 12.7486L-4.86747 15.6233C-5.14141 15.7422 -5.40134 15.891 -5.6425 16.0672L-7.21671 17.2168C-8.20861 17.9411 -8.40081 19.3442 -7.64023 20.3086C-7.04496 21.0634 -6.02436 21.3356 -5.13037 20.9822C-2.61265 19.987 0.825897 18.6623 1.5651 18.5093C2.46901 18.3222 5.99369 17.1343 7.96781 17.1839C15.1953 16.9969 14.8357 20.9514 21.3388 20.111C22.9256 19.906 24.2548 19.413 25.3894 18.7707C30.278 16.0029 34.9184 13.4689 40.4234 14.5892C44.8002 14.8614 49.3923 12.379 49.2676 11.7764C49.1116 11.0231 47.7346 8.16604 47.358 8.244C47.1962 8.27749 44.6614 7.274 41.8324 6.29214C37.6456 4.83906 33.2506 6.00711 29.7708 8.75145C25.5232 12.1013 19.9591 13.2847 14.7159 11.9534L13.9697 11.7639C9.90142 10.731 5.6054 11.0773 1.75516 12.7486Z"
                    fill={COLORS.DARK_BLUE_20}
                />
                <path
                    opacity="0.5"
                    d="M45.7192 30.3659L52.6978 32.2158C52.9865 32.2923 53.2658 32.4005 53.5306 32.5385L55.2593 33.4393C56.3486 34.0068 56.7488 35.3653 56.1414 36.4328C55.6659 37.2682 54.6976 37.6902 53.7608 37.4748C51.1224 36.8682 47.5241 36.0736 46.7703 36.0331C45.8486 35.9835 42.1857 35.3372 40.2413 35.682C33.0674 36.5801 34.0155 40.4361 27.4599 40.5797C25.8603 40.6147 24.4722 40.3265 23.2542 39.8614C18.0061 37.8574 13.0383 36.0473 7.76339 37.9798C3.47676 38.9048 -1.43548 37.1385 -1.40244 36.524C-1.36113 35.7559 -0.427788 32.7247 -0.0437294 32.7454C0.12122 32.7542 2.47706 31.3823 5.12706 29.9876C9.04886 27.9235 13.5692 28.4199 17.421 30.6118C22.1225 33.2873 27.8011 33.6236 32.7856 31.5218L33.4951 31.2226C37.3626 29.5917 41.662 29.2904 45.7192 30.3659Z"
                    fill={COLORS.DARK_BLUE_20}
                />
            </g>
            <path
                d="M16.9355 29.4667C17.1936 30.757 17.9032 32.1549 18.2258 32.6925C18.9634 34.3591 21.2568 37.6923 24.5305 37.6923C27.7708 37.6923 30.4536 34.2159 31.4224 32.4246C31.4425 32.3874 31.4646 32.3531 31.4893 32.3188C32.1213 31.4412 32.3512 30.2187 32.4057 29.4824C32.4218 29.2659 32.5075 29.0587 32.6552 28.8995C33.9961 27.454 34.2414 26.2067 34.2414 25.5957V22.4363C34.2414 22.3923 34.2347 22.3486 34.2194 22.3074C33.9554 21.5956 33.1804 20.6757 32.8189 20.2119C32.7682 20.1469 32.7406 20.0695 32.7354 19.9871C32.575 17.4765 30.7687 12.6924 24.6775 12.6924C18.4839 12.6924 16.5054 17.8537 16.2903 20.4344C15.3813 21.1162 15.2394 22.0148 15.0716 22.5133C15.0311 22.6338 15 22.7586 15 22.8857V26.108C15 26.196 15.014 26.2836 15.0427 26.3667C15.5582 27.8566 16.3079 29.0483 16.9355 29.4667Z"
                fill={COLORS.LIGHT_BLUE}
            />
            <rect
                x="24.1665"
                y="12.6923"
                width="1.18247"
                height="5.91237"
                fill="white"
            />
            <path
                d="M19.7654 20.4342C18.7976 20.4342 16.7331 21.0149 16.2169 23.3375C16.1094 24.3052 16.1524 26.4988 17.1847 27.531C17.589 28.0163 18.5529 28.7569 19.7745 28.765C20.6391 28.7707 21.3356 28.1425 21.8698 27.4626L23.9604 24.8019C24.2881 24.3847 24.92 24.3847 25.2478 24.8019L27.3384 27.4626C27.8726 28.1425 28.5691 28.7707 29.4337 28.765C30.6552 28.7569 31.6191 28.0163 32.0235 27.531C33.0557 26.4988 33.0988 24.3052 32.9912 23.3375C32.4751 21.0149 30.4106 20.4342 29.4428 20.4342H19.7654Z"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M19.7654 20.4342C18.7976 20.4342 16.7331 21.0149 16.2169 23.3375C16.1094 24.3052 16.1524 26.4988 17.1847 27.531C17.589 28.0163 18.5529 28.7569 19.7745 28.765C20.6391 28.7707 21.3356 28.1425 21.8698 27.4626L23.9604 24.8019C24.2881 24.3847 24.92 24.3847 25.2478 24.8019L27.3384 27.4626C27.8726 28.1425 28.5691 28.7707 29.4337 28.765C30.6552 28.7569 31.6191 28.0163 32.0235 27.531C33.0557 26.4988 33.0988 24.3052 32.9912 23.3375C32.4751 21.0149 30.4106 20.4342 29.4428 20.4342H19.7654Z"
                stroke="white"
                strokeWidth="1.15385"
            />
            <path
                d="M27.2131 32.9613C27.2131 34.4243 26.0272 35.6102 24.5642 35.6102C23.1013 35.6102 21.9153 34.4243 21.9153 32.9613C21.9153 31.4984 23.1013 30.3124 24.5642 30.3124C26.0272 30.3124 27.2131 31.4984 27.2131 32.9613Z"
                stroke={COLORS.DARK_BLUE}
                strokeWidth="1.15385"
            />
            <ellipse
                cx="24.5638"
                cy="32.9615"
                rx="1.29033"
                ry="1.29033"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M24.3737 26.0708L23.3857 28.5409C23.3319 28.6753 23.4309 28.8215 23.5757 28.8215H25.5518C25.6966 28.8215 25.7956 28.6753 25.7418 28.5409L24.7538 26.0708C24.6852 25.8992 24.4424 25.8992 24.3737 26.0708Z"
                fill="white"
            />
            <path
                d="M34.5637 18.8213L33.9892 18.7691L33.9868 18.7952V18.8213H34.5637ZM31.6772 12.2257C31.4196 12.0383 31.0587 12.0952 30.8713 12.3529C30.6839 12.6106 30.7409 12.9714 30.9986 13.1588L31.6772 12.2257ZM31.0415 33.6859C32.068 33.6859 33.1029 33.0832 33.8601 32.1922C34.6286 31.2882 35.1668 30.0285 35.1668 28.5928H34.013C34.013 29.7377 33.5835 30.7361 32.981 31.445C32.3673 32.167 31.6279 32.532 31.0415 32.532V33.6859ZM35.1668 28.5928C35.1668 27.3012 35.1603 25.4754 35.1537 23.6646C35.1472 21.853 35.1407 20.0566 35.1407 18.8213H33.9868C33.9868 20.0592 33.9934 21.8582 33.9999 23.6688C34.0065 25.4804 34.013 27.3037 34.013 28.5928H35.1668ZM35.1383 18.8736C35.2614 17.5194 34.7039 14.4269 31.6772 12.2257L30.9986 13.1588C33.6494 15.0867 34.0811 17.7577 33.9892 18.7691L35.1383 18.8736Z"
                fill={COLORS.DARK_BLUE}
            />
            <path
                d="M33.1558 23.8647H35.4078C35.7196 23.8647 35.9723 24.1175 35.9723 24.4293V24.8263C35.9723 25.1381 35.7196 25.3909 35.4078 25.3909H33.1558V23.8647Z"
                fill="white"
                stroke="white"
                strokeWidth="0.409318"
            />
            <path
                d="M27.083 31.859H30.4804C30.9053 31.859 31.2497 32.2034 31.2497 32.6282V33.5898C31.2497 34.0146 30.9053 34.359 30.4804 34.359H27.083V31.859Z"
                fill={COLORS.DARK_BLUE}
            />
            <defs>
                <filter
                    id="filter0_i_21877_20906"
                    x="6.53857"
                    y="4.02563"
                    width="36.9232"
                    height="42.3335"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.384615" dy="0.384615" />
                    <feGaussianBlur stdDeviation="0.192308" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_20906"
                    />
                </filter>
            </defs>
        </svg>
    )
}

ScubaDivingBadge.propTypes = {
    size: PropTypes.number,
}

ScubaDivingBadge.defaultProps = {
    size: 50,
}

export default ScubaDivingBadge
