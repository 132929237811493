import React, { useContext } from 'react'

import { AlertContext } from 'contexts/AlertContext'

import Icon from 'components/Icon'

const Alert = () => {
    const {
        alert: { message, type, position },
        setAlert,
    } = useContext(AlertContext)

    if (!message) return null

    return (
        <div className={`a-alert -${type} -${position}`}>
            <div className="a-alert__left">
                <Icon name={`alert_${type}`} />
                <div className="-text">
                    <span className="-type a-bodyTextMedium">{type}</span>
                    <span className="-message a-captionsTextRegular">
                        {message}
                    </span>
                </div>
            </div>
            <span
                className="removeItemIcon -large"
                onClick={() => setAlert('')}
            ></span>
        </div>
    )
}

export default Alert
