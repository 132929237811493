import Breadcrumbs from 'components/Breadcrumbs'
import ROUTES from 'constants/routes'
import React, { useState } from 'react'
import ICONS from 'constants/icons'
import AdciFormSearch from 'screens/common/adci/components/AdciFormSearch'
import AdciPhotoSearch from 'screens/common/adci/components/AdciPhotoSearch'
import ToggleButtons from 'components/ToggleButtons'
import { useTranslate } from 'react-polyglot'

const AdciSearch = () => {
    const t = useTranslate()
    const [isBasicSearch, setIsBasicSearch] = useState(true)

    const toggleButtons = [
        {
            label: 'adci.basicSearch',
            clickHandler: () => {
                setIsBasicSearch(true)
            },
        },
        {
            label: 'adci.imageSearch',
            clickHandler: () => {
                setIsBasicSearch(false)
            },
        },
    ]

    const breadcrumbs = [
        {
            label: 'general.homepage',
            to: ROUTES.HOME,
            icon: ICONS.HOMEPAGE_BREADCRUMB,
        },
    ]

    return (
        <div>
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            <div className="m-boxes__main -fullWidth">
                <div className="_wr">
                    <div className="_w justify-center m-adciContainer">
                        <div className="aligned-center column -mt100 m-adciContainer__wrapper">
                            <h3 className="-mb20">
                                {t('adci.checkCertificates')}
                            </h3>
                            <div className="-mb20">
                                <ToggleButtons buttons={toggleButtons} />
                            </div>
                            {isBasicSearch ? (
                                <AdciFormSearch />
                            ) : (
                                <AdciPhotoSearch />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdciSearch
