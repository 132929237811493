import React, { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'

import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { BUTTON_SIZE, BUTTON_STATUS, ICON_SIZE } from 'constants/enums'

import InputField from 'components/formFields/InputField'
import { TextAreaField } from 'components/formFields'
import Button from 'components/Button'

const RejectionContext = ({
    setCreatingTemplate,
    template,
    setEditingNewChanges,
}) => {
    const formikContext = useFormikContext()
    const { values } = formikContext

    useEffect(() => {
        setEditingNewChanges &&
            (template.title !== values.title || template.body !== values.body
                ? setEditingNewChanges(true)
                : setEditingNewChanges(false))

        setCreatingTemplate &&
            setCreatingTemplate((template) => {
                return {
                    ...template,
                    title: values.title,
                    body: values.body,
                }
            })
    }, [values.title, values.body])
}

const RejectionForm = ({
    template = {
        id: null,
        title: '',
        body: '',
    },
    setCreatingTemplate = null,
    cancelTemplate = null,
    deleteTemplate = null,
    saveTemplate = null,
    setEditingNewChanges = false,
    newTemplate,
}) => {
    const t = useTranslate()

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        title: Yup.string().required(requiredMessage),
        body: Yup.string().required(requiredMessage),
    })

    const handleSubmit = (values) => {
        saveTemplate(values)
    }

    return (
        <Formik
            initialValues={template}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ values, handleSubmit }) => {
                return (
                    <Form>
                        <RejectionContext
                            setCreatingTemplate={setCreatingTemplate}
                            template={template}
                            setEditingNewChanges={setEditingNewChanges}
                        />

                        <div className="m-rejectionForm__infoBox a-mediumText -mb10">
                            <span>{t('general.choosePlaceholders')}</span>
                            <ul className="list">
                                <li className="a-mediumText">{`${t(
                                    'listElements.forProjectName'
                                )} `}</li>
                                <li className="a-mediumText">{`${t(
                                    'listElements.forDiverName'
                                )} `}</li>
                                <li className="a-mediumText">
                                    {`${t('listElements.forProjectPosition')} `}
                                </li>
                            </ul>
                            <span>
                                {`
                                            Example: "Hello {diverName}, your
                                            application to {project} for
                                            {projectPosition} is rejected
                                            because...."`}
                            </span>
                        </div>
                        <div className="m-rejectionForm">
                            {template && (
                                <>
                                    <div className="m-rejectionForm__title aligned-center">
                                        <label className="a-bodyTextMedium">
                                            {t('form.label.onlyTitle')}:
                                        </label>
                                        <InputField
                                            name="title"
                                            required
                                            showLabel={false}
                                            showPlaceholder={false}
                                        />
                                    </div>
                                    <div className="m-rejectionForm__body">
                                        <TextAreaField
                                            name="body"
                                            required
                                            showLabel={false}
                                            showPlaceholder={false}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="template-actions-wrapper m-rejectionForm__actions space-between">
                                <div className="left aligned-center">
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        onClick={cancelTemplate}
                                        label="button.cancel"
                                        icon={ICONS.CLOSE}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconSize={ICON_SIZE.SIZE20}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                    />
                                </div>
                                <div className="right aligned-center">
                                    {deleteTemplate && (
                                        <Button
                                            btnClass={BUTTON_STATUS.TERTIARY}
                                            onClick={deleteTemplate}
                                            label="button.deleteTemplate"
                                            icon={ICONS.DELETE}
                                            iconColor={COLORS.RED}
                                            iconSize={ICON_SIZE.SIZE20}
                                            buttonSize={BUTTON_SIZE.XSMALL}
                                        />
                                    )}
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        onClick={() => handleSubmit(values)}
                                        label={
                                            template.id || newTemplate
                                                ? 'button.saveChanges'
                                                : 'button.saveAsTemplate'
                                        }
                                        icon={ICONS.SAVE}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconSize={ICON_SIZE.SIZE20}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default RejectionForm
