import React from 'react'
import { useField } from 'formik'

import RadioGroup, { RadioMainPropTypes } from 'components/RadioGroup'

const RadioGroupField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { setValue } = helpers

    const hasError = meta.touched && meta.error

    return (
        <RadioGroup
            {...field}
            {...props}
            setValue={setValue}
            error={hasError}
        />
    )
}

RadioGroupField.propTypes = {
    ...RadioMainPropTypes,
}

export default RadioGroupField
