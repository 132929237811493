import { useEffect, useState } from 'react'

const MESSAGE_TIME = 300

const useEmptyValueMessage = (condition, time = MESSAGE_TIME) => {
    const [showEmptyValueMessage, setShowEmptyValueMessage] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (condition) {
                setShowEmptyValueMessage(true)
            }
        }, time)

        return () => {
            clearTimeout(timer)
        }
    }, [condition])

    return { showEmptyValueMessage }
}

export default useEmptyValueMessage
