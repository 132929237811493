
export const getChannelFilters = (searchTerm, currentUserId) => {
    return {
        type: 'team',
        name: { $autocomplete: searchTerm },
        members: { $in: [currentUserId] }
    }
}

export const getDMFilters = (searchTerm, currentUserId) => {
    return {
        id: { $ne: currentUserId },
        $and: [
            { name: { $autocomplete: searchTerm } },
        ]
    }
}