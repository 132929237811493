import React from 'react'
import { useField } from 'formik'

import DateTime, { DateTimeMainPropTypes } from 'components/DateTime'

const DateTimeField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { setValue, setTouched } = helpers

    const hasError = meta.touched && meta.error

    return (
        <DateTime
            {...field}
            {...props}
            setValue={setValue}
            setTouched={setTouched}
            error={hasError}
        />
    )
}

DateTimeField.propTypes = {
    ...DateTimeMainPropTypes,
}

export default DateTimeField
