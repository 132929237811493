import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { checkExpiryDate, formatDate } from 'services/moment.service'

import { INFO_VALUE_TYPE } from 'constants/enums'

import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import InfoFiles from 'components/InfoFiles'

const CertificateCard = ({ item, showName }) => {
    const t = useTranslate()

    return (
        <div>
            <Info
                label="form.label.certificateIssuerName"
                value={item.certificateIssuerName}
            />
            {showName && (
                <Info label="form.label.certificateName" value={item.name} />
            )}
            <InfoRow>
                {item.issuedDate && (
                    <Info
                        label="form.label.issuedDate"
                        value={formatDate(item.issuedDate)}
                    />
                )}
                <Info
                    label="form.label.expiresDate"
                    value={
                        item.notExpire
                            ? t('form.label.notExpire')
                            : formatDate(item.expiresDate)
                    }
                    textColor={
                        item.notExpire
                            ? 'green'
                            : checkExpiryDate(item.expiresDate)
                    }
                />
            </InfoRow>
            {item.certificateNumber && (
                <Info
                    label="form.label.certificateNumber"
                    value={item.certificateNumber}
                />
            )}
            {item.certificateUrl && (
                <Info
                    label="form.label.certificateUrl"
                    value={item.certificateUrl}
                    valueType={INFO_VALUE_TYPE.CERTIFICATE_URL}
                />
            )}
            <InfoFiles
                label="general.trainingCertificateFiles"
                files={item.files}
            />
        </div>
    )
}

CertificateCard.propTypes = {
    item: PropTypes.object.isRequired,
    showName: PropTypes.bool,
}

CertificateCard.defaultProps = {
    showName: false,
}

export default CertificateCard
