import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getEntityByIdService, getEntityService } from 'services/entity.service'
import { setEntityFilters } from 'services/localStorage.service'

import useQueryParams from './useQueryParams'

const useFetchActiveFilters = (filters, filtersKey) => {
    const searchParams = useLocation().search

    const { filterParams } = useQueryParams()

    const [activeFilters, setActiveFilters] = useState({})
    const [isLoadingFilters, setIsLoadingFilters] = useState(false)

    useEffect(() => {
        const fetchFilters = async () => {
            setIsLoadingFilters(true)
            let filterValues = {}
            for (const key of Object.keys(filterParams)) {
                const entityType = filters[key]?.entityType
                const fetchAttribute = filters[key]?.fetchAttribute
                const value = filterParams[key]

                if (Array.isArray(value) && entityType && !fetchAttribute) {
                    const { data } = await getEntityService(entityType, {
                        id: value.map((item) => item.id),
                        pagination: false,
                    })
                    filterValues = { ...filterValues, [key]: data }
                } else if (
                    Array.isArray(value) &&
                    entityType &&
                    fetchAttribute
                ) {
                    const entityTypeArray = Array.isArray(entityType)
                        ? entityType
                        : [entityType]
                    const fetchAttributeArray = Array.isArray(fetchAttribute)
                        ? fetchAttribute
                        : [fetchAttribute]

                    for (const index in entityTypeArray) {
                        const { data } = await getEntityService(
                            entityTypeArray[index],
                            {
                                id: value.map(
                                    (item) => item[fetchAttributeArray[index]]
                                ),
                                pagination: false,
                            }
                        )
                        filterValues = {
                            ...filterValues,
                            [key]: value.map((item, i) => ({
                                ...item,
                                ...(filterValues[key]
                                    ? filterValues[key][i]
                                    : {}),
                                [fetchAttributeArray[index]]: data.find(
                                    (el) =>
                                        el.id.toString() ===
                                        item[
                                            fetchAttributeArray[index]
                                        ].toString()
                                ),
                            })),
                        }
                    }
                } else if (
                    typeof value === 'object' &&
                    value.id &&
                    entityType
                ) {
                    const { data } = await getEntityByIdService(
                        entityType,
                        value.id
                    )

                    filterValues = { ...filterValues, [key]: data }
                } else {
                    filterValues = { ...filterValues, [key]: value }
                }
            }
            setActiveFilters(filterValues)
            setIsLoadingFilters(false)
        }

        fetchFilters()
        setEntityFilters(filtersKey, searchParams)
    }, [searchParams])

    return {
        activeFilters,
        isLoadingFilters:
            isLoadingFilters ||
            (Object.keys(filterParams).length &&
                !Object.keys(activeFilters).length),
    }
}

export default useFetchActiveFilters
