import { useTranslate } from 'react-polyglot'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { useRef, useState, useEffect } from 'react'

import { GOOGLE_MAPS_API_KEY, MAP_ID, libraries } from 'constants/constants'

const mapContainerStyle = {
    width: '350vw',
    height: '350px',
}

const mapId = MAP_ID

const MapView = ({ geoLat, geoLong }) => {
    const t = useTranslate()

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: libraries,
        async: true,
    })

    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const [marker, setMarker] = useState(null)
    const [center, setCenter] = useState({ lat: 54.526, lng: 15.2551 }) // Default center
    const zoom = 3

    useEffect(() => {
        if (geoLat && geoLong) {
            setCenter({ lat: Number(geoLat), lng: Number(geoLong) })
        }
    }, [geoLat, geoLong])

    const onLoad = (map) => {
        mapRef.current = map
        setMap(map)

        if (geoLat && geoLong) {
            const markerPosition = { lat: Number(geoLat), lng: Number(geoLong) }
            const newMarker =
                new window.google.maps.marker.AdvancedMarkerElement({
                    position: markerPosition,
                    map: map,
                })
            setMarker(newMarker)
        }
    }

    const onUnmount = () => {
        if (marker) {
            marker.setMap(null)
            setMarker(null)
        }
        mapRef.current = null
        setMap(null)
    }

    if (loadError) {
        return <div>{t('general.errorLoadingMaps')}</div>
    }

    if (!isLoaded) {
        return <div>{t('general.loadingMaps')}</div>
    }

    return (
        <div className="_w _12 googleMaps">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={center}
                id={mapId}
                options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                    mapId: mapId,
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}
            ></GoogleMap>
        </div>
    )
}

export default MapView
