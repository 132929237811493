import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import useQueryParams from 'hooks/useQueryParams'

import ScrollableTabs from './ScrollableTabs'
import ColumnTabs from './ColumnTabs'

const TabNavigation = ({
    tabs,
    tabQueryParam,
    translate,
    useAsFilter,
    myProfile,
    columnView,
    scrollToTarget,
}) => {
    const { pathname } = useLocation()

    const queryParams = useQueryParams()
    const tab = useAsFilter
        ? queryParams.filterParams[tabQueryParam]
        : queryParams[tabQueryParam]

    // If there are queryParams, first we need to replace the value of the tab param if it exists
    // If we don't have a tab param in the queryParams, we have to add a tab param
    const getPath = (key, urlParams) => {
        if (urlParams) {
            const queryParamsWithNewTab = urlParams.replace(
                `${tabQueryParam}=${tab}`,
                `${tabQueryParam}=${key}`
            )
            const newQueryParams = useAsFilter
                ? queryParamsWithNewTab.replace(
                      `page=${queryParams.page}`,
                      `page=1`
                  )
                : queryParamsWithNewTab
            const newPath = `${pathname}${newQueryParams}${
                !newQueryParams.includes(`${tabQueryParam}=${key}`)
                    ? `&${tabQueryParam}=${key}`
                    : ''
            }`
            return newPath
        }
        return `${pathname}?${tabQueryParam}=${key}`
    }

    const activeTab =
        tabs.find((item) => item.key.toString() === tab?.toString()) || tabs[0]

    return (
        <Fragment>
            {columnView ? (
                <ColumnTabs
                    tabs={tabs}
                    getPath={getPath}
                    translate={translate}
                    activeTab={activeTab}
                    pathname={pathname}
                    myProfile={myProfile}
                    scrollToTarget={scrollToTarget}
                />
            ) : (
                <ScrollableTabs
                    tabs={tabs}
                    getPath={getPath}
                    translate={translate}
                    activeTab={activeTab}
                    myProfile={myProfile}
                />
            )}
        </Fragment>
    )
}

TabNavigation.propTypes = {
    tabs: PropTypes.array.isRequired,
    tabQueryParam: PropTypes.string,
    translate: PropTypes.bool,
    useAsFilter: PropTypes.bool,
    myProfile: PropTypes.bool,
    columnView: PropTypes.bool,
    scrollToTarget: PropTypes.bool,
}

TabNavigation.defaultProps = {
    tabQueryParam: 'tab',
    translate: true,
    useAsFilter: false,
    myProfile: false,
    columnView: false,
    scrollToTarget: true,
}

export default TabNavigation
