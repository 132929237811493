import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import CardHeader from 'components/card/CardHeader'
import CardFooter from 'components/card/CardFooter'

const LockOutEventList = ({
    data,
    fetchData,
    isLoading,
    setEditItem,
    hideActions,
    unitImperial,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.LOCK_OUT_EVENT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'lockOutEvent.deleteAreYouSure',
            title: 'lockOutEvent.delete',
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })

    if (isLoading && data.length === 0 && !hideActions) {
        return <CardPlaceholder />
    }

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            !hideActions ? '_12 _l6 -mt20' : 'fullWidth -mb20'
                        }`}
                    >
                        <CardContainer>
                            <CardHeader
                                item={item}
                                title={t('lockOutEvent.title')}
                                actions={
                                    hideActions
                                        ? []
                                        : [
                                              {
                                                  handleAction: setEditItem,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip: 'lockOutEvent.edit',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip:
                                                      'lockOutEvent.delete',
                                              },
                                          ]
                                }
                            />
                            <Info
                                label="form.label.lockOutTimeHours"
                                value={
                                    item.lockOutTimeHours +
                                    'h ' +
                                    item.lockOutTimeMinutes +
                                    'min'
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.breathingMixOxygen"
                                value={
                                    item.breathingMixOxygen + t('form.label.%')
                                }
                                withBorder
                                withSpaceBetween
                            />
                            {item.excursionShallowestDepth !== undefined && (
                                <Info
                                    label="form.label.excursionShallowestDepth"
                                    value={
                                        item.excursionShallowestDepth +
                                        unitOfMeasurementLabel
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.excursionDeepestDepth"
                                value={
                                    item.excursionDeepestDepth +
                                    unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.lockInTimeHours"
                                value={
                                    item.lockInTimeHours +
                                    'h ' +
                                    item.lockInTimeMinutes +
                                    'min'
                                }
                                withBorder
                                withSpaceBetween
                            />
                            {(item.bottomTimeElapsedHours !== undefined ||
                                item.bottomTimeElapsedMinutes !==
                                    undefined) && (
                                <Info
                                    label="form.label.bottomTimeElapsed"
                                    value={
                                        (item.bottomTimeElapsedHours || 0) +
                                        'h ' +
                                        (item.bottomTimeElapsedMinutes || 0) +
                                        'min'
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.comment && (
                                <Info
                                    label="form.label.comment"
                                    value={item.comment}
                                    column
                                />
                            )}
                            {!hideActions && (
                                <CardFooter withTopMargin>
                                    <Button
                                        label="lockOutEvent.view"
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition={ICON_POSITION.RIGHT}
                                        onClick={() => setEditItem(item)}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                    />
                                </CardFooter>
                            )}
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LockOutEventList
