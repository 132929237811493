import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import CompleteProfile from 'components/CompleteProfile'
import { PROFILE_STEPS } from '../../../../../constants/constants'

const ProfileCompletion = ({
    userID,
    user,
    myProfile,
    isClientOrganization,
    profileHash,
}) => {
    const { isSmallScreen } = useWindowDimensions()

    const completedSteps = PROFILE_STEPS.filter(
        ({ key }) => user.completionProgress[key] === 1
    )

    const stepsToComplete = PROFILE_STEPS.filter(
        ({ key }) => user.completionProgress[key] === 0
    )

    const allSteps = PROFILE_STEPS.filter(
        ({ key }) => key in user.completionProgress
    )

    if (!myProfile || isClientOrganization || stepsToComplete.length === 0)
        return null

    return (
        <div
            className={`${
                isSmallScreen ? 'm-boxes__main' : 'm-boxes__white'
            } -noPadding -mb20`}
        >
            <CompleteProfile
                title={'general.profileCompletion'}
                profilePage={true}
                showProgress={true}
                stepsToComplete={stepsToComplete}
                completedSteps={completedSteps}
                allSteps={allSteps}
                entityId={userID}
                currentUser={user}
                profileHash={profileHash}
            />
        </div>
    )
}

ProfileCompletion.propTypes = {
    user: PropTypes.object,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    isClientOrganization: PropTypes.bool,
    profileHash: PropTypes.string,
}

export default ProfileCompletion
